import React from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  disabled?: boolean;
  className?: string;
  icon: IconProp;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

export default function ButtonIcon({
  onClick,
  disabled,
  className = "pl-0 pr-0 pt-0 pb-0 show-on-hover",
  icon,
}: Props) {
  return (
    <Button
      disabled={disabled}
      variant="link"
      className={className}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
}
