import React from "react";
import { FormikErrors } from "formik";
import isObject from "lodash/isObject";
import { uniq } from "lodash";

type Props<T> = {
  errors: FormikErrors<T>;
};

export default function ErrorSummary<T>({ errors }: Props<T>) {
  const errorSummary = (e: FormikErrors<T>): string[] => {
    let result: string[] = [];
    Object.keys(e).forEach((errorKey) => {
      const errorValue = (e as Record<string, any>)[errorKey];
      if (isObject(errorValue)) {
        result = result.concat(errorSummary(errorValue as FormikErrors<T>));
      } else {
        result.push(errorValue);
      }
    });

    return result;
  };

  const getErrors = (e: FormikErrors<T>) => {
    const result = uniq(errorSummary(e));
    return result.map((error, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`error-${index}`}>{error}</div>
    ));
  };

  return errors && Object.keys(errors).length ? (
    <div className="alert alert-danger" role="alert">
      {getErrors(errors)}
    </div>
  ) : null;
}
