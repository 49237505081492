import React from "react";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Searchable from "../../Searchable/Searchable";
import { useBusinessContext } from "../../Context/BusinessContext";
import ScheduleTable from "./ScheduleTable";
import Loader from "../../common/Loader";
import HeaderPortal from "../../Portal/HeaderPortal";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import { useAppRouter } from "../../hooks/useAppRouter";

const SchedulesQuery = graphql`
  query Schedules_InternalQuery(
    $businessId: ID!
    $searchValue: String!
    $pageSize: Int!
    $after: String
  ) {
    ...ScheduleTable_viewer
  }
`;

interface MatchParams {
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

export default function Schedules(properties: Props) {
  const { environment } = useBusinessContext();
  const [searchValue, debouncedSearchValue, onSearchChange] =
    useDebouncedValue("");

  const {
    params: { business_id: businessId, stack_id: stackId },
  } = useAppRouter<MatchParams>();

  return (
    <>
      <HeaderPortal as="span" elementId="sub-header-portal">
        <span className="ml-2 mr-2">&gt;</span>
        <span>Schedules</span>
      </HeaderPortal>

      <Row className="my-2">
        <Col>
          <Searchable
            className="schedules"
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            placeholder="search schedules..."
          />
        </Col>
        <Col md="auto">{/* primary button */}</Col>
      </Row>

      <Card body>
        <QueryRenderer
          environment={environment}
          query={SchedulesQuery}
          variables={{
            businessId,
            searchValue: debouncedSearchValue ?? "",
            pageSize: 50,
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>Error!</div>;
            }
            if (!props) {
              return <Loader />;
            }
            return (
              <ScheduleTable
                {...properties}
                stackId={stackId}
                businessId={businessId}
                searchValue={debouncedSearchValue}
                viewer={props as any}
              />
            );
          }}
        />
      </Card>
    </>
  );
}
