/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ScheduleTable_viewer = {
    readonly schedules: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly scheduleName: string;
                readonly isGroup: boolean;
            };
        }>;
        readonly pageInfo: {
            readonly hasNextPage: boolean;
            readonly endCursor: string | null;
        };
    };
    readonly " $refType": "ScheduleTable_viewer";
};
export type ScheduleTable_viewer$data = ScheduleTable_viewer;
export type ScheduleTable_viewer$key = {
    readonly " $data"?: ScheduleTable_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ScheduleTable_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "businessId"
    },
    {
      "kind": "RootArgument",
      "name": "pageSize"
    },
    {
      "kind": "RootArgument",
      "name": "searchValue"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "schedules"
        ]
      }
    ]
  },
  "name": "ScheduleTable_viewer",
  "selections": [
    {
      "alias": "schedules",
      "args": [
        {
          "kind": "Variable",
          "name": "businessId",
          "variableName": "businessId"
        },
        {
          "kind": "Literal",
          "name": "isGroup",
          "value": false
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "searchValue"
        },
        {
          "kind": "Literal",
          "name": "sort",
          "value": [
            {
              "field": "scheduleName",
              "order": "asc"
            }
          ]
        }
      ],
      "concreteType": "ScheduleConnection",
      "kind": "LinkedField",
      "name": "__ScheduleTable_schedules_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ScheduleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Schedule",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "scheduleName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isGroup",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InternalQuery",
  "abstractKey": null
};
(node as any).hash = '7d54b39a5b138ee61f32ad9140cdc843';
export default node;
