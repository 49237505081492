import React from "react";
import Row from "react-bootstrap/Row";
import { useFormikContext } from "formik";
import { startCase, isFunction, isBoolean } from "lodash";
// eslint-disable-next-line import/no-cycle
import Field from "./Field";
import GroupHeader from "./GroupHeader";
import SubGroupHeader from "./SubGroupHeader";
import {
  IProperty,
  ComponentRule,
  GroupName,
  SubGroupName,
  CommonComponentRule,
  FormGroupProperties,
} from "./models";
import CommonComponents from "./CommonComponents";
import { useDynamicFormContext } from "../Context/DynamicFormContext";

export type DisabledRule = boolean | ((x: any) => boolean);

type Props = FormGroupProperties & {
  fieldKey?: string;
  fields: Map<GroupName, Map<SubGroupName, IProperty[]>>;
  disabled?: boolean;
  hideGroupName?: boolean;
  hideSubGroupName?: boolean;
};

export default function DynamicInputGroup({
  fields = new Map<GroupName, Map<SubGroupName, IProperty[]>>(),
  fieldKey,
  disabled,
  hideGroupName,
  hideSubGroupName,
  ...componentRuleProperties
}: Props) {
  const dynamicFormContext = useDynamicFormContext();
  const { values } = useFormikContext();

  const formsFields: JSX.Element[] = [];
  fields.forEach(
    (subGroups: Map<SubGroupName, IProperty[]>, fieldGroupName: GroupName) => {
      const subGroupsFields: JSX.Element[] = [];
      const displayGroupName = fieldGroupName || "";

      subGroups.forEach(
        (properties: IProperty[], fieldSubGroupName: SubGroupName) => {
          const displayedSubGroupName = fieldSubGroupName;
          subGroupsFields.push(
            <fieldset
              className="sub-group"
              key={`${displayGroupName}-${displayedSubGroupName}`}
            >
              {!hideSubGroupName && displayedSubGroupName && (
                <SubGroupHeader>{displayedSubGroupName}</SubGroupHeader>
              )}
              <Row>
                {properties.map((field: IProperty) => {
                  const {
                    key,
                    name,
                    type,
                    label,
                    component: componentName,
                    description,
                  } = field;

                  let mergedComponentRuleProperties = {
                    ...componentRuleProperties,
                  };

                  // check if component name is registered in CommonComponents
                  if (componentName != null) {
                    const commonDefinition: CommonComponentRule =
                      CommonComponents[componentName];

                    if (
                      commonDefinition &&
                      commonDefinition.component != null
                    ) {
                      // merge with common component rules
                      mergedComponentRuleProperties = {
                        ...mergedComponentRuleProperties,
                        ...commonDefinition,
                      };
                    }
                  }

                  // check if component is defined in custom componentRules
                  if (dynamicFormContext.componentRules != null) {
                    const profileComponentRule: ComponentRule =
                      dynamicFormContext.componentRules[key];

                    if (profileComponentRule != null) {
                      // Property is defined in componentRules
                      const { disabled: profileComponentRuleDisabled } =
                        profileComponentRule;

                      // Process disabled option
                      const disabledRule = {
                        disabled,
                      };
                      if (isFunction(profileComponentRuleDisabled)) {
                        disabledRule.disabled =
                          disabled || profileComponentRuleDisabled(values);
                      } else if (isBoolean(profileComponentRuleDisabled)) {
                        disabledRule.disabled =
                          disabled || profileComponentRuleDisabled;
                      }

                      mergedComponentRuleProperties = {
                        ...mergedComponentRuleProperties,
                        ...profileComponentRule,
                        ...disabledRule,
                      };
                    }
                  }

                  const safeFieldKey = fieldKey ? `${fieldKey}.${name}` : key;

                  return (
                    type && (
                      <Field
                        key={key}
                        schemaFieldType={type}
                        fieldKey={safeFieldKey}
                        label={label || startCase(name)}
                        description={description}
                        fields={fields}
                        {...mergedComponentRuleProperties}
                      />
                    )
                  );
                })}
              </Row>
            </fieldset>,
          );
        },
      );

      formsFields.push(
        <fieldset className="group" key={displayGroupName}>
          {!hideGroupName && displayGroupName && (
            <GroupHeader>{displayGroupName}</GroupHeader>
          )}
          {subGroupsFields}
        </fieldset>,
      );
    },
  );

  return <>{formsFields}</>;
}
