import React from "react";
import { graphql, Environment, useFragment } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import UpdateScheduleMutation from "../../mutations/UpdateScheduleMutation";
import { UpdateScheduleMutationResponse } from "../../mutations/__generated__/UpdateScheduleMutation.graphql";
import properties from "../../../../data/schedule-settings.json";
import FormLayout from "../../../Form/FormLayout";
import FormLayoutFooter from "../../../Form/FormLayoutFooter";
import {
  IProperty,
  punchSlipModeOptions,
  daysOfWeekOptions,
  restScreenThemeOptions,
  ComponentRule,
  payFrequencyOptions,
  PayFrequncies,
} from "../../../Form/models";
import DynamicSelect from "../../../Form/DynamicSelect";
import PayDates from "../../../Form/PayDates";
import { useScheduleContext } from "../../../Context/ScheduleContext";
import DynamicInputGroup from "../../../Form/DynamicInputGroup";
import {
  getFieldsByInputObjectName,
  getSettingsByGroup,
} from "../../../Form/formUtilities";
import {
  ScheduleProfileForm_schedule,
  ScheduleProfileForm_schedule$key,
} from "./__generated__/ScheduleProfileForm_schedule.graphql";
import ScheduleTimeClockPreShiftMessages from "./ScheduleTimeclockPreShiftMessages";

interface MatchParams {
  business_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  schedule: ScheduleProfileForm_schedule$key;
  environment: Environment;
};

export default function ScheduleProfileForm(props: Props) {
  const { t } = useTranslation();
  const scheduleContext = useScheduleContext();
  const {
    businessContext: { businessInCache },
  } = scheduleContext;

  const scheduleFragment = useFragment(
    graphql`
      # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
      fragment ScheduleProfileForm_schedule on Schedule {
        id
        timeZone
        ### Replaceable content start
        scheduleName
        scheduleStatus
        code
        settings
        locale
        hrGoLiveDate
        timekeepingGoLiveDate
        firstDayOfWeek
        dayStartTime
        dayEndTime
        dayPartTemplateId
        dayPartInheritedScheduleId
        analyticsEnabled
        snapshotStartDate
        snapshotDuration
        dataLakeId
        autoPublishEnabled
        autoPublishConfig
        ruleSetId
        complianceStartDate
        autoGfeEnabled
        autoGfeConfig
        payPeriodEnabled
        payPeriodRequiresApproval
        employeeMultiRateEnabled
        payrollCutoffTime
        payCycleStartDate
        payFrequency
        payDates
        clockTrackingEnabled
        clockInThreshold
        clockOutThreshold
        shiftUnpublishConsentEnabled
        shiftUnpublishConsentThreshold
        shiftShowUpThreshold
        shiftCanOverlapLeaveRequest
        shiftCanOverlapUnavailability
        shiftMultiRateEnabled
        shiftSwapEnabled
        shiftSwapNeedsApproval
        shiftDropEnabled
        showEarnings
        idleTimeoutEnabled
        idleTimeoutLength
        voluntaryLateClockOutEnabled
        usesBiometrics
        usesPin
        unplannedShiftStartThreshold
        punchSlipMode
        restScreenTheme
        timeClockAppWorkflow
        timeClockAppPreShiftFormattedMessages {
          style
          configurable {
            version
            code
            title
            message
            buttons
          }
          predefined
        }
        approvalNeededForShiftChange
        approvalNeededForUnplannedShift
        approvalNeededForEarlyBreakEnd
        approvalNeededForNonCompliantBreak
        approvalNeededForPinLogin
        approvalNeededForMinorViolations
        approvalNeededForRoleRateChange
        timeClockRoleRateChangeAuthThreshold
        timeClockAppRestScreenSettings {
          shiftApproachingLowerBound
          shiftDueUpperBound
          shiftOverdueUpperBound
          shiftLateUpperBound
          shiftEndApproachingLowerBound
          shiftEndDueUpperBound
          mealBreakApproachingLowerBound
          mealBreakDueUpperBound
          mealBreakOverdueUpperBound
          mealBreakEndApproachingLowerBound
          mealBreakEndDueUpperBound
          breakCompletedUpperBound
          onTimeThreshold
        }
        monthlyCostLoading
        populateScheduleConfig
        ### Replaceable content finish
      }
    `,
    props.schedule,
  );

  const { params } = props.match;
  const businessId = params.business_id;

  const validationRules = yup.object({
    shiftUnpublishConsentThreshold: yup
      .number()
      .min(1)
      .label(t("property.shiftUnpublishConsentThreshold.value")),
    timeClockAppRestScreenSettings: yup.object({
      shiftApproachingLowerBound: yup
        .number()
        .min(0)
        .label(t("property.shiftApproachingLowerBound.value")),
      shiftDueUpperBound: yup
        .number()
        .min(0)
        .label(t("property.shiftDueUpperBound.value")),
      shiftOverdueUpperBound: yup
        .number()
        .min(0)
        .label(t("property.shiftOverdueUpperBound.value")),
      shiftLateUpperBound: yup
        .number()
        .min(0)
        .label(t("property.shiftLateUpperBound.value")),
      shiftEndApproachingLowerBound: yup
        .number()
        .min(0)
        .label(t("property.shiftEndApproachingLowerBound.value")),
      shiftEndDueUpperBound: yup
        .number()
        .min(0)
        .label(t("property.shiftEndDueUpperBound.value")),
      mealBreakApproachingLowerBound: yup
        .number()
        .min(0)
        .label(t("property.mealBreakApproachingLowerBound.value")),
      mealBreakDueUpperBound: yup
        .number()
        .min(0)
        .label(t("property.mealBreakDueUpperBound.value")),
      mealBreakOverdueUpperBound: yup
        .number()
        .min(0)
        .label(t("property.mealBreakOverdueUpperBound.value")),
      mealBreakEndApproachingLowerBound: yup
        .number()
        .min(0)
        .label(t("property.mealBreakEndApproachingLowerBound.value")),
      mealBreakEndDueUpperBound: yup
        .number()
        .min(0)
        .label(t("property.mealBreakEndDueUpperBound.value")),
      onTimeThreshold: yup
        .number()
        .min(0)
        .label(t("property.onTimeThreshold.value")),
      breakCompletedUpperBound: yup
        .number()
        .min(0)
        .label(t("property.breakCompletedUpperBound.value")),
    }),
    settings: yup.mixed().isValidJson(t("property.settings.value")),
    autoGfeConfig: yup.mixed().isValidJson(t("property.autoGfeConfig.value")),
    populateScheduleConfig: yup
      .mixed()
      .isValidJson(t("property.populateScheduleConfig.value")),
  });

  const onSaved = (response: UpdateScheduleMutationResponse) => {
    const { setSchedule: setScheduleContext } = scheduleContext;
    setScheduleContext(response.updateSchedule);
    toast(t("form.notifications.saved_successfully"));
  };

  // Add a new event handler that fires off the mutation
  const handleSave = (
    changes: Partial<ScheduleProfileForm_schedule>,
    onError: (err: Error) => void,
  ) => {
    const { environment } = props;
    UpdateScheduleMutation(
      environment,
      changes,
      scheduleFragment.id,
      businessId,
      onSaved,
      onError,
    );
  };

  // Custom component rules
  const componentRules: Record<string, ComponentRule> = {
    payDates: {
      component: PayDates,
      componentProps: {},
      disabled: (values: ScheduleProfileForm_schedule) =>
        values.payFrequency !== PayFrequncies.BI_MONTHLY,
    },
    payFrequency: {
      component: DynamicSelect,
      componentProps: {
        options: payFrequencyOptions,
        defaultValue: null,
      },
    },
    restScreenTheme: {
      component: DynamicSelect,
      componentProps: {
        options: restScreenThemeOptions,
        defaultValue: null,
      },
    },
    punchSlipMode: {
      component: DynamicSelect,
      componentProps: {
        options: punchSlipModeOptions,
        defaultValue: null,
      },
    },
    firstDayOfWeek: {
      component: DynamicSelect,
      componentProps: {
        options: daysOfWeekOptions,
        defaultValue: null,
      },
    },
    idleTimeoutLength: {
      disabled: (values: ScheduleProfileForm_schedule) =>
        !values.idleTimeoutEnabled,
    },
    payrollCutoffTime: {
      disabled: () => !businessInCache?.aggregateToStartDay,
    },
    timeClockAppPreShiftFormattedMessages: {
      component: (componentProps: any) => (
        <ScheduleTimeClockPreShiftMessages
          properties={properties as unknown as IProperty[]}
          {...componentProps}
        />
      ),
      hideError: true,
      hideLabel: true,
      hideDescription: true,
      xs: 12,
      md: 12,
      lg: 12,
    },
    shiftCanOverlapLeaveRequest: {
      disabled: () => businessInCache?.shiftCanOverlapLeaveRequest !== true,
      tooltipText:
        businessInCache?.shiftCanOverlapLeaveRequest !== true
          ? t("schedules:form.disabledAtBusinessLevel")
          : undefined,
    },
    shiftCanOverlapUnavailability: {
      disabled: () => businessInCache?.shiftCanOverlapUnavailability !== true,
      tooltipText:
        businessInCache?.shiftCanOverlapLeaveRequest !== true
          ? t("schedules:form.disabledAtBusinessLevel")
          : undefined,
    },
    shiftUnpublishConsentThreshold: {
      disabled: (values: ScheduleProfileForm_schedule) =>
        !values.shiftUnpublishConsentEnabled,
    },
  };

  if (scheduleFragment) {
    return (
      <div className="panel">
        <FormLayout<ScheduleProfileForm_schedule>
          base={scheduleFragment}
          onSave={handleSave}
          propertyList={properties as unknown as IProperty[]}
          validationRules={validationRules}
          componentRules={componentRules}
        >
          <DynamicInputGroup
            fields={getSettingsByGroup(
              getFieldsByInputObjectName(
                properties as unknown as IProperty[],
                "ScheduleUpdateInput",
              ),
            )}
          />
          <FormLayoutFooter />
        </FormLayout>
      </div>
    );
  }
  return <div>{t("schedules:form.scheduleNotFound")}</div>;
}
