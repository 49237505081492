/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PeopleSettingsQueriesMutations_GetBusinessRoles_QueryVariables = {
    businessId: string;
};
export type PeopleSettingsQueriesMutations_GetBusinessRoles_QueryResponse = {
    readonly businessRoles: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly businessRoleName: string;
            readonly replaceByAos: boolean;
        }>;
    };
};
export type PeopleSettingsQueriesMutations_GetBusinessRoles_Query = {
    readonly response: PeopleSettingsQueriesMutations_GetBusinessRoles_QueryResponse;
    readonly variables: PeopleSettingsQueriesMutations_GetBusinessRoles_QueryVariables;
};



/*
query PeopleSettingsQueriesMutations_GetBusinessRoles_Query(
  $businessId: ID!
) {
  businessRoles(businessId: $businessId) {
    nodes {
      id
      businessRoleName
      replaceByAos
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      }
    ],
    "concreteType": "BusinessRoleConnection",
    "kind": "LinkedField",
    "name": "businessRoles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessRole",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "businessRoleName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replaceByAos",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PeopleSettingsQueriesMutations_GetBusinessRoles_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PeopleSettingsQueriesMutations_GetBusinessRoles_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f5c66858f84c8cf138896c4c5b1b48fc",
    "id": null,
    "metadata": {},
    "name": "PeopleSettingsQueriesMutations_GetBusinessRoles_Query",
    "operationKind": "query",
    "text": "query PeopleSettingsQueriesMutations_GetBusinessRoles_Query(\n  $businessId: ID!\n) {\n  businessRoles(businessId: $businessId) {\n    nodes {\n      id\n      businessRoleName\n      replaceByAos\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c1ebfff8cdb10c5f72d126c764ca3af7';
export default node;
