import React, { useCallback, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useFormikContext } from "formik";
import * as yup from "yup";
import { Button, Modal, Row } from "react-bootstrap";
import {
  download,
  getRequestBlob,
  getRequestJson,
  postRequestJson,
  snakeCaseToCamelCaseObjectRecursive,
} from "../../../environment";
import { Id } from "../../../models/common";
import { useBusinessContext } from "../../Context/BusinessContext";
import { useModal } from "../../Context/ModalContext";
import FileUpload from "../../Form/FileUpload";
import { formatDate } from "../../../utils/utility";
import FormLayout from "../../Form/FormLayout";
import { StringFieldType } from "../../Form/models";
import Field from "../../Form/Field";

type Workflow = {
  id: Id;
  description: string;
  name: string;
  updatedAt: string;
  updatedById: Id;
  updatedByName: string;
};

type Props = {
  isNewBusiness: boolean;
};
type FileUploadProps = {
  fileToUpload: File;
};

const StyledModal = styled(Modal)`
  .modal-title {
    font-size: 20px;
  }
  .modal-content,
  .modal-body {
    width: 742px;
  }
`;

const StyledTbody = styled.tbody`
  tr {
    cursor: pointer;
  }
`;

export default function TimeclockWorkflow({ isNewBusiness }: Props) {
  const { t } = useTranslation();
  const businessContext = useBusinessContext();
  const { business } = businessContext;
  const { stack } = businessContext.appContext;

  const [businessWorkflows, setBusinessWorkflows] = useState<Workflow[]>([]);

  const mainFormErrors = useFormikContext<{ timeClockWorkflow: any }>().errors;

  const { showModal, hideModal } = useModal();

  const loadData = useCallback(async () => {
    if (isNewBusiness) {
      return;
    }
    const result = await getRequestJson(
      stack?.domainName,
      `internal/workflows/?business_id=${business?.id}`,
    );
    // massage response into simple structure
    const loadedWorkflows: Workflow[] = result?.data.map((d: any) => {
      const workflowEntry = { id: d.id, ...d.attributes };
      // convert the object keys to camel case.
      return snakeCaseToCamelCaseObjectRecursive(workflowEntry);
    });
    if (loadedWorkflows) {
      setBusinessWorkflows(loadedWorkflows);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  async function downloadWorkflow(id: Id, name: string) {
    const blob = await getRequestBlob(
      stack?.domainName,
      `internal/workflows/${id}/content?business_id=${business?.id}`,
    );
    download(blob, name, "zip");
  }

  function FileUploadModal({ fileToUpload }: FileUploadProps) {
    return (
      <StyledModal backdrop="static" centered onHide={hideModal} show>
        <Modal.Header>
          <Modal.Title>{t(`timeclockWorkflow.modal.title`)}</Modal.Title>
        </Modal.Header>
        <FormLayout<{ name: string; description: string }>
          isCreate
          base={{ name: "", description: "" }}
          onSave={async (values) => {
            if (values.name) {
              uploadWorkflow(
                fileToUpload,
                values.name,
                values.description || "",
              );
              hideModal();
            }
          }}
          validationRules={yup.object({
            name: yup
              .string()
              .max(100)
              .trim()
              .required(t("timeclockWorkflow.modal.required_error"))
              .label(t("timeclockWorkflow.modal.labels.name")),
            description: yup
              .string()
              .max(500)
              .label(t("timeclockWorkflow.modal.labels.description")),
          })}
          hidePrompt
          hideError
        >
          <Modal.Body>
            <Row className="mt-2 mb-2">
              <Field
                md={4}
                label={t(`timeclockWorkflow.modal.labels.name`)}
                fieldKey="name"
                schemaFieldType={StringFieldType}
                placeholder=""
              />
              <Field
                md={4}
                label={t(`timeclockWorkflow.modal.labels.description`)}
                fieldKey="description"
                schemaFieldType={StringFieldType}
                placeholder=""
              />
            </Row>

            <div>{t(`timeclockWorkflow.modal.save_immediately_warning`)}</div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button variant="link" className="mr-4" onClick={hideModal}>
                {t("translation:form.actions.cancel")}
              </Button>
              <Button type="submit" variant="primary">
                {t("translation:form.actions.save")}
              </Button>
            </div>
          </Modal.Footer>
        </FormLayout>
      </StyledModal>
    );
  }

  async function uploadWorkflow(
    workflowFile: File,
    name: string,
    description: string,
  ) {
    const payload = new FormData();
    payload.append("content", workflowFile);
    payload.append("business_id", business?.id || "");
    payload.append("name", name);
    payload.append("description", description);
    await postRequestJson(stack?.domainName, "internal/workflow/", payload);
    loadData();
  }

  async function uploadHandler(uploadedFile: File) {
    showModal(<FileUploadModal fileToUpload={uploadedFile} />);
  }

  if (isNewBusiness) {
    return <div>{t("timeclockWorkflow.is_new_business")}</div>;
  }
  return (
    <div>
      <FileUpload
        dropzoneProps={{
          multiple: false,
          accept: "application/zip, application/x-zip-compressed, .zip",
          maxSize: 500000,
        }}
        returnAsFile
        onChange={uploadHandler}
        fieldKey="timeClockWorkflow"
      />
      {mainFormErrors.timeClockWorkflow && (
        <div className="invalid-feedback">
          {mainFormErrors.timeClockWorkflow}
        </div>
      )}
      {businessWorkflows.length > 0 && (
        <Table hover size="sm">
          <thead>
            <tr>
              <th>{t("timeclockWorkflow.table.headers.name")}</th>
              <th>{t("timeclockWorkflow.table.headers.description")}</th>
              <th>{t("timeclockWorkflow.table.headers.last_updated")}</th>
              <th>{t("timeclockWorkflow.table.headers.last_updated_by")}</th>
            </tr>
          </thead>
          <StyledTbody>
            {businessWorkflows.map((workflow) => (
              <tr
                key={workflow.id}
                onClick={() => downloadWorkflow(workflow.id, workflow.name)}
              >
                <td>{workflow.name}</td>
                <td>{workflow.description}</td>
                <td>
                  {formatDate(workflow.updatedAt, {
                    toFormat: "dd MMM yyyy",
                    showRelative: true,
                  })}
                </td>
                <td>{workflow.updatedByName}</td>
              </tr>
            ))}
          </StyledTbody>
        </Table>
      )}
    </div>
  );
}
