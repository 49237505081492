import React from "react";
import Form from "react-bootstrap/Form";

import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { Translation } from "react-i18next";
import isFunction from "lodash/isFunction";
import isArray from "lodash/isArray";
import { Prompt } from "react-router-dom";
import { forIn, isObject } from "lodash";

import { addMethod, mixed } from "yup";
import i18next from "i18next";
import ErrorSummary from "./ErrorSummary";

import {
  differenceWith,
  getHandleServerValidationErrorFn,
  isEmptyChildren,
} from "../../utils/utility";
import { IProperty } from "./models";
import { DynamicFormContext } from "../Context/DynamicFormContext";
import { isValidSingleLevelJson, isValidYupJson } from "./formUtilities";

// Add global validation methods to be used by yup in here
// eg. yup.string().isValidCreditCardNumber("Credit Card is invalid")
addGlobalValidationMethods();

export type DisabledRule = boolean | ((x: any) => boolean);

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props<T> = typeof FormLayout.defaultProps & {
  /** The initial data of the form - this is important for ensuring we can reset the form to the original state */
  base: T;
  propertyList?: IProperty[];

  /**
   * Fired when the form is submitted
   * Depending on the value of isCreate, the state may contain a full or partial object (full if isCreate = true)
   */
  onSave: (
    state: Partial<T>,
    onError: (err: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: T,
    formikHelpers?: FormikHelpers<T>,
  ) => void;

  /** Validation rules for form - uses yup.ObjectSchema validation */
  validationRules?: yup.ObjectSchema<any>;

  componentRules?: Record<string, any>;

  /** If this is a NEW record the onSave method will pass the entire object with all properties, rather than only the changes made */
  isCreate: boolean;

  /** form id is useful if the submit button is outside of the <Form> */
  formId?: string;

  /** Whether or not the unsaved changes prompt should be hidden (particularly if inside a dialog) */
  hidePrompt?: boolean;

  /** Whether or not the error banner should be shown */
  hideError?: boolean;
};

type State<T> = {
  base: T;
  saveTriggered: boolean;
};

export default class FormLayout<T> extends React.Component<Props<T>, State<T>> {
  private readonly errorRef: React.RefObject<HTMLDivElement>;

  static defaultProps = {
    isCreate: false,
  };

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      base: FormLayout.getState(props) as T,
      saveTriggered: false,
    };

    this.errorRef = React.createRef();
  }

  private static getState(props: Props<any>) {
    const { base } = props;
    const obj: Record<string, any> = {};

    // Cannot use cloneDeep as base values are readonly
    const cloneObject = (
      source: Record<string, any>,
      destination: Record<string, any>,
    ) => {
      forIn(source, (value, key) => {
        if (isObject(value)) {
          (destination as any)[key] = cloneObject(value, {});
        } else if (isArray(value)) {
          (destination as any)[key] = { ...value };
        } else {
          (destination as any)[key] = value;
        }
      });
      return source;
    };

    return cloneObject(base, obj);
  }

  static getDerivedStateFromProps(
    props: Readonly<Props<any>>,
    state: State<any>,
  ) {
    // Any time base changes, update state.
    if (JSON.stringify(props.base) !== JSON.stringify(state.base)) {
      return {
        base: FormLayout.getState(props),
      };
    }
    return null;
  }

  private handleSubmit = (
    values: T,
    base: T,
    helpers: FormikHelpers<T>,
    // errors: FormikErrors<T>,
    event?: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const changes = this.props.isCreate ? values : differenceWith(values, base);
    if (changes == null) {
      return;
    }

    helpers.setSubmitting(true);
    console.debug("changes: ", changes);
    this.props.onSave(
      changes,
      getHandleServerValidationErrorFn(helpers),
      event,
      values,
      helpers,
    );
  };

  render() {
    const {
      propertyList,
      validationRules,
      componentRules,
      children,
      isCreate,
    } = this.props;
    const { base, saveTriggered } = this.state;

    const getChildren = () => {
      if (children) {
        if (isFunction(children)) {
          return (children as (p: Props<T>) => React.ReactNode)({
            base,
          } as Props<T>);
        }

        if (!isEmptyChildren(children)) {
          return children;
        }
      }

      return null;
    };

    if (!base) {
      return null;
    }

    return (
      <DynamicFormContext.Provider
        value={{
          propertyList: propertyList ?? [],
          validationRules,
          componentRules,
        }}
      >
        <Translation>
          {(t) => (
            <Formik<T>
              validateOnChange={saveTriggered}
              validateOnBlur={saveTriggered}
              enableReinitialize
              validationSchema={validationRules}
              onSubmit={(values, helpers) => {
                this.handleSubmit(values, base, helpers as FormikHelpers<T>);
              }}
              initialValues={base}
            >
              {({
                handleSubmit,
                errors,
                dirty,
                isValid,
                validateForm,
                values,
                isSubmitting,
                setFieldError,
              }) => {
                return (
                  <>
                    <Form
                      id={this.props.formId}
                      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                        this.setState({
                          saveTriggered: true,
                        });

                        validateForm(values).then((formErrors) => {
                          if (
                            !isValid &&
                            Object.keys(formErrors).length &&
                            this.errorRef &&
                            this.errorRef.current
                          ) {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }
                        });

                        handleSubmit(e);
                      }}
                    >
                      <div ref={this.errorRef}>
                        {!this.props.hideError && (
                          <ErrorSummary errors={errors} />
                        )}
                      </div>
                      {getChildren()}
                    </Form>

                    {!this.props.hidePrompt && (
                      <Prompt
                        when={dirty && !isSubmitting && !isCreate}
                        message={t("form.confirmations.unsaved_changes")}
                      />
                    )}
                  </>
                );
              }}
            </Formik>
          )}
        </Translation>
      </DynamicFormContext.Provider>
    );
  }
}

function addGlobalValidationMethods() {
  addMethod<yup.MixedSchema>(
    mixed,
    "isValidJson",
    function isValidJson(label: string, nullable = true) {
      return this.transform((value, originalValue) => {
        if (value == null) {
          return null;
        }
        return originalValue;
      }).test(
        "test_json_validation",
        i18next.t("translation:form.invalid_json", {
          fieldName: label,
        }),
        (val: Record<string, unknown> | null) => {
          return isValidYupJson(val, nullable);
        },
      );
    },
  );

  addMethod<yup.MixedSchema>(
    mixed,
    "isSingleLevelJson",
    function isSingleLevelJson(
      label: string,
      keyType: "number" | "string" | "boolean" | undefined = undefined,
      keysNullable = true,
      nullable = true,
    ) {
      const errorLabel =
        keyType == null
          ? i18next.t("translation:form.invalid_json_single_level", {
              fieldName: label,
            })
          : i18next.t("translation:form.invalid_json_single_level_typed", {
              fieldName: label,
              keyType,
            });

      return this.isValidJson(label, nullable).test(
        "single_level_json",
        errorLabel,
        (val: any) => {
          return isValidSingleLevelJson(val, keyType, keysNullable, nullable);
        },
      );
    },
  );
}
