import React from "react";
import { FormikContextType } from "formik";
import cloneDeep from "lodash/cloneDeep";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";

type Props = {
  formikContext: FormikContextType<any>;
  fieldKey: string;
  index: number;
  disabled?: boolean;
  className?: string;
};

export default function RemoveIcon({
  formikContext,
  fieldKey,
  index,
  disabled,
  className = "pl-0 pr-0 show-on-hover",
}: Props) {
  const { t } = useTranslation();

  return (
    <Button
      disabled={disabled}
      variant="link"
      className={`close ${className || ""}`}
      aria-label={t("form.actions.remove")}
      onClick={() => {
        const helpers = formikContext.getFieldHelpers(fieldKey);
        const fields = formikContext.getFieldProps(fieldKey);
        const temp = cloneDeep(fields.value);
        temp.splice(index, 1);
        helpers.setValue(temp);
      }}
    >
      <span aria-hidden="true">
        <FontAwesomeIcon size="sm" color="#8C8C8C" icon={faTrashAlt} />
      </span>
    </Button>
  );
}
