// eslint-disable-next-line import/no-extraneous-dependencies
import { Environment, Network, RecordSource, Store } from "relay-runtime";
import Authentication from "./utils/authentication";
import { SessionInfo } from "./models/common";
import ServerError, { ServerErrorCode } from "./utils/server-error";

export enum GraphQLEnvironment {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

export class RelayEnvironmentConfig {
  public static VERSION = "";

  public static IDM_URL = "";

  public static LIFELENZ_DEVICE = "platform-admin";

  public static BUSINESS_ID?: string;
}

const transformStackDomain = (stackDomain: string) => {
  // assume stack domain incl. http protocol
  // transform domain (LK-3763)
  const restrictedStackDomain = stackDomain.replace(
    "-connect",
    "-connect-restricted-proxy",
  );
  const url = new URL(restrictedStackDomain);
  return url.href;
};

const getApiUrl: (
  session: SessionInfo,
  stackDomain: string | null,
) => string = (session: SessionInfo, stackDomain: string | null = null) => {
  if (!session) {
    return "";
  }

  const { apiUrl: idmUrl } = session;
  if (!idmUrl) {
    return "";
  }

  const url = new URL(idmUrl);
  if (!stackDomain) {
    return url.href;
  }

  return transformStackDomain(stackDomain);
};

/* RestAPI functions */
export async function getRequest(
  stackDomain: string | null = null,
  path: string,
): Promise<Response> {
  const session: SessionInfo = Authentication.restoreSession();
  const options = {
    headers: {
      "X-VERSION": RelayEnvironmentConfig.VERSION,
      "X-AUTH-TOKEN": session?.authToken || "",
      "X-LIFELENZ-DEVICE": RelayEnvironmentConfig.LIFELENZ_DEVICE,
    },
  };

  const url = `${getApiUrl(session, stackDomain)}${path}`;
  return fetch(url, options);
}

export async function postRequest(
  stackDomain: string | null = null,
  path: string,
  paramsJson: any,
  contentType?: string,
): Promise<Response> {
  const session: SessionInfo = Authentication.restoreSession();
  const options = {
    method: "POST",
    headers: {
      Accept: "*/*",
      // Optional here for use with FormData
      // https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
      ...(contentType ? { "Content-Type": contentType } : null),
      "X-VERSION": RelayEnvironmentConfig.VERSION,
      "X-AUTH-TOKEN": session?.authToken || "",
      "X-LIFELENZ-DEVICE": RelayEnvironmentConfig.LIFELENZ_DEVICE,
    },
    body: paramsJson,
  };

  const url = `${getApiUrl(session, stackDomain)}${path}`;
  return fetch(url, options);
}

export async function getRequestBlobUrl(
  stackDomain: string | null = null,
  path: string,
): Promise<string> {
  const responseBlob = await getRequestBlob(stackDomain, path);
  return URL.createObjectURL(responseBlob);
}

export async function getRequestBlob(
  stackDomain: string | null = null,
  path: string,
): Promise<Blob> {
  const response = await getRequest(stackDomain, path);
  return response.blob();
}

export function download(
  blob: Blob,
  filename: string,
  extension?: string,
): void {
  // In order to create a file with a name we need to create an a tag
  // This will also trigger a consistant download - using the download attribute.
  const objectUrl = window.URL.createObjectURL(blob);
  const downloadTag = document.createElement("a");
  downloadTag.href = objectUrl;
  downloadTag.download = extension ? `${filename}.${extension}` : `${filename}`;
  downloadTag.click();
  // Release reference to the file.
  window.URL.revokeObjectURL(objectUrl);
}

export async function getRequestJson(
  stackDomain: string | null = null,
  path: string,
): Promise<any> {
  const response = await getRequest(stackDomain, path);
  return response.json().catch(() => ({}));
}

export async function postRequestJson(
  stackDomain: string | null = null,
  path: string,
  paramsJson: any,
  contentType?: string,
): Promise<any> {
  const response = await postRequest(
    stackDomain,
    path,
    paramsJson,
    contentType,
  );
  return response.json().catch(() => ({}));
}

export function snakeCaseToCamelCaseObjectRecursive(obj: any): any {
  const getJavascriptName = (name: string) => {
    // change from dashed-case or underscore_case to camelCase
    const regex = /[_-]([a-z])/gi;
    return name.replace(regex, ($0, $1) => $1.toUpperCase());
  };

  if (Array.isArray(obj)) {
    return obj.map((o) => snakeCaseToCamelCaseObjectRecursive(o));
  }

  if (typeof obj !== "object" || obj === null) {
    // primitive
    return obj;
  }

  const newObj: Record<string, any> = {};

  for (const attName in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(attName)) {
      newObj[getJavascriptName(attName)] = snakeCaseToCamelCaseObjectRecursive(
        obj[attName],
      );
    }
  }

  return newObj;
}

const getFetchQueryFn = (
  schema: GraphQLEnvironment = GraphQLEnvironment.INTERNAL,
  stack: string | null = null,
) => {
  // Define a function that fetches the results of an operation (query/mutation/etc)
  // and returns its results as a Promise:
  return (
    operation: any,
    variables: any,
    // cacheConfig: any,
    // uploadables: any,
  ) => {
    const session: SessionInfo = Authentication.restoreSession();

    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-VERSION": RelayEnvironmentConfig.VERSION,
      "X-LIFELENZ-DEVICE": RelayEnvironmentConfig.LIFELENZ_DEVICE,
    };

    // used for authentication
    const authTokenHeader = session?.authToken
      ? {
          "X-AUTH-TOKEN": session?.authToken || "",
        }
      : {};

    const userIdHeader = session?.user
      ? {
          "X-USER-ID": session?.user.id || "",
        }
      : {};

    // used for dev ops performance monitoring
    const businessIdHeader = RelayEnvironmentConfig.BUSINESS_ID
      ? {
          "X-BUSINESS-ID": RelayEnvironmentConfig.BUSINESS_ID || "",
        }
      : {};

    headers = {
      ...headers,
      ...businessIdHeader,
      ...authTokenHeader,
      ...userIdHeader,
    };

    let url = `${getApiUrl(session, stack)}${schema}/graphql`;

    if (operation.name) {
      url = `${url}?${operation.name}`;
    }

    return fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json && json.errors) {
          const serverError = new ServerError(json);
          const { code } = serverError;

          if (code === ServerErrorCode.AuthenticationError) {
            Authentication.clearSession();
          }

          if (code !== ServerErrorCode.AuthorizationError) {
            throw serverError;
          }
        }

        return json;
      });
  };
};

function createEnvironment(
  schema: GraphQLEnvironment = GraphQLEnvironment.INTERNAL,
  stackDomain: string | null = null,
) {
  // Create a network layer from the fetch function
  const network = Network.create(getFetchQueryFn(schema, stackDomain));

  // Create seperate data store for each domain/schema
  // ie. stacks query in IDM internal schema lists all available stacks in IDM
  // stacks query in IDM external schema only has logged in user's stacks
  const store = new Store(new RecordSource());

  return new Environment({
    network,
    store,
    // ... other options
  });
}

const idmInternalEnvironment = createEnvironment(GraphQLEnvironment.INTERNAL);
const idmExternalEnvironment = createEnvironment(GraphQLEnvironment.EXTERNAL);

const stackEnvironments: Map<string, any> = new Map<string, any>();
function getRegionalStackEnvironment(domainName?: string | null) {
  if (domainName == null) {
    return null;
  }
  if (!stackEnvironments.has(domainName)) {
    stackEnvironments.set(
      domainName,
      createEnvironment(GraphQLEnvironment.INTERNAL, domainName),
    );
  }

  return stackEnvironments.get(domainName);
}

export {
  idmInternalEnvironment,
  idmExternalEnvironment,
  getRegionalStackEnvironment,
  transformStackDomain,
};
