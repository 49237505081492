/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EmploymentSortField = "firstName" | "id" | "lastName" | "%future added value";
export type Order = "asc" | "desc" | "%future added value";
export type EmploymentSort = {
    field: EmploymentSortField;
    order: Order;
};
export type EmploymentService_EmploymentsSearch_QueryVariables = {
    businessId: string;
    search?: string | null | undefined;
    deleted?: boolean | null | undefined;
    pageSize?: number | null | undefined;
    sort?: Array<EmploymentSort> | null | undefined;
};
export type EmploymentService_EmploymentsSearch_QueryResponse = {
    readonly employments: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly computedName: string;
            };
        }>;
    };
};
export type EmploymentService_EmploymentsSearch_Query = {
    readonly response: EmploymentService_EmploymentsSearch_QueryResponse;
    readonly variables: EmploymentService_EmploymentsSearch_QueryVariables;
};



/*
query EmploymentService_EmploymentsSearch_Query(
  $businessId: ID!
  $search: String
  $deleted: Boolean
  $pageSize: Int
  $sort: [EmploymentSort!]
) {
  employments(businessId: $businessId, search: $search, deleted: $deleted, first: $pageSize, sort: $sort) {
    edges {
      node {
        id
        computedName
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleted"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "deleted",
        "variableName": "deleted"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "pageSize"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      }
    ],
    "concreteType": "EmploymentConnection",
    "kind": "LinkedField",
    "name": "employments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Employment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "computedName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentService_EmploymentsSearch_Query",
    "selections": (v5/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "EmploymentService_EmploymentsSearch_Query",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "5ab4556b89a293ad18f749c6d5cb75bf",
    "id": null,
    "metadata": {},
    "name": "EmploymentService_EmploymentsSearch_Query",
    "operationKind": "query",
    "text": "query EmploymentService_EmploymentsSearch_Query(\n  $businessId: ID!\n  $search: String\n  $deleted: Boolean\n  $pageSize: Int\n  $sort: [EmploymentSort!]\n) {\n  employments(businessId: $businessId, search: $search, deleted: $deleted, first: $pageSize, sort: $sort) {\n    edges {\n      node {\n        id\n        computedName\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ce1f3e787c6f7893eba070c651a31b19';
export default node;
