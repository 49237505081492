/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MetadataTypeDataTypeEnum = "boolean" | "date" | "number" | "object" | "string" | "timestamp" | "%future added value";
export type MetadataTypeObjectClassEnum = "Employment" | "%future added value";
export type MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQueryVariables = {
    businessId: string;
    id: string;
    skip: boolean;
};
export type MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQueryResponse = {
    readonly metadataTypes?: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly displayName: string | null;
            readonly objectClass: MetadataTypeObjectClassEnum;
            readonly required: boolean;
            readonly description: string | null;
            readonly dataType: MetadataTypeDataTypeEnum;
            readonly minimum: number | null;
            readonly maximum: number | null;
            readonly validValues: unknown | null;
            readonly isTimeboxed: boolean;
            readonly external: boolean;
            readonly internalAccess: boolean;
            readonly encrypted: boolean;
            readonly obfuscatedNumber: number | null;
        }>;
    } | undefined;
};
export type MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQuery = {
    readonly response: MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQueryResponse;
    readonly variables: MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQueryVariables;
};



/*
query MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQuery(
  $businessId: ID!
  $id: ID!
  $skip: Boolean!
) {
  metadataTypes(businessId: $businessId, ids: [$id]) @skip(if: $skip) {
    nodes {
      id
      name
      displayName
      objectClass
      required
      description
      dataType
      minimum
      maximum
      validValues
      isTimeboxed
      external
      internalAccess
      encrypted
      obfuscatedNumber
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "businessId",
            "variableName": "businessId"
          },
          {
            "items": [
              {
                "kind": "Variable",
                "name": "ids.0",
                "variableName": "id"
              }
            ],
            "kind": "ListValue",
            "name": "ids"
          }
        ],
        "concreteType": "MetadataTypeConnection",
        "kind": "LinkedField",
        "name": "metadataTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MetadataType",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectClass",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "required",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minimum",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maximum",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "validValues",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isTimeboxed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "external",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internalAccess",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "encrypted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "obfuscatedNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cb0dc7d5aadc8be7b5699f1d93539524",
    "id": null,
    "metadata": {},
    "name": "MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQuery",
    "operationKind": "query",
    "text": "query MetadataTypesQueriesMutations_GetSingleBusinessMetadataTypeQuery(\n  $businessId: ID!\n  $id: ID!\n  $skip: Boolean!\n) {\n  metadataTypes(businessId: $businessId, ids: [$id]) @skip(if: $skip) {\n    nodes {\n      id\n      name\n      displayName\n      objectClass\n      required\n      description\n      dataType\n      minimum\n      maximum\n      validValues\n      isTimeboxed\n      external\n      internalAccess\n      encrypted\n      obfuscatedNumber\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd317c5b72e7fc642df6d939cc94e1d10';
export default node;
