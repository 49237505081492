import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useFormikContext } from "formik";
import React, { CSSProperties } from "react";
import { Dropdown, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import VerticalSortableContext, {
  getSortableIdByIndex,
  SortableDragHandle,
  SortableTrashIcon,
} from "../../../../Context/VerticalSortableContext";
import DynamicSelect from "../../../../Form/DynamicSelect";
import Field from "../../../../Form/Field";
import GroupHeader from "../../../../Form/GroupHeader";
import { StringFieldType } from "../../../../Form/models";
import { useAppRouter } from "../../../../hooks/useAppRouter";

import {
  GetKpiScreenConfigs_KpiDisplayConfig,
  GetKpiScreenConfigs_Node,
  GetKpis_Node,
  useKpis,
  useKpiTargets,
} from "../KpiComponentsQueryMutations";

const TableWrapper = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  max-width: 1700px;

  overflow: visible;

  .group-header {
    margin-left: -8px;
  }

  .table-responsive {
    overflow: visible !important;
    overflow-x: hidden;
  }

  // Some of these styles can probably be made in a more common place eventually
  tbody {
    border: 0.03em solid ${(props) => props.theme.grey300};
  }

  thead {
    border: 0.03em solid ${(props) => props.theme.grey300};
    > tr > th {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
    }
  }
`;

const StyledRow = styled.tr`
  background: ${(props) => props.theme.white};

  &.row-error {
    td {
      padding-bottom: 35px;
    }
  }
  td {
    display: table-cell;
    vertical-align: middle;
    height: 55px;

    .form-group,
    > div {
      margin: 0;
      padding: 0;
    }

    &.drag-cell {
      padding-right: 15px;
      padding-left: 15px;
    }

    &.delete-cell {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
`;

const StyledAddButtonRow = styled.div`
  margin-bottom: 150px;

  button {
    width: 250px;
    &:after {
      display: none;
    }
  }
`;

type FormModel = GetKpiScreenConfigs_Node;

export function KpiMetricsTable() {
  const { t } = useTranslation("kpi-components");
  const router = useAppRouter<{ business_id: string }>();

  const [
    kpiDisplayConfigFields,
    { kpisAvailableToAdd, kpiTargets },
    formikContext,
  ] = useKpiDisplayConfigs(router.params.business_id);

  const updateKpiMetricsDisplayOrder = (
    entries: GetKpiScreenConfigs_KpiDisplayConfig[],
  ) => {
    // Manually update the display order to ensure the order is correct
    const updatedEntries = entries.map((config, index) => ({
      ...config,
      displayOrder: index + 1,
    }));

    const formikDisplayConfigHelpers =
      formikContext.getFieldHelpers<GetKpiScreenConfigs_KpiDisplayConfig[]>(
        "kpiDisplayConfig",
      );

    formikDisplayConfigHelpers.setValue(updatedEntries);
  };

  const handleSort = (
    kpiDisplayConfigs: GetKpiScreenConfigs_KpiDisplayConfig[],
  ) => {
    updateKpiMetricsDisplayOrder(
      kpiDisplayConfigs.map((config, index) => ({
        ...config,
        displayOrder: index + 1,
      })),
    );
  };

  const handleAdd = (kpi: GetKpis_Node) => {
    updateKpiMetricsDisplayOrder([
      ...kpiDisplayConfigFields,
      {
        kpiId: kpi.id,
        displayOrder: kpiDisplayConfigFields.length + 1,
        displayLabel: kpi.name,
        targetId: null,
        displayTooltip: null,
        kpi,
      },
    ]);
  };

  const handleDelete = (kpiId: string) => {
    updateKpiMetricsDisplayOrder(
      kpiDisplayConfigFields.filter((x) => x.kpiId !== kpiId),
    );
  };

  return (
    <TableWrapper>
      <GroupHeader className="group-header" hasBackgroundColour={false}>
        {t("profile.table.title")}
      </GroupHeader>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>{/* Drag icon */}</th>
            <th>{t("profile.table.headers.name")}</th>
            <th>{t("profile.table.headers.displayLabel")}</th>
            <th>{t("profile.table.headers.displayTooltip")}</th>
            <th>{t("profile.table.headers.showRelativeBudget")}</th>
            <th>{/* Delete icon */}</th>
          </tr>
        </thead>
        <tbody>
          <VerticalSortableContext
            data={kpiDisplayConfigFields}
            onSort={handleSort}
          >
            {kpiDisplayConfigFields.map((kpi, index) => (
              <SortableKpiRow
                key={kpi.kpiId}
                index={index}
                item={kpi}
                handleDelete={handleDelete}
                kpiTargets={kpiTargets}
              />
            ))}
          </VerticalSortableContext>
        </tbody>
      </Table>

      <StyledAddButtonRow>
        <Dropdown>
          <Dropdown.Toggle
            disabled={kpisAvailableToAdd.length === 0}
            id="dropdown-basic"
            variant="light"
          >
            +
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {kpisAvailableToAdd.map((kpi: GetKpis_Node) => (
              <Dropdown.Item key={kpi.id} onClick={() => handleAdd(kpi)}>
                {kpi.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </StyledAddButtonRow>
    </TableWrapper>
  );
}

function useKpiDisplayConfigs(businessId: string) {
  const kpis = useKpis({ businessId });
  const targets = useKpiTargets({ businessId });
  const formikContext = useFormikContext<FormModel>();

  const selectedKpiDisplayConfigs = (
    formikContext.getFieldProps<GetKpiScreenConfigs_KpiDisplayConfig[]>(
      "kpiDisplayConfig",
    ).value ?? []
  )
    .slice()
    .sort((a, b) => {
      return a.displayOrder < b.displayOrder ? -1 : 1;
    });

  // Depending on which kpi's are currently selected, the list will update
  const availableKpis = kpis.filter(
    (kpi) =>
      selectedKpiDisplayConfigs.find((field) => field.kpiId === kpi.id) == null,
  );

  const kpiTargetOptions = targets
    .map((x) => ({ value: x.id, label: x.code }))
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

  return [
    selectedKpiDisplayConfigs,
    {
      kpisAvailableToAdd: availableKpis,
      allKpis: kpis,
      kpiTargets: kpiTargetOptions,
    },
    formikContext,
  ] as const;
}

type SortableKpiRowProps = {
  disabled?: boolean;
  handleDelete: (kpiId: string) => void;
  item: GetKpiScreenConfigs_KpiDisplayConfig;
  index: number;
  kpiTargets: { value: string; label: string }[];
};

function SortableKpiRow({
  disabled,
  item,
  handleDelete,
  index,
  kpiTargets,
}: SortableKpiRowProps) {
  const { t } = useTranslation("kpi-components");

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: getSortableIdByIndex(index),
      transition: {
        duration: 150,
        easing: "cubic-bezier(0.25, 1, 0.5, 1)",
      },
    });

  const onTrashIconClicked = () => {
    handleDelete(item.kpiId);
  };

  return (
    <StyledRow
      ref={setNodeRef}
      style={
        {
          transform: CSS.Transform.toString(transform),
          transition,
        } as CSSProperties
      }
    >
      <td className="drag-cell">
        <SortableDragHandle
          disabled={disabled ?? false}
          {...attributes}
          {...listeners}
        />
      </td>
      <td>{item.kpi?.name ?? " "}</td>
      <td>
        <Field
          xs={11}
          md={11}
          lg={11}
          fieldKey={`kpiDisplayConfig[${index}].displayLabel`}
          label={undefined}
          schemaFieldType={StringFieldType}
        />
      </td>
      <td>
        <Field
          xs={11}
          md={11}
          lg={11}
          fieldKey={`kpiDisplayConfig[${index}].displayTooltip`}
          label={undefined}
          emptyValue={null}
          schemaFieldType={StringFieldType}
          placeholder={t("profile.fields.displayTooltip.placeholder")}
        />
      </td>

      <td>
        <Field
          xs={11}
          md={11}
          lg={11}
          fieldKey={`kpiDisplayConfig[${index}].targetId`}
          component={DynamicSelect}
          componentProps={{
            options: kpiTargets,
            isClearable: true,
            placeholder: t("profile.fields.targetId.placeholder"),
          }}
          schemaFieldType={StringFieldType}
        />
      </td>

      <td>
        <SortableTrashIcon onClick={onTrashIconClicked} />
      </td>
    </StyledRow>
  );
}
