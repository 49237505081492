import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { isFunction, isNumber, isUndefined } from "lodash";
import { stringToNumber } from "../../utils/utility";

type Props = {
  value?: number | null;
  fieldKey: string;
  onChange?: (newValue: number | null | string) => any;
  disabled?: boolean;
  error?: string;

  min?: number;
  minLabel?: string;
  max?: number;
  maxLabel?: string;
};

export default function Range({
  fieldKey,
  value,
  onChange,
  disabled,
  min,
  max,
  minLabel,
  maxLabel,
  error,
  ...rest
}: Props) {
  return (
    <>
      <Form.Control
        type="range"
        {...rest}
        name={fieldKey}
        disabled={disabled}
        isInvalid={error != null}
        value={!isUndefined(value) && isNumber(value) ? value : ""}
        min={min}
        max={max}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const v = e.target.value;
          const numValue = stringToNumber(v);
          if (isFunction(onChange)) {
            onChange(numValue);
          }
        }}
      />
      <Row>
        <Col md={6}>
          <Form.Text className="text-muted text-left">{min}</Form.Text>
          {minLabel ? (
            <Form.Text className="text-muted text-left">{minLabel}</Form.Text>
          ) : null}
        </Col>
        <Col md={6}>
          <Form.Text className="text-muted text-right">{max}</Form.Text>
          {maxLabel ? (
            <Form.Text className="text-muted text-right">{maxLabel}</Form.Text>
          ) : null}
        </Col>
      </Row>
    </>
  );
}
