import { Environment, fetchQuery, graphql } from "react-relay";
import { Id } from "../../../models/common";
import {
  ScheduleService_GetSchedules_Query,
  ScheduleService_GetSchedules_QueryResponse,
} from "./__generated__/ScheduleService_GetSchedules_Query.graphql";
import {
  ScheduleService_SchedulesByName_Query,
  ScheduleService_SchedulesByName_QueryResponse,
} from "./__generated__/ScheduleService_SchedulesByName_Query.graphql";

const GetAllSchedules = graphql`
  query ScheduleService_GetSchedules_Query($businessId: ID!) {
    schedules(businessId: $businessId) {
      edges {
        node {
          id
          scheduleName
          isGroup
        }
      }
    }
  }
`;

const schedulesByName = graphql`
  query ScheduleService_SchedulesByName_Query(
    $businessId: ID!
    $search: String
  ) {
    schedules(
      businessId: $businessId
      search: $search
      sort: [{ field: scheduleName, order: desc }]
      isGroup: false
    ) {
      edges {
        node {
          id
          scheduleName
        }
      }
    }
  }
`;

type ScheduleService_GetAllSchedules_EdgeType =
  {} & ScheduleService_GetSchedules_QueryResponse["schedules"]["edges"];
type ScheduleService_GetAllSchedules_NodeType =
  {} & ScheduleService_GetAllSchedules_EdgeType[number];
export type ScheduleByIdsType =
  ScheduleService_GetAllSchedules_NodeType["node"];

type EdgeType =
  {} & ScheduleService_SchedulesByName_QueryResponse["schedules"]["edges"];
type NodeType = {} & EdgeType[number];
export type ScheduleByNameType = {} & NodeType["node"];

export class ScheduleService {
  /**
   * Fetch schedules matching specified ids
   * @param businessId an id of business
   * @param scheduleIds an array of schedule ids
   * @returns a list of schedules matching the specified ids
   */
  public static async getSchedules(environment: Environment, businessId: Id) {
    const result = await fetchQuery<ScheduleService_GetSchedules_Query>(
      environment,
      GetAllSchedules,
      {
        businessId,
      },
      {
        fetchPolicy: "store-or-network",
      },
    ).toPromise();

    const map = new Map();

    if (!result || !result.schedules || !result.schedules.edges) {
      return map;
    }

    result.schedules.edges.forEach((i) => {
      if (i && i.node) {
        map.set(i.node.id, i.node);
      }
    });

    return map;
  }

  public static async searchScheduleByName(
    environment: Environment,
    businessId: Id,
    search: string,
  ): Promise<Map<Id, ScheduleByNameType> | null> {
    const result = await fetchQuery<ScheduleService_SchedulesByName_Query>(
      environment,
      schedulesByName,
      {
        businessId,
        search,
      },
    ).toPromise();

    if (!result || !result.schedules || !result.schedules.edges) {
      return null;
    }

    const map = new Map();
    result.schedules.edges.forEach((i) => {
      if (i && i.node) {
        map.set(i.node.id, i.node);
      }
    });

    return map;
  }
}
