import React, { FunctionComponent, useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { RouteComponentProps } from "react-router-dom";
import { fetchQuery, graphql } from "react-relay";
import { toRelative } from "../../../../utils/utility";
import { idmInternalEnvironment } from "../../../../environment";
import { AccountDevices_Query } from "./__generated__/AccountDevices_Query.graphql";
import Loader from "../../../common/Loader";

const AccountDevicesQuery = graphql`
  query AccountDevices_Query($ids: [ID!]) {
    users(ids: $ids) {
      edges {
        node {
          id
          devices {
            id
            category
            token
            updatedAt
          }
        }
      }
    }
  }
`;

export interface MatchParams {
  user_id: string;
}
type User = {
  readonly id: string;
  readonly devices?: ReadonlyArray<{
    readonly id: string;
    readonly category: string;
    readonly token: string;
    readonly updatedAt: string;
  }>;
};

type Props = RouteComponentProps<MatchParams> & WithTranslation;

const AccountDevices: FunctionComponent<Props> = ({ t, match }) => {
  const [user, setUser] = useState<User>();
  const {
    params: { user_id: userId },
  } = match;

  useEffect(() => {
    async function load() {
      const result = await fetchQuery<AccountDevices_Query>(
        idmInternalEnvironment,
        AccountDevicesQuery,
        {
          ids: [userId],
        },
      ).toPromise();

      setUser(result?.users?.edges?.[0]?.node ?? undefined);
    }

    load();
  }, [userId]);

  if (!user) {
    return <Loader />;
  }

  const { devices } = user;
  return (
    <Card body>
      <Table hover size="sm">
        <thead>
          <tr>
            <th>{t("devicesTable.headers.category")}</th>
            <th>{t("devicesTable.headers.token")}</th>
            <th>{t("devicesTable.headers.lastUpdate")}</th>
          </tr>
        </thead>
        <tbody>
          {(devices || []).map((i, index) => {
            const { category, token, updatedAt, id } = i;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={`${id}-${index}`}>
                <td>{category}</td>
                <td>{token}</td>
                <td>
                  {toRelative(updatedAt as string, {
                    defaultValue: "-",
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
};

export default withTranslation("accounts")(AccountDevices);
