/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CorporatesQueryMutations_GetCorporates_InternalQueryVariables = {
    stackId?: string | null | undefined;
    nameIncludes?: string | null | undefined;
};
export type CorporatesQueryMutations_GetCorporates_InternalQueryResponse = {
    readonly corporates: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly ssoAuthMethods: ReadonlyArray<{
                readonly id: string;
                readonly authType: string;
                readonly code: string;
                readonly corporateId: string;
                readonly createdAt: string;
                readonly displayName: string;
                readonly employmentIdAttributes: ReadonlyArray<string>;
                readonly employmentAttrToSearch: string;
                readonly metadataConfig: string | null;
                readonly metadataIssuer: string | null;
                readonly metadataOverrides: unknown | null;
                readonly metadataSyncedAt: string | null;
                readonly metadataUrl: string | null;
                readonly updatedAt: string;
            }>;
            readonly corporateStacks: ReadonlyArray<{
                readonly id: string;
                readonly stack: {
                    readonly id: string;
                    readonly stackCode: string;
                };
            }>;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly deleted: boolean;
        } | null> | null;
    };
};
export type CorporatesQueryMutations_GetCorporates_InternalQuery = {
    readonly response: CorporatesQueryMutations_GetCorporates_InternalQueryResponse;
    readonly variables: CorporatesQueryMutations_GetCorporates_InternalQueryVariables;
};



/*
query CorporatesQueryMutations_GetCorporates_InternalQuery(
  $stackId: ID
  $nameIncludes: String
) {
  corporates(stackId: $stackId, nameIncludes: $nameIncludes) {
    nodes {
      id
      name
      ssoAuthMethods {
        id
        authType
        code
        corporateId
        createdAt
        displayName
        employmentIdAttributes
        employmentAttrToSearch
        metadataConfig
        metadataIssuer
        metadataOverrides
        metadataSyncedAt
        metadataUrl
        updatedAt
      }
      corporateStacks {
        id
        stack {
          id
          stackCode
        }
      }
      createdAt
      updatedAt
      deleted
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nameIncludes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stackId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "nameIncludes",
        "variableName": "nameIncludes"
      },
      {
        "kind": "Variable",
        "name": "stackId",
        "variableName": "stackId"
      }
    ],
    "concreteType": "CorporateConnection",
    "kind": "LinkedField",
    "name": "corporates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Corporate",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SSOAuthMethod",
            "kind": "LinkedField",
            "name": "ssoAuthMethods",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "authType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "corporateId",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentIdAttributes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentAttrToSearch",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadataConfig",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadataIssuer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadataOverrides",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadataSyncedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadataUrl",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CorporateStack",
            "kind": "LinkedField",
            "name": "corporateStacks",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Stack",
                "kind": "LinkedField",
                "name": "stack",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stackCode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporatesQueryMutations_GetCorporates_InternalQuery",
    "selections": (v5/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CorporatesQueryMutations_GetCorporates_InternalQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "8cba5bd999104116f46576367d4c3643",
    "id": null,
    "metadata": {},
    "name": "CorporatesQueryMutations_GetCorporates_InternalQuery",
    "operationKind": "query",
    "text": "query CorporatesQueryMutations_GetCorporates_InternalQuery(\n  $stackId: ID\n  $nameIncludes: String\n) {\n  corporates(stackId: $stackId, nameIncludes: $nameIncludes) {\n    nodes {\n      id\n      name\n      ssoAuthMethods {\n        id\n        authType\n        code\n        corporateId\n        createdAt\n        displayName\n        employmentIdAttributes\n        employmentAttrToSearch\n        metadataConfig\n        metadataIssuer\n        metadataOverrides\n        metadataSyncedAt\n        metadataUrl\n        updatedAt\n      }\n      corporateStacks {\n        id\n        stack {\n          id\n          stackCode\n        }\n      }\n      createdAt\n      updatedAt\n      deleted\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0add0401537e18a50177cf2dff4d0c2d';
export default node;
