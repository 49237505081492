/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EmploymentMetadataQueriesMutations_GetEmploymentScheduleHistories_QueryVariables = {
    businessId: string;
    employmentId: string;
};
export type EmploymentMetadataQueriesMutations_GetEmploymentScheduleHistories_QueryResponse = {
    readonly employmentScheduleHistories: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly isHomeSchedule: boolean;
            readonly employmentId: string;
            readonly schedule: {
                readonly dayStartTime: string | null;
                readonly timeZone: string | null;
            } | null;
        }>;
    };
};
export type EmploymentMetadataQueriesMutations_GetEmploymentScheduleHistories_Query = {
    readonly response: EmploymentMetadataQueriesMutations_GetEmploymentScheduleHistories_QueryResponse;
    readonly variables: EmploymentMetadataQueriesMutations_GetEmploymentScheduleHistories_QueryVariables;
};



/*
query EmploymentMetadataQueriesMutations_GetEmploymentScheduleHistories_Query(
  $businessId: ID!
  $employmentId: ID!
) {
  employmentScheduleHistories(businessId: $businessId, employmentId: $employmentId) {
    nodes {
      id
      isHomeSchedule
      employmentId
      schedule {
        dayStartTime
        timeZone
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "employmentId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "employmentId",
    "variableName": "employmentId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHomeSchedule",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dayStartTime",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeZone",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentMetadataQueriesMutations_GetEmploymentScheduleHistories_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EmploymentScheduleHistoryConnection",
        "kind": "LinkedField",
        "name": "employmentScheduleHistories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmploymentScheduleHistory",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentMetadataQueriesMutations_GetEmploymentScheduleHistories_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EmploymentScheduleHistoryConnection",
        "kind": "LinkedField",
        "name": "employmentScheduleHistories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmploymentScheduleHistory",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ba2080810f487a653c575ad7612d3d03",
    "id": null,
    "metadata": {},
    "name": "EmploymentMetadataQueriesMutations_GetEmploymentScheduleHistories_Query",
    "operationKind": "query",
    "text": "query EmploymentMetadataQueriesMutations_GetEmploymentScheduleHistories_Query(\n  $businessId: ID!\n  $employmentId: ID!\n) {\n  employmentScheduleHistories(businessId: $businessId, employmentId: $employmentId) {\n    nodes {\n      id\n      isHomeSchedule\n      employmentId\n      schedule {\n        dayStartTime\n        timeZone\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e700845c49e667355ce3d1fee6279c8d';
export default node;
