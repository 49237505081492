/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SkillLevelProfile_QueryVariables = {
    ids?: Array<string> | null | undefined;
    businessId: string;
    skip: boolean;
};
export type SkillLevelProfile_QueryResponse = {
    readonly skillLevels?: {
        readonly nodes: ReadonlyArray<{
            readonly " $fragmentRefs": FragmentRefs<"Profile_skillLevel">;
        }>;
    } | undefined;
};
export type SkillLevelProfile_Query = {
    readonly response: SkillLevelProfile_QueryResponse;
    readonly variables: SkillLevelProfile_QueryVariables;
};



/*
query SkillLevelProfile_Query(
  $ids: [ID!]
  $businessId: ID!
  $skip: Boolean!
) {
  skillLevels(ids: $ids, businessId: $businessId) @skip(if: $skip) {
    nodes {
      ...Profile_skillLevel
      id
    }
  }
}

fragment Profile_skillLevel on SkillLevel {
  name
  isDefault
  level
  aosLevel
  assignmentAction
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ids"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = [
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillLevelProfile_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "SkillLevelConnection",
            "kind": "LinkedField",
            "name": "skillLevels",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillLevel",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Profile_skillLevel"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SkillLevelProfile_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "SkillLevelConnection",
            "kind": "LinkedField",
            "name": "skillLevels",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillLevel",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDefault",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "level",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "aosLevel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assignmentAction",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "33d46461427cb236452587287209b3da",
    "id": null,
    "metadata": {},
    "name": "SkillLevelProfile_Query",
    "operationKind": "query",
    "text": "query SkillLevelProfile_Query(\n  $ids: [ID!]\n  $businessId: ID!\n  $skip: Boolean!\n) {\n  skillLevels(ids: $ids, businessId: $businessId) @skip(if: $skip) {\n    nodes {\n      ...Profile_skillLevel\n      id\n    }\n  }\n}\n\nfragment Profile_skillLevel on SkillLevel {\n  name\n  isDefault\n  level\n  aosLevel\n  assignmentAction\n}\n"
  }
};
})();
(node as any).hash = '2b4c12d5eb2f30d35b503fe106aabf98';
export default node;
