import React, { FunctionComponent } from "react";
import { Route, RouteComponentProps, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";

import SkillLevelProfile from "./SkillLevelProfile";
import Loader from "../../common/Loader";
import HeaderPortal from "../../Portal/HeaderPortal";
import { useBusinessContext } from "../../Context/BusinessContext";

interface MatchParams {
  stack_id: string;
  business_id: string;
  skill_level_id?: string;
}

type Props = RouteComponentProps<MatchParams> & {};

const SkillLevelLayout: FunctionComponent<Props> = (props: Props) => {
  const { match } = props;
  const { t } = useTranslation("skill-levels");
  const { business } = useBusinessContext();
  const { stack_id: stackId } = match.params;

  return (
    <>
      <HeaderPortal as="span">
        <Link
          to={`/stack/${match.params.stack_id}/business/${business?.id}/profile/schedules`}
        >
          <span>{business?.businessName}</span>
        </Link>
        <span className="ml-2 mr-2">&gt;</span>
        <Link
          to={`/stack/${stackId}/business/${business?.id}/profile/settings/skill_levels`}
        >
          <span>{t("breadcrumb")}</span>
        </Link>

        <span id="skill-level-header-portal" />
      </HeaderPortal>
      <header className="sub-header-container">
        <Nav variant="tabs">
          <LinkContainer to={`${match.url}`} exact>
            <Nav.Link>{t("layout.nav.settings")}</Nav.Link>
          </LinkContainer>
        </Nav>
      </header>
      <div className="mt-4">
        <React.Suspense fallback={<Loader />}>
          <Route exact path={`${match.path}`} component={SkillLevelProfile} />
        </React.Suspense>
      </div>
      <footer />
    </>
  );
};

export default SkillLevelLayout;
