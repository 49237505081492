import React from "react";
import { Link, match } from "react-router-dom";
import { createFragmentContainer, graphql } from "react-relay";
import { StackRow_viewer } from "./__generated__/StackRow_viewer.graphql";

type Props = {
  viewer: StackRow_viewer;
  match: match<any>;
};

function StackRowBase(props: Props) {
  const stack = props.viewer;
  return (
    <tr key={stack.id}>
      <td>
        <Link to={`/stack/${stack.id}`}>{stack.domainName}</Link>
      </td>
      <td>{stack.stackCode}</td>
    </tr>
  );
}

export default createFragmentContainer(StackRowBase, {
  viewer: graphql`
    fragment StackRow_viewer on Stack {
      id
      domainName
      stackCode
      deleted
    }
  `,
});
