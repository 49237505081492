/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AOSTemplateProfile_QueryVariables = {
    businessId: string;
    id: string;
};
export type AOSTemplateProfile_QueryResponse = {
    readonly aosConfigTemplates: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly " $fragmentRefs": FragmentRefs<"Profile_template">;
            };
        }>;
    };
};
export type AOSTemplateProfile_Query = {
    readonly response: AOSTemplateProfile_QueryResponse;
    readonly variables: AOSTemplateProfile_QueryVariables;
};



/*
query AOSTemplateProfile_Query(
  $businessId: ID!
  $id: ID!
) {
  aosConfigTemplates(businessId: $businessId, ids: [$id]) {
    edges {
      node {
        ...Profile_template
        id
      }
    }
  }
}

fragment Profile_template on AosConfigTemplate {
  id
  templateName
  isDefault
  aosConfig {
    shiftConfig
    schedulingDayStart
    addUnderstaffingShifts
    weeklyWorkhoursMin
    weeklyWorkhoursMax
    shiftMin
    shiftMax
    shiftMinAbsolute
    shiftGapMin
    shiftMaxStart
    maxRolesPerShift
    minShiftPartLength
    breakTrigger
    breakLength
    breakMinStart
    breakMaxStart
    breakEndPad
    includeRoles
    demandOnly
    bonusRoles
    ignoreBreakRoles
    flexibleRole
    coreObeysAosRules
    skipSchedulingManagers
    skipSchedulingCore
    addCoreBreaks
    optimizeCoreBreaks
    weeklyDayparts
    daypartRanks
    planningOrder
    overstaffingPenalty
    skillPreference
    shiftLengthPreference
    penaltyShortParts
    penaltyShortPartsCutoff
    costEqualLabor
    weightEqualLaborByRating
    applyRule24HoursRest
    maxWorkdaysCalweek
    maxWorkdaysWorkweek
    overstaffingByRole
    understaffingByRole
    aosTimeout
    aosCombinedRoles
    aosOrchestratorConfig
    replanningTimeout
    replanningOrchestratorConfig
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "items": [
      {
        "kind": "Variable",
        "name": "ids.0",
        "variableName": "id"
      }
    ],
    "kind": "ListValue",
    "name": "ids"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AOSTemplateProfile_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AosConfigTemplateConnection",
        "kind": "LinkedField",
        "name": "aosConfigTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AosConfigTemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AosConfigTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Profile_template"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AOSTemplateProfile_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AosConfigTemplateConnection",
        "kind": "LinkedField",
        "name": "aosConfigTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AosConfigTemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AosConfigTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "templateName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDefault",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AosConfig",
                    "kind": "LinkedField",
                    "name": "aosConfig",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shiftConfig",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "schedulingDayStart",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "addUnderstaffingShifts",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "weeklyWorkhoursMin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "weeklyWorkhoursMax",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shiftMin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shiftMax",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shiftMinAbsolute",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shiftGapMin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shiftMaxStart",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxRolesPerShift",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "minShiftPartLength",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "breakTrigger",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "breakLength",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "breakMinStart",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "breakMaxStart",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "breakEndPad",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "includeRoles",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "demandOnly",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bonusRoles",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ignoreBreakRoles",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "flexibleRole",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "coreObeysAosRules",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "skipSchedulingManagers",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "skipSchedulingCore",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "addCoreBreaks",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "optimizeCoreBreaks",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "weeklyDayparts",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "daypartRanks",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "planningOrder",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "overstaffingPenalty",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "skillPreference",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shiftLengthPreference",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "penaltyShortParts",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "penaltyShortPartsCutoff",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "costEqualLabor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "weightEqualLaborByRating",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "applyRule24HoursRest",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxWorkdaysCalweek",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxWorkdaysWorkweek",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "overstaffingByRole",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "understaffingByRole",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "aosTimeout",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "aosCombinedRoles",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "aosOrchestratorConfig",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "replanningTimeout",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "replanningOrchestratorConfig",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "164bd576d4438d7261f5dde5b162d8e7",
    "id": null,
    "metadata": {},
    "name": "AOSTemplateProfile_Query",
    "operationKind": "query",
    "text": "query AOSTemplateProfile_Query(\n  $businessId: ID!\n  $id: ID!\n) {\n  aosConfigTemplates(businessId: $businessId, ids: [$id]) {\n    edges {\n      node {\n        ...Profile_template\n        id\n      }\n    }\n  }\n}\n\nfragment Profile_template on AosConfigTemplate {\n  id\n  templateName\n  isDefault\n  aosConfig {\n    shiftConfig\n    schedulingDayStart\n    addUnderstaffingShifts\n    weeklyWorkhoursMin\n    weeklyWorkhoursMax\n    shiftMin\n    shiftMax\n    shiftMinAbsolute\n    shiftGapMin\n    shiftMaxStart\n    maxRolesPerShift\n    minShiftPartLength\n    breakTrigger\n    breakLength\n    breakMinStart\n    breakMaxStart\n    breakEndPad\n    includeRoles\n    demandOnly\n    bonusRoles\n    ignoreBreakRoles\n    flexibleRole\n    coreObeysAosRules\n    skipSchedulingManagers\n    skipSchedulingCore\n    addCoreBreaks\n    optimizeCoreBreaks\n    weeklyDayparts\n    daypartRanks\n    planningOrder\n    overstaffingPenalty\n    skillPreference\n    shiftLengthPreference\n    penaltyShortParts\n    penaltyShortPartsCutoff\n    costEqualLabor\n    weightEqualLaborByRating\n    applyRule24HoursRest\n    maxWorkdaysCalweek\n    maxWorkdaysWorkweek\n    overstaffingByRole\n    understaffingByRole\n    aosTimeout\n    aosCombinedRoles\n    aosOrchestratorConfig\n    replanningTimeout\n    replanningOrchestratorConfig\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e2bc088abe7b682cf2e5159b26dd668f';
export default node;
