import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { QueryRenderer, graphql, RelayEnvironmentProvider } from "react-relay";
import styled from "styled-components";
import { IUser } from "../../../models/common";

import Accounts from "../internal/Account/Accounts";
import AccountLayout from "../internal/Account/AccountLayout";
import Businesses from "../../Stack/Business/Businesses";
import StackContextLayout from "../../Stack/StackContextLayout";
import Sidebar from "../../Sidebar/Sidebar";
import { ModalContextProvider } from "../../Context/ModalContextLegacy";
import AppContext, { AppContextProvider } from "./Context/AppContext";
import TopNav from "../../TopNav/TopNav";
import Stacks from "../internal/Stack/Stacks";
import StackLayout from "../internal/Stack/StackLayout";

import {
  getRegionalStackEnvironment,
  idmExternalEnvironment,
} from "../../../environment";
import { AuthenticatedApp_Query } from "./__generated__/AuthenticatedApp_Query.graphql";
import Loader from "../../common/Loader";
import GlobalBusinessLayout from "../../Stack/GlobalBusiness/GlobalBusinessLayout";
import CorporatesLayout from "../internal/Corporates/CorporatesLayout/CorporatesLayout";
import Corporates from "../internal/Corporates/Corporates";

const AuthenticatedAppQuery = graphql`
  query AuthenticatedApp_Query {
    stacks {
      edges {
        node {
          id
          domainName
          deleted
          stackCode
        }
      }
    }
  }
`;

type Props = {
  user: IUser;
  handleLogout: () => void;
  stackCode: string;
};

const StyledContainer = styled(Container)`
  padding-left: 0px;
  padding-right: 0px;
`;

const NonWrappingRow = styled(Row)`
  flex-wrap: nowrap;
`;

const StyledContent = styled(Col)`
  padding-left: 200px !important;
`;

export default function AuthenticatedApp(properties: Props) {
  const { user, handleLogout, stackCode } = properties;

  return (
    <QueryRenderer<AuthenticatedApp_Query>
      environment={idmExternalEnvironment}
      query={AuthenticatedAppQuery}
      variables={{}}
      render={({ error, props }) => {
        if (error) {
          // Go back to the login screen if the session has expired
          if (error.message?.includes("Session expired")) {
            handleLogout();
            return null;
          }
          return (
            <Router>
              {/* show top nav bar so that user can log out */}
              <TopNav handleLogout={handleLogout} user={user}>
                <div id="header-portal" />
              </TopNav>
              <Container fluid className="justify-content-md-center">
                <div>Error! {error.message}</div>
              </Container>
            </Router>
          );
        }

        if (!props) {
          return <Loader />;
        }

        const stacks = (props.stacks?.edges || []).map(
          (edge) => edge?.node as any,
        );

        if (!stacks.length) {
          alert("Account does not have any access");
          handleLogout();
          return null;
        }

        return (
          <Router basename={stackCode}>
            <div className="App bg-light">
              <AppContextProvider
                user={user}
                stacks={stacks}
                stackCode={stackCode}
              >
                <ModalContextProvider>
                  <ToastContainer />
                  <StyledContainer fluid>
                    <NonWrappingRow noGutters>
                      <div className="pull-left">
                        <Sidebar />
                      </div>
                      <StyledContent className="col-md-12 content">
                        <TopNav handleLogout={handleLogout} user={user}>
                          <div id="header-portal" />
                        </TopNav>
                        <Container fluid>
                          <Route path="/" exact>
                            <Redirect to="/stacks" />
                          </Route>
                          <AppContext.Consumer>
                            {({ stack }) => {
                              return (
                                <RelayEnvironmentProvider
                                  environment={getRegionalStackEnvironment(
                                    stack?.domainName,
                                  )}
                                >
                                  <Route
                                    path="/stack/:stack_id/businesses/"
                                    exact
                                    component={Businesses}
                                  />

                                  <Route
                                    path={[
                                      "/stack/:stack_id/business/create",
                                      "/stack/:stack_id/business/:business_id",
                                    ]}
                                    component={StackContextLayout}
                                  />
                                </RelayEnvironmentProvider>
                              );
                            }}
                          </AppContext.Consumer>
                          <Route path="/accounts" exact component={Accounts} />
                          <Route
                            path={["/account/create", "/account/:user_id"]}
                            component={AccountLayout}
                          />
                          <Route path="/stacks" exact component={Stacks} />
                          <Route
                            path={[
                              "/stack_people/:stack_id/",
                              "/stack_global_business/:stack_id/",
                            ]}
                            exact
                            component={StackLayout}
                          />
                          <Route
                            path={["/stack/create", "/stack/:stack_id"]}
                            exact
                            component={StackLayout}
                          />
                          <Route
                            path={[
                              "/stack/:stack_id/global_business/create",
                              "/stack/:stack_id/global_business/edit/:global_business_id",
                            ]}
                            exact
                            component={GlobalBusinessLayout}
                          />
                          <Route
                            path={["/corporates"]}
                            exact
                            component={Corporates}
                          />
                          <Route
                            path={[
                              "/corporates/edit/:corporate_id",
                              "/corporates/create",
                            ]}
                            component={CorporatesLayout}
                          />
                        </Container>
                      </StyledContent>
                    </NonWrappingRow>
                  </StyledContainer>
                </ModalContextProvider>
              </AppContextProvider>
            </div>
          </Router>
        );
      }}
    />
  );
}
