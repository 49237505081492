import React from "react";
import { FormikContext } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Field from "./Field";
import { SchemaFieldType } from "./models";
import { getSchemaFieldType } from "./formUtilities";

type Props = {
  value: number[];
  fieldKey: string;
  disabled?: boolean;
  onChange: (newValue: number[] | null) => void;
  type: SchemaFieldType;
};

export default function PayDates({ disabled, fieldKey, type }: Props) {
  return (
    <FormikContext.Consumer>
      {() => {
        return (
          <>
            {[0, 1].map((i: number) => {
              const indexKey = `${fieldKey}[${i}]`;
              return (
                <Row key={indexKey}>
                  <Col md={10}>
                    <Field
                      schemaFieldType={getSchemaFieldType(
                        type.ofType as SchemaFieldType,
                      )}
                      fieldKey={indexKey}
                      md={12}
                      lg={12}
                      label={`Pay cycle ${i + 1}`}
                      disabled={disabled != null ? disabled : false}
                      hideError
                    />
                  </Col>
                </Row>
              );
            })}
          </>
        );
      }}
    </FormikContext.Consumer>
  );
}
