import { GraphQLDateFormat } from "../../models/common";
import DateTimePicker from "./DateTimePicker";
import DurationInput from "./DurationInput";
import { CommonComponentRule } from "./models";
import Textarea from "./Textarea";
import ColorInput from "./ColorInput";

// Register common components
const CommonComponents: Record<string, CommonComponentRule> = {
  Date: {
    component: DateTimePicker,
    componentProps: {
      displayFormat: "yyyy-MM-dd",
      valueFormat: "yyyy-MM-dd",
    },
  },
  Time: {
    component: DateTimePicker,
    componentProps: {
      showTimeSelect: true,
      showTimeSelectOnly: true,
      timeIntervals: 30,
      valueFormat: GraphQLDateFormat,
      displayFormat: "hh:mm aa",
    },
  },
  SecondsToHourDuration: {
    component: DurationInput,
    componentProps: {
      from: "seconds",
      to: "hours",
      postfix: "hrs",
    },
  },
  MinuteToHourDuration: {
    component: DurationInput,
    componentProps: {
      from: "minutes",
      to: "hours",
      postfix: "hrs",
    },
  },
  SecondsToMinutesDuration: {
    component: DurationInput,
    componentProps: {
      from: "seconds",
      to: "minutes",
      postfix: "mins",
    },
  },
  MinuteDuration: {
    component: DurationInput,
    componentProps: {
      postfix: "mins",
      allowDecimals: false,
    },
  },
  HourDuration: {
    component: DurationInput,
    componentProps: {
      postfix: "hrs",
      allowDecimals: false,
    },
  },
  YearDuration: {
    component: DurationInput,
    componentProps: {
      postfix: "years",
      allowDecimals: false,
    },
  },
  Textarea: {
    lg: 12, // col-lg-12
    md: 12, // col-md-12
    xs: 12, // col-xs-12
    component: Textarea,
    componentProps: {
      rows: 3,
    },
  },
  ColorInput: {
    component: ColorInput,
    lg: 3, // col-lg-3
    md: 6, // col-md-6
    xs: 12, // col-xs-12
  },
};

export default CommonComponents;
