import React from "react";
import styled from "styled-components";

const StyledGroupName = styled("legend")<{ $hasBackgroundColour: boolean }>`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 8px;
  background-color: ${(props) =>
    props.$hasBackgroundColour ? props.theme.borderColor : "initial"};
  border-radius: 3px;
`;

type Props = {
  groupName?: string;
  className?: string;
  children: React.ReactNode;
  hasBackgroundColour?: boolean;
};

export default function GroupHeader({
  className = "fieldset-group",
  groupName,
  children,
  hasBackgroundColour = true,
}: Props) {
  return (
    <StyledGroupName
      $hasBackgroundColour={hasBackgroundColour}
      className={className}
    >
      {children}
    </StyledGroupName>
  );
}
