export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: any;
  /** Denotes the value should be interpreted as currency */
  Currency: any;
  /**
   * Represents signed double-precision fractional values as specified by [IEEE
   * 754](https://en.wikipedia.org/wiki/IEEE_floating_point).
   */
  Hours: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** A high precision ISO 8601-encoded datetime */
  ISO8601DateTimeHighPrecision: any;
  /** An ISO 8601-encoded time in hh:mm */
  ISO8601HoursMinutes: any;
  /** Represents untyped JSON */
  JSON: any;
  /** Represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
  Minutes: any;
  /** Represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
  Seconds: any;
};

/** Employment Access Level values */
export enum AccessLevelEnum {
  BusinessOwner = "business_owner",
  Employee = "employee",
  EmployeeManager = "employee_manager",
  GeneralManager = "general_manager",
  GroupAdmin = "group_admin",
  ScheduleManager = "schedule_manager",
  ScheduleManagerWithPay = "schedule_manager_with_pay",
  ShiftManager = "shift_manager",
}

/** User Access Level values */
export enum AccessTypeEnum {
  Default = "default",
  Support = "support",
}

/** Action Log fields */
export type ActionLog = {
  __typename?: "ActionLog";
  /** Action type of the action log */
  actionType: Scalars["String"];
  /** Action log creation time */
  createdAt: Scalars["ISO8601DateTime"];
  /** Action Log ID */
  id: Scalars["ID"];
  /** ID of Object the action log belongs to */
  objId: Scalars["ID"];
  /** Class Name of Object the action log belongs to */
  objType: Scalars["String"];
  /** When the event happened which created the log */
  occurredAt: Scalars["ISO8601DateTime"];
  /** ID of Employment (if any) who created the action log */
  performedById?: Maybe<Scalars["ID"]>;
  /** ID of an object the action log is related to */
  relatedToId?: Maybe<Scalars["ID"]>;
  /** The unique ID of the API request associated with the action log */
  requestId?: Maybe<Scalars["ID"]>;
  /** Schedule ID of the action log if applicable */
  scheduleId?: Maybe<Scalars["ID"]>;
  /** Snapshot of the data changed in the action log */
  snapshot: Scalars["JSON"];
  /** Whether the action log was created by the backend / client support */
  systemAdded: Scalars["Boolean"];
  /** Source of system added action logs. Can be null if the action log was not created by the system */
  systemSource?: Maybe<SystemSourceEnum>;
  /** Action log hours update time */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for ActionLog. */
export type ActionLogConnection = {
  __typename?: "ActionLogConnection";
  /** A list of edges. */
  edges: Array<ActionLogEdge>;
  /** A list of nodes. */
  nodes: Array<ActionLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ActionLogEdge = {
  __typename?: "ActionLogEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ActionLog>;
};

/** Action Log Sort fields */
export type ActionLogSort = {
  /** Field to sort by */
  field: ActionLogSortField;
  /** Sort order */
  order: Order;
};

/** Action Log Sort Field values */
export enum ActionLogSortField {
  /** Action type */
  ActionType = "actionType",
  /** Primary key */
  Id = "id",
  /** Model name */
  ObjType = "objType",
  /** Occurred at */
  OccurredAt = "occurredAt",
}

/** Action Logs Meta fields */
export type ActionLogsMeta = {
  __typename?: "ActionLogsMeta";
  /** Time created */
  createdAt: Scalars["ISO8601DateTime"];
  /** The data type of the field */
  dataType?: Maybe<Scalars["String"]>;
  /** Whether logging of the field is deprecated */
  deprecated: Scalars["Boolean"];
  /** The display order of the field to use when sorting in the UI */
  displayOrder: Scalars["Int"];
  /** Human readable name of the field */
  externalName: Scalars["String"];
  /** Name of a field that can be in the changed_attributes jsonb of an action_logs entry for the given obj_type */
  fieldName: Scalars["String"];
  /** Replace sensitive snapshot data with placeholder values */
  hideSnapshotData: Scalars["Boolean"];
  /** Action Logs Meta ID */
  id: Scalars["ID"];
  /** Whether the field is an array of the data_type or a single value */
  isArray?: Maybe<Scalars["Boolean"]>;
  /** Class Name of Object the action log meta belongs to */
  objType: Scalars["String"];
  /** Time updated */
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** The connection type for ActionLogsMeta. */
export type ActionLogsMetaConnection = {
  __typename?: "ActionLogsMetaConnection";
  /** A list of edges. */
  edges: Array<ActionLogsMetaEdge>;
  /** A list of nodes. */
  nodes: Array<ActionLogsMeta>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ActionLogsMetaEdge = {
  __typename?: "ActionLogsMetaEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ActionLogsMeta>;
};

/** Analytics Actual fields */
export type AnalyticsActual = {
  __typename?: "AnalyticsActual";
  /** Analytics Actual data */
  data: Array<Scalars["Float"]>;
  /** Analytics Actual key */
  key: Scalars["String"];
  /** Analytics Actual data name (e.g. 'sales') */
  name: Scalars["String"];
  /** Analytics Actual timestamps */
  timestamps: Array<Scalars["ISO8601DateTime"]>;
  /** Analytics Actual data units */
  units: Scalars["String"];
};

/** Analytics Interval values */
export enum AnalyticsIntervalEnum {
  /** 15 minutes */
  FifteenMinutes = "fifteenMinutes",
  /** 1 day */
  OneDay = "oneDay",
}

/** Internal Analytics Sales Data Based Labour fields */
export type AnalyticsSalesDataBasedLabour = {
  __typename?: "AnalyticsSalesDataBasedLabour";
  /** Business Role ID */
  businessRoleId: Scalars["String"];
  /** Description */
  description: Scalars["String"];
  /** Forecast subtype */
  forecastSubtype: Scalars["String"];
  /** Forecast type */
  forecastType: Scalars["String"];
  /** Units for values */
  units: Scalars["String"];
  /** Values */
  values: Array<Scalars["Float"]>;
};

export type AnalyticsTopLevelMeasurement = {
  __typename?: "AnalyticsTopLevelMeasurement";
  /** Indicates measurement is of type currency */
  isCurrency: Scalars["Boolean"];
  /** Indicates primary measurement. Adjustments can only be added to primary measurement */
  isPrimary: Scalars["Boolean"];
  /** Label for forecast subtype measurement to be displayed on WebUI */
  label: Scalars["String"];
  /** Name of forecast subtype measurement */
  name: Scalars["String"];
};

/** Internal Analytics Top Level Measurement input fields */
export type AnalyticsTopLevelMeasurementInput = {
  /** Indicates measurement is of type currency */
  isCurrency: Scalars["Boolean"];
  /** Indicates primary measurement. Adjustments can only be added to primary measurement */
  isPrimary: Scalars["Boolean"];
  /** Label for forecast subtype measurement to be displayed on WebUI */
  label: Scalars["String"];
  /** Name of forecast subtype measurement */
  name: Scalars["String"];
};

/** Internal Analytics Total fields */
export type AnalyticsTotal = {
  __typename?: "AnalyticsTotal";
  /** Analytics Total data name (e.g. 'sales') */
  name: Scalars["String"];
  /** Analytics Total value */
  total: Scalars["Float"];
  /** Analytics Total data units */
  units: Scalars["String"];
};

/** Internal AOS Config fields */
export type AosConfig = {
  __typename?: "AosConfig";
  /** If selected, add breaks to core shifts that appears to need there */
  addCoreBreaks?: Maybe<Scalars["Boolean"]>;
  /** Add unfulfilled labor demand as unassigned shifts */
  addUnderstaffingShifts?: Maybe<Scalars["Boolean"]>;
  /** AOS combined roles object */
  aosCombinedRoles?: Maybe<Scalars["JSON"]>;
  /** AOS orchestrator config */
  aosOrchestratorConfig?: Maybe<Scalars["JSON"]>;
  /** Maximum duration AOS can run before timeout. Value is stored in seconds (duration). */
  aosTimeout?: Maybe<Scalars["Int"]>;
  /** Apply the 24-hour rest period rule */
  applyRule24HoursRest?: Maybe<Scalars["Boolean"]>;
  /** Roles which are eligible to be added in as an extra role on top of maximum role per shift */
  bonusRoles?: Maybe<Array<Scalars["String"]>>;
  /** Minimum duration between break end and the end of shift. Value is stored in minutes (duration). */
  breakEndPad?: Maybe<Scalars["Int"]>;
  /** Break duration. Value is stored in minutes (duration). Note: Ignored when AOS is rules-enabled */
  breakLength?: Maybe<Scalars["Int"]>;
  /** Maximum break start time from the start of shift. Value is stored in minutes (duration). */
  breakMaxStart?: Maybe<Scalars["Int"]>;
  /** Minimum break start time from the start of shift. Value is stored in minutes (duration). */
  breakMinStart?: Maybe<Scalars["Int"]>;
  /**
   * Add unpaid breaks to ensure the shift does not contain continuous work  longer
   * than this duration. Value is stored in minutes (duration). Note: Ignored when
   * AOS is rules-enabled
   */
  breakTrigger?: Maybe<Scalars["Int"]>;
  /**
   * If selected, templated shifts will be verified against the AOS custom rules
   * configuration. Otherwise, they will only be verified against the standard labor laws.
   */
  coreObeysAosRules?: Maybe<Scalars["Boolean"]>;
  /** How important is it to assign a similar number of labor hours to each employee? */
  costEqualLabor?: Maybe<Scalars["Int"]>;
  /** Configuration for daily part */
  daypartRanks?: Maybe<Scalars["JSON"]>;
  /** Roles which can only be scheduled when there is demand */
  demandOnly?: Maybe<Array<Scalars["String"]>>;
  /**
   * Assigning this role to a core shift (or a part of it) indicates to AOS to
   * optimally replace it with one of the "Schedulable Roles".
   */
  flexibleRole?: Maybe<Scalars["String"]>;
  /**
   * Roles for which breaks are ignored when determining their labor contribution
   * (Useful for roles where covering breaks is not essential)
   */
  ignoreBreakRoles?: Maybe<Array<Scalars["String"]>>;
  /** Roles included for scheduling by AOS */
  includeRoles?: Maybe<Array<Scalars["String"]>>;
  /** Maximum number of roles in a non-core shift (with the exception of bonus roles) */
  maxRolesPerShift?: Maybe<Scalars["Int"]>;
  /** How many calendar days can be worked per calendar week */
  maxWorkdaysCalweek?: Maybe<Scalars["Int"]>;
  /** How many work days can be worked per work week */
  maxWorkdaysWorkweek?: Maybe<Scalars["Int"]>;
  /** Minimum duration of each role */
  minShiftPartLength?: Maybe<Scalars["Int"]>;
  /** If selected, AOS will reassign breaks in core shifts to find an optimal schedule */
  optimizeCoreBreaks?: Maybe<Scalars["Boolean"]>;
  /** If we have to overstaff, which roles are preferable to put extra employees in? */
  overstaffingByRole?: Maybe<Array<Scalars["String"]>>;
  /** Should we try to avoid overstaffing, even if it might be less optimal? */
  overstaffingPenalty?: Maybe<Scalars["Int"]>;
  /** How much should we avoid assigning short shift parts in shifts? */
  penaltyShortParts?: Maybe<Scalars["Int"]>;
  /** What is the maximum shift part duration to consider 'short', and hence penalize? Value is stored in minutes (duration). */
  penaltyShortPartsCutoff?: Maybe<Scalars["Int"]>;
  /** The order of weekdays for AOS to plan (create) the schedule */
  planningOrder?: Maybe<Array<Scalars["String"]>>;
  /** AOS replanning orchestrator config */
  replanningOrchestratorConfig?: Maybe<Scalars["JSON"]>;
  /** Maximum duration replanning can run before timeout. Value is stored in seconds (duration). */
  replanningTimeout?: Maybe<Scalars["Int"]>;
  /** Start of scheduling day (24h) for AOS */
  schedulingDayStart?: Maybe<Scalars["String"]>;
  /** Custom configuration for a specific shift */
  shiftConfig?: Maybe<Scalars["JSON"]>;
  /** The minimum duration between shifts (to avoid clopening). Value is stored in minutes (duration). */
  shiftGapMin?: Maybe<Scalars["Int"]>;
  /** How much should we favor fewer, longer shifts over several, shorter shifts? */
  shiftLengthPreference?: Maybe<Scalars["Int"]>;
  /** Maximum shift duration. Value is stored in minutes (duration). */
  shiftMax?: Maybe<Scalars["Int"]>;
  /** The latest shift start time */
  shiftMaxStart?: Maybe<Scalars["String"]>;
  /** Minimum shift duration. Value is stored in minutes (duration). */
  shiftMin?: Maybe<Scalars["Int"]>;
  /**
   * The absolute minimum shift duration that AOS can allocate when available
   * period are shorter than minimum. Value is stored in minutes (duration).
   */
  shiftMinAbsolute?: Maybe<Scalars["Int"]>;
  /** How much should we favor assigning higher skilled staff to each role? */
  skillPreference?: Maybe<Scalars["Int"]>;
  /** If selected, AOS will not schedule more shifts to employee who have shifts in AOS core schedule */
  skipSchedulingCore?: Maybe<Scalars["Boolean"]>;
  /** If selected, AOS will not schedule manager and only assign managers shifts from AOS core schedule if present */
  skipSchedulingManagers?: Maybe<Scalars["Boolean"]>;
  /** If we have to cut, which roles should we AVOID cutting? */
  understaffingByRole?: Maybe<Array<Scalars["String"]>>;
  /** Configuration for weekly part */
  weeklyDayparts?: Maybe<Scalars["JSON"]>;
  /**
   * The maximum paid work hours that can be allocated to each employee for any
   * given week. Value is stored in minutes (duration).
   */
  weeklyWorkhoursMax?: Maybe<Scalars["Int"]>;
  /**
   * The desired minimum paid work hours to be allocated to each employee for any
   * given week. Value is stored in minutes (duration).
   */
  weeklyWorkhoursMin?: Maybe<Scalars["Int"]>;
  /** Relative preference for assigning hours to each group. Range 1-10, where 10 is the highest priority */
  weightEqualLaborByRating?: Maybe<Array<Scalars["Int"]>>;
};

/** Internal AOS Config input fields */
export type AosConfigInput = {
  /** If selected, add breaks to core shifts that appears to need there */
  addCoreBreaks?: Maybe<Scalars["Boolean"]>;
  /** Add unfulfilled labor demand as unassigned shifts */
  addUnderstaffingShifts?: Maybe<Scalars["Boolean"]>;
  /** AOS combined roles object */
  aosCombinedRoles?: Maybe<Scalars["JSON"]>;
  /** AOS orchestrator config */
  aosOrchestratorConfig?: Maybe<Scalars["JSON"]>;
  /** Maximum duration AOS can run before timeout. Value is stored in seconds (duration). */
  aosTimeout?: Maybe<Scalars["Int"]>;
  /** Apply the 24-hour rest period rule */
  applyRule24HoursRest?: Maybe<Scalars["Boolean"]>;
  /** Roles which are eligible to be added in as an extra role on top of maximum role per shift */
  bonusRoles?: Maybe<Array<Scalars["String"]>>;
  /** Minimum duration between break end and the end of shift. Value is stored in minutes (duration). */
  breakEndPad?: Maybe<Scalars["Int"]>;
  /** Break duration. Value is stored in minutes (duration). Note: Ignored when AOS is rules-enabled */
  breakLength?: Maybe<Scalars["Int"]>;
  /** Maximum break start time from the start of shift. Value is stored in minutes (duration). */
  breakMaxStart?: Maybe<Scalars["Int"]>;
  /** Minimum break start time from the start of shift. Value is stored in minutes (duration). */
  breakMinStart?: Maybe<Scalars["Int"]>;
  /**
   * Add unpaid breaks to ensure the shift does not contain continuous work  longer
   * than this duration. Value is stored in minutes (duration). Note: Ignored when
   * AOS is rules-enabled
   */
  breakTrigger?: Maybe<Scalars["Int"]>;
  /**
   * If selected, templated shifts will be verified against the AOS custom rules
   * configuration. Otherwise, they will only be verified against the standard labor laws.
   */
  coreObeysAosRules?: Maybe<Scalars["Boolean"]>;
  /** How important is it to assign a similar number of labor hours to each employee? */
  costEqualLabor?: Maybe<Scalars["Int"]>;
  /** Configuration for daily part */
  daypartRanks?: Maybe<Scalars["JSON"]>;
  /** Roles which can only be scheduled when there is demand */
  demandOnly?: Maybe<Array<Scalars["String"]>>;
  /**
   * Assigning this role to a core shift (or a part of it) indicates to AOS to
   * optimally replace it with one of the "Schedulable Roles".
   */
  flexibleRole?: Maybe<Scalars["String"]>;
  /**
   * Roles for which breaks are ignored when determining their labor contribution
   * (Useful for roles where covering breaks is not essential)
   */
  ignoreBreakRoles?: Maybe<Array<Scalars["String"]>>;
  /** Roles included for scheduling by AOS */
  includeRoles?: Maybe<Array<Scalars["String"]>>;
  /** Maximum number of roles in a non-core shift (with the exception of bonus roles) */
  maxRolesPerShift?: Maybe<Scalars["Int"]>;
  /** How many calendar days can be worked per calendar week */
  maxWorkdaysCalweek?: Maybe<Scalars["Int"]>;
  /** How many work days can be worked per work week */
  maxWorkdaysWorkweek?: Maybe<Scalars["Int"]>;
  /** Minimum duration of each role */
  minShiftPartLength?: Maybe<Scalars["Int"]>;
  /** If selected, AOS will reassign breaks in core shifts to find an optimal schedule */
  optimizeCoreBreaks?: Maybe<Scalars["Boolean"]>;
  /** If we have to overstaff, which roles are preferable to put extra employees in? */
  overstaffingByRole?: Maybe<Array<Scalars["String"]>>;
  /** Should we try to avoid overstaffing, even if it might be less optimal? */
  overstaffingPenalty?: Maybe<Scalars["Int"]>;
  /** How much should we avoid assigning short shift parts in shifts? */
  penaltyShortParts?: Maybe<Scalars["Int"]>;
  /** What is the maximum shift part duration to consider 'short', and hence penalize? Value is stored in minutes (duration). */
  penaltyShortPartsCutoff?: Maybe<Scalars["Int"]>;
  /** The order of weekdays for AOS to plan (create) the schedule */
  planningOrder?: Maybe<Array<Scalars["String"]>>;
  /** AOS replanning orchestrator config */
  replanningOrchestratorConfig?: Maybe<Scalars["JSON"]>;
  /** Maximum duration replanning can run before timeout. Value is stored in seconds (duration). */
  replanningTimeout?: Maybe<Scalars["Int"]>;
  /** Start of scheduling day (24h) for AOS */
  schedulingDayStart?: Maybe<Scalars["String"]>;
  /** Custom configuration for a specific shift */
  shiftConfig?: Maybe<Scalars["JSON"]>;
  /** The minimum duration between shifts (to avoid clopening). Value is stored in minutes (duration). */
  shiftGapMin?: Maybe<Scalars["Int"]>;
  /** How much should we favor fewer, longer shifts over several, shorter shifts? */
  shiftLengthPreference?: Maybe<Scalars["Int"]>;
  /** Maximum shift duration. Value is stored in minutes (duration). */
  shiftMax?: Maybe<Scalars["Int"]>;
  /** The latest shift start time */
  shiftMaxStart?: Maybe<Scalars["String"]>;
  /** Minimum shift duration. Value is stored in minutes (duration). */
  shiftMin?: Maybe<Scalars["Int"]>;
  /**
   * The absolute minimum shift duration that AOS can allocate when available
   * period are shorter than minimum. Value is stored in minutes (duration).
   */
  shiftMinAbsolute?: Maybe<Scalars["Int"]>;
  /** How much should we favor assigning higher skilled staff to each role? */
  skillPreference?: Maybe<Scalars["Int"]>;
  /** If selected, AOS will not schedule more shifts to employee who have shifts in AOS core schedule */
  skipSchedulingCore?: Maybe<Scalars["Boolean"]>;
  /** If selected, AOS will not schedule manager and only assign managers shifts from AOS core schedule if present */
  skipSchedulingManagers?: Maybe<Scalars["Boolean"]>;
  /** If we have to cut, which roles should we AVOID cutting? */
  understaffingByRole?: Maybe<Array<Scalars["String"]>>;
  /** Configuration for weekly part */
  weeklyDayparts?: Maybe<Scalars["JSON"]>;
  /**
   * The maximum paid work hours that can be allocated to each employee for any
   * given week. Value is stored in minutes (duration).
   */
  weeklyWorkhoursMax?: Maybe<Scalars["Int"]>;
  /**
   * The desired minimum paid work hours to be allocated to each employee for any
   * given week. Value is stored in minutes (duration).
   */
  weeklyWorkhoursMin?: Maybe<Scalars["Int"]>;
  /** Relative preference for assigning hours to each group. Range 1-10, where 10 is the highest priority */
  weightEqualLaborByRating?: Maybe<Array<Scalars["Int"]>>;
};

/** AOS Config Template fields */
export type AosConfigTemplate = {
  __typename?: "AosConfigTemplate";
  /** AOS Configuration */
  aosConfig: AosConfig;
  /** Time AOS configuration template created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employment ID of creator */
  createdById: Scalars["ID"];
  /** Name of creator employment */
  createdByName?: Maybe<Scalars["String"]>;
  /** AOS Configuration Template ID */
  id: Scalars["ID"];
  /** Indicates which AOS configuration template is the default for a business */
  isDefault: Scalars["Boolean"];
  /** AOS Configuration Template name */
  templateName: Scalars["String"];
  /** Time AOS configuration template updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Employment ID of updater */
  updatedById?: Maybe<Scalars["ID"]>;
  /** Name of updater employment */
  updatedByName?: Maybe<Scalars["String"]>;
};

/** The connection type for AosConfigTemplate. */
export type AosConfigTemplateConnection = {
  __typename?: "AosConfigTemplateConnection";
  /** A list of edges. */
  edges: Array<AosConfigTemplateEdge>;
  /** A list of nodes. */
  nodes: Array<AosConfigTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** Internal Aos Config Template Create input fields */
export type AosConfigTemplateCreateInput = {
  /** AOS Configuration */
  aosConfig?: Maybe<AosConfigInput>;
  /** Indicates which AOS configuration template is the default for a business */
  isDefault?: Maybe<Scalars["Boolean"]>;
  /** AOS Configuration Template name */
  templateName: Scalars["String"];
};

/** An edge in a connection. */
export type AosConfigTemplateEdge = {
  __typename?: "AosConfigTemplateEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<AosConfigTemplate>;
};

/** AOS Configuration Template Sort fields */
export type AosConfigTemplateSort = {
  /** Field to sort by */
  field: AosConfigTemplateSortField;
  /** Sort order */
  order: Order;
};

/** AOS Configuration Template Sort Field values */
export enum AosConfigTemplateSortField {
  /** Primary key */
  Id = "id",
  /** name of the template */
  TemplateName = "templateName",
}

/** Internal Aos Config Template Update input fields */
export type AosConfigTemplateUpdateInput = {
  /** AOS Configuration */
  aosConfig?: Maybe<AosConfigInput>;
  /** Indicates which AOS configuration template is the default for a business */
  isDefault?: Maybe<Scalars["Boolean"]>;
  /** AOS Configuration Template name */
  templateName?: Maybe<Scalars["String"]>;
};

/** Internal AOS Group fields */
export type AosGroup = {
  __typename?: "AosGroup";
  /** Proficiency Level */
  group?: Maybe<Scalars["Int"]>;
  /** Proficiency Level Label */
  name?: Maybe<Scalars["String"]>;
};

/** Approval Requests Configuration fields */
export type ApprovalRequestsConfig = {
  __typename?: "ApprovalRequestsConfig";
  /** Indicates if maintenance of Employment rate is allowed */
  employmentRateChange: EmploymentRateChangeRequestConfig;
};

/** Internal Approval Requests Configuration fields */
export type ApprovalRequestsConfigInput = {
  /** Indicates if maintenance of Employment rate is allowed */
  employmentRateChange?: Maybe<EmploymentRateChangeRequestConfigInput>;
};

/** Internal Audit Log fields */
export type AuditLog = {
  __typename?: "AuditLog";
  /** A short action tag related to the log. Eg: created, updated */
  actionType: AuditLogActionTypeEnum;
  /** The 3rd party identifier code of the logged object, if any */
  code?: Maybe<Scalars["String"]>;
  /** Audit log id */
  id: Scalars["ID"];
  /** A json blob with some information related to whatever the log is reporting on */
  meta: Scalars["JSON"];
  /** UUID of the object */
  objId: Scalars["ID"];
  /** Object type */
  objType: Scalars["String"];
  /** When the event happened which created the log */
  occurredAt: Scalars["ISO8601DateTime"];
  /** The employment who created the record */
  performedBy?: Maybe<Employment>;
  /** The employment_id of who created the record */
  performedById?: Maybe<Scalars["ID"]>;
  /** A hash indicating related objects and listing attributes that were modified by the logged action, if any */
  related?: Maybe<Scalars["JSON"]>;
  /** The employment_id of the employee this log is associated to */
  relatedToId?: Maybe<Scalars["ID"]>;
  /** The unique ID of the API request, if any. Not a foreign key */
  requestId?: Maybe<Scalars["ID"]>;
  /** The schedule this log is associated to */
  schedule?: Maybe<Schedule>;
  /** Schedule ID */
  scheduleId?: Maybe<Scalars["ID"]>;
  /** Any specific action type like bulk copy etc. Cannot be null if action_type is 'specific' */
  specificAction?: Maybe<Scalars["String"]>;
};

/** Audit Log Action Type values */
export enum AuditLogActionTypeEnum {
  Created = "created",
  Deleted = "deleted",
  Specific = "specific",
  Updated = "updated",
}

/** The connection type for AuditLog. */
export type AuditLogConnection = {
  __typename?: "AuditLogConnection";
  /** A list of edges. */
  edges: Array<AuditLogEdge>;
  /** A list of nodes. */
  nodes: Array<AuditLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type AuditLogEdge = {
  __typename?: "AuditLogEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<AuditLog>;
};

/** Audit Log Sort fields */
export type AuditLogSort = {
  /** Field to sort by */
  field: AuditLogSortField;
  /** Sort order */
  order: Order;
};

/** Audit Log Sort Field values */
export enum AuditLogSortField {
  /** Primary key */
  Id = "id",
  /** Model name */
  ObjType = "objType",
  /** Occurred at */
  OccurredAt = "occurredAt",
}

/** Internal Auto Reject Unavailability Event Time fields */
export type AutoRejectTime = {
  __typename?: "AutoRejectTime";
  /** Unit of time prior to shift start to reject unavailability event (i.e. 'days', 'hours') */
  unit: Scalars["String"];
  /** Number of :unit prior to shift start to reject unavailability event. Must be a whole number > 0. */
  value: Scalars["Int"];
};

/** Internal Auto Reject Unavailability Event Time input fields */
export type AutoRejectTimeInput = {
  /** Unit of time prior to shift start to reject unavailability event (i.e. 'days', 'hours') */
  unit?: Maybe<Scalars["String"]>;
  /** Number of :unit prior to shift start to reject unavailability event. Must be a whole number > 0. */
  value?: Maybe<Scalars["Int"]>;
};

/** Internal Auto Warn Unavailability Event Time fields */
export type AutoWarnTime = {
  __typename?: "AutoWarnTime";
  /** Unit of time prior to shift start to warn of unavailability event rejection (i.e. 'days', 'hours') */
  unit: Scalars["String"];
  /** Number of :unit prior to shift start to warn of unavailability event rejection. Must be a whole number > 0. */
  value: Scalars["Int"];
};

/** Internal Auto Warn Unavailability Event Time input fields */
export type AutoWarnTimeInput = {
  /** Unit of time prior to shift start to warn of unavailability event rejection (i.e. 'days', 'hours') */
  unit?: Maybe<Scalars["String"]>;
  /** Number of :unit prior to shift start to warn of unavailability event rejection. Must be a whole number > 0. */
  value?: Maybe<Scalars["Int"]>;
};

/** Availability interface */
export type Availability = {
  /** Array of objects containing minimum hours for each week in availability definition */
  cachedMinHoursPerWeek?: Maybe<Scalars["JSON"]>;
  /** Indicates if the linked employment waived consecutive days off */
  consecutiveDaysOffWaived: Scalars["Boolean"];
  /** Time created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employment id of creator */
  createdById?: Maybe<Scalars["ID"]>;
  /** Association linking the availability definition to day groups containing available hours definitions */
  days: Array<AvailabilityDetailsByDay>;
  /** Whether the availability definition is soft-deleted */
  deleted: Scalars["Boolean"];
  /** Indicates if the linked employment created the availability */
  employeeInitiated: Scalars["Boolean"];
  /** Employment that availability is associated to */
  employmentId: Scalars["ID"];
  /** Availability end date. Can be null */
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Availability ID */
  id: Scalars["ID"];
  /** Time that manager acknowledged notification. Can be null */
  managerAcknowledgedNotificationAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /**
   * The max number of seconds per week for the whole availability definition. It
   * will be the same value for each week in a multi-week definition
   */
  maxDurationPerWeek?: Maybe<Scalars["Seconds"]>;
  /** How many weeks are in the availability definition */
  numberOfWeeks: Scalars["Int"];
  /** The message attached to the request if any */
  requestingMessage?: Maybe<Scalars["String"]>;
  /** The time of the response (could be manager or employee) */
  respondedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The employment id of the responder (could be manager or employee). respondedBy === createdBy =>"Cancelled" */
  respondedById?: Maybe<Scalars["ID"]>;
  /** The message attached to the response if any */
  respondingMessage?: Maybe<Scalars["String"]>;
  /** Availability start date. Defaults to date of tomorrow */
  startDate: Scalars["ISO8601Date"];
  /** Status of availability */
  status: AvailabilityStatusEnum;
  /** Time of submission for approval/acceptance */
  submittedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /**
   * Most often equal to createdBy, but or the case of manager-initiated, Manager A
   * could start a draft and manager B could finish and submit
   */
  submittedById?: Maybe<Scalars["ID"]>;
  /** Type of availability */
  type: AvailabilityTypeEnum;
  /** Time updated */
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Employment id of updater */
  updatedById?: Maybe<Scalars["ID"]>;
  /** If true, employee is willing to work extra hours outside of fixed contract */
  willingToWorkExtraHours: Scalars["Boolean"];
};

/** The connection type for Availability. */
export type AvailabilityConnection = {
  __typename?: "AvailabilityConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AvailabilityEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Availability>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Internal Availability Details By Day fields */
export type AvailabilityDetailsByDay = {
  __typename?: "AvailabilityDetailsByDay";
  /** Available hours that belong under the day sorting */
  availableHours: Array<AvailableHours>;
  /** Represents Sunday to Saturday day of week */
  dayOfWeek: DayOfWeekEnum;
  /** Fixed shifts that belong under the day sorting */
  fixedShifts?: Maybe<Array<FixedShift>>;
  /** 0, 1, 2 etc - to represent 1st week, 2nd week etc */
  weekIndex: Scalars["Int"];
};

/** Internal Available Hours By Day Create input fields */
export type AvailabilityDetailsByDayCreateInput = {
  /** An array of available hours within a specific day sorting to link to the availability */
  availableHours?: Maybe<Array<AvailableHoursCreateInput>>;
  /** Represents Sunday to Saturday day of week */
  dayOfWeek: DayOfWeekEnum;
  /** An array of fixed shifts within a specific day sorting to link to the availability */
  fixedShifts?: Maybe<Array<FixedShiftCreateInput>>;
  /** 0, 1, 2 etc - to represent 1st week, 2nd week etc */
  weekIndex: Scalars["Int"];
};

/** Internal Availability Details By Day Update input fields */
export type AvailabilityDetailsByDayUpdateInput = {
  /** An array of available hours within a specific day sorting to link to the availability */
  availableHours?: Maybe<Array<AvailableHoursUpdateInput>>;
  /** Represents Sunday to Saturday day of week */
  dayOfWeek: DayOfWeekEnum;
  /** An array of fixed shifts within a specific day sorting to link to the availability */
  fixedShifts?: Maybe<Array<FixedShiftUpdateInput>>;
  /** 0, 1, 2 etc - to represent 1st week, 2nd week etc */
  weekIndex: Scalars["Int"];
};

/** An edge in a connection. */
export type AvailabilityEdge = {
  __typename?: "AvailabilityEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Availability>;
};

/** Availability Sort fields */
export type AvailabilitySort = {
  /** Field to sort by */
  field: AvailabilitySortField;
  /** Sort order */
  order: Order;
};

/** Availability Sort Field values */
export enum AvailabilitySortField {
  EmploymentId = "employmentId",
  EndDate = "endDate",
  StartDate = "startDate",
  Status = "status",
  Type = "type",
}

/** Availability Status values */
export enum AvailabilityStatusEnum {
  Active = "active",
  ConsentPending = "consent_pending",
  ConsentRejected = "consent_rejected",
  Draft = "draft",
}

/** Availability Type values */
export enum AvailabilityTypeEnum {
  AvailabilityNoPermanentWorkSchedule = "Availability_NoPermanentWorkSchedule",
  AvailabilityTemporaryAvailability = "Availability_TemporaryAvailability",
}

/** Internal Available Hours fields */
export type AvailableHours = {
  __typename?: "AvailableHours";
  /** ID of the availability these available-hours belong to */
  availabilityId: Scalars["ID"];
  /** Represents Sunday to Saturday day of week */
  dayOfWeek: DayOfWeekEnum;
  /** Whether the available hours are soft-deleted */
  deleted: Scalars["Boolean"];
  /** End time of available hours */
  endTime: Scalars["ISO8601HoursMinutes"];
  /** Available hours ID */
  id?: Maybe<Scalars["ID"]>;
  /** Start time of available hours */
  startTime: Scalars["ISO8601HoursMinutes"];
  /** 0, 1, 2 etc - to represent 1st week, 2nd week etc */
  weekIndex: Scalars["Int"];
};

/** Internal Available Hours By Day Create input fields */
export type AvailableHoursByDayCreateInput = {
  /** An array of available hours within a specific day sorting to link to the availability */
  availableHours?: Maybe<Array<AvailableHoursCreateInput>>;
  /** Represents Sunday to Saturday day of week */
  dayOfWeek: DayOfWeekEnum;
  /** 0, 1, 2 etc - to represent 1st week, 2nd week etc */
  weekIndex: Scalars["Int"];
};

/** Internal Available Hours By Day Update input fields */
export type AvailableHoursByDayUpdateInput = {
  /** An array of available hours within a specific day sorting to link to the availability */
  availableHours?: Maybe<Array<AvailableHoursUpdateInput>>;
  /** Represents Sunday to Saturday day of week */
  dayOfWeek: DayOfWeekEnum;
  /** 0, 1, 2 etc - to represent 1st week, 2nd week etc */
  weekIndex: Scalars["Int"];
};

/** Internal Available Hours Create input fields */
export type AvailableHoursCreateInput = {
  /** End time of available hours */
  endTime: Scalars["ISO8601HoursMinutes"];
  /** Start time of available hours */
  startTime: Scalars["ISO8601HoursMinutes"];
};

/** Internal Available Hours Update input fields */
export type AvailableHoursUpdateInput = {
  /** End time of available hours */
  endTime: Scalars["ISO8601HoursMinutes"];
  /** Start time of available hours */
  startTime: Scalars["ISO8601HoursMinutes"];
};

/** Blackout Day fields */
export type BlackoutDay = {
  __typename?: "BlackoutDay";
  /** Created at of blackout day */
  createdAt: Scalars["ISO8601DateTime"];
  /** End date of blackout day */
  endDate: Scalars["ISO8601Date"];
  /** Blackout day ID */
  id: Scalars["ID"];
  /** ID of the schedule this blackout day belongs to */
  scheduleId: Scalars["ID"];
  /** Start date of blackout day */
  startDate: Scalars["ISO8601Date"];
  /** title of blackout day */
  title: Scalars["String"];
  /** Updated at of blackout day */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for BlackoutDay. */
export type BlackoutDayConnection = {
  __typename?: "BlackoutDayConnection";
  /** A list of edges. */
  edges: Array<BlackoutDayEdge>;
  /** A list of nodes. */
  nodes: Array<BlackoutDay>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type BlackoutDayEdge = {
  __typename?: "BlackoutDayEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<BlackoutDay>;
};

/** Break Type fields */
export type BreakType = {
  __typename?: "BreakType";
  /** Available range of the break. */
  availableRange?: Maybe<HoursMinutesRange>;
  /** Ordering of the break types in time clock "Select break type" screen. Must be unique */
  breakScreenOrdering: Scalars["Int"];
  /** Code of the break type */
  breakTypeCode: Scalars["String"];
  /** If true, the paid / unpaid value of a time clock break can be modified, regardless of threshold */
  canOverrideClockedPaidBreak: Scalars["Boolean"];
  /** 3rd party code for the break type */
  code?: Maybe<Scalars["String"]>;
  /** Time the break type was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Default duration of the break in minutes. Can be nil to signify no default duration */
  defaultDuration?: Maybe<Scalars["Minutes"]>;
  /** Whether the break type has been soft deleted */
  deleted: Scalars["Boolean"];
  /** Description of the break type */
  description?: Maybe<Scalars["String"]>;
  /** Employment metadata used to determine break eligibility */
  employmentMetadata?: Maybe<Scalars["String"]>;
  /** Types of employee eligible for this break type, defaults to empty array. */
  employmentTypeCodes: Array<Scalars["String"]>;
  /** Types of employee eligible for this break type, defaults to empty array. */
  employmentTypeConfigs: Array<EmploymentTypeConfig>;
  /** Small ids of types of employee eligible for this break type, defaults to empty array. */
  employmentTypeSmallIds: Array<Scalars["Int"]>;
  /**
   * Legacy types of employee eligible for this break type, defaults to empty array.
   * @deprecated Use employmentTypeCodes or employmentTypeConfigs { nodes { employmentTypeCode } } instead.
   */
  employmentTypes: Array<EmploymentTypeEnum>;
  /** Break Type ID */
  id: Scalars["ID"];
  /** Legacy break type this break is associated with. Unknown is allowed only for the default unknown break type. */
  legacyBreakType: LegacyBreakTypeEnum;
  /** Name of the break type */
  name: Scalars["String"];
  /** Duration above which break becomes unpaid */
  paidThreshold: Scalars["Minutes"];
  /** Whether the break type can be scheduled for a shift. */
  schedulable: Scalars["Boolean"];
  /** Time the break type was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for BreakType. */
export type BreakTypeConnection = {
  __typename?: "BreakTypeConnection";
  /** A list of edges. */
  edges: Array<BreakTypeEdge>;
  /** A list of nodes. */
  nodes: Array<BreakType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** Internal Break Type Create input fields */
export type BreakTypeCreateInput = {
  /** Time range for which the break can be scheduled */
  availableRange?: Maybe<HoursMinutesRangeCreateInput>;
  /** Ordering of the break types in time clock "Select break type" screen. Must be unique */
  breakScreenOrdering: Scalars["Int"];
  /** Code of the break type */
  breakTypeCode: Scalars["String"];
  /** If true, the paid / unpaid value of a time clock break can be modified, regardless of threshold */
  canOverrideClockedPaidBreak: Scalars["Boolean"];
  /** 3rd party code for the break type */
  code?: Maybe<Scalars["String"]>;
  /** Default duration of the break in minutes. Can be nil to signify no default duration */
  defaultDuration?: Maybe<Scalars["Minutes"]>;
  /** Description of the break type */
  description?: Maybe<Scalars["String"]>;
  /** Employment metadata used to determine break eligibility */
  employmentMetadata?: Maybe<Scalars["String"]>;
  /** Types of employee eligible for this break type, defaults to empty array. */
  employmentTypeCodes?: Maybe<Array<Scalars["String"]>>;
  /** Small ids of types of employee eligible for this break type, defaults to empty array. */
  employmentTypeSmallIds?: Maybe<Array<Scalars["Int"]>>;
  /** Legacy types of employee eligible for this break type, defaults to empty array. */
  employmentTypes?: Maybe<Array<EmploymentTypeEnum>>;
  /** Legacy break type this break is associated with. Unknown is allowed only for the default unknown break type. */
  legacyBreakType: LegacyBreakTypeEnum;
  /** Name of the break type */
  name: Scalars["String"];
  /** Duration above which break becomes unpaid */
  paidThreshold: Scalars["Minutes"];
  /** Whether the break type can be scheduled for a shift. */
  schedulable?: Maybe<Scalars["Boolean"]>;
};

/** An edge in a connection. */
export type BreakTypeEdge = {
  __typename?: "BreakTypeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<BreakType>;
};

/** Break Type Sort fields */
export type BreakTypeSort = {
  /** Field to sort by */
  field: BreakTypeSortField;
  /** Sort order */
  order: Order;
};

/** Break Type Sort Field values */
export enum BreakTypeSortField {
  /** Break screen order of the break type */
  BreakScreenOrdering = "breakScreenOrdering",
  /** Code of the break type */
  BreakTypeCode = "breakTypeCode",
  /** Primary key */
  Id = "id",
  /** Break Type name */
  Name = "name",
}

/** Internal Break Type Update input fields */
export type BreakTypeUpdateInput = {
  /** Time range for which the break can be scheduled */
  availableRange?: Maybe<HoursMinutesRangeCreateInput>;
  /** Ordering of the break types in time clock "Select break type" screen. Must be unique */
  breakScreenOrdering?: Maybe<Scalars["Int"]>;
  /** If true, the paid / unpaid value of a time clock break can be modified, regardless of threshold */
  canOverrideClockedPaidBreak?: Maybe<Scalars["Boolean"]>;
  /** 3rd party code for the break type */
  code?: Maybe<Scalars["String"]>;
  /** Default duration of the break in minutes. Can be nil to signify no default duration */
  defaultDuration?: Maybe<Scalars["Minutes"]>;
  /** Description of the break type */
  description?: Maybe<Scalars["String"]>;
  /** Employment metadata used to determine break eligibility */
  employmentMetadata?: Maybe<Scalars["String"]>;
  /** Types of employee eligible for this break type, defaults to empty array. */
  employmentTypeCodes?: Maybe<Array<Scalars["String"]>>;
  /** Small ids of types of employee eligible for this break type, defaults to empty array. */
  employmentTypeSmallIds?: Maybe<Array<Scalars["Int"]>>;
  /** Legacy types of employee eligible for this break type, defaults to empty array. */
  employmentTypes?: Maybe<Array<EmploymentTypeEnum>>;
  /** Legacy break type this break is associated with. Unknown is allowed only for the default unknown break type. */
  legacyBreakType?: Maybe<LegacyBreakTypeEnum>;
  /** Name of the break type */
  name?: Maybe<Scalars["String"]>;
  /** Duration above which break becomes unpaid */
  paidThreshold?: Maybe<Scalars["Minutes"]>;
  /** Whether the break type can be scheduled for a shift. */
  schedulable?: Maybe<Scalars["Boolean"]>;
};

/** Internal Business fields */
export type Business = {
  __typename?: "Business";
  /** Primary accent color */
  accentColor1: Scalars["String"];
  /**
   * Indicates if WebUI aggregator should aggregate pivot metrics 'next_day: true'
   * to same day the shift starts on. Default is false
   */
  aggregateToStartDay: Scalars["Boolean"];
  /** When enabled manager can approve their own punches in TimeClockApp. Default is false. */
  allowManagerToApproveOwnPunch: Scalars["Boolean"];
  /** Indicates whether analytics are enabled for the business as a whole. Default is false. */
  analyticsEnabled: Scalars["Boolean"];
  /** Base URL of the Analytics server */
  analyticsServerUrl?: Maybe<Scalars["String"]>;
  /** Indicates whether anonymous drop is enabled to the user. Default is false. */
  anonymousShiftDropToUser: Scalars["Boolean"];
  /** Indicates whether anonymous swap is enabled to the user. Default is false. */
  anonymousShiftSwapToUser: Scalars["Boolean"];
  /** AOS Configuration Schema. */
  aosConfigSchema: Scalars["JSON"];
  /** Proficiency level configuration of employee. */
  aosGroups?: Maybe<Array<AosGroup>>;
  /** Determines whether an approval is needed for commendation */
  approvalNeededForCommendation: Scalars["Boolean"];
  /** Determines whether an approval is needed for disciplinary action */
  approvalNeededForDisciplinaryAction: Scalars["Boolean"];
  /** Indicates whether manager approval needed on role change, Default is false */
  approvalNeededForRoleRateChange: Scalars["Boolean"];
  /** If requesting manager belongs to highest level approval is not required */
  approvalRequests: ApprovalRequestsConfig;
  /** User assigned employment confirmation text. Maximum character limit is 500 */
  assignEmployeeConfirmationText?: Maybe<Scalars["String"]>;
  /** Indicates whether rostered shifts should be auto accepted when published. Default is false. */
  autoAcceptRosteredShifts: Scalars["Boolean"];
  /** Amount of time prior to start time where the unavailability request is automatically rejected */
  autoRejectTimeFromUnavailabilityStart: AutoRejectTime;
  /** Reject the user's unavailability request if it is past the threshold. Default is false. */
  autoRejectUnavailabilityEvents: Scalars["Boolean"];
  /** When true, allows employees to create and modify availability requests on weeks prior to the latest published week. */
  availabilityChangeDuringPublishedScheduleEnabled: Scalars["Boolean"];
  /**
   * Whether a manager created availability requires consent from the employee.
   * If not, manager created availabilities go straight from draft to active
   */
  availabilityConsentRequired: Scalars["Boolean"];
  /** The number of days from the start of a new availability that are checked for coverage of shifts. Default is 28. */
  availabilityCoverageHorizonDays: Scalars["Int"];
  /** Allow employee comments in availability objects */
  availabilityEmployeeComments: Scalars["Boolean"];
  /** Enable to make any published shift that is not covered an error. Default is false. */
  availabilityErrorOnAnyShiftConflict: Scalars["Boolean"];
  /** An employee initiated availability must be created at least this many days in the future. Minimum is 1 */
  availabilityErrorOnMinNoticeDays: Scalars["Int"];
  /** Enable to make any published rostered shift that is not covered an error. Default is false. */
  availabilityErrorOnRosteredShiftConflict: Scalars["Boolean"];
  /** Allow manager comments in availability objects */
  availabilityManagerComments: Scalars["Boolean"];
  /** The maximum number of weeks in an availability cycle. Minimum is 1 */
  availabilityMaxCycleWeeks: Scalars["Int"];
  /** The set of availability types that may be created */
  availabilityTypesEnabled: Array<AvailabilityTypeEnum>;
  /** Enable to allow availability consecutive days off waived. Default is: false. */
  availabilityUseConsecutiveDaysOffWaived: Scalars["Boolean"];
  /** Whether max duration per week is validated on the backend and displayed in the UI */
  availabilityUseMaxDurationPerWeek: Scalars["Boolean"];
  /**
   * An employee initiated availability created less than this many days in the
   * future will have a warning displayed prior to submission.
   * Minimum is 1.
   * If equal to or greater than availability_error_on_min_notice_days, the warning is not shown as the error takes precedence
   */
  availabilityWarnOnMinNoticeDays: Scalars["Int"];
  /** Business name */
  businessName: Scalars["String"];
  /** Business terms that want to be replaced */
  businessTerms: Scalars["JSON"];
  /**
   * Whether managers can make forecast adjustments. Defaults to true. Set to false
   * in cases where forecast adjustment/management is external to LIFELENZ
   */
  canAdjustForecast: Scalars["Boolean"];
  /** Indicates if chat is enabled. Default is true. */
  chatEnabled: Scalars["Boolean"];
  /** Minutes before the shift start time in which user can clock in */
  clockInThreshold?: Maybe<Scalars["Int"]>;
  /** Minutes after the shift end time in which user can clock out */
  clockOutThreshold?: Maybe<Scalars["Int"]>;
  /** Status of Shift clock-in/out tracking. Default is false. */
  clockTrackingEnabled: Scalars["Boolean"];
  /** Enable branding on the top left of clock using the LIFELENZ logo as a replacement for home. Default is false. */
  coBrandingEnabled: Scalars["Boolean"];
  /** Determines whether employee sees any notifications for published commendations */
  commendationEmploymentNotifications: Scalars["Boolean"];
  /** Determines whether commendation setting is enabled */
  commendationEnabled: Scalars["Boolean"];
  /**
   * Indicates whether times other than contracted are treated as available. This
   * setting is deprecated and returns false always.
   */
  contractedTimesImplicitlyAvailable: Scalars["Boolean"];
  /** Whether an employment contract change needs consent from the employee */
  contractsConsentEnabled: Scalars["Boolean"];
  /** Whether employment type is used for employment contracts */
  contractsUseEmploymentType: Scalars["Boolean"];
  /** Whether min duration per week is used for employment contracts */
  contractsUseMinDurationPerWeek: Scalars["Boolean"];
  /** Time business created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Enable running rules engine checks across all schedules */
  crossScheduleComplianceChecksEnabled: Scalars["Boolean"];
  /** Enable disclaimer for csv reports. */
  csvReportDisclaimerEnabled: Scalars["Boolean"];
  /** Currency types. Default is USD ($) */
  currencyType: CurrencyEnum;
  /** Sync delay in minutes before fetching data from analytics for DAR. Default is 10. */
  darAnalyticsSyncThreshold: Scalars["Int"];
  /** Only display past time slices in the daily activity report. */
  darDataOnlyPastSlicesEnabled: Scalars["Boolean"];
  /** Indicates whether daily activity report is enabled. Default is false. */
  darEnabled: Scalars["Boolean"];
  /** Configuration daily activity report settings */
  darFields: Array<BusinessDarField>;
  /** Data lake global business id (used by importer). Maximum character limit is 500 */
  dataLakeGlobalId?: Maybe<Scalars["String"]>;
  /** Data lake business id (used by importer). Maximum character limit is 500 */
  dataLakeId?: Maybe<Scalars["String"]>;
  /**
   * List of employment metadata_types that when modified should update
   * datapass_changed. Maps to the "name" column of the metadata_types table.
   */
  datapassAffectingMetadata: Array<Scalars["String"]>;
  /** Whether to compute and keep up to date the datapass_changed timestamp. */
  datapassTrackingEnabled: Scalars["Boolean"];
  /** Date format. Default is 'date_month_year' */
  dateFormat: Scalars["String"];
  /** The time in minutes that manager created breaks should be initially set to. Default is 30. */
  defaultBreakDuration: Scalars["Int"];
  /** Default value for use in setting 'payroll_cutoff_time' property on schedules. Default is nil */
  defaultPayrollCutoffTime?: Maybe<Scalars["String"]>;
  /** Default value for use in setting 'day_end_time' property on schedules. Default is '00:00:00' */
  defaultScheduleDayEndTime: Scalars["String"];
  /** Default value for use in setting 'day_start_time' property on schedules. Default is '00:00:00' */
  defaultScheduleDayStartTime: Scalars["String"];
  /** Default value for use in setting 'snapshot_duration' property on schedules. Default is 7. */
  defaultScheduleSnapshotDuration: Scalars["Int"];
  /** Default value for use in setting 'snapshot_start_date' property on schedules */
  defaultScheduleSnapshotStartDate?: Maybe<Scalars["String"]>;
  /** Whether you can delete primary JTCs through the web ui. Default is false. */
  deletePrimaryJobTitlesEnabled: Scalars["Boolean"];
  /** Indicates whether employee can initiate a leave request. Default is false. */
  disableEmployeeLeaveRequest: Scalars["Boolean"];
  /** Determines whether employee sees any notifications for published disciplinary actions */
  disciplinaryActionEmploymentNotifications: Scalars["Boolean"];
  /** Determines whether disciplinary action setting is enabled */
  disciplinaryActionEnabled: Scalars["Boolean"];
  /** Display warnings to the user when their unavailability request is past the threshold. Default is false. */
  displayWarningUnavailabilityEvents: Scalars["Boolean"];
  /** The text to display in a disclaimer popup to employees when creating or accepting a shift drop */
  dropMobileDisclaimer?: Maybe<Scalars["String"]>;
  /** Whether to display a disclaimer popup to employees when creating or accepting a shift drop */
  dropMobileDisclaimerEnabled: Scalars["Boolean"];
  /** Duration format. Default is 'decimal_hours' */
  durationFormat: Scalars["String"];
  /** Layout for the Dynamic Fields */
  dynamicFieldsLayout: DynamicFieldsLayout;
  /** The time in minutes that user can early punch in without manager authorize */
  earlyShiftStartThreshold?: Maybe<Scalars["Int"]>;
  /** Type of Earnings estimator */
  earningsEstimator: Scalars["String"];
  /**
   * Enum of GMH restrictions for each hard coded employment type. If an employment
   * type is missing, the defaults are true, null, null
   * @deprecated Access EmploymentTypeConfigs directly
   */
  emplTypeGmhSettings: EmplTypeGmhSettings;
  /**
   * Whether a compliance warning should be generated when an employee with the
   * specified employment type has leave with no "time_off" shifts covering it
   */
  emplTypeTimeOffComplianceEnabled: EmplTypeTimeOffComplianceEnabled;
  /** Indicates whether employee availabilities are editable by manager. Default is true. */
  employeeAvailabilityEditableByManager: Scalars["Boolean"];
  /** Whether this business uses employee drafts feature. Default is false. */
  employeeDraftsEnabled: Scalars["Boolean"];
  /** Indicates if employee multi-rate is enabled. Default is false. */
  employeeMultiRateEnabled: Scalars["Boolean"];
  /** Contains the config to pre-process the employment code before storing it in DB. */
  employmentCodePreprocessor?: Maybe<Scalars["JSON"]>;
  /** Set of flags to indicate which employment attributes are externally managed */
  employmentExternalManagedFlags: EmploymentExternalManagedFlags;
  /** Whether editing of employment rates is enabled. Default is: false */
  employmentRateEditEnabled: Scalars["Boolean"];
  /** Determines how editing of employment rates in the past is handled. Defaults to Disabled */
  employmentRateEditPast: EmploymentRateEditPastEnum;
  /**
   * Fixed shift restrictions for employment types.
   * @deprecated Access EmploymentTypeConfigs directly
   */
  employmentTypeFixedShiftRestriction: EmploymentTypeFixedShiftRestriction;
  /** Indicates whether the business allows miscellaneous pay. Default is false. */
  enableMiscPay: Scalars["Boolean"];
  /** Indicates if creating/modifying/deleting shifts in past will be allowed. Default is false. */
  enablePastShiftOperation: Scalars["Boolean"];
  /**
   * Whether shift differentials are enabled for this business. Note that the
   * additional_pivot_metrics may still be used by other features even if shift
   * differentials is disabled.
   */
  enableShiftDifferentials: Scalars["Boolean"];
  /** At least one of this flag or feedback_reason_enabled must be true (feedback needs either a reason or a comment) */
  feedbackCommentEnabled: Scalars["Boolean"];
  /** Determines whether a reason is selectable & required for an employment_feedback */
  feedbackReasonEnabled: Scalars["Boolean"];
  /** Index to indicate the first day of a work week, starting from Sunday (0). Default is 0. */
  firstDayOfWeek: Scalars["Int"];
  /** First name abbreviation count. Default is 24. */
  firstNameCharCount: Scalars["Int"];
  /** Ability to add suffix to first name of all employees */
  firstNameSuffix: Scalars["String"];
  /** Whether break duration is visible on the management screen. Default is false. */
  fixedShiftsBreakDurationEnabled: Scalars["Boolean"];
  /** Whether this business uses fixed shifts. Default is false. */
  fixedShiftsEnabled: Scalars["Boolean"];
  /** ID of business role to use when fixed_shifts are enabled. Default is nil. */
  fixedShiftsRoleId?: Maybe<Scalars["ID"]>;
  /** Disclaimer text to include in PDF footer. Maximum character limit is 500 */
  footerDisclaimer?: Maybe<Scalars["String"]>;
  /**
   * Whether forecasts require approval. Defaults to true. Set to false in cases
   * where forecast adjustment/management is external to LIFELENZ
   */
  forecastRequiresApproval: Scalars["Boolean"];
  /** The ID of the Global Business this business belongs to */
  globalBusinessId?: Maybe<Scalars["ID"]>;
  /** ID of image to be used as the business avatar */
  globalImageId?: Maybe<Scalars["ID"]>;
  /** Whether client software should send multiple graphql queries batched in the one request. Default is false. */
  graphqlQueryBatchingEnabled: Scalars["Boolean"];
  /** Indicates that overstaffing should look more severe than understaffing. Default is true. */
  highlightOverstaffing: Scalars["Boolean"];
  /** When true, allows managers to add comments during approval process */
  homeStoreTransferManagerCommentsEnabled: Scalars["Boolean"];
  /** Business id */
  id: Scalars["ID"];
  /** Indicates if the browser should log the user out if there is :idle_timeout_length minutes of inactivity. Default is false. */
  idleTimeoutEnabled: Scalars["Boolean"];
  /** Length of inactivity in minutes before the user is logged out. Must be between 2 and 200. Default is 10. */
  idleTimeoutLength: Scalars["Int"];
  /** Footer added to the employment invitation email */
  inviteFooter?: Maybe<Scalars["String"]>;
  /** Header added to the employment invitation email */
  inviteHeader?: Maybe<Scalars["String"]>;
  /** Disclaimer or Message added to the employment invitation email */
  inviteMessage?: Maybe<Scalars["String"]>;
  /** User invite confirmation text. Maximum character limit is 500 */
  inviteUserConfirmationText?: Maybe<Scalars["String"]>;
  /** Indicates if this business is only to be seen and used by LIFELENZ employees. Default is false. */
  isFake: Scalars["Boolean"];
  /** Configuration to enable job titles. Default is false. */
  jobTitlesEnabled: Scalars["Boolean"];
  /** Whether to enable the average hourly rate KPI feature. */
  kpiAvgHourlyRateEnabled: Scalars["Boolean"];
  /** Whether to enable the sales per employee hour KPI feature. */
  kpiSalesPerEmployeeHourEnabled: Scalars["Boolean"];
  /** Last name abbreviation count. Default is 24. */
  lastNameCharCount: Scalars["Int"];
  /** Ability to add suffix to last name of all employees */
  lastNameSuffix: Scalars["String"];
  /** Enable live time clock view feature on mobile */
  liveTimeClockViewEnabled: Scalars["Boolean"];
  /** Locale suffix of the business */
  localeSuffix?: Maybe<Scalars["String"]>;
  /**
   * List of locales to use when there is no user/locale for a business invite.
   * Default is nil (meaning application default locale).
   */
  mailerLocales?: Maybe<Scalars["JSON"]>;
  /** Configuration to enable mobile management. Default is false. */
  managerAppEnabled: Scalars["Boolean"];
  /** Indicates if "Add Existing Users" and "Remove from Schedule" buttons are available in the schedule. Default is true. */
  managerAssignUsersToSchedulesEnabled: Scalars["Boolean"];
  /** Indicates if "Invite New User" button is available in business & schedule people list. Default is true. */
  managerInviteUsersEnabled: Scalars["Boolean"];
  /**
   * Indicates if "Terminate"/"Rehire" button is available to Schedule Managers
   * with Pay in the employee detail view, else restrict to Group Admins. Default is true.
   */
  managerTerminateUsersEnabled: Scalars["Boolean"];
  /**
   * Configuration for making termination reasons mandatory. Default is { 'active':
   * 'false', 'terminated': false, 'leave_of_absence': false, 'suspended': false }
   */
  mandatoryReasonsForEmploymentStatus: MandatoryReasonsForEmploymentStatus;
  /** Indicates whether managers can set that employees won't attend/didn't attend their shift. Default is false. */
  markAsAbsentEnabled: Scalars["Boolean"];
  /** Indicates whether the employees can use their mobile to identify when they won't attend their shift. Default is false. */
  markAsAbsentEnabledToUser: Scalars["Boolean"];
  /** Business maximum shift duration in seconds. Default is 43200 seconds (12 hours). */
  maxShiftDuration: Scalars["Int"];
  /**
   * Indicates if times shown in the UI should be shown in 24 hour time, otherwise
   * they are shown in 12 hour time. Default is false.
   */
  militaryTime: Scalars["Boolean"];
  /** Minimum age an employee must be before they can be shared between stores. Default is 0. */
  minSharingAge: Scalars["Int"];
  /** Indicates if monthly accrual loading enabled. Default is false. */
  monthlyCostLoadingEnabled: Scalars["Boolean"];
  /** Indicates whether nickname replaces only firstname or firstname and lastname. Default is false. */
  nicknameReplacesOnlyFirstName: Scalars["Boolean"];
  /** When enabled TimeClockApp will display notifications for employees. Default is false. */
  notificationsOnTimeClockAppEnabled: Scalars["Boolean"];
  /** Start date of the pay cycle */
  payCycleStartDate: Scalars["String"];
  /** Array of integer to determine the day 1 and 2 of bi-monthly pay period */
  payDates?: Maybe<Array<Scalars["Int"]>>;
  /** Disclaimer shown to the user about the pay estimate */
  payDisclaimer?: Maybe<Scalars["String"]>;
  /** Pay frequency. Default is 'weekly' */
  payFrequency: Scalars["String"];
  /** Indicates if names in pay_period_daily_report should be split and repeated for each row. Default is false. */
  payPeriodDailyReportSplitNames: Scalars["Boolean"];
  /** Indicates whether pay period review is enabled. Default is false. */
  payPeriodReviewEnabled: Scalars["Boolean"];
  /** Indicates whether GMs may approve their own pay periods. Default is false. */
  payPeriodSelfApprovalEnabled: Scalars["Boolean"];
  /** Indicates if populate schedule replanning is enabled. Default is false. */
  populateScheduleReplanningEnabled: Scalars["Boolean"];
  /** When true, consent is needed to adjust a time punch. Default is false. */
  punchAdjustmentConsentEnabled: Scalars["Boolean"];
  /** Whether the employee can add comments to the time clock adjustment (time_clock.contest_reason). Default is true. */
  punchEditEmployeeCommentsEnabled: Scalars["Boolean"];
  /**
   * If the contest punch changes feature is enabled, whether a legal disclaimer is
   * shown to the employee if the adjustment is forced. Default is true.
   */
  punchEditForceChangesLegalDisclaimerEnabled: Scalars["Boolean"];
  /** If the contest punch changes feature is enabled, legal disclaimer shown to the employee if a punch adjustment is forced. */
  punchEditLegalDisclaimer?: Maybe<Scalars["String"]>;
  /**
   * Whether the manager can add comments to the time clock adjustment
   * (time_clock.punch_edit_reason, time_clock.resolution_reason. Default is true.
   */
  punchEditManagerCommentsEnabled: Scalars["Boolean"];
  /** The status of print punch slip mode. Default is optional. */
  punchSlipMode: Scalars["String"];
  /** Whether to compute and keep up to date the time_clocks.custom_updated_at timestamp. Default is false. */
  punchTrackingEnabled: Scalars["Boolean"];
  /** Indicates if quarterly aggregation is enabled. Default is false. */
  quarterlyAggregationEnabled: Scalars["Boolean"];
  /** Rate type that want to paid by */
  rateType: Scalars["JSON"];
  /** Business registration number */
  registrationNumber?: Maybe<Scalars["String"]>;
  /** Whether regular (non SSO) business invites which uses invite token need to be sent to employees. Default is true. */
  regularBusinessInviteEnabled: Scalars["Boolean"];
  /** Settings pertaining to reports. */
  reportSettings: Scalars["JSON"];
  /** Indicates whether dark or light rest screen theme, Default is Dark */
  restScreenTheme: Scalars["String"];
  /** job rate code to job title map. */
  roleJobTitles: Scalars["JSON"];
  /** Indicates whether the UI should default creation of new shifts to rostered or offered. Default is false. */
  rostered: Scalars["Boolean"];
  /** Rounding Strategy, first char(3-9) is the rounding digit, second char(U,R,D) is rounding strategy, */
  roundingStrategy?: Maybe<Scalars["String"]>;
  /** Indicates whether school calendars feature is enabled. Default is false. */
  schoolCalendarsEnabled: Scalars["Boolean"];
  /** Indicates whether employee sharing request requires approval. Default is true. */
  shareRequiresApproval: Scalars["Boolean"];
  /** Whether shared store shifts is visible on the management screen. Default is false. */
  sharedStoreShiftsEnabled: Scalars["Boolean"];
  /** When true, generate shift auto accepted notification. Default is true. */
  shiftAutoAcceptNotificationEnabled: Scalars["Boolean"];
  /** Allow shifts to overlap leave requests. Default is true. */
  shiftCanOverlapLeaveRequest: Scalars["Boolean"];
  /** Allow shifts to overlap unavailability. Default is true. */
  shiftCanOverlapUnavailability: Scalars["Boolean"];
  /** Whether to show the shift cost breakdown in the webUI */
  shiftCostBreakdownEnabled: Scalars["Boolean"];
  /** Indicates whether shift drop feature is enabled. Default is false. */
  shiftDropEnabled: Scalars["Boolean"];
  /** Number of hours prior to the shift start time when incomplete shift drops will be automatically rejected. Default is 0. */
  shiftDropThreshold: Scalars["Int"];
  /** Enable schedule to support multi-rate shift, time clock. Default is false. */
  shiftMultiRateEnabled: Scalars["Boolean"];
  /** Indicates whether shift swap feature is enabled. Default is false. */
  shiftSwapEnabled: Scalars["Boolean"];
  /** Indicates whether shift swap request needs approval. Effective only when shift swap feature is enabled. Default is true. */
  shiftSwapNeedsApproval: Scalars["Boolean"];
  /** Short description of the business */
  shortDescription?: Maybe<Scalars["String"]>;
  /** Indicates whether the employees avatar are displayed. Default is true. */
  showAvatarIcon: Scalars["Boolean"];
  /** Indicates whether the breaks of a shift are displayed to the user. Default is true. */
  showBreaksToUser: Scalars["Boolean"];
  /** Indicates whether the colleagues of a shift are displayed to the user. Default is true. */
  showColleaguesToUser: Scalars["Boolean"];
  /** Indicates whether salary/earnings information should be displayed. Default is false. */
  showEarnings: Scalars["Boolean"];
  /** Indicates whether the estimated earnings to be shown to the user. Default is true. */
  showEarningsToUser: Scalars["Boolean"];
  /** Ability to replace first and last name with a nickname for all employees. Default is false. */
  showNickname: Scalars["Boolean"];
  /** Indicates whether the roles of a shift are displayed to the user. Default is true. */
  showShiftRolesToUser: Scalars["Boolean"];
  /** Whether roles and permissions are shown in manager settings. Default true */
  showSystemRolesAndPermissions: Scalars["Boolean"];
  /** Whether SSO business invites to be sent to employees. Default is false. */
  ssoBusinessInviteEnabled: Scalars["Boolean"];
  /** Whether store hours are enabled for the business. Default is false. */
  storeHoursEnabled: Scalars["Boolean"];
  /** When true, allows store transfers on the hire date of an employment. */
  storeTransferOnHireDateEnabled: Scalars["Boolean"];
  /** While swapping/dropping shift, what filters to apply to the list of shifts/candidates. */
  swapDropFiltering: SwapDropFilteringEnum;
  /** The text to display in a disclaimer popup to employees when creating or accepting a shift swap */
  swapMobileDisclaimer?: Maybe<Scalars["String"]>;
  /** Whether to display a disclaimer popup to employees when creating or accepting a shift swap */
  swapMobileDisclaimerEnabled: Scalars["Boolean"];
  /** While swapping, how to sort the list of shifts. */
  swapSorting: SwapSortingEnum;
  /** Whether Targets page is available under Analytics menu. Default is false. */
  targetsScreenEnabled: Scalars["Boolean"];
  /** User terminate confirmation text. Maximum character limit is 500 */
  terminateUserConfirmationText?: Maybe<Scalars["String"]>;
  /** ID of the time clock app business image. */
  timeClockAppBusinessImageId?: Maybe<Scalars["ID"]>;
  /** When true, fetch schedule specific job_titles, otherwise return all active job titles. Default is false. */
  timeClockAppFetchScheduleJobTitles: Scalars["Boolean"];
  /** Minimum amount of time allowed between breaks. Default is 10 */
  timeClockAppMinTimeBetweenBreaks: Scalars["Minutes"];
  /** Configuration for time clock app pin length. Default is { 'min': 6, 'max': 6 } */
  timeClockAppPinConfig: TimeClockAppPinConfig;
  /** Number of seconds between time clock and backend sync. Default is 60. */
  timeClockAppSyncIntervalInSeconds: Scalars["Int"];
  /** Configuration for time clock workflow */
  timeClockAppWorkflowConfig?: Maybe<Scalars["JSON"]>;
  /** Minutes that required manager approval for role rate change */
  timeClockRoleRateChangeAuthThreshold?: Maybe<Scalars["Int"]>;
  /** When true, allows employees to create time off requests on weeks prior to the latest published week. */
  timeOffDuringPublishedScheduleEnabled: Scalars["Boolean"];
  /** Whether the employee can add comments to the time off request. Default is true */
  timeOffEmployeeCommentsEnabled: Scalars["Boolean"];
  /** Whether the manager can add comments to the time off request. Default is true */
  timeOffManagerCommentsEnabled: Scalars["Boolean"];
  /** Whether employee can request leave during blackout periods. Default is true. */
  timeOffRequestDuringBlackoutEnabled: Scalars["Boolean"];
  /** Whether a notification describing all tos will be generated */
  timeOffShiftNotificationsEnabled: Scalars["Boolean"];
  /** Whether time off shifts can be created */
  timeOffShiftsEnabled: Scalars["Boolean"];
  /** When true, default to week view when navigating to timekeeping. Default is false. */
  timekeepingDefaultToWeekView: Scalars["Boolean"];
  /** When true, prepopulate hours in time clock entries based on shift data. Default is true. */
  timekeepingPrepopulateNewEntry: Scalars["Boolean"];
  /** Indicates whether the timekeeping is readonly. Default is true. */
  timekeepingReadonly: Scalars["Boolean"];
  /** Indicates whether roles in timekeeping are visible. Default is true. */
  timekeepingRolesVisible: Scalars["Boolean"];
  /** The time in minutes that user will auto logout from time clock device. Default is 1. */
  timeoutThreshold: Scalars["Int"];
  /** Configuration forecast measurements display settings */
  topLevelForecastMeasurements?: Maybe<Array<AnalyticsTopLevelMeasurement>>;
  /** User unassigned employment confirmation text. Maximum character limit is 500 */
  unassignEmployeeConfirmationText?: Maybe<Scalars["String"]>;
  /** Indicates if role rate should be used to calculate earnings on unassigned shifts. Default is false. */
  unassignedShiftDefaultRateEnabled: Scalars["Boolean"];
  /** Whether to unshare from all stores upon termination of an employee */
  unshareOnTermination: Scalars["Boolean"];
  /** Time business last updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Array of User Businesses */
  userBusinesses: Array<UserBusiness>;
  /** Indicates if employees are allowed to select "I wanted to stay late" when clocking out late. Default is false. */
  voluntaryLateClockOutEnabled: Scalars["Boolean"];
  /** Defines a mapping of internal warning code to customer specific code. */
  warningCodeMap: Scalars["JSON"];
  /** Amount of time prior to start time where a warning is displayed to the user */
  warningTimeFromUnavailabilityStart: AutoWarnTime;
  /** Hash of view options. Default is nil. */
  webuiViewOptions?: Maybe<Scalars["JSON"]>;
};

/** The connection type for Business. */
export type BusinessConnection = {
  __typename?: "BusinessConnection";
  /** A list of edges. */
  edges: Array<BusinessEdge>;
  /** A list of nodes. */
  nodes: Array<Business>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** Internal DAR fields */
export type BusinessDarField = {
  __typename?: "BusinessDarField";
  /** Description of the field code */
  description?: Maybe<Scalars["String"]>;
  /** Code of the field code */
  fieldCode: Scalars["String"];
  /** Name of the field code */
  name: Scalars["String"];
  /** Value type of the field code */
  valueType: Scalars["String"];
};

/** Internal DAR field input fields */
export type BusinessDarFieldInput = {
  /** Description of the field code */
  description?: Maybe<Scalars["String"]>;
  /** Code of the field code */
  fieldCode: Scalars["String"];
  /** Name of the field code */
  name: Scalars["String"];
  /** Value type of the field code */
  valueType: Scalars["String"];
};

/** An edge in a connection. */
export type BusinessEdge = {
  __typename?: "BusinessEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Business>;
};

/** Internal Business input fields */
export type BusinessInput = {
  /** Primary accent color */
  accentColor1?: Maybe<Scalars["String"]>;
  /**
   * Indicates if WebUI aggregator should aggregate pivot metrics 'next_day: true'
   * to same day the shift starts on. Default is false
   */
  aggregateToStartDay?: Maybe<Scalars["Boolean"]>;
  /** When enabled manager can approve their own punches in TimeClockApp. Default is false. */
  allowManagerToApproveOwnPunch?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether analytics are enabled for the business as a whole. Default is false. */
  analyticsEnabled?: Maybe<Scalars["Boolean"]>;
  /** Base URL of the Analytics server */
  analyticsServerUrl?: Maybe<Scalars["String"]>;
  /** Indicates whether anonymous drop is enabled to the user. Default is false. */
  anonymousShiftDropToUser?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether anonymous swap is enabled to the user. Default is false. */
  anonymousShiftSwapToUser?: Maybe<Scalars["Boolean"]>;
  /** Determines whether an approval is needed for commendation */
  approvalNeededForCommendation?: Maybe<Scalars["Boolean"]>;
  /** Determines whether an approval is needed for disciplinary action */
  approvalNeededForDisciplinaryAction?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether manager approval needed on role change, Default is false */
  approvalNeededForRoleRateChange?: Maybe<Scalars["Boolean"]>;
  /** If requesting manager belongs to highest level approval is not required */
  approvalRequests?: Maybe<ApprovalRequestsConfigInput>;
  /** User assigned employment confirmation text. Maximum character limit is 500 */
  assignEmployeeConfirmationText?: Maybe<Scalars["String"]>;
  /** Indicates whether rostered shifts should be auto accepted when published. Default is false. */
  autoAcceptRosteredShifts?: Maybe<Scalars["Boolean"]>;
  /** Amount of time prior to start time where the unavailability request is automatically rejected */
  autoRejectTimeFromUnavailabilityStart?: Maybe<AutoRejectTimeInput>;
  /** Reject the user's unavailability request if it is past the threshold. Default is false. */
  autoRejectUnavailabilityEvents?: Maybe<Scalars["Boolean"]>;
  /** When true, allows employees to create and modify availability requests on weeks prior to the latest published week. */
  availabilityChangeDuringPublishedScheduleEnabled?: Maybe<Scalars["Boolean"]>;
  /**
   * Whether a manager created availability requires consent from the employee.
   * If not, manager created availabilities go straight from draft to active
   */
  availabilityConsentRequired?: Maybe<Scalars["Boolean"]>;
  /** The number of days from the start of a new availability that are checked for coverage of shifts. Default is 28. */
  availabilityCoverageHorizonDays?: Maybe<Scalars["Int"]>;
  /** Allow employee comments in availability objects */
  availabilityEmployeeComments?: Maybe<Scalars["Boolean"]>;
  /** Enable to make any published shift that is not covered an error. Default is false. */
  availabilityErrorOnAnyShiftConflict?: Maybe<Scalars["Boolean"]>;
  /** An employee initiated availability must be created at least this many days in the future. Minimum is 1 */
  availabilityErrorOnMinNoticeDays?: Maybe<Scalars["Int"]>;
  /** Enable to make any published rostered shift that is not covered an error. Default is false. */
  availabilityErrorOnRosteredShiftConflict?: Maybe<Scalars["Boolean"]>;
  /** Allow manager comments in availability objects */
  availabilityManagerComments?: Maybe<Scalars["Boolean"]>;
  /** The maximum number of weeks in an availability cycle. Minimum is 1 */
  availabilityMaxCycleWeeks?: Maybe<Scalars["Int"]>;
  /** The set of availability types that may be created */
  availabilityTypesEnabled?: Maybe<Array<AvailabilityTypeEnum>>;
  /** Enable to allow availability consecutive days off waived. Default is: false. */
  availabilityUseConsecutiveDaysOffWaived?: Maybe<Scalars["Boolean"]>;
  /** Whether max duration per week is validated on the backend and displayed in the UI */
  availabilityUseMaxDurationPerWeek?: Maybe<Scalars["Boolean"]>;
  /**
   * An employee initiated availability created less than this many days in the
   * future will have a warning displayed prior to submission.
   * Minimum is 1.
   * If equal to or greater than availability_error_on_min_notice_days, the warning is not shown as the error takes precedence
   */
  availabilityWarnOnMinNoticeDays?: Maybe<Scalars["Int"]>;
  /** Business name */
  businessName?: Maybe<Scalars["String"]>;
  /** Business terms that want to be replaced */
  businessTerms?: Maybe<Scalars["JSON"]>;
  /**
   * Whether managers can make forecast adjustments. Defaults to true. Set to false
   * in cases where forecast adjustment/management is external to LIFELENZ
   */
  canAdjustForecast?: Maybe<Scalars["Boolean"]>;
  /** Indicates if chat is enabled. Default is true. */
  chatEnabled?: Maybe<Scalars["Boolean"]>;
  /** Minutes before the shift start time in which user can clock in */
  clockInThreshold?: Maybe<Scalars["Int"]>;
  /** Minutes after the shift end time in which user can clock out */
  clockOutThreshold?: Maybe<Scalars["Int"]>;
  /** Status of Shift clock-in/out tracking. Default is false. */
  clockTrackingEnabled?: Maybe<Scalars["Boolean"]>;
  /** Enable branding on the top left of clock using the LIFELENZ logo as a replacement for home. Default is false. */
  coBrandingEnabled?: Maybe<Scalars["Boolean"]>;
  /** Determines whether employee sees any notifications for published commendations */
  commendationEmploymentNotifications?: Maybe<Scalars["Boolean"]>;
  /** Determines whether commendation setting is enabled */
  commendationEnabled?: Maybe<Scalars["Boolean"]>;
  /** Whether an employment contract change needs consent from the employee */
  contractsConsentEnabled?: Maybe<Scalars["Boolean"]>;
  /** Whether employment type is used for employment contracts */
  contractsUseEmploymentType?: Maybe<Scalars["Boolean"]>;
  /** Whether min duration per week is used for employment contracts */
  contractsUseMinDurationPerWeek?: Maybe<Scalars["Boolean"]>;
  /** Enable running rules engine checks across all schedules */
  crossScheduleComplianceChecksEnabled?: Maybe<Scalars["Boolean"]>;
  /** Enable disclaimer for csv reports. */
  csvReportDisclaimerEnabled?: Maybe<Scalars["Boolean"]>;
  /** Currency types. Default is USD ($) */
  currencyType?: Maybe<CurrencyEnum>;
  /** Sync delay in minutes before fetching data from analytics for DAR. Default is 10. */
  darAnalyticsSyncThreshold?: Maybe<Scalars["Int"]>;
  /** Only display past time slices in the daily activity report. */
  darDataOnlyPastSlicesEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether daily activity report is enabled. Default is false. */
  darEnabled?: Maybe<Scalars["Boolean"]>;
  /** Configuration daily activity report settings */
  darFields?: Maybe<Array<BusinessDarFieldInput>>;
  /** Data lake global business id (used by importer). Maximum character limit is 500 */
  dataLakeGlobalId?: Maybe<Scalars["String"]>;
  /** Data lake business id (used by importer). Maximum character limit is 500 */
  dataLakeId?: Maybe<Scalars["String"]>;
  /**
   * List of employment metadata_types that when modified should update
   * datapass_changed. Maps to the "name" column of the metadata_types table.
   */
  datapassAffectingMetadata?: Maybe<Array<Scalars["String"]>>;
  /** Whether to compute and keep up to date the datapass_changed timestamp. */
  datapassTrackingEnabled?: Maybe<Scalars["Boolean"]>;
  /** Date format. Default is 'date_month_year' */
  dateFormat?: Maybe<Scalars["String"]>;
  /** Default value for use in setting 'payroll_cutoff_time' property on schedules. Default is nil */
  defaultPayrollCutoffTime?: Maybe<Scalars["String"]>;
  /** Default value for use in setting 'day_end_time' property on schedules. Default is '00:00:00' */
  defaultScheduleDayEndTime?: Maybe<Scalars["String"]>;
  /** Default value for use in setting 'day_start_time' property on schedules. Default is '00:00:00' */
  defaultScheduleDayStartTime?: Maybe<Scalars["String"]>;
  /** Default value for use in setting 'snapshot_duration' property on schedules. Default is 7. */
  defaultScheduleSnapshotDuration?: Maybe<Scalars["Int"]>;
  /** Default value for use in setting 'snapshot_start_date' property on schedules */
  defaultScheduleSnapshotStartDate?: Maybe<Scalars["String"]>;
  /** Whether you can delete primary JTCs through the web ui. Default is false. */
  deletePrimaryJobTitlesEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether employee can initiate a leave request. Default is false. */
  disableEmployeeLeaveRequest?: Maybe<Scalars["Boolean"]>;
  /** Determines whether employee sees any notifications for published disciplinary actions */
  disciplinaryActionEmploymentNotifications?: Maybe<Scalars["Boolean"]>;
  /** Determines whether disciplinary action setting is enabled */
  disciplinaryActionEnabled?: Maybe<Scalars["Boolean"]>;
  /** Display warnings to the user when their unavailability request is past the threshold. Default is false. */
  displayWarningUnavailabilityEvents?: Maybe<Scalars["Boolean"]>;
  /** The text to display in a disclaimer popup to employees when creating or accepting a shift drop */
  dropMobileDisclaimer?: Maybe<Scalars["String"]>;
  /** Whether to display a disclaimer popup to employees when creating or accepting a shift drop */
  dropMobileDisclaimerEnabled?: Maybe<Scalars["Boolean"]>;
  /** Duration format. Default is 'decimal_hours' */
  durationFormat?: Maybe<Scalars["String"]>;
  /** Layout for the Dynamic Fields */
  dynamicFieldsLayout?: Maybe<DynamicFieldsLayoutInput>;
  /** The time in minutes that user can early punch in without manager authorize */
  earlyShiftStartThreshold?: Maybe<Scalars["Int"]>;
  /** Type of Earnings estimator */
  earningsEstimator?: Maybe<Scalars["String"]>;
  /**
   * Enum of GMH restrictions for each hard coded employment type. If an employment
   * type is missing, the defaults are true, null, null
   */
  emplTypeGmhSettings?: Maybe<EmplTypeGmhSettingsUpdateInput>;
  /**
   * Whether a compliance warning should be generated when an employee with the
   * specified employment type has leave with no "time_off" shifts covering it
   */
  emplTypeTimeOffComplianceEnabled?: Maybe<EmplTypeTimeOffComplianceEnabledInput>;
  /** Indicates whether employee availabilities are editable by manager. Default is true. */
  employeeAvailabilityEditableByManager?: Maybe<Scalars["Boolean"]>;
  /** Whether this business uses employee drafts feature. Default is false. */
  employeeDraftsEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates if employee multi-rate is enabled. Default is false. */
  employeeMultiRateEnabled?: Maybe<Scalars["Boolean"]>;
  /** Contains the config to pre-process the employment code before storing it in DB. */
  employmentCodePreprocessor?: Maybe<Scalars["JSON"]>;
  /** Set of flags to indicate which employment attributes are externally managed */
  employmentExternalManagedFlags?: Maybe<EmploymentExternalManagedFlagsUpdateInput>;
  /** Whether editing of employment rates is enabled. Default is: false */
  employmentRateEditEnabled?: Maybe<Scalars["Boolean"]>;
  /** Determines how editing of employment rates in the past is handled. Defaults to Disabled */
  employmentRateEditPast?: Maybe<EmploymentRateEditPastEnum>;
  /** Fixed shift restrictions for employment types. */
  employmentTypeFixedShiftRestriction?: Maybe<EmploymentTypeFixedShiftRestrictionUpdateInput>;
  /** Indicates whether the business allows miscellaneous pay. Default is false. */
  enableMiscPay?: Maybe<Scalars["Boolean"]>;
  /** Indicates if creating/modifying/deleting shifts in past will be allowed. Default is false. */
  enablePastShiftOperation?: Maybe<Scalars["Boolean"]>;
  /**
   * Whether shift differentials are enabled for this business. Note that the
   * additional_pivot_metrics may still be used by other features even if shift
   * differentials is disabled.
   */
  enableShiftDifferentials?: Maybe<Scalars["Boolean"]>;
  /** At least one of this flag or feedback_reason_enabled must be true (feedback needs either a reason or a comment) */
  feedbackCommentEnabled?: Maybe<Scalars["Boolean"]>;
  /** Determines whether a reason is selectable & required for an employment_feedback */
  feedbackReasonEnabled?: Maybe<Scalars["Boolean"]>;
  /** Index to indicate the first day of a work week, starting from Sunday (0). Default is 0. */
  firstDayOfWeek?: Maybe<Scalars["Int"]>;
  /** First name abbreviation count. Default is 24. */
  firstNameCharCount?: Maybe<Scalars["Int"]>;
  /** Ability to add suffix to first name of all employees */
  firstNameSuffix?: Maybe<Scalars["String"]>;
  /** Whether break duration is visible on the management screen. Default is false. */
  fixedShiftsBreakDurationEnabled?: Maybe<Scalars["Boolean"]>;
  /** Whether this business uses fixed shifts. Default is false. */
  fixedShiftsEnabled?: Maybe<Scalars["Boolean"]>;
  /** ID of business role to use when fixed_shifts are enabled. Default is nil. */
  fixedShiftsRoleId?: Maybe<Scalars["ID"]>;
  /** Disclaimer text to include in PDF footer. Maximum character limit is 500 */
  footerDisclaimer?: Maybe<Scalars["String"]>;
  /**
   * Whether forecasts require approval. Defaults to true. Set to false in cases
   * where forecast adjustment/management is external to LIFELENZ
   */
  forecastRequiresApproval?: Maybe<Scalars["Boolean"]>;
  /** The ID of the Global Business this business belongs to */
  globalBusinessId?: Maybe<Scalars["ID"]>;
  /** ID of image to be used as the business avatar */
  globalImageId?: Maybe<Scalars["ID"]>;
  /** Whether client software should send multiple graphql queries batched in the one request. Default is false. */
  graphqlQueryBatchingEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates that overstaffing should look more severe than understaffing. Default is true. */
  highlightOverstaffing?: Maybe<Scalars["Boolean"]>;
  /** When true, allows managers to add comments during approval process */
  homeStoreTransferManagerCommentsEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates if the browser should log the user out if there is :idle_timeout_length minutes of inactivity. Default is false. */
  idleTimeoutEnabled?: Maybe<Scalars["Boolean"]>;
  /** Length of inactivity in minutes before the user is logged out. Must be between 2 and 200. Default is 10. */
  idleTimeoutLength?: Maybe<Scalars["Int"]>;
  /** Image uri to be used as the business avatar */
  image?: Maybe<Scalars["String"]>;
  /** Footer added to the employment invitation email */
  inviteFooter?: Maybe<Scalars["String"]>;
  /** Header added to the employment invitation email */
  inviteHeader?: Maybe<Scalars["String"]>;
  /** Disclaimer or Message added to the employment invitation email */
  inviteMessage?: Maybe<Scalars["String"]>;
  /** User invite confirmation text. Maximum character limit is 500 */
  inviteUserConfirmationText?: Maybe<Scalars["String"]>;
  /** Indicates if this business is only to be seen and used by LIFELENZ employees. Default is false. */
  isFake?: Maybe<Scalars["Boolean"]>;
  /** Configuration to enable job titles. Default is false. */
  jobTitlesEnabled?: Maybe<Scalars["Boolean"]>;
  /** Whether to enable the average hourly rate KPI feature. */
  kpiAvgHourlyRateEnabled?: Maybe<Scalars["Boolean"]>;
  /** Whether to enable the sales per employee hour KPI feature. */
  kpiSalesPerEmployeeHourEnabled?: Maybe<Scalars["Boolean"]>;
  /** Last name abbreviation count. Default is 24. */
  lastNameCharCount?: Maybe<Scalars["Int"]>;
  /** Ability to add suffix to last name of all employees */
  lastNameSuffix?: Maybe<Scalars["String"]>;
  /** Enable live time clock view feature on mobile */
  liveTimeClockViewEnabled?: Maybe<Scalars["Boolean"]>;
  /** Locale suffix of the business */
  localeSuffix?: Maybe<Scalars["String"]>;
  /**
   * List of locales to use when there is no user/locale for a business invite.
   * Default is nil (meaning application default locale).
   */
  mailerLocales?: Maybe<Scalars["JSON"]>;
  /** Configuration to enable mobile management. Default is false. */
  managerAppEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates if "Add Existing Users" and "Remove from Schedule" buttons are available in the schedule. Default is true. */
  managerAssignUsersToSchedulesEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates if "Invite New User" button is available in business & schedule people list. Default is true. */
  managerInviteUsersEnabled?: Maybe<Scalars["Boolean"]>;
  /**
   * Indicates if "Terminate"/"Rehire" button is available to Schedule Managers
   * with Pay in the employee detail view, else restrict to Group Admins. Default is true.
   */
  managerTerminateUsersEnabled?: Maybe<Scalars["Boolean"]>;
  /**
   * Configuration for making termination reasons mandatory. Default is { 'active':
   * 'false', 'terminated': false, 'leave_of_absence': false, 'suspended': false }
   */
  mandatoryReasonsForEmploymentStatus?: Maybe<MandatoryReasonsForEmploymentStatusInput>;
  /** Indicates whether managers can set that employees won't attend/didn't attend their shift. Default is false. */
  markAsAbsentEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether the employees can use their mobile to identify when they won't attend their shift. Default is false. */
  markAsAbsentEnabledToUser?: Maybe<Scalars["Boolean"]>;
  /** Business maximum shift duration in seconds. Default is 43200 seconds (12 hours). */
  maxShiftDuration?: Maybe<Scalars["Int"]>;
  /**
   * Indicates if times shown in the UI should be shown in 24 hour time, otherwise
   * they are shown in 12 hour time. Default is false.
   */
  militaryTime?: Maybe<Scalars["Boolean"]>;
  /** Minimum age an employee must be before they can be shared between stores. Default is 0. */
  minSharingAge?: Maybe<Scalars["Int"]>;
  /** Indicates if monthly accrual loading enabled. Default is false. */
  monthlyCostLoadingEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether nickname replaces only firstname or firstname and lastname. Default is false. */
  nicknameReplacesOnlyFirstName?: Maybe<Scalars["Boolean"]>;
  /** When enabled TimeClockApp will display notifications for employees. Default is false. */
  notificationsOnTimeClockAppEnabled?: Maybe<Scalars["Boolean"]>;
  /** Start date of the pay cycle */
  payCycleStartDate?: Maybe<Scalars["String"]>;
  /** Array of integer to determine the day 1 and 2 of bi-monthly pay period */
  payDates?: Maybe<Array<Scalars["Int"]>>;
  /** Disclaimer shown to the user about the pay estimate */
  payDisclaimer?: Maybe<Scalars["String"]>;
  /** Pay frequency. Default is 'weekly' */
  payFrequency?: Maybe<Scalars["String"]>;
  /** Indicates if names in pay_period_daily_report should be split and repeated for each row. Default is false. */
  payPeriodDailyReportSplitNames?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether pay period review is enabled. Default is false. */
  payPeriodReviewEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether GMs may approve their own pay periods. Default is false. */
  payPeriodSelfApprovalEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates if populate schedule replanning is enabled. Default is false. */
  populateScheduleReplanningEnabled?: Maybe<Scalars["Boolean"]>;
  /** When true, consent is needed to adjust a time punch. Default is false. */
  punchAdjustmentConsentEnabled?: Maybe<Scalars["Boolean"]>;
  /** Whether the employee can add comments to the time clock adjustment (time_clock.contest_reason). Default is true. */
  punchEditEmployeeCommentsEnabled?: Maybe<Scalars["Boolean"]>;
  /**
   * If the contest punch changes feature is enabled, whether a legal disclaimer is
   * shown to the employee if the adjustment is forced. Default is true.
   */
  punchEditForceChangesLegalDisclaimerEnabled?: Maybe<Scalars["Boolean"]>;
  /** If the contest punch changes feature is enabled, legal disclaimer shown to the employee if a punch adjustment is forced. */
  punchEditLegalDisclaimer?: Maybe<Scalars["String"]>;
  /**
   * Whether the manager can add comments to the time clock adjustment
   * (time_clock.punch_edit_reason, time_clock.resolution_reason. Default is true.
   */
  punchEditManagerCommentsEnabled?: Maybe<Scalars["Boolean"]>;
  /** The status of print punch slip mode. Default is optional. */
  punchSlipMode?: Maybe<Scalars["String"]>;
  /** Whether to compute and keep up to date the time_clocks.custom_updated_at timestamp. Default is false. */
  punchTrackingEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates if quarterly aggregation is enabled. Default is false. */
  quarterlyAggregationEnabled?: Maybe<Scalars["Boolean"]>;
  /** Rate type that want to paid by */
  rateType?: Maybe<Scalars["JSON"]>;
  /** Business registration number */
  registrationNumber?: Maybe<Scalars["String"]>;
  /** Whether regular (non SSO) business invites which uses invite token need to be sent to employees. Default is true. */
  regularBusinessInviteEnabled?: Maybe<Scalars["Boolean"]>;
  /** Settings pertaining to reports. */
  reportSettings?: Maybe<Scalars["JSON"]>;
  /** Indicates whether dark or light rest screen theme, Default is Dark */
  restScreenTheme?: Maybe<Scalars["String"]>;
  /** job rate code to job title map. */
  roleJobTitles?: Maybe<Scalars["JSON"]>;
  /** Indicates whether the UI should default creation of new shifts to rostered or offered. Default is false. */
  rostered?: Maybe<Scalars["Boolean"]>;
  /** Rounding Strategy, first char(3-9) is the rounding digit, second char(U,R,D) is rounding strategy, */
  roundingStrategy?: Maybe<Scalars["String"]>;
  /** Indicates whether school calendars feature is enabled. Default is false. */
  schoolCalendarsEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether employee sharing request requires approval. Default is true. */
  shareRequiresApproval?: Maybe<Scalars["Boolean"]>;
  /** Whether shared store shifts is visible on the management screen. Default is false. */
  sharedStoreShiftsEnabled?: Maybe<Scalars["Boolean"]>;
  /** When true, generate shift auto accepted notification. Default is true. */
  shiftAutoAcceptNotificationEnabled?: Maybe<Scalars["Boolean"]>;
  /** Allow shifts to overlap leave requests. Default is true. */
  shiftCanOverlapLeaveRequest?: Maybe<Scalars["Boolean"]>;
  /** Allow shifts to overlap unavailability. Default is true. */
  shiftCanOverlapUnavailability?: Maybe<Scalars["Boolean"]>;
  /** Whether to show the shift cost breakdown in the webUI */
  shiftCostBreakdownEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether shift drop feature is enabled. Default is false. */
  shiftDropEnabled?: Maybe<Scalars["Boolean"]>;
  /** Number of hours prior to the shift start time when incomplete shift drops will be automatically rejected. Default is 0. */
  shiftDropThreshold?: Maybe<Scalars["Int"]>;
  /** Enable schedule to support multi-rate shift, time clock. Default is false. */
  shiftMultiRateEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether shift swap feature is enabled. Default is false. */
  shiftSwapEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether shift swap request needs approval. Effective only when shift swap feature is enabled. Default is true. */
  shiftSwapNeedsApproval?: Maybe<Scalars["Boolean"]>;
  /** Short description of the business */
  shortDescription?: Maybe<Scalars["String"]>;
  /** Indicates whether the employees avatar are displayed. Default is true. */
  showAvatarIcon?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether the breaks of a shift are displayed to the user. Default is true. */
  showBreaksToUser?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether the colleagues of a shift are displayed to the user. Default is true. */
  showColleaguesToUser?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether salary/earnings information should be displayed. Default is false. */
  showEarnings?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether the estimated earnings to be shown to the user. Default is true. */
  showEarningsToUser?: Maybe<Scalars["Boolean"]>;
  /** Ability to replace first and last name with a nickname for all employees. Default is false. */
  showNickname?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether the roles of a shift are displayed to the user. Default is true. */
  showShiftRolesToUser?: Maybe<Scalars["Boolean"]>;
  /** Whether roles and permissions are shown in manager settings. Default true */
  showSystemRolesAndPermissions?: Maybe<Scalars["Boolean"]>;
  /** Whether SSO business invites to be sent to employees. Default is false. */
  ssoBusinessInviteEnabled?: Maybe<Scalars["Boolean"]>;
  /** Whether store hours are enabled for the business. Default is false. */
  storeHoursEnabled?: Maybe<Scalars["Boolean"]>;
  /** When true, allows store transfers on the hire date of an employment. */
  storeTransferOnHireDateEnabled?: Maybe<Scalars["Boolean"]>;
  /** While swapping/dropping shift, what filters to apply to the list of shifts/candidates. */
  swapDropFiltering?: Maybe<SwapDropFilteringEnum>;
  /** The text to display in a disclaimer popup to employees when creating or accepting a shift swap */
  swapMobileDisclaimer?: Maybe<Scalars["String"]>;
  /** Whether to display a disclaimer popup to employees when creating or accepting a shift swap */
  swapMobileDisclaimerEnabled?: Maybe<Scalars["Boolean"]>;
  /** While swapping, how to sort the list of shifts. */
  swapSorting?: Maybe<SwapSortingEnum>;
  /** Whether Targets page is available under Analytics menu. Default is false. */
  targetsScreenEnabled?: Maybe<Scalars["Boolean"]>;
  /** User terminate confirmation text. Maximum character limit is 500 */
  terminateUserConfirmationText?: Maybe<Scalars["String"]>;
  /** Image uri to be used as the time clock app business image */
  timeClockAppBusinessImageUri?: Maybe<Scalars["String"]>;
  /** When true, fetch schedule specific job_titles, otherwise return all active job titles. Default is false. */
  timeClockAppFetchScheduleJobTitles?: Maybe<Scalars["Boolean"]>;
  /** Minimum amount of time allowed between breaks. Default is 10 */
  timeClockAppMinTimeBetweenBreaks?: Maybe<Scalars["Minutes"]>;
  /** Configuration for time clock app pin length. Default is { 'min': 6, 'max': 6 } */
  timeClockAppPinConfig?: Maybe<TimeClockAppPinConfigInput>;
  /** Number of seconds between time clock and backend sync. Default is 60. */
  timeClockAppSyncIntervalInSeconds?: Maybe<Scalars["Int"]>;
  /** Configuration for time clock workflow */
  timeClockAppWorkflowConfig?: Maybe<Scalars["JSON"]>;
  /** Minutes that required manager approval for role rate change */
  timeClockRoleRateChangeAuthThreshold?: Maybe<Scalars["Int"]>;
  /** When true, allows employees to create time off requests on weeks prior to the latest published week. */
  timeOffDuringPublishedScheduleEnabled?: Maybe<Scalars["Boolean"]>;
  /** Whether the employee can add comments to the time off request. Default is true */
  timeOffEmployeeCommentsEnabled?: Maybe<Scalars["Boolean"]>;
  /** Whether the manager can add comments to the time off request. Default is true */
  timeOffManagerCommentsEnabled?: Maybe<Scalars["Boolean"]>;
  /** Whether employee can request leave during blackout periods. Default is true. */
  timeOffRequestDuringBlackoutEnabled?: Maybe<Scalars["Boolean"]>;
  /** Whether a notification describing all tos will be generated */
  timeOffShiftNotificationsEnabled?: Maybe<Scalars["Boolean"]>;
  /** Whether time off shifts can be created */
  timeOffShiftsEnabled?: Maybe<Scalars["Boolean"]>;
  /** When true, default to week view when navigating to timekeeping. Default is false. */
  timekeepingDefaultToWeekView?: Maybe<Scalars["Boolean"]>;
  /** When true, prepopulate hours in time clock entries based on shift data. Default is true. */
  timekeepingPrepopulateNewEntry?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether the timekeeping is readonly. Default is true. */
  timekeepingReadonly?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether roles in timekeeping are visible. Default is true. */
  timekeepingRolesVisible?: Maybe<Scalars["Boolean"]>;
  /** The time in minutes that user will auto logout from time clock device. Default is 1. */
  timeoutThreshold?: Maybe<Scalars["Int"]>;
  /** Configuration forecast measurements display settings */
  topLevelForecastMeasurements?: Maybe<
    Array<AnalyticsTopLevelMeasurementInput>
  >;
  /** User unassigned employment confirmation text. Maximum character limit is 500 */
  unassignEmployeeConfirmationText?: Maybe<Scalars["String"]>;
  /** Indicates if role rate should be used to calculate earnings on unassigned shifts. Default is false. */
  unassignedShiftDefaultRateEnabled?: Maybe<Scalars["Boolean"]>;
  /** Whether to unshare from all stores upon termination of an employee */
  unshareOnTermination?: Maybe<Scalars["Boolean"]>;
  /** Indicates if employees are allowed to select "I wanted to stay late" when clocking out late. Default is false. */
  voluntaryLateClockOutEnabled?: Maybe<Scalars["Boolean"]>;
  /** Defines a mapping of internal warning code to customer specific code. */
  warningCodeMap?: Maybe<Scalars["JSON"]>;
  /** Amount of time prior to start time where a warning is displayed to the user */
  warningTimeFromUnavailabilityStart?: Maybe<AutoWarnTimeInput>;
  /** Hash of view options. Default is nil. */
  webuiViewOptions?: Maybe<Scalars["JSON"]>;
};

/** Internal Business Invite fields */
export type BusinessInvite = {
  __typename?: "BusinessInvite";
  /** Business invite activation code */
  activationCode: Scalars["String"];
  /** Attempts remaining to confirm invite */
  attemptsRemaining: Scalars["Int"];
  /** Business invited to */
  business: Business;
  /** Business id */
  businessId: Scalars["ID"];
  /** Employment this invite belongs to */
  employment: BusinessInviteEmployment;
  /** Employment id */
  employmentId: Scalars["ID"];
  /** The time that this invite will expire */
  expiryTime: Scalars["ISO8601DateTime"];
  /** Business invite type id */
  id: Scalars["ID"];
  /** Business invite token */
  inviteToken: Scalars["String"];
};

/** The connection type for BusinessInvite. */
export type BusinessInviteConnection = {
  __typename?: "BusinessInviteConnection";
  /** A list of edges. */
  edges: Array<BusinessInviteEdge>;
  /** A list of nodes. */
  nodes: Array<BusinessInvite>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type BusinessInviteEdge = {
  __typename?: "BusinessInviteEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<BusinessInvite>;
};

/** Internal Employment fields as seen through Business Invites */
export type BusinessInviteEmployment = {
  __typename?: "BusinessInviteEmployment";
  /** Whether employment has accepted invite to business */
  acceptedInvite: Scalars["Boolean"];
  /** ID from 3rd party system that uniquely identifies the application & system it came from */
  applicationCode?: Maybe<Scalars["String"]>;
  /** Date the employee applied for a job in the 3rd party system. */
  applicationDate?: Maybe<Scalars["ISO8601Date"]>;
  /** ID of the business this employment belongs to */
  businessId: Scalars["ID"];
  /** 3rd party unique ID for the employment */
  code?: Maybe<Scalars["String"]>;
  /** Formatted name of the employment */
  computedName: Scalars["String"];
  /** Time employment created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Denotes the current employment_contract of the employee. */
  currentEmploymentContractId?: Maybe<Scalars["ID"]>;
  /** ID of the current employment history */
  currentEmploymentHistoryId?: Maybe<Scalars["ID"]>;
  /** Denotes the current home schedule of the employee. */
  currentHomeScheduleId?: Maybe<Scalars["ID"]>;
  /** Denotes the current primary employment rate of the employee. */
  currentPrimaryEmploymentRateId?: Maybe<Scalars["ID"]>;
  /** Last time this employment record changed in a way which matters to datapass. Default to now on creation of a record. */
  datapassChangedAt?: Maybe<Scalars["ISO8601DateTimeHighPrecision"]>;
  /** Date of Birth */
  dateOfBirth: Scalars["String"];
  /** Whether the employment is soft deleted */
  deleted: Scalars["Boolean"];
  /** Indicates whether the employment is in draft state. Default is false */
  draft: Scalars["Boolean"];
  /** DateTime range representing when the employment is employed */
  during?: Maybe<Scalars["String"]>;
  /** Email Address */
  email: Scalars["String"];
  /** The minimum rate an employee will be paid. Default is 0 */
  employmentRate?: Maybe<Scalars["Float"]>;
  /** The small ID of the employment type config of the employee in this contract. */
  employmentTypeSmallId: Scalars["Int"];
  /** Indicates whether employment ingested from external system. Default is false */
  external: Scalars["Boolean"];
  /** First Name */
  firstName: Scalars["String"];
  /** Employment ID */
  id: Scalars["ID"];
  /** ID of image used as the employment avatar */
  imageId?: Maybe<Scalars["ID"]>;
  /** Last Name */
  lastName: Scalars["String"];
  /** Approximate time employment last successfully accessed business */
  lastSeenAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Locale of the user */
  locale: Scalars["String"];
  /** Metadata values */
  metadata: Scalars["JSON"];
  /** Features for which an employee is muted */
  mutedFeatures?: Maybe<Array<Scalars["String"]>>;
  /** ID of the next employment history */
  nextEmploymentHistoryId?: Maybe<Scalars["ID"]>;
  /** Represents nickname of employee */
  nickname?: Maybe<Scalars["String"]>;
  /** Represents payroll ID of employee */
  payrollCode?: Maybe<Scalars["String"]>;
  /** Indicates whether the employee needs to be sent an SSO invite. Internal field. */
  pendingSsoBusinessInvite: Scalars["Boolean"];
  /** Represents rating of the employee */
  rating: Scalars["Int"];
  /** School the employee goes to, or NULL if the employee does not attend school. */
  schoolId?: Maybe<Scalars["ID"]>;
  /** The security role the employee has within the business. Default is employee */
  securityRole: Scalars["String"];
  /** Represents SSO code of employee */
  ssoCode?: Maybe<Scalars["String"]>;
  /** Time employment last updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Email of the user this employment belongs to */
  userEmail?: Maybe<Scalars["ID"]>;
  /** ID of the user this employment belongs to */
  userId?: Maybe<Scalars["ID"]>;
  /** Indicates whether biometrics login is enabled on a time clock device. Default is false */
  usesBiometrics?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether pin login is enabled on a time clock device. Default is false */
  usesPin?: Maybe<Scalars["Boolean"]>;
};

/** Business Invite Sort fields */
export type BusinessInviteSort = {
  /** Field to sort by */
  field: BusinessInviteSortField;
  /** Sort order */
  order: Order;
};

/** Business Invite Sort Field values */
export enum BusinessInviteSortField {
  /** Employee id */
  EmploymentId = "employmentId",
  /** Primary key */
  Id = "id",
}

/** Internal Business Office Location fields */
export type BusinessOfficeLocation = {
  __typename?: "BusinessOfficeLocation";
  /** Business ID */
  businessId: Scalars["ID"];
  /** 3rd party unique ID for the location */
  code?: Maybe<Scalars["String"]>;
  /** Country */
  country?: Maybe<Scalars["String"]>;
  /** Created At */
  createdAt: Scalars["ISO8601DateTime"];
  /** Deleted */
  deleted: Scalars["Boolean"];
  /** Business Office Location ID */
  id: Scalars["ID"];
  /** Latitude */
  latitude?: Maybe<Scalars["Float"]>;
  /** Locality */
  locality?: Maybe<Scalars["String"]>;
  /** Longitude */
  longitude?: Maybe<Scalars["Float"]>;
  /** The name of the location */
  officeName: Scalars["String"];
  /** Postcode */
  postcode?: Maybe<Scalars["String"]>;
  /** Region */
  region?: Maybe<Scalars["String"]>;
  /** Street Name */
  street?: Maybe<Scalars["String"]>;
  /** Street Number */
  streetNumber?: Maybe<Scalars["String"]>;
  /** Time Zone */
  timeZone?: Maybe<Scalars["String"]>;
  /** Updated At */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for BusinessOfficeLocation. */
export type BusinessOfficeLocationConnection = {
  __typename?: "BusinessOfficeLocationConnection";
  /** A list of edges. */
  edges: Array<BusinessOfficeLocationEdge>;
  /** A list of nodes. */
  nodes: Array<BusinessOfficeLocation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type BusinessOfficeLocationEdge = {
  __typename?: "BusinessOfficeLocationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<BusinessOfficeLocation>;
};

/** Business Office Location input fields for update */
export type BusinessOfficeLocationUpdateInput = {
  /** 3rd party unique ID for the location */
  code?: Maybe<Scalars["String"]>;
  /** Country */
  country?: Maybe<Scalars["String"]>;
  /** Deleted */
  deleted?: Maybe<Scalars["Boolean"]>;
  /** Latitude */
  latitude?: Maybe<Scalars["Float"]>;
  /** Locality */
  locality?: Maybe<Scalars["String"]>;
  /** Longitude */
  longitude?: Maybe<Scalars["Float"]>;
  /** The name of the location */
  officeName?: Maybe<Scalars["String"]>;
  /** Postcode */
  postcode?: Maybe<Scalars["String"]>;
  /** Region */
  region?: Maybe<Scalars["String"]>;
  /** Street Name */
  street?: Maybe<Scalars["String"]>;
  /** Street Number */
  streetNumber?: Maybe<Scalars["String"]>;
  /** Time Zone */
  timeZone?: Maybe<Scalars["String"]>;
};

/** Internal Business Role fields */
export type BusinessRole = {
  __typename?: "BusinessRole";
  /** Business ID */
  businessId: Scalars["ID"];
  /** Business role category ID */
  businessRoleCategoryId?: Maybe<Scalars["ID"]>;
  /** The name of the business role */
  businessRoleName: Scalars["String"];
  /** 3rd party unique ID for the role */
  code?: Maybe<Scalars["String"]>;
  /** A 6 digit hex color for the role - must be from BusinessRole::COLOR_PALETTE */
  color: Scalars["String"];
  /** Time business role created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Whether the business role is soft deleted */
  deleted: Scalars["Boolean"];
  /** Description of the business role */
  description?: Maybe<Scalars["String"]>;
  /** Business Role ID */
  id: Scalars["ID"];
  /** Flag that AOS should replace this role */
  replaceByAos: Scalars["Boolean"];
  /** The minimum rate an employee will be paid when performing this role */
  roleRate: Scalars["Float"];
  /** Short abbreviation for the role */
  symbol?: Maybe<Scalars["String"]>;
  /** Time business role last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Internal Business Role Category fields */
export type BusinessRoleCategory = {
  __typename?: "BusinessRoleCategory";
  /** Business ID */
  businessId: Scalars["ID"];
  /** The name of the business role category */
  businessRoleCategoryName: Scalars["String"];
  /** 3rd party unique ID for the role category */
  code?: Maybe<Scalars["String"]>;
  /** Time business role category created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Business Role Category ID */
  id: Scalars["ID"];
  /** Ordering position within sibling categories. New categories will be added to last position */
  order: Scalars["Int"];
  /** Business Role Category ID of this category's parent, or null if this category does not have a parent */
  parentId?: Maybe<Scalars["ID"]>;
  /** Time business role category last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for BusinessRoleCategory. */
export type BusinessRoleCategoryConnection = {
  __typename?: "BusinessRoleCategoryConnection";
  /** A list of edges. */
  edges: Array<BusinessRoleCategoryEdge>;
  /** A list of nodes. */
  nodes: Array<BusinessRoleCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type BusinessRoleCategoryEdge = {
  __typename?: "BusinessRoleCategoryEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<BusinessRoleCategory>;
};

/** Business Role Category Sort fields */
export type BusinessRoleCategorySort = {
  /** Field to sort by */
  field: BusinessRoleCategorySortField;
  /** Sort order */
  order: Order;
};

/** Business Role Category Sort Field values */
export enum BusinessRoleCategorySortField {
  /** name of the business role category */
  BusinessRoleCategoryName = "businessRoleCategoryName",
  /** Primary key */
  Id = "id",
  /** order of the business role category */
  Order = "order",
}

/** The connection type for BusinessRole. */
export type BusinessRoleConnection = {
  __typename?: "BusinessRoleConnection";
  /** A list of edges. */
  edges: Array<BusinessRoleEdge>;
  /** A list of nodes. */
  nodes: Array<BusinessRole>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type BusinessRoleEdge = {
  __typename?: "BusinessRoleEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<BusinessRole>;
};

/** Business Role Sort fields */
export type BusinessRoleSort = {
  /** Field to sort by */
  field: BusinessRoleSortField;
  /** Sort order */
  order: Order;
};

/** Business Role Sort Field values */
export enum BusinessRoleSortField {
  /** name of the business role */
  BusinessRoleName = "businessRoleName",
  /** Primary key */
  Id = "id",
}

/** Business Sort fields */
export type BusinessSort = {
  /** Field to sort by */
  field: BusinessSortField;
  /** Sort order */
  order: Order;
};

/** Business Sort Field values */
export enum BusinessSortField {
  /** name of the business */
  BusinessName = "businessName",
  /** Primary key */
  Id = "id",
}

/** Internal Compliance Warning fields */
export type ComplianceWarning = {
  __typename?: "ComplianceWarning";
  /** Compliance warning category */
  category?: Maybe<Scalars["String"]>;
  /** Compliance warning code */
  code?: Maybe<Scalars["String"]>;
  /** Consent needed */
  consentNeeded: Scalars["Boolean"];
  /** Cost penalty */
  costPenalty?: Maybe<Scalars["Float"]>;
  /** Time compliance warning created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Description of the compliance warning */
  description?: Maybe<Scalars["String"]>;
  /** The employment associated with this compliance warning */
  employment: Employment;
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** Extra key */
  extraKey: Scalars["String"];
  /** Compliance warning hints */
  hints: Scalars["JSON"];
  /** Hour penalty */
  hourPenalty?: Maybe<Scalars["Float"]>;
  /** Compliance Warning ID */
  id: Scalars["ID"];
  /** Informational cost */
  informationalCost?: Maybe<Scalars["Float"]>;
  /** Indicates that the warning was dismissed by a manager */
  isOverridden: Scalars["Boolean"];
  /** Is permanent */
  isPermanent: Scalars["Boolean"];
  /** Whether compliance warning is locked */
  locked: Scalars["Boolean"];
  /** Maybe obsolete */
  maybeObsolete: Scalars["Boolean"];
  /** Message to employee */
  messageToEmployee?: Maybe<Scalars["String"]>;
  /** Compliance warning meta */
  meta: Scalars["JSON"];
  /** Time compliance warning was overridden */
  overriddenAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The employment who overrode this compliance warning */
  overriddenBy?: Maybe<Employment>;
  /** ID of employment the compliance warning was overridden by */
  overriddenById?: Maybe<Scalars["ID"]>;
  /** Reason for compliance warning override */
  overrideReason?: Maybe<Scalars["String"]>;
  /** Request ID */
  requestId?: Maybe<Scalars["ID"]>;
  /** Rule name */
  ruleName: Scalars["String"];
  /** The schedule associated with this compliance warning */
  schedule: Schedule;
  /** Schedule ID */
  scheduleId: Scalars["ID"];
  /** Severity of compliance warning */
  severity: Scalars["Int"];
  /** Shift ID */
  shiftId?: Maybe<Scalars["ID"]>;
  /** Shift offer ID */
  shiftOfferId?: Maybe<Scalars["ID"]>;
  /** Time clock ID */
  timeClockId?: Maybe<Scalars["ID"]>;
  /** Compliance warning title */
  title?: Maybe<Scalars["String"]>;
  /** Time compliance warning last updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** High precision time compliance warning last updated */
  updatedAtHighPrecision: Scalars["ISO8601DateTimeHighPrecision"];
  /** The time that this warning occurred at */
  warningTime: Scalars["ISO8601DateTime"];
};

/** The connection type for ComplianceWarning. */
export type ComplianceWarningConnection = {
  __typename?: "ComplianceWarningConnection";
  /** A list of edges. */
  edges: Array<ComplianceWarningEdge>;
  /** A list of nodes. */
  nodes: Array<ComplianceWarning>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ComplianceWarningEdge = {
  __typename?: "ComplianceWarningEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ComplianceWarning>;
};

/** Compliance Warning Sort fields */
export type ComplianceWarningSort = {
  /** Field to sort by */
  field: ComplianceWarningSortField;
  /** Sort order */
  order: Order;
};

/** Compliance Warning Sort Field values */
export enum ComplianceWarningSortField {
  /** Primary key */
  Id = "id",
  /** Warning time */
  WarningTime = "warningTime",
}

/** Internal Confirm Business Invite fields */
export type ConfirmBusinessInvite = {
  __typename?: "ConfirmBusinessInvite";
  acceptedInvite: Scalars["Boolean"];
  businessId: Scalars["ID"];
  userAutoConfirmed: Scalars["Boolean"];
};

/** Confirm Business Invite input fields */
export type ConfirmBusinessInviteInput = {
  /** Time at which user email was confirmed */
  confirmedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** User email */
  email: Scalars["String"];
  /** ID of user to sync */
  id: Scalars["String"];
};

/** Internal Contract Occurrence fields */
export type ContractOccurrence = {
  __typename?: "ContractOccurrence";
  contractedTimeId: Scalars["ID"];
  employmentId: Scalars["ID"];
  endTime: Scalars["ISO8601DateTime"];
  eventType: Scalars["String"];
  id: Scalars["ID"];
  startTime: Scalars["ISO8601DateTime"];
  temporary: Scalars["Boolean"];
};

/** The connection type for ContractOccurrence. */
export type ContractOccurrenceConnection = {
  __typename?: "ContractOccurrenceConnection";
  /** A list of edges. */
  edges: Array<ContractOccurrenceEdge>;
  /** A list of nodes. */
  nodes: Array<ContractOccurrence>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ContractOccurrenceEdge = {
  __typename?: "ContractOccurrenceEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ContractOccurrence>;
};

/** Input for creating a Global Business */
export type CreateGlobalBusinessInput = {
  /** The start of the Employment GUID sequence */
  employmentUidStart: Scalars["BigInt"];
  /** Name of the Global Business */
  name: Scalars["String"];
};

/** Input for creating a Global Business Stack */
export type CreateGlobalBusinessStackInput = {
  /** True if the Global Business Stack refers to the current (local) stack */
  isSelf: Scalars["Boolean"];
  /** Domain of the Global Business Stack */
  stackDomainName: Scalars["String"];
};

/** Currency values and symbols */
export enum CurrencyEnum {
  Aud = "aud",
  Cad = "cad",
  Eur = "eur",
  Gbp = "gbp",
  Nzd = "nzd",
  Usd = "usd",
}

/** Employment Status values for an employment */
export enum CurrentEmploymentStatusEnum {
  Active = "active",
  LeaveOfAbsence = "leave_of_absence",
  Suspended = "suspended",
  Terminated = "terminated",
}

/** Internal System Databse metadata fields */
export type Database = {
  __typename?: "Database";
  /** Check for duplicate indexes */
  duplicateIndexes: Scalars["JSON"];
  /** Check for possible duplicate indexes in the first column */
  duplicateIndexesFirstColumn: Scalars["JSON"];
  /** Check for foreign keys without an index */
  foreignKeysWithoutIndex: Scalars["JSON"];
  /** Check indexes for bloat */
  indexBloatCheck: Scalars["JSON"];
  /** Rank tables for urgency of autovacuum */
  lastAutovacuum: Scalars["JSON"];
  /** Rank tables for urgency of autovacuum and autoanalyze */
  lastAutovacuumAndAutoanalyze: Scalars["JSON"];
  /** Check for needed indexes */
  neededIndexes: Scalars["JSON"];
  /** Check tables for bloat */
  tableBloatCheck: Scalars["JSON"];
  /** Check for unused indexes */
  unusedIndexes: Scalars["JSON"];
};

/** Day Of Week values */
export enum DayOfWeekEnum {
  Friday = "friday",
  Monday = "monday",
  Saturday = "saturday",
  Sunday = "sunday",
  Thursday = "thursday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
}

/** Internal Day Part fields */
export type DayPart = {
  __typename?: "DayPart";
  businessId: Scalars["ID"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  name: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for DayPart. */
export type DayPartConnection = {
  __typename?: "DayPartConnection";
  /** A list of edges. */
  edges: Array<DayPartEdge>;
  /** A list of nodes. */
  nodes: Array<DayPart>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type DayPartEdge = {
  __typename?: "DayPartEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<DayPart>;
};

/** Internal Day Part Template fields */
export type DayPartTemplate = {
  __typename?: "DayPartTemplate";
  businessId: Scalars["ID"];
  createdAt: Scalars["ISO8601DateTime"];
  details?: Maybe<Scalars["JSON"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for DayPartTemplate. */
export type DayPartTemplateConnection = {
  __typename?: "DayPartTemplateConnection";
  /** A list of edges. */
  edges: Array<DayPartTemplateEdge>;
  /** A list of nodes. */
  nodes: Array<DayPartTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type DayPartTemplateEdge = {
  __typename?: "DayPartTemplateEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<DayPartTemplate>;
};

/** Differential Definition fields */
export type DifferentialDefinition = {
  __typename?: "DifferentialDefinition";
  /** List of JTCs that get the differential - null or empty means all jtcs */
  applyToJtcs?: Maybe<Array<Scalars["ID"]>>;
  /** List of roles that get the differential - null or empty means all roles */
  applyToRoles?: Maybe<Array<Scalars["ID"]>>;
  /** Code used to export to other systems and display on UI. Note that this does not need to be unique */
  code: Scalars["String"];
  /** Time differential created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employment ID of creator */
  createdById: Scalars["ID"];
  /** An array of days of the week that are covered by the differential rate - 0 = Sunday, 1= Monday etc */
  daysCovered: Array<DayOfWeekEnum>;
  /** The last day that the differential definition will be used. null means open ended */
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  /** The end time of the differential definition for the selected days of the week */
  endTime: Scalars["ISO8601HoursMinutes"];
  /** UUID of differential definition */
  id: Scalars["ID"];
  /**
   * If is_hourly is false, then rate is a one-time payment per shift worked. If
   * is_hourly is true then the rate is applied as an additional hourly rate
   */
  isHourly: Scalars["Boolean"];
  /** The differential pay rate, either as a one time payment per shift or an hourly rate */
  rate: Scalars["Float"];
  /** UUID of schedule that this differential definition is for */
  scheduleId: Scalars["ID"];
  /** The start date of the differential definition */
  startDate: Scalars["ISO8601Date"];
  /** The start time of the differential definition for the selected days of the week */
  startTime: Scalars["ISO8601HoursMinutes"];
  /** Time differential updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Employment ID of updater */
  updatedById?: Maybe<Scalars["ID"]>;
};

/** The connection type for DifferentialDefinition. */
export type DifferentialDefinitionConnection = {
  __typename?: "DifferentialDefinitionConnection";
  /** A list of edges. */
  edges: Array<DifferentialDefinitionEdge>;
  /** A list of nodes. */
  nodes: Array<DifferentialDefinition>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type DifferentialDefinitionEdge = {
  __typename?: "DifferentialDefinitionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<DifferentialDefinition>;
};

/** Differential Definition Sort fields */
export type DifferentialDefinitionSort = {
  /** Field to sort by */
  field: DifferentialDefinitionSortField;
  /** Sort order */
  order: Order;
};

/** Differential Definition Sort Field values */
export enum DifferentialDefinitionSortField {
  Id = "id",
  StartDate = "start_date",
}

/** Generic Document */
export type Document = {
  __typename?: "Document";
  /** Document creation time */
  createdAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Employment id of creator */
  createdById: Scalars["ID"];
  /** The optional comment added by the user to describe the file */
  description?: Maybe<Scalars["String"]>;
  /** The document links this document is associated to */
  documentLinks: Array<DocumentLink>;
  /** The enum values: visa, school_exemption etc. */
  documentType: DocumentType;
  /** Employment document associated to */
  employmentId: Scalars["ID"];
  /** Document id */
  id: Scalars["ID"];
  /** The optional file name */
  name?: Maybe<Scalars["String"]>;
  /** Document update time */
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** The connection type for Document. */
export type DocumentConnection = {
  __typename?: "DocumentConnection";
  /** A list of edges. */
  edges: Array<DocumentEdge>;
  /** A list of nodes. */
  nodes: Array<Document>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type DocumentEdge = {
  __typename?: "DocumentEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Document>;
};

/** Generic Document Link */
export type DocumentLink = {
  __typename?: "DocumentLink";
  /** Document link creation time */
  createdAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Employment id of creator */
  createdById: Scalars["ID"];
  /** Document id associated to */
  documentId: Scalars["ID"];
  /** The ID of the obj this document is associated to */
  linkedObjId: Scalars["ID"];
  /** The Class of obj (E-g: Employment, UnavailabilityEvent) */
  linkedObjType: Scalars["String"];
  /** Document link updated time */
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** Sort by a field in a specific order */
export type DocumentSort = {
  /** Field to sort by */
  field: DocumentSortField;
  /** Sort order */
  order: Order;
};

/** Sortable fields */
export enum DocumentSortField {
  /** Time at which the document was uploaded */
  CreatedAt = "createdAt",
}

/** Generic Document Type values */
export enum DocumentType {
  SchoolExemption = "school_exemption",
  Visa = "visa",
}

/** Generic Document Update Input arguments */
export type DocumentUpdateInput = {
  /** Document soft deleted */
  deleted: Scalars["Boolean"];
};

/** Dynamic Fields Layout */
export type DynamicFieldsLayout = {
  __typename?: "DynamicFieldsLayout";
  /** Groups of metadata and display info */
  groups: Array<DynamicFieldsLayoutGroup>;
  /** List of groups to be associated with a view */
  views: Array<DynamicFieldsLayoutView>;
};

export type DynamicFieldsLayoutGroup = {
  __typename?: "DynamicFieldsLayoutGroup";
  /** Fields associated with the group */
  fields: Array<DynamicFieldsLayoutGroupField>;
  /** Label for the group */
  label: Scalars["String"];
  /** Name of the group */
  name: Scalars["String"];
};

export type DynamicFieldsLayoutGroupField = {
  __typename?: "DynamicFieldsLayoutGroupField";
  /** Name of the Metadata Type field is associated with */
  metadataTypeName: Scalars["String"];
  /** Display width of the field */
  width: Scalars["Int"];
};

export type DynamicFieldsLayoutGroupFieldInput = {
  /** Name of the Metadata Type field is associated with */
  metadataTypeName: Scalars["String"];
  /** Display width of the field */
  width: Scalars["Int"];
};

export type DynamicFieldsLayoutGroupInput = {
  /** Fields associated with the group */
  fields: Array<DynamicFieldsLayoutGroupFieldInput>;
  /** Label for the group */
  label: Scalars["String"];
  /** Name of the group */
  name: Scalars["String"];
};

/** Dynamic Fields Layout Input */
export type DynamicFieldsLayoutInput = {
  /** Groups of metadata and display info */
  groups: Array<DynamicFieldsLayoutGroupInput>;
  /** List of groups to be associated with a view */
  views: Array<DynamicFieldsLayoutViewInput>;
};

export type DynamicFieldsLayoutView = {
  __typename?: "DynamicFieldsLayoutView";
  /** Groups to be displayed in this view */
  groups: Array<Scalars["String"]>;
  /** Name of the view */
  name: Scalars["String"];
};

export type DynamicFieldsLayoutViewInput = {
  /** Groups to be displayed in this view */
  groups: Array<Scalars["String"]>;
  /** Name of the view */
  name: Scalars["String"];
};

/** Employment Type GMH Settings fields */
export type EmplTypeGmhSettings = {
  __typename?: "EmplTypeGmhSettings";
  /** Whether GMH for casual employees is enabled, and their warning and error thresholds */
  casual: EmplTypeGmhSettingsEmploymentTypeConfig;
  /** Whether GMH for full time employees is enabled, and their warning and error thresholds */
  fullTime: EmplTypeGmhSettingsEmploymentTypeConfig;
  /** Whether GMH for part time employees is enabled, and their warning and error thresholds */
  partTime: EmplTypeGmhSettingsEmploymentTypeConfig;
  /** Whether GMH for permanent employees is enabled, and their warning and error thresholds */
  permanent: EmplTypeGmhSettingsEmploymentTypeConfig;
};

/** Employment Type GMH Settings Employment Type Config fields */
export type EmplTypeGmhSettingsEmploymentTypeConfig = {
  __typename?: "EmplTypeGmhSettingsEmploymentTypeConfig";
  /** Whether GMH is available for this employee type */
  enabled: Scalars["Boolean"];
  /** The lowest number of seconds the GMH can be without causing an error. 0 or null disables the error */
  minimumDurationError?: Maybe<Scalars["Seconds"]>;
  /** The lowest number of seconds the GMH can be without causing a warning. 0 or null disables the warning */
  minimumDurationWarning?: Maybe<Scalars["Seconds"]>;
};

/** Employment Type GMH Settings Employment Type Config update input fields */
export type EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput = {
  /** Whether GMH is available for this employee type */
  enabled?: Maybe<Scalars["Boolean"]>;
  /** The lowest number of seconds the GMH can be without causing an error. 0 or null disables the error */
  minimumDurationError?: Maybe<Scalars["Seconds"]>;
  /** The lowest number of seconds the GMH can be without causing a warning. 0 or null disables the warning */
  minimumDurationWarning?: Maybe<Scalars["Seconds"]>;
};

/** Employment Type GMH Settings update input fields */
export type EmplTypeGmhSettingsUpdateInput = {
  /** Whether GMH for casual employees is enabled, and their warning and error thresholds */
  casual?: Maybe<EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput>;
  /** Whether GMH for full time employees is enabled, and their warning and error thresholds */
  fullTime?: Maybe<EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput>;
  /** Whether GMH for part time employees is enabled, and their warning and error thresholds */
  partTime?: Maybe<EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput>;
  /** Whether GMH for permanent employees is enabled, and their warning and error thresholds */
  permanent?: Maybe<EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput>;
};

/** EmplTypeTimeOffComplianceEnabled */
export type EmplTypeTimeOffComplianceEnabled = {
  __typename?: "EmplTypeTimeOffComplianceEnabled";
  /** Whether casual employments raise compliance errors when their leave is not covered by time off shifts */
  casual: Scalars["Boolean"];
  /** Whether full_time employments raise compliance errors when their leave is not covered by time off shifts */
  fullTime: Scalars["Boolean"];
  /** Whether part_time employments raise compliance errors when their leave is not covered by time off shifts */
  partTime: Scalars["Boolean"];
  /** Whether permanent employments raise compliance errors when their leave is not covered by time off shifts */
  permanent: Scalars["Boolean"];
};

/** EmplTypeTimeOffComplianceEnabled Input */
export type EmplTypeTimeOffComplianceEnabledInput = {
  /** Whether casual employments raise compliance errors when their leave is not covered by time off shifts */
  casual: Scalars["Boolean"];
  /** Whether full_time employments raise compliance errors when their leave is not covered by time off shifts */
  fullTime: Scalars["Boolean"];
  /** Whether part_time employments raise compliance errors when their leave is not covered by time off shifts */
  partTime: Scalars["Boolean"];
  /** Whether permanent employments raise compliance errors when their leave is not covered by time off shifts */
  permanent: Scalars["Boolean"];
};

/** Internal Employment fields */
export type Employment = {
  __typename?: "Employment";
  /** Whether employment has accepted invite to business */
  acceptedInvite: Scalars["Boolean"];
  /** ID from 3rd party system that uniquely identifies the application & system it came from */
  applicationCode?: Maybe<Scalars["String"]>;
  /** Date the employee applied for a job in the 3rd party system. */
  applicationDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Association linking the employment to the business */
  business: Business;
  /** ID of the business this employment belongs to */
  businessId: Scalars["ID"];
  /** Association linking the employment to business invite */
  businessInvite?: Maybe<BusinessInvite>;
  /** 3rd party unique ID for the employment */
  code?: Maybe<Scalars["String"]>;
  /** Formatted name of the employment */
  computedName: Scalars["String"];
  /** Time employment created */
  createdAt: Scalars["ISO8601DateTime"];
  /** The employees current employment contract */
  currentEmploymentContract?: Maybe<EmploymentContract>;
  /** Denotes the current employment_contract of the employee. */
  currentEmploymentContractId?: Maybe<Scalars["ID"]>;
  /** ID of the current employment history */
  currentEmploymentHistoryId?: Maybe<Scalars["ID"]>;
  /** The employees current home schedule */
  currentHomeSchedule?: Maybe<Schedule>;
  /** Denotes the current home schedule of the employee. */
  currentHomeScheduleId?: Maybe<Scalars["ID"]>;
  /** The employees current primary employment rate */
  currentPrimaryEmploymentRate?: Maybe<EmploymentRate>;
  /** Denotes the current primary employment rate of the employee. */
  currentPrimaryEmploymentRateId?: Maybe<Scalars["ID"]>;
  /** Last time this employment record changed in a way which matters to datapass. Default to now on creation of a record. */
  datapassChangedAt?: Maybe<Scalars["ISO8601DateTimeHighPrecision"]>;
  /** Date of Birth */
  dateOfBirth: Scalars["String"];
  /** Whether the employment is soft deleted */
  deleted: Scalars["Boolean"];
  /** Indicates whether the employment is in draft state. Default is false */
  draft: Scalars["Boolean"];
  /** DateTime range representing when the employment is employed */
  during?: Maybe<Scalars["String"]>;
  /** Email Address */
  email: Scalars["String"];
  /** Association linking the employment to employment contracts */
  employmentContracts?: Maybe<Array<EmploymentContract>>;
  employmentExternalCodes?: Maybe<Array<EmploymentExternalCode>>;
  /** Association of time-ranged histories of the employent */
  employmentHistories?: Maybe<Array<EmploymentHistory>>;
  /** Association linking the employment to business office locations */
  employmentLocations?: Maybe<Array<EmploymentOfficeLocation>>;
  /** Association of time-ranged max hours of the employment */
  employmentMaxHours?: Maybe<Array<MaxHour>>;
  /** Association linking the employment metadata */
  employmentMetadata?: Maybe<Array<EmploymentMetadata>>;
  /** The minimum rate an employee will be paid. Default is 0 */
  employmentRate: Scalars["Float"];
  /** Association linking the employment to job titles */
  employmentRates: Array<EmploymentRate>;
  /** Association linking the employment to business roles */
  employmentRoles: Array<EmploymentRole>;
  /** Association linking the employment to employment schedule histories */
  employmentScheduleHistories?: Maybe<Array<EmploymentScheduleHistory>>;
  /** Association linking the employment to schedules */
  employmentSchedules?: Maybe<Array<EmploymentSchedule>>;
  /** Association of security data of the employment */
  employmentSecurities?: Maybe<Array<EmploymentSecurity>>;
  /**
   * The employment type of this employee, constrained to legacy values.
   * @deprecated Use employmentTypeCode or employmentTypeConfig { employmentTypeCode } instead.
   */
  employmentType: Scalars["String"];
  /** The employment type code of this employee. */
  employmentTypeCode: Scalars["String"];
  /** The small ID of the employment type config of the employee in this contract. */
  employmentTypeConfig: EmploymentTypeConfig;
  /** The small ID of the employment type config of the employee in this contract. */
  employmentTypeSmallId: Scalars["Int"];
  /** Indicates whether employment ingested from external system. Default is false */
  external: Scalars["Boolean"];
  /** First Name */
  firstName: Scalars["String"];
  /** Employment ID */
  id: Scalars["ID"];
  /** ID of image used as the employment avatar */
  imageId?: Maybe<Scalars["ID"]>;
  /** Indicates whether the employment has a manager status during the date input */
  isManager: Scalars["Boolean"];
  /** Last Name */
  lastName: Scalars["String"];
  /** Approximate time employment last successfully accessed business */
  lastSeenAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Locale of the user */
  locale: Scalars["String"];
  /** Features for which an employee is muted */
  mutedFeatures?: Maybe<Array<Scalars["String"]>>;
  /** ID of the next employment history */
  nextEmploymentHistoryId?: Maybe<Scalars["ID"]>;
  /** Represents nickname of employee */
  nickname?: Maybe<Scalars["String"]>;
  /** Represents payroll ID of employee */
  payrollCode?: Maybe<Scalars["String"]>;
  /** Indicates whether the employee needs to be sent an SSO invite. Internal field. */
  pendingSsoBusinessInvite: Scalars["Boolean"];
  /** Represents rating of the employee */
  rating: Scalars["Int"];
  /** School the employee goes to, or NULL if the employee does not attend school. */
  schoolId?: Maybe<Scalars["ID"]>;
  /** The security role the employee has within the business. Default is employee */
  securityRole: Scalars["String"];
  /** Represents SSO code of employee */
  ssoCode?: Maybe<Scalars["String"]>;
  /** Association linking the employment to time off balances */
  timeOffBalances?: Maybe<Array<TimeOffBalance>>;
  /** Time employment last updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Email of the user this employment belongs to */
  userEmail?: Maybe<Scalars["ID"]>;
  /** ID of the user this employment belongs to */
  userId?: Maybe<Scalars["ID"]>;
  /** Indicates whether biometrics login is enabled on a time clock device. Default is false */
  usesBiometrics?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether pin login is enabled on a time clock device. Default is false */
  usesPin?: Maybe<Scalars["Boolean"]>;
};

/** Internal Employment fields */
export type EmploymentIsManagerArgs = {
  date: Scalars["String"];
};

/** Employment creation input fields for above store SSO */
export type EmploymentAutoCreateInput = {
  /** Employment date of birth */
  dateOfBirth: Scalars["ISO8601Date"];
  /** Employment email */
  email: Scalars["String"];
  /** Employment first name */
  firstName: Scalars["String"];
  /** Employment last name */
  lastName: Scalars["String"];
  /** Employment permissions */
  permissions: Scalars["JSON"];
  /** Employment sso code */
  ssoCode: Scalars["String"];
};

/** The connection type for Employment. */
export type EmploymentConnection = {
  __typename?: "EmploymentConnection";
  /** A list of edges. */
  edges: Array<EmploymentEdge>;
  /** A list of nodes. */
  nodes: Array<Employment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** Internal Employment Contract fields */
export type EmploymentContract = {
  __typename?: "EmploymentContract";
  /** The employee who acknowledged the employment contract */
  acknowledgedBy?: Maybe<Employment>;
  /** Employment ID of who acknowledged the employment contract, or NULL if not acknowledged */
  acknowledgedById?: Maybe<Scalars["ID"]>;
  /** Cached end time computed from end_date using the schedules day_start_time and time_zone */
  cachedEndTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Cached start time computed from start_date using the schedules day_start_time and time_zone */
  cachedStartTime: Scalars["ISO8601DateTime"];
  /** The 3rd party identifier code of the employment contract, if any */
  code?: Maybe<Scalars["String"]>;
  /** Time employment contract was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** The employee who created the employment contract */
  createdBy?: Maybe<Employment>;
  /** Employment ID of the creator */
  createdById?: Maybe<Scalars["ID"]>;
  /** Whether the employment contract is soft-deleted */
  deleted: Scalars["Boolean"];
  /** The employee who the employment contract applies to */
  employment?: Maybe<Employment>;
  /** Employment ID */
  employmentId: Scalars["ID"];
  /**
   * The employment type of this employment contract, constrained to legacy values.
   * @deprecated Use employmentTypeCode or employmentTypeConfig { employmentTypeCode } instead.
   */
  employmentType: EmploymentTypeEnum;
  /** The employment type code of this employment contract. */
  employmentTypeCode: Scalars["String"];
  /** The employment type config of the employee in this contract. */
  employmentTypeConfig: EmploymentTypeConfig;
  /** The small ID of the employment type config of the employee in this contract. */
  employmentTypeSmallId: Scalars["Int"];
  /** End date for date range. Range is inclusive of end */
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Employment Contract ID */
  id: Scalars["ID"];
  /** Time of acknowledgement by responding manager, or NULL if not acknowledged */
  managerAcknowledgedNotificationAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Minimum hours per week stored as seconds */
  minDurationPerWeek?: Maybe<Scalars["Seconds"]>;
  /** Message from the requesting manager, if any */
  requestingMessage?: Maybe<Scalars["String"]>;
  /** Time of response */
  respondedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The employee who responded the employment contract */
  respondedBy?: Maybe<Employment>;
  /** Employment ID of the responder */
  respondedById?: Maybe<Scalars["ID"]>;
  /** Message from the responding employee, if any */
  respondingMessage?: Maybe<Scalars["String"]>;
  /** Start date for date range. Range is inclusive of start */
  startDate: Scalars["ISO8601Date"];
  /** Computed status of the employment contract */
  status: EmploymentContractStatusEnum;
  /** Time of submission for approval */
  submittedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The employee who submitted the employment contract */
  submittedBy?: Maybe<Employment>;
  /** Same as created_by_id, there is no draft state for employment contract currently */
  submittedById?: Maybe<Scalars["ID"]>;
  /** Whether this record is added by system or created by manager */
  systemAdded: Scalars["Boolean"];
  /** Time employment contract was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** The employee who updated the employment contract */
  updatedBy?: Maybe<Employment>;
  /** Employment ID of the updater */
  updatedById?: Maybe<Scalars["ID"]>;
};

/** The connection type for EmploymentContract. */
export type EmploymentContractConnection = {
  __typename?: "EmploymentContractConnection";
  /** A list of edges. */
  edges: Array<EmploymentContractEdge>;
  /** A list of nodes. */
  nodes: Array<EmploymentContract>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EmploymentContractEdge = {
  __typename?: "EmploymentContractEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<EmploymentContract>;
};

/** Employment Contract Sort fields */
export type EmploymentContractSort = {
  /** Field to sort by */
  field: EmploymentContractSortField;
  /** Sort order */
  order: Order;
};

/** Employment Contract Sort Field values */
export enum EmploymentContractSortField {
  CachedEndTime = "cachedEndTime",
  CachedStartTime = "cachedStartTime",
  Deleted = "deleted",
  EmploymentId = "employmentId",
  EndDate = "endDate",
  ManagerAcknowledgedNotificationAt = "managerAcknowledgedNotificationAt",
  StartDate = "startDate",
  Status = "status",
}

/** Internal Employment Contract Status values */
export enum EmploymentContractStatusEnum {
  Active = "active",
  Completed = "completed",
  ConsentPending = "consent_pending",
  ConsentRejected = "consent_rejected",
  Draft = "draft",
  Upcoming = "upcoming",
}

/** An edge in a connection. */
export type EmploymentEdge = {
  __typename?: "EmploymentEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Employment>;
};

/** Internal Employment External Code fields */
export type EmploymentExternalCode = {
  __typename?: "EmploymentExternalCode";
  /** Time employment external code was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Whether employment external code is deleted */
  deleted: Scalars["Boolean"];
  /** ID of employment */
  employmentId: Scalars["ID"];
  /** Employment external code */
  externalCode: Scalars["String"];
  /** Employment external subcode */
  externalSubcode: Scalars["String"];
  /** ID of employment external code */
  id: Scalars["ID"];
  /** Rate preference index */
  ratePreferenceIndex: Scalars["Int"];
  /** ID of schedule */
  scheduleId: Scalars["ID"];
  /** Time employment external code was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for EmploymentExternalCode. */
export type EmploymentExternalCodeConnection = {
  __typename?: "EmploymentExternalCodeConnection";
  /** A list of edges. */
  edges: Array<EmploymentExternalCodeEdge>;
  /** A list of nodes. */
  nodes: Array<EmploymentExternalCode>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EmploymentExternalCodeEdge = {
  __typename?: "EmploymentExternalCodeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<EmploymentExternalCode>;
};

/** Employment External Managed Flags fields */
export type EmploymentExternalManagedFlags = {
  __typename?: "EmploymentExternalManagedFlags";
  /** Whether availability is externally managed */
  availability: Scalars["Boolean"];
  /** Whether contracts are externally managed */
  contract: Scalars["Boolean"];
  /** Whether hire history is externally managed */
  hireHistory: Scalars["Boolean"];
  /** Whether home store is externally managed */
  homeStore: Scalars["Boolean"];
  /** Whether jtcs are externally managed */
  jtc: Scalars["Boolean"];
  /** Whether nickname is externally managed */
  nickname: Scalars["Boolean"];
  /** Whether personal details are externally managed */
  personalDetails: Scalars["Boolean"];
  /** Whether role and rate is externally managed */
  roleAndRate: Scalars["Boolean"];
  /** Whether school is externally managed */
  school: Scalars["Boolean"];
  /** Whether shared store is externally managed */
  sharedStore: Scalars["Boolean"];
  /** Whether skill level is externally managed */
  skillLevel: Scalars["Boolean"];
  /** Whether time off is externally managed */
  timeOff: Scalars["Boolean"];
};

/** Employment External Manager Flags Input fields */
export type EmploymentExternalManagedFlagsUpdateInput = {
  /** Whether availability is externally managed */
  availability?: Maybe<Scalars["Boolean"]>;
  /** Whether contracts are externally managed */
  contract?: Maybe<Scalars["Boolean"]>;
  /** Whether hire history is externally managed */
  hireHistory?: Maybe<Scalars["Boolean"]>;
  /** Whether home store is externally managed */
  homeStore?: Maybe<Scalars["Boolean"]>;
  /** Whether jtcs are externally managed */
  jtc?: Maybe<Scalars["Boolean"]>;
  /** Whether nickname is externally managed */
  nickname?: Maybe<Scalars["Boolean"]>;
  /** Whether personal details are externally managed */
  personalDetails?: Maybe<Scalars["Boolean"]>;
  /** Whether role and rate is externally managed */
  roleAndRate?: Maybe<Scalars["Boolean"]>;
  /** Whether school is externally managed */
  school?: Maybe<Scalars["Boolean"]>;
  /** Whether shared store is externally managed */
  sharedStore?: Maybe<Scalars["Boolean"]>;
  /** Whether skill level is externally managed */
  skillLevel?: Maybe<Scalars["Boolean"]>;
  /** Whether time off is externally managed */
  timeOff?: Maybe<Scalars["Boolean"]>;
};

/** Employment Feedback fields */
export type EmploymentFeedback = {
  __typename?: "EmploymentFeedback";
  /** Time employment feedback was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Manager who created the employment feedback */
  createdBy?: Maybe<Employment>;
  /** Manager who initially created feedback */
  createdById: Scalars["ID"];
  /** timestamp for employee to acknowledged Published */
  employeeAcknowledgedNotificationAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Related employment */
  employment?: Maybe<Employment>;
  /** Related employment feedback reason */
  employmentFeedbackReason?: Maybe<EmploymentFeedbackReason>;
  /** Employment feedback reason ID */
  employmentFeedbackReasonId?: Maybe<Scalars["ID"]>;
  /** ID of the employment this employment feedback belongs to */
  employmentId: Scalars["ID"];
  /** Employment Feedback ID */
  id: Scalars["ID"];
  /** Date to which feedback applies */
  incidentDate: Scalars["ISO8601Date"];
  /** timestamp for requesting manager to acknowledge Approved/Rejected */
  managerAcknowledgedNotificationAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Manager comment */
  requestingMessage?: Maybe<Scalars["String"]>;
  /** Time of approving manager response */
  respondedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Manager who approved/rejected */
  respondedById?: Maybe<Scalars["ID"]>;
  /** ID of the schedule this employment feedback belongs to */
  scheduleId: Scalars["ID"];
  /** Status of the employment feedback */
  status: EmploymentFeedbackStatusEnum;
  /** Action posted time */
  submittedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Manager who published/submitted */
  submittedById?: Maybe<Scalars["ID"]>;
  /** Type of the employment feedback */
  type: EmploymentFeedbackTypeEnum;
  /** Time of last change by a manager */
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Manager who last updated the employment feedback */
  updatedBy?: Maybe<Employment>;
  /** Record manager who made the last change */
  updatedById?: Maybe<Scalars["ID"]>;
};

/** The connection type for EmploymentFeedback. */
export type EmploymentFeedbackConnection = {
  __typename?: "EmploymentFeedbackConnection";
  /** A list of edges. */
  edges: Array<EmploymentFeedbackEdge>;
  /** A list of nodes. */
  nodes: Array<EmploymentFeedback>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EmploymentFeedbackEdge = {
  __typename?: "EmploymentFeedbackEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<EmploymentFeedback>;
};

/** Employment Feedback Status values without any computed value */
export enum EmploymentFeedbackRawStatusEnum {
  Approved = "approved",
  Draft = "draft",
  Pending = "pending",
  Rejected = "rejected",
}

/** Employment Feedback Reason fields */
export type EmploymentFeedbackReason = {
  __typename?: "EmploymentFeedbackReason";
  /** Used to pre-populate comments field */
  defaultMessage?: Maybe<Scalars["String"]>;
  /** Short description, displayed in dropdowns */
  deleted: Scalars["Boolean"];
  /** Short description, displayed in dropdowns */
  description: Scalars["String"];
  /** Type of the employment feedback */
  feedbackType: EmploymentFeedbackTypeEnum;
  /** Employment Feedback Reason ID */
  id: Scalars["ID"];
};

/** The connection type for EmploymentFeedbackReason. */
export type EmploymentFeedbackReasonConnection = {
  __typename?: "EmploymentFeedbackReasonConnection";
  /** A list of edges. */
  edges: Array<EmploymentFeedbackReasonEdge>;
  /** A list of nodes. */
  nodes: Array<EmploymentFeedbackReason>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** Internal Employment Feedback Reason Create Input fields */
export type EmploymentFeedbackReasonCreateInput = {
  /** Used to pre-populate comments field */
  defaultMessage?: Maybe<Scalars["String"]>;
  /** Short description, displayed in dropdowns */
  description?: Maybe<Scalars["String"]>;
  /** Type of the employment feedback */
  feedbackType: EmploymentFeedbackTypeEnum;
};

/** An edge in a connection. */
export type EmploymentFeedbackReasonEdge = {
  __typename?: "EmploymentFeedbackReasonEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<EmploymentFeedbackReason>;
};

/** Internal Employment Feedback Reason Update Input fields */
export type EmploymentFeedbackReasonUpdateInput = {
  /** Used to pre-populate comments field */
  defaultMessage?: Maybe<Scalars["String"]>;
  /** Short description, displayed in dropdowns */
  description?: Maybe<Scalars["String"]>;
};

/** Sort by a field in a specific order */
export type EmploymentFeedbackSort = {
  /** Field to sort by */
  field: EmploymentFeedbackSortField;
  /** Sort order */
  order: Order;
};

export enum EmploymentFeedbackSortField {
  /** Primary key */
  Id = "id",
  /** incident Date */
  IncidentDate = "incidentDate",
  /** updated At */
  UpdatedAt = "updatedAt",
}

/** Employment Feedback Status values */
export enum EmploymentFeedbackStatusEnum {
  Acknowledged = "acknowledged",
  Approved = "approved",
  Draft = "draft",
  Pending = "pending",
  Rejected = "rejected",
}

/** Employment Feedback Type values */
export enum EmploymentFeedbackTypeEnum {
  Commendation = "commendation",
  DisciplinaryAction = "disciplinary_action",
}

/** Internal Employment History fields */
export type EmploymentHistory = {
  __typename?: "EmploymentHistory";
  /** 3rd party unique ID for the employment history */
  code?: Maybe<Scalars["String"]>;
  /** Time employment history was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** Employment status change reason ID */
  employmentStatusChangeReasonId?: Maybe<Scalars["ID"]>;
  /** End time for time range */
  endTime?: Maybe<Scalars["String"]>;
  /** Employment History ID */
  id: Scalars["ID"];
  /** Start time for time range */
  startTime: Scalars["String"];
  /** Status of employment for the duration of this history object */
  status: CurrentEmploymentStatusEnum;
  /** Indicates whether history was created by system to fill gap */
  systemAdded: Scalars["Boolean"];
  /** Time employment history was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for EmploymentHistory. */
export type EmploymentHistoryConnection = {
  __typename?: "EmploymentHistoryConnection";
  /** A list of edges. */
  edges: Array<EmploymentHistoryEdge>;
  /** A list of nodes. */
  nodes: Array<EmploymentHistory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EmploymentHistoryEdge = {
  __typename?: "EmploymentHistoryEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<EmploymentHistory>;
};

/** Employment History Sort fields */
export type EmploymentHistorySort = {
  /** Field to sort by */
  field: EmploymentHistorySortField;
  /** Sort order */
  order: Order;
};

/** Employment History Sort Field values */
export enum EmploymentHistorySortField {
  /** Employee id */
  EmploymentId = "employmentId",
  /** Primary key */
  Id = "id",
}

/** Internal Employment Input fields */
export type EmploymentInput = {
  /** 3rd party unique ID for the employment */
  code?: Maybe<Scalars["String"]>;
  /** Date of Birth */
  dateOfBirth?: Maybe<Scalars["String"]>;
  /** Email Address */
  email?: Maybe<Scalars["String"]>;
  /** The minimum rate an employee will be paid. Default is 0 */
  employmentRate?: Maybe<Scalars["Float"]>;
  /** The employment type of this employee, constrained to legacy values. */
  employmentType?: Maybe<Scalars["String"]>;
  /** The employment type code of this employee. */
  employmentTypeCode?: Maybe<Scalars["String"]>;
  /** The small ID of the employment type config of the employee in this contract. */
  employmentTypeSmallId?: Maybe<Scalars["Int"]>;
  /** Indicates whether employment ingested from external system. Default is false */
  external?: Maybe<Scalars["Boolean"]>;
  /** First Name */
  firstName?: Maybe<Scalars["String"]>;
  /** ID of image used as the employment avatar */
  imageId?: Maybe<Scalars["ID"]>;
  /** Last Name */
  lastName?: Maybe<Scalars["String"]>;
  /** Features for which an employee is muted */
  mutedFeatures?: Maybe<Array<Scalars["String"]>>;
  /** Represents nickname of employee */
  nickname?: Maybe<Scalars["String"]>;
  /** Represents payroll ID of employee */
  payrollCode?: Maybe<Scalars["String"]>;
  /** Represents rating of the employee */
  rating?: Maybe<Scalars["Int"]>;
  /** School the employee goes to, or NULL if the employee does not attend school. */
  schoolId?: Maybe<Scalars["ID"]>;
  /** The security role the employee has within the business. Default is employee */
  securityRole?: Maybe<Scalars["String"]>;
  /** Represents SSO code of employee */
  ssoCode?: Maybe<Scalars["String"]>;
  /** Indicates whether biometrics login is enabled on a time clock device. Default is false */
  usesBiometrics?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether pin login is enabled on a time clock device. Default is false */
  usesPin?: Maybe<Scalars["Boolean"]>;
};

/** Internal Employment Metadata fields */
export type EmploymentMetadata = {
  __typename?: "EmploymentMetadata";
  /** Time record was created */
  createdAt: Scalars["ISO8601DateTime"];
  /**
   * Metadata details. Example values:
   *  - 1
   *  - true
   *  - { "title": "Some string" }
   */
  details: Scalars["JSON"];
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** End time for time range. Range is exclusive of end. Must be null when Metadata is_timeboxed is false. */
  endTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Employment Metadata ID */
  id: Scalars["ID"];
  /** Metadata type */
  metadataType?: Maybe<MetadataType>;
  /** Metadata Type ID */
  metadataTypeId: Scalars["ID"];
  /** Start time for time range. Range is exclusive of start. Must be null when Metadata is_timeboxed is false. */
  startTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Time record was created */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for EmploymentMetadata. */
export type EmploymentMetadataConnection = {
  __typename?: "EmploymentMetadataConnection";
  /** A list of edges. */
  edges: Array<EmploymentMetadataEdge>;
  /** A list of nodes. */
  nodes: Array<EmploymentMetadata>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** Internal Employment Metadata Create input fields */
export type EmploymentMetadataCreateInput = {
  /**
   * Metadata details. Example values:
   *  - 1
   *  - true
   *  - { "title": "Some string" }
   */
  details: Scalars["JSON"];
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** End time for time range. Range is exclusive of end. Must be null when Metadata is_timeboxed is false. */
  endTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Metadata Type ID */
  metadataTypeId: Scalars["ID"];
  /** Start time for time range. Range is exclusive of start. Must be null when Metadata is_timeboxed is false. */
  startTime?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** An edge in a connection. */
export type EmploymentMetadataEdge = {
  __typename?: "EmploymentMetadataEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<EmploymentMetadata>;
};

/** Employment schedule history Sort fields */
export type EmploymentMetadataSort = {
  /** Field to sort by */
  field: EmploymentMetadataSortField;
  /** Sort order */
  order: Order;
};

/** Employment Metadata Sort Field values */
export enum EmploymentMetadataSortField {
  /** Employee id */
  EmploymentId = "employmentId",
  /** Primary key */
  Id = "id",
}

/** Internal Employment Metadata Update input fields */
export type EmploymentMetadataUpdateInput = {
  /**
   * Metadata details. Example values:
   *  - 1
   *  - true
   *  - { "title": "Some string" }
   */
  details?: Maybe<Scalars["JSON"]>;
  /** Employment ID */
  employmentId?: Maybe<Scalars["ID"]>;
  /** End time for time range. Range is exclusive of end. Must be null when Metadata is_timeboxed is false. */
  endTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Metadata Type ID */
  metadataTypeId?: Maybe<Scalars["ID"]>;
  /** Start time for time range. Range is exclusive of start. Must be null when Metadata is_timeboxed is false. */
  startTime?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** Employment Metadata Upsert input fields */
export type EmploymentMetadataUpsertInput = {
  /**
   * Metadata details. Example values:
   *  - 1
   *  - true
   *  - { "title": "Some string" }
   */
  details?: Maybe<Scalars["JSON"]>;
  /** Metadata Type ID */
  metadataTypeId: Scalars["ID"];
};

/** Internal Employment Office Location fields */
export type EmploymentOfficeLocation = {
  __typename?: "EmploymentOfficeLocation";
  /** Business Office Location ID */
  businessOfficeLocationId: Scalars["ID"];
  /** Time employment office location was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** Employment Office Location ID */
  id: Scalars["ID"];
  /** Time employment office location was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Internal Employment Rate fields */
export type EmploymentRate = {
  __typename?: "EmploymentRate";
  /** The annual salary an employee will be paid. Default is 0 */
  annualSalary: Scalars["Float"];
  /** 3rd party unique ID for the shift */
  code?: Maybe<Scalars["String"]>;
  /** When the create/edit JTC request was submitted */
  createSubmittedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Who submitted the create/edit JTC request */
  createSubmittedById?: Maybe<Scalars["ID"]>;
  /** Time employment rate was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** When the delete JTC request was submitted */
  deleteSubmittedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Who submitted the delete JTC request */
  deleteSubmittedById?: Maybe<Scalars["ID"]>;
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** End date for date range. Range is inclusive of end. */
  endDate?: Maybe<Scalars["String"]>;
  /** Employment Rate ID */
  id: Scalars["ID"];
  /** The job title associated with this employment rate */
  jobTitle?: Maybe<JobTitle>;
  /** Job title ID */
  jobTitleId?: Maybe<Scalars["ID"]>;
  /** The prior agreed rate an employee will be paid. Default is 0 */
  priorAgreedRate: Scalars["Float"];
  /** The rate an employee will be paid. Default is 0 */
  rate: Scalars["Float"];
  /** Preferred employment rate index. Default is 0 */
  ratePreferenceIndex: Scalars["Int"];
  /** Start date for date range. Range is inclusive of start */
  startDate: Scalars["String"];
  /** Status of the employment rate */
  status: EmploymentRateStatusEnum;
  /** Time employment rate was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Employment Rate Change Request Configuration fields */
export type EmploymentRateChangeRequestConfig = {
  __typename?: "EmploymentRateChangeRequestConfig";
  /**
   * Determines automatic or manual approval workflow. Defaults to false for businesses.
   * When disabled, all requests must be approved by another person of the appropriate level.
   * When enabled, requesters of the appropriate level will have the request auto approved.
   */
  autoApprovalEnabled: Scalars["Boolean"];
};

/** Internal Employment Rate Change Request Configuration fields */
export type EmploymentRateChangeRequestConfigInput = {
  /**
   * Determines automatic or manual approval workflow. Defaults to false for businesses.
   * When disabled, all requests must be approved by another person of the appropriate level.
   * When enabled, requesters of the appropriate level will have the request auto approved.
   */
  autoApprovalEnabled?: Maybe<Scalars["Boolean"]>;
};

/** The connection type for EmploymentRate. */
export type EmploymentRateConnection = {
  __typename?: "EmploymentRateConnection";
  /** A list of edges. */
  edges: Array<EmploymentRateEdge>;
  /** A list of nodes. */
  nodes: Array<EmploymentRate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EmploymentRateEdge = {
  __typename?: "EmploymentRateEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<EmploymentRate>;
};

/** Employment Rate Edit Past values */
export enum EmploymentRateEditPastEnum {
  /** Cannot edit in past */
  Disabled = "disabled",
  /** Can edit in past (no restrictions) */
  Enabled = "enabled",
}

/** Employment Rate Sort fields */
export type EmploymentRateSort = {
  /** Field to sort by */
  field: EmploymentRateSortField;
  /** Sort order */
  order: Order;
};

/** Employment Rate Sort Field values */
export enum EmploymentRateSortField {
  /** Employee id */
  EmploymentId = "employmentId",
  /** Primary key */
  Id = "id",
}

/** Employment rates status values */
export enum EmploymentRateStatusEnum {
  Active = "active",
  Approved = "approved",
  Completed = "completed",
  Deleted = "deleted",
  PendingApproval = "pending_approval",
  PendingDeletion = "pending_deletion",
  Rejected = "rejected",
  Upcoming = "upcoming",
}

/** Internal Employment Role fields */
export type EmploymentRole = {
  __typename?: "EmploymentRole";
  /** Association link to business role */
  businessRole: BusinessRole;
  /** Business Role ID */
  businessRoleId: Scalars["ID"];
  /** Time employment role was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** Employment Role ID */
  id: Scalars["ID"];
  /**
   * AOS Skill Level
   * @deprecated Use the employmentRoleSkillLevel aosLevel field
   */
  rating: Scalars["Int"];
  /** The rate an employee would be paid for the role */
  roleRate?: Maybe<Scalars["Float"]>;
  /** Skill Level */
  skillLevel: SkillLevel;
  /** Employment Role Skill Level ID */
  skillLevelId: Scalars["ID"];
  /** Time employment role was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for EmploymentRole. */
export type EmploymentRoleConnection = {
  __typename?: "EmploymentRoleConnection";
  /** A list of edges. */
  edges: Array<EmploymentRoleEdge>;
  /** A list of nodes. */
  nodes: Array<EmploymentRole>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EmploymentRoleEdge = {
  __typename?: "EmploymentRoleEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<EmploymentRole>;
};

/** Employment Role Sort fields */
export type EmploymentRoleSort = {
  /** Field to sort by */
  field: EmploymentRoleSortField;
  /** Sort order */
  order: Order;
};

/** Employment Role Sort Field values */
export enum EmploymentRoleSortField {
  /** Employee id */
  EmploymentId = "employmentId",
  /** Primary key */
  Id = "id",
}

/** Internal Employment Schedule fields */
export type EmploymentSchedule = {
  __typename?: "EmploymentSchedule";
  /** Time employment schedule was created */
  createdAt: Scalars["ISO8601DateTime"];
  defined: Scalars["Boolean"];
  /** The employment associated with this employment schedule */
  employment: Employment;
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** Whether the employee has general_manager rights to the schedule. Default false */
  generalManager: Scalars["Boolean"];
  /** Whether the employee has group_admin rights to the schedule. Default false */
  groupAdmin: Scalars["Boolean"];
  /** Employment Schedule ID */
  id: Scalars["ID"];
  inherited: Scalars["Boolean"];
  /** Whether the employee has inherited general_manager rights to the schedule. Default false */
  inheritedGeneralManager: Scalars["Boolean"];
  inheritedGroupAdmin: Scalars["Boolean"];
  inheritedSchedulable: Scalars["Boolean"];
  inheritedScheduleManager: Scalars["Boolean"];
  inheritedScheduleManagerWithPay: Scalars["Boolean"];
  inheritedShiftManager: Scalars["Boolean"];
  /** Whether the employee should be schedulable. Default false */
  schedulable: Scalars["Boolean"];
  /** The schedule associated with this employment schedule */
  schedule: Schedule;
  /** Schedule ID */
  scheduleId: Scalars["ID"];
  /** Whether the employee has schedule_manager rights to the schedule. Default false */
  scheduleManager: Scalars["Boolean"];
  /** Whether the employee has schedule_manager_with_pay rights to the schedule. Default false */
  scheduleManagerWithPay: Scalars["Boolean"];
  /** Whether the employee has shift_manager rights to the schedule. Default false */
  shiftManager: Scalars["Boolean"];
  /** Time employment schedule was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for EmploymentSchedule. */
export type EmploymentScheduleConnection = {
  __typename?: "EmploymentScheduleConnection";
  /** A list of edges. */
  edges: Array<EmploymentScheduleEdge>;
  /** A list of nodes. */
  nodes: Array<EmploymentSchedule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EmploymentScheduleEdge = {
  __typename?: "EmploymentScheduleEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<EmploymentSchedule>;
};

/** Internal Employment schedule history fields */
export type EmploymentScheduleHistory = {
  __typename?: "EmploymentScheduleHistory";
  /** Time of acknowledgement by responding Employee, or NULL if not acknowledged */
  acknowledgedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Employment ID of who acknowledged the transfer request, or NULL if not acknowledged */
  acknowledgedById?: Maybe<Scalars["ID"]>;
  /** Cached end time computed from end_date using the schedules day_start_time and time_zone */
  cachedEndTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Cached start time computed from start_date using the schedules day_start_time and time_zone */
  cachedStartTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** employment schedule history code */
  code?: Maybe<Scalars["String"]>;
  /** Time employment schedule history was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employment ID of creator, align with other approval processes that have draft status */
  createdById?: Maybe<Scalars["ID"]>;
  /** Association linking the employment schedule history to employments */
  employment?: Maybe<Employment>;
  /** Employment ID linked to employment schedule history */
  employmentId: Scalars["ID"];
  /** End date of employment schedule history assignment */
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  /** employment schedule history ID */
  id: Scalars["ID"];
  /** Whether Employment Schedule is connected to employment schedule history */
  isHomeSchedule?: Maybe<Scalars["Boolean"]>;
  /** Message from the requested manager */
  requestedMessage?: Maybe<Scalars["String"]>;
  /** Schedule ID from where the employee is getting transferred from */
  requestingScheduleId?: Maybe<Scalars["ID"]>;
  /** Time of response */
  respondedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Employment ID of the responder */
  respondedById?: Maybe<Scalars["ID"]>;
  /** Message from the responded manager */
  respondedMessage?: Maybe<Scalars["String"]>;
  /** Destination schedule of the employment schedule history */
  schedule?: Maybe<Schedule>;
  /** Schedule ID of target store */
  scheduleId: Scalars["ID"];
  /** Start date of employment schedule history assignment */
  startDate: Scalars["ISO8601Date"];
  /** Status of the employment schedule history. E.g. pending, rejected, approved */
  status: EmploymentScheduleHistoryStatusEnum;
  /** Time of submission for approval */
  submittedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Same as created_by_id, there is no draft state for store transfer request */
  submittedById?: Maybe<Scalars["ID"]>;
  /** Time employment schedule history was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for EmploymentScheduleHistory. */
export type EmploymentScheduleHistoryConnection = {
  __typename?: "EmploymentScheduleHistoryConnection";
  /** A list of edges. */
  edges: Array<EmploymentScheduleHistoryEdge>;
  /** A list of nodes. */
  nodes: Array<EmploymentScheduleHistory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** Internal Employment schedule history input fields for creation */
export type EmploymentScheduleHistoryCreateInput = {
  /** Time of acknowledgement by responding Employee, or NULL if not acknowledged */
  acknowledgedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Employment ID of who acknowledged the transfer request, or NULL if not acknowledged */
  acknowledgedById?: Maybe<Scalars["ID"]>;
  /** Cached end time computed from end_date using the schedules day_start_time and time_zone */
  cachedEndTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Cached start time computed from start_date using the schedules day_start_time and time_zone */
  cachedStartTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** employment schedule history code */
  code?: Maybe<Scalars["String"]>;
  /** Employment ID of creator, align with other approval processes that have draft status */
  createdById?: Maybe<Scalars["ID"]>;
  /** Employment ID linked to employment schedule history */
  employmentId: Scalars["ID"];
  /** End date of employment schedule history assignment */
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Whether Employment Schedule is connected to employment schedule history */
  isHomeSchedule?: Maybe<Scalars["Boolean"]>;
  /** Message from the requested manager */
  requestedMessage?: Maybe<Scalars["String"]>;
  /** Schedule ID from where the employee is getting transferred from */
  requestingScheduleId?: Maybe<Scalars["ID"]>;
  /** Time of response */
  respondedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Employment ID of the responder */
  respondedById?: Maybe<Scalars["ID"]>;
  /** Message from the responded manager */
  respondedMessage?: Maybe<Scalars["String"]>;
  /** Schedule ID linked to employment schedule history */
  scheduleId: Scalars["ID"];
  /** Start date of employment schedule history assignment */
  startDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Status of the employment schedule history. E.g. pending, rejected, approved */
  status?: Maybe<EmploymentScheduleHistoryStatusEnum>;
  /** Time of submission for approval */
  submittedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Same as created_by_id, there is no draft state for store transfer request */
  submittedById?: Maybe<Scalars["ID"]>;
};

/** An edge in a connection. */
export type EmploymentScheduleHistoryEdge = {
  __typename?: "EmploymentScheduleHistoryEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<EmploymentScheduleHistory>;
};

/** Employment schedule history Sort fields */
export type EmploymentScheduleHistorySort = {
  /** Field to sort by */
  field: EmploymentScheduleHistorySortField;
  /** Sort order */
  order: Order;
};

/** Employment schedule history Sort Field values */
export enum EmploymentScheduleHistorySortField {
  /** Employee id */
  EmploymentId = "employmentId",
  /** Primary key */
  Id = "id",
}

/** Employment Schedule History Status values */
export enum EmploymentScheduleHistoryStatusEnum {
  Approved = "approved",
  Pending = "pending",
  Rejected = "rejected",
}

/** Internal Employment schedule history input fields for update */
export type EmploymentScheduleHistoryUpdateInput = {
  /** Time of acknowledgement by responding Employee, or NULL if not acknowledged */
  acknowledgedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Employment ID of who acknowledged the transfer request, or NULL if not acknowledged */
  acknowledgedById?: Maybe<Scalars["ID"]>;
  /** Cached end time computed from end_date using the schedules day_start_time and time_zone */
  cachedEndTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Cached start time computed from start_date using the schedules day_start_time and time_zone */
  cachedStartTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** employment schedule history code */
  code?: Maybe<Scalars["String"]>;
  /** Employment ID of creator, align with other approval processes that have draft status */
  createdById?: Maybe<Scalars["ID"]>;
  /** Employment ID linked to employment schedule history */
  employmentId?: Maybe<Scalars["ID"]>;
  /** End date of employment schedule history assignment */
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Whether Employment Schedule is connected to employment schedule history */
  isHomeSchedule?: Maybe<Scalars["Boolean"]>;
  /** Message from the requested manager */
  requestedMessage?: Maybe<Scalars["String"]>;
  /** Schedule ID from where the employee is getting transferred from */
  requestingScheduleId?: Maybe<Scalars["ID"]>;
  /** Time of response */
  respondedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Employment ID of the responder */
  respondedById?: Maybe<Scalars["ID"]>;
  /** Message from the responded manager */
  respondedMessage?: Maybe<Scalars["String"]>;
  /** Schedule ID of target store */
  scheduleId?: Maybe<Scalars["ID"]>;
  /** Start date of employment schedule history assignment */
  startDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Status of the employment schedule history. E.g. pending, rejected, approved */
  status?: Maybe<EmploymentScheduleHistoryStatusEnum>;
  /** Time of submission for approval */
  submittedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Same as created_by_id, there is no draft state for store transfer request */
  submittedById?: Maybe<Scalars["ID"]>;
};

/** Employment Schedule Sort fields */
export type EmploymentScheduleSort = {
  /** Field to sort by */
  field: EmploymentScheduleSortField;
  /** Sort order */
  order: Order;
};

/** Employment Schedule Sort Field values */
export enum EmploymentScheduleSortField {
  /** Employee id */
  EmploymentId = "employmentId",
}

/** Internal Employment Security fields */
export type EmploymentSecurity = {
  __typename?: "EmploymentSecurity";
  /** Employment biometrics */
  biometrics?: Maybe<Scalars["String"]>;
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** Employment encrypted biometrics */
  encryptedBiometrics?: Maybe<Scalars["String"]>;
  /** Employment encrypted biometrics initialization vector */
  encryptedBiometricsIv?: Maybe<Scalars["String"]>;
  /** Employment encrypted pin */
  encryptedPin?: Maybe<Scalars["String"]>;
  /** Employment encrypted pin initialization vector */
  encryptedPinIv?: Maybe<Scalars["String"]>;
  /** Employment Security ID */
  id: Scalars["ID"];
  /** Employment pin */
  pin?: Maybe<Scalars["String"]>;
};

/** Internal Employment Security fields for update. */
export type EmploymentSecurityUpdateInput = {
  /** Employment pin */
  pin?: Maybe<Scalars["String"]>;
};

/** Employment Sort fields */
export type EmploymentSort = {
  /** Field to sort by */
  field: EmploymentSortField;
  /** Sort order */
  order: Order;
};

/** Employment Sort Field values */
export enum EmploymentSortField {
  /** First name of the employee */
  FirstName = "firstName",
  /** Primary key */
  Id = "id",
  /** Last name of the employee */
  LastName = "lastName",
}

/** Internal Employment Status Change Reason fields */
export type EmploymentStatusChangeReason = {
  __typename?: "EmploymentStatusChangeReason";
  /** 3rd party unique code */
  code?: Maybe<Scalars["String"]>;
  /** Time Employment Status Change Reason was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Whether the Employment Status Change Reason is soft deleted */
  deleted: Scalars["Boolean"];
  /** The text for Employment Status Change Reason */
  displayText: Scalars["String"];
  /** Employment Status Change Reason ID */
  id: Scalars["ID"];
  /** Employment status that reason is affiliated to */
  status: CurrentEmploymentStatusEnum;
  /** Time Employment Status Change Reason was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for EmploymentStatusChangeReason. */
export type EmploymentStatusChangeReasonConnection = {
  __typename?: "EmploymentStatusChangeReasonConnection";
  /** A list of edges. */
  edges: Array<EmploymentStatusChangeReasonEdge>;
  /** A list of nodes. */
  nodes: Array<EmploymentStatusChangeReason>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EmploymentStatusChangeReasonEdge = {
  __typename?: "EmploymentStatusChangeReasonEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<EmploymentStatusChangeReason>;
};

/** Employment Status Change Reason Sort fields */
export type EmploymentStatusChangeReasonSort = {
  /** Field to sort by */
  field: EmploymentStatusChangeReasonSortField;
  /** Sort order */
  order: Order;
};

/** Employment status change reason Sort Field values */
export enum EmploymentStatusChangeReasonSortField {
  /** Code */
  Code = "code",
  /** Primary key */
  Id = "id",
}

/** Internal Employment Time Line fields */
export type EmploymentTimeLine = {
  __typename?: "EmploymentTimeLine";
  /** Employment time line creation time */
  createdAt: Scalars["ISO8601DateTime"];
  /** An array of schedule_ids for schedules the given employee is employed in. Default is [] */
  employedInScheduleIds: Array<Scalars["ID"]>;
  /** Employment history status of employment for the duration of this employment time line */
  employmentHistoryStatus: CurrentEmploymentStatusEnum;
  /** Employment that employment time line is associated to */
  employmentId: Scalars["ID"];
  /** Employment time line end time */
  endDateTime: Scalars["ISO8601DateTime"];
  /** Employment Time Line ID */
  id: Scalars["ID"];
  /** Indicates if the time line is during defined availability */
  onDefinedAvailability: Scalars["Boolean"];
  /** Indicates if the time line is during a fixed shift */
  onFixedShift: Scalars["Boolean"];
  /** Indicates if the time line is during leave */
  onLeave: Scalars["Boolean"];
  /** Indicates if the time line is during preferred hours */
  onPreferredHours: Scalars["Boolean"];
  /** ID of the schedule that the time lines fixed shift belongs to - NOTE: do not use this to filter by schedule */
  onScheduleId?: Maybe<Scalars["ID"]>;
  /** Employment time line start time */
  startDateTime: Scalars["ISO8601DateTime"];
  /** Employment time line update time */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for EmploymentTimeLine. */
export type EmploymentTimeLineConnection = {
  __typename?: "EmploymentTimeLineConnection";
  /** A list of edges. */
  edges: Array<EmploymentTimeLineEdge>;
  /** A list of nodes. */
  nodes: Array<EmploymentTimeLine>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EmploymentTimeLineEdge = {
  __typename?: "EmploymentTimeLineEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<EmploymentTimeLine>;
};

/** Employment Time Line Sort fields */
export type EmploymentTimeLineSort = {
  /** Field to sort by */
  field: EmploymentTimeLineSortField;
  /** Sort order */
  order: Order;
};

/** Employment Time Line Sort Field values */
export enum EmploymentTimeLineSortField {
  /** end time of the employment time line */
  EndDateTime = "endDateTime",
  /** Primary key */
  Id = "id",
  /** start time of the employment time line */
  StartDateTime = "startDateTime",
}

/** Internal Employment Time Line Request fields */
export type EmploymentTimeLinesRequest = {
  __typename?: "EmploymentTimeLinesRequest";
  /** End date of the request input */
  endDate: Scalars["ISO8601Date"];
  /** Input data of the request input */
  inputData: EmploymentTimeLinesRequestInputData;
  /** Start date of the request input */
  startDate: Scalars["ISO8601Date"];
};

/** Internal Employment Time Line Request Input Data fields */
export type EmploymentTimeLinesRequestInputData = {
  __typename?: "EmploymentTimeLinesRequestInputData";
  /** Core data of the request input */
  data: Scalars["JSON"];
  /** Data included as part of the request input */
  included: Scalars["JSON"];
  /** Metadata associated with the request input */
  meta: Scalars["JSON"];
};

/** Employment Type Config fields */
export type EmploymentTypeConfig = {
  __typename?: "EmploymentTypeConfig";
  /** 3rd-party code of the employment type */
  code?: Maybe<Scalars["String"]>;
  /** Whether this employment type has been soft deleted. */
  deleted: Scalars["Boolean"];
  /** Code of the employment type (such as part_time, casual, etc) */
  employmentTypeCode: Scalars["String"];
  /** Type of fixed shift restriction. */
  fixedShiftRestriction: FixedShiftRestrictionEnum;
  /** Whether GMH is available for this employment type. */
  gmhEnabled: Scalars["Boolean"];
  /** The threshold of duration in seconds below which a GMH error is issued for this employment type. */
  gmhMinimumDurationError?: Maybe<Scalars["Seconds"]>;
  /** The threshold of duration in seconds below which a GMH warning is issued for this employment type. */
  gmhMinimumDurationWarning?: Maybe<Scalars["Seconds"]>;
  /** Employment Type Config ID */
  id: Scalars["ID"];
  /** Whether this employment type is the default. Exactly one employment type may be the default. */
  isDefault: Scalars["Boolean"];
  /** Name of the employment type */
  name: Scalars["String"];
  /** Short/Abbreviated name of the employment type */
  shortName: Scalars["String"];
  /** Small ID of the employment type internally generated */
  smallId: Scalars["Int"];
  /** An very short/abbreviated name of the employment type */
  timeClockName: Scalars["String"];
  /** Whether time off complicance is enabled for this employment type. */
  timeOffComplianceEnabled: Scalars["Boolean"];
};

/** The connection type for EmploymentTypeConfig. */
export type EmploymentTypeConfigConnection = {
  __typename?: "EmploymentTypeConfigConnection";
  /** A list of edges. */
  edges: Array<EmploymentTypeConfigEdge>;
  /** A list of nodes. */
  nodes: Array<EmploymentTypeConfig>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** Internal Employment Type Config Create input fields */
export type EmploymentTypeConfigCreateInput = {
  /** 3rd-party code of the employment type */
  code?: Maybe<Scalars["String"]>;
  /** Whether this employment type has been soft deleted. */
  deleted?: Maybe<Scalars["Boolean"]>;
  /** Code of the employment type (such as part_time, casual, etc) */
  employmentTypeCode: Scalars["String"];
  /** Type of fixed shift restriction. */
  fixedShiftRestriction?: Maybe<FixedShiftRestrictionEnum>;
  /** Whether GMH is available for this employment type. */
  gmhEnabled?: Maybe<Scalars["Boolean"]>;
  /** The threshold of duration in seconds below which a GMH error is issued for this employment type. */
  gmhMinimumDurationError?: Maybe<Scalars["Seconds"]>;
  /** The threshold of duration in seconds below which a GMH warning is issued for this employment type. */
  gmhMinimumDurationWarning?: Maybe<Scalars["Seconds"]>;
  /** Whether this employment type is the default. Exactly one employment type may be the default. */
  isDefault?: Maybe<Scalars["Boolean"]>;
  /** Name of the employment type */
  name: Scalars["String"];
  /** Short/Abbreviated name of the employment type */
  shortName: Scalars["String"];
  /** An very short/abbreviated name of the employment type */
  timeClockName: Scalars["String"];
  /** Whether time off complicance is enabled for this employment type. */
  timeOffComplianceEnabled?: Maybe<Scalars["Boolean"]>;
};

/** An edge in a connection. */
export type EmploymentTypeConfigEdge = {
  __typename?: "EmploymentTypeConfigEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<EmploymentTypeConfig>;
};

/** Employment Type Config Sort fields */
export type EmploymentTypeConfigSort = {
  /** Field to sort by */
  field: EmploymentTypeConfigSortField;
  /** Sort order */
  order: Order;
};

/** Employment Type Config Sort Field values */
export enum EmploymentTypeConfigSortField {
  Code = "code",
  Deleted = "deleted",
  EmploymentTypeCode = "employmentTypeCode",
  Name = "name",
  ShortName = "shortName",
  SmallId = "smallId",
  TimeClockName = "timeClockName",
}

/** Employment Type Config Update input fields */
export type EmploymentTypeConfigUpdateInput = {
  /** 3rd-party code of the employment type */
  code?: Maybe<Scalars["String"]>;
  /** Whether this employment type has been soft deleted. */
  deleted?: Maybe<Scalars["Boolean"]>;
  /** Type of fixed shift restriction. */
  fixedShiftRestriction?: Maybe<FixedShiftRestrictionEnum>;
  /** Whether GMH is available for this employment type. */
  gmhEnabled?: Maybe<Scalars["Boolean"]>;
  /** The threshold of duration in seconds below which a GMH error is issued for this employment type. */
  gmhMinimumDurationError?: Maybe<Scalars["Seconds"]>;
  /** The threshold of duration in seconds below which a GMH warning is issued for this employment type. */
  gmhMinimumDurationWarning?: Maybe<Scalars["Seconds"]>;
  /** Whether this employment type is the default. Exactly one employment type may be the default. */
  isDefault?: Maybe<Scalars["Boolean"]>;
  /** Name of the employment type */
  name?: Maybe<Scalars["String"]>;
  /** Short/Abbreviated name of the employment type */
  shortName?: Maybe<Scalars["String"]>;
  /** Whether time off complicance is enabled for this employment type. */
  timeOffComplianceEnabled?: Maybe<Scalars["Boolean"]>;
};

/** Employment Type values for an employment */
export enum EmploymentTypeEnum {
  Casual = "casual",
  FullTime = "full_time",
  PartTime = "part_time",
  Permanent = "permanent",
}

/** Employment Type Fixed Shift Restriction fields */
export type EmploymentTypeFixedShiftRestriction = {
  __typename?: "EmploymentTypeFixedShiftRestriction";
  /** Casual options are not_applicable, optional or required */
  casual: EmploymentTypeFixedShiftRestrictionOptionEnum;
  /** Full time options are not_applicable, optional or required */
  fullTime: EmploymentTypeFixedShiftRestrictionOptionEnum;
  /** Part time options are not_applicable, optional or required */
  partTime: EmploymentTypeFixedShiftRestrictionOptionEnum;
  /** Permanent options are not_applicable, optional or required */
  permanent: EmploymentTypeFixedShiftRestrictionOptionEnum;
};

/** Employment Type Fixed Shift Restriction options */
export enum EmploymentTypeFixedShiftRestrictionOptionEnum {
  NotApplicable = "not_applicable",
  Optional = "optional",
  Required = "required",
}

/** Employment Type Fixed Shift Restriction update input fields */
export type EmploymentTypeFixedShiftRestrictionUpdateInput = {
  /** Casual options are not_applicable, optional or required */
  casual?: Maybe<EmploymentTypeFixedShiftRestrictionOptionEnum>;
  /** Full time options are not_applicable, optional or required */
  fullTime?: Maybe<EmploymentTypeFixedShiftRestrictionOptionEnum>;
  /** Part time options are not_applicable, optional or required */
  partTime?: Maybe<EmploymentTypeFixedShiftRestrictionOptionEnum>;
  /** Permanent options are not_applicable, optional or required */
  permanent?: Maybe<EmploymentTypeFixedShiftRestrictionOptionEnum>;
};

/** Internal Fixed shift fields */
export type FixedShift = {
  __typename?: "FixedShift";
  /** ID of the availability this fixed shift belong to */
  availabilityId: Scalars["ID"];
  /** Time created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Represents Sunday to Saturday day of week */
  dayOfWeek: DayOfWeekEnum;
  /** Whether the fixed shift are soft-deleted */
  deleted: Scalars["Boolean"];
  /** End time of fixed shift */
  endTime: Scalars["ISO8601HoursMinutes"];
  /** Available hours ID */
  id?: Maybe<Scalars["ID"]>;
  /** Start time of fixed shift */
  startTime: Scalars["ISO8601HoursMinutes"];
  /** Duration of unpaid break */
  unpaidBreakDuration?: Maybe<Scalars["Seconds"]>;
  /** Time updated */
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** 0, 1, 2 etc - to represent 1st week, 2nd week etc */
  weekIndex: Scalars["Int"];
};

/** Internal Fixed Shift Create input fields */
export type FixedShiftCreateInput = {
  /** End time of fixed shift */
  endTime: Scalars["ISO8601HoursMinutes"];
  /** Start time of fixed shift */
  startTime: Scalars["ISO8601HoursMinutes"];
  /** Duration of unpaid break */
  unpaidBreakDuration?: Maybe<Scalars["Seconds"]>;
};

/** Types of fixed shift restriction for an employment type */
export enum FixedShiftRestrictionEnum {
  NotApplicable = "not_applicable",
  Optional = "optional",
  Required = "required",
}

/** Internal Fixed Shift Time Line fields */
export type FixedShiftTimeLine = {
  __typename?: "FixedShiftTimeLine";
  /** Employment time line creation time */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employment that fixed shift time line is associated to */
  employmentId: Scalars["ID"];
  /** Employment time line end time */
  endDateTime: Scalars["ISO8601DateTime"];
  /** Fixed shift that fixed shift time line is associated to */
  fixedShiftId: Scalars["ID"];
  /** Fixed Shift Time Line ID */
  id: Scalars["ID"];
  /** Schedule that fixed shift time line is associated to */
  scheduleId: Scalars["ID"];
  /** Employment time line start time */
  startDateTime: Scalars["ISO8601DateTime"];
  /** Employment time line update time */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for FixedShiftTimeLine. */
export type FixedShiftTimeLineConnection = {
  __typename?: "FixedShiftTimeLineConnection";
  /** A list of edges. */
  edges: Array<FixedShiftTimeLineEdge>;
  /** A list of nodes. */
  nodes: Array<FixedShiftTimeLine>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type FixedShiftTimeLineEdge = {
  __typename?: "FixedShiftTimeLineEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<FixedShiftTimeLine>;
};

/** Fixed Shift Time Line Sort values */
export type FixedShiftTimeLineSort = {
  /** Field to sort by */
  field: FixedShiftTimeLineSortField;
  /** Sort order */
  order: Order;
};

/** Fixed Shift Time Line Sort Field values */
export enum FixedShiftTimeLineSortField {
  /** end time of the fixed shift time line */
  EndDateTime = "endDateTime",
  /** Primary key */
  Id = "id",
  /** start time of the fixed shift time line */
  StartDateTime = "startDateTime",
}

/** Internal Fixed Shift Update input fields */
export type FixedShiftUpdateInput = {
  /** End time of fixed shift */
  endTime: Scalars["ISO8601HoursMinutes"];
  /** Start time of fixed shift */
  startTime: Scalars["ISO8601HoursMinutes"];
  /** Duration of unpaid break */
  unpaidBreakDuration?: Maybe<Scalars["Seconds"]>;
};

/** Internal Forecast fields */
export type Forecast = {
  __typename?: "Forecast";
  /** Analytics ID */
  analyticsId?: Maybe<Scalars["ID"]>;
  /** Time the forecast was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** End time of the forecast */
  endTime: Scalars["ISO8601DateTime"];
  /** Measurements associated with the forecast */
  forecastMeasurements: Array<ForecastMeasurement>;
  /** Time the forecast was generated */
  generatedAt: Scalars["ISO8601DateTime"];
  /** Forecast ID */
  id: Scalars["ID"];
  /** Interval of the forecast */
  interval: Scalars["Int"];
  /** Schedule ID */
  scheduleId: Scalars["ID"];
  /** Start time of the forecast */
  startTime: Scalars["ISO8601DateTime"];
  /** Time the forecast was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Internal Forecast Adjustment fields */
export type ForecastAdjustment = {
  __typename?: "ForecastAdjustment";
  amount: Scalars["Float"];
  comment?: Maybe<Scalars["String"]>;
  createdAt: Scalars["ISO8601DateTime"];
  createdBy: Scalars["ID"];
  endTime: Scalars["ISO8601DateTime"];
  forecastSubtypes: Scalars["JSON"];
  forecastType: Scalars["String"];
  id: Scalars["ID"];
  mode: Scalars["String"];
  originalValues: Scalars["JSON"];
  removedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  removedBy?: Maybe<Scalars["ID"]>;
  removedComment?: Maybe<Scalars["String"]>;
  scheduleId: Scalars["ID"];
  startTime: Scalars["ISO8601DateTime"];
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for ForecastAdjustment. */
export type ForecastAdjustmentConnection = {
  __typename?: "ForecastAdjustmentConnection";
  /** A list of edges. */
  edges: Array<ForecastAdjustmentEdge>;
  /** A list of nodes. */
  nodes: Array<ForecastAdjustment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ForecastAdjustmentEdge = {
  __typename?: "ForecastAdjustmentEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ForecastAdjustment>;
};

/** The connection type for Forecast. */
export type ForecastConnection = {
  __typename?: "ForecastConnection";
  /** A list of edges. */
  edges: Array<ForecastEdge>;
  /** A list of nodes. */
  nodes: Array<Forecast>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ForecastEdge = {
  __typename?: "ForecastEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Forecast>;
};

/** Internal Forecast Measurement fields */
export type ForecastMeasurement = {
  __typename?: "ForecastMeasurement";
  /** Adjusted values of the measurement */
  adjustedValues?: Maybe<Scalars["JSON"]>;
  /** Business role ID */
  businessRoleId?: Maybe<Scalars["ID"]>;
  /** Time the forecast measurement was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Description of the forecast measurement */
  description?: Maybe<Scalars["String"]>;
  /** Forecast ID */
  forecastId: Scalars["ID"];
  /** Subtype of the forecast measurement */
  forecastSubtype: Scalars["String"];
  /** Type of the forecast measurement */
  forecastType: Scalars["String"];
  /** Forecast measurement ID */
  id: Scalars["ID"];
  /** Units of the forecast measurement */
  units: Scalars["String"];
  /** Time the forecast measurement was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Values of the forecast measurement */
  values: Scalars["JSON"];
};

/** Sort by a field in a specific order */
export type ForecastSort = {
  field: ForecastSortField;
  /** Sort order */
  order: Order;
};

export enum ForecastSortField {
  /** end time of the forecast */
  EndTime = "endTime",
  /** Primary Key */
  Id = "id",
  /** start time of the forecast */
  StartTime = "startTime",
}

/** Forum fields */
export type Forum = {
  __typename?: "Forum";
  /** Business ID */
  businessId: Scalars["ID"];
  /** Business Office Location ID */
  businessOfficeLocationId: Scalars["ID"];
  /** Forecast ID */
  id: Scalars["ID"];
  /** Domain component of user JID */
  jabberDomain?: Maybe<Scalars["String"]>;
  /** Domain component of user JID */
  jabberId?: Maybe<Scalars["String"]>;
  /** Topic of the forum */
  topic?: Maybe<Scalars["String"]>;
};

/** Internal Global Business Stack Type fields */
export type GlobalBusiness = {
  __typename?: "GlobalBusiness";
  /** Time global business was created */
  createdAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** User that created the Global Business */
  createdByUser?: Maybe<User>;
  /** Created by user ID */
  createdByUserId?: Maybe<Scalars["ID"]>;
  /** The next unallocated Employment GUID in the sequence */
  employmentUidNext: Scalars["BigInt"];
  /** The name of the PG Sequence Object used to track GUID */
  employmentUidSequence: Scalars["String"];
  /** The start of the Employment GUID sequence */
  employmentUidStart: Scalars["BigInt"];
  /** Global Business ID */
  id: Scalars["ID"];
  /** Name of the Global Business */
  name: Scalars["String"];
  /** Time global business was created */
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** User that last updated the Global Business */
  updatedByUser?: Maybe<User>;
  /** Updated by user ID */
  updatedByUserId?: Maybe<Scalars["ID"]>;
  /** Flag indicating business is active */
  used: Scalars["Boolean"];
};

/** The connection type for GlobalBusiness. */
export type GlobalBusinessConnection = {
  __typename?: "GlobalBusinessConnection";
  /** A list of edges. */
  edges: Array<GlobalBusinessEdge>;
  /** A list of nodes. */
  nodes: Array<GlobalBusiness>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type GlobalBusinessEdge = {
  __typename?: "GlobalBusinessEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<GlobalBusiness>;
};

/** Internal Global Business Stack Type fields */
export type GlobalBusinessStack = {
  __typename?: "GlobalBusinessStack";
  /** Time the Global Business Stack was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Global Business Stack ID */
  id: Scalars["ID"];
  /** True if the Global Business Stack refers to the current (local) stack */
  isSelf: Scalars["Boolean"];
  /** Domain of the Global Business Stack */
  stackDomainName: Scalars["String"];
  /** Time the Global Business Stack was updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Internal Graphql Schema fields */
export type GraphqlSchema = {
  __typename?: "GraphqlSchema";
  name: Scalars["String"];
  schemaGraphql: Scalars["String"];
  schemaJson: Scalars["JSON"];
};

/** Time Range fields */
export type HoursMinutesRange = {
  __typename?: "HoursMinutesRange";
  /** Denotes whether the range_end_time is exclusive */
  exclusiveEnd?: Maybe<Scalars["Boolean"]>;
  /** End time of the range */
  rangeEndTime: Scalars["ISO8601HoursMinutes"];
  /** Start time of the range */
  rangeStartTime: Scalars["ISO8601HoursMinutes"];
};

/** Time Range input type for creation */
export type HoursMinutesRangeCreateInput = {
  /** End time of the range */
  rangeEndTime: Scalars["ISO8601HoursMinutes"];
  /** Start time of the range */
  rangeStartTime: Scalars["ISO8601HoursMinutes"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQuery = {
  __typename?: "InternalQuery";
  /**
   * Returns array of action logs that satisfy the filtering arguments.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  actionLogs: ActionLogConnection;
  /**
   * Returns array of action logs meta that satisfy the filtering arguments.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  actionLogsMeta: ActionLogsMetaConnection;
  /**
   * Returns array of analytics actual sales for the specified schedule and time range.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   GENERAL_ERROR:
   *     - Schedule does not have analytics enabled
   *     - Failed sales summary request
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   *   ANALYTICS_ERROR:
   *     - Analytics settings not correctly configured. Please contact customer support.
   *     - Analytics service: {Specific error message from Analytics}
   *     - No sales actuals data found
   */
  actualSales: AnalyticsActual;
  /**
   * Returns array of analytics actuals for the specified schedule, time range, measurement type and interval.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   GENERAL_ERROR:
   *     - Schedule does not have analytics enabled
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   *   ANALYTICS_ERROR:
   *     - Analytics settings not correctly configured. Please contact customer support.
   *     - Analytics service: {Specific error message from Analytics}
   *     - No sales actuals data found
   */
  analyticsActuals: Array<AnalyticsActual>;
  /**
   * Returns array of analytics sales data based labour for the specified schedule and time range.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   GENERAL_ERROR:
   *     - Schedule does not have analytics enabled
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   *   ANALYTICS_ERROR:
   *     - Analytics settings not correctly configured. Please contact customer support.
   *     - Analytics service: {Specific error message from Analytics}
   *     - No sales actuals data found
   */
  analyticsSalesDataBasedLabour: Array<AnalyticsSalesDataBasedLabour>;
  /**
   * Returns array of analytics totals for the specified schedule, time range and measurement type.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   GENERAL_ERROR:
   *     - Schedule does not have analytics enabled
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   *   ANALYTICS_ERROR:
   *     - Analytics settings not correctly configured. Please contact customer support
   *     - Analytics service: {Specific error message from Analytics}
   *     - No sales actuals data found
   */
  analyticsTotal: Array<AnalyticsTotal>;
  /**
   * Returns array of AOS Configuration Templates that satisfy the filtering arguments.
   * Returns all templates if no filters provided.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  aosConfigTemplates: AosConfigTemplateConnection;
  /**
   * Returns array of audit logs that satisfy the filtering arguments.
   * Returns all audit logs for the specified schedule if no filters provided.
   * Must be logged in as business owner.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be business owner
   *   {date_time arg supplied} is supplied without {date_time arg not supplied}
   */
  auditLogs: AuditLogConnection;
  /**
   * Returns array of union-typed Availabilities that satisfy the filtering arguments.
   * Returns all non-deleted availabilities if no filters provided.
   * Must be logged in as client support user.
   *
   * Common errors:
   * AUTHORIZATION_ERROR:
   *  - LIFELENZ Support Only
   *  - Must be employed
   * End date must be after start date
   */
  availabilities: AvailabilityConnection;
  /**
   * Returns array of blackout days that satisfy the filtering arguments.
   * Returns all blackout days for the specified schedule and date range if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager with pay
   *   Invalid start_date format: {start_date arg supplied}
   *   Invalid end_date format: {end_date arg supplied}
   *   End date must be after start date
   */
  blackoutDays: BlackoutDayConnection;
  /**
   * Returns list of break types defined at the business level
   *
   * Common errors:
   * AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  breakTypes: BreakTypeConnection;
  /**
   * Returns business invite for the given token.
   * Must be service request from IDM.
   *
   * Common error codes:
   *   RESOURCE_NOT_FOUND: Invite code not found
   */
  businessInviteByToken?: Maybe<BusinessInvite>;
  /**
   * Returns array of business invites for specified business that satisfy the filtering arguments.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  businessInvites: BusinessInviteConnection;
  /**
   * Returns array of business office locations that satisfy the filtering arguments.
   * Returns all business office locations if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  businessOfficeLocations: BusinessOfficeLocationConnection;
  /**
   * Returns array of business role categories that satisfy the filtering arguments.
   * Returns all business role categories if no filters provided.
   * Must be logged in as manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be manager
   */
  businessRoleCategories: BusinessRoleCategoryConnection;
  /**
   * Returns array of business roles that satisfy the filtering arguments.
   * Returns all business roles if no filters provided.
   * Must be logged in as manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be manager
   */
  businessRoles: BusinessRoleConnection;
  /**
   * Returns array of businesses that satisfy the filtering arguments.
   * Returns all businesses if no filters provided.
   * Returns only businesses requester is employed in, if not a service request.
   * Must be logged in or a service request.
   */
  businesses: BusinessConnection;
  /**
   * Returns array of compliance warnings that satisfy the filtering arguments.
   * Returns all compliance warnings for the specified schedule and time range if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   */
  complianceWarnings: ComplianceWarningConnection;
  /**
   * Returns array of contract occurrences that satisfy the filtering arguments.
   * Returns all contract occurrences for the specified schedule, time range, and employments if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   */
  contractOccurrences: ContractOccurrenceConnection;
  /**
   *           Returns array of core shifts for the specified schedule, starting at
   * the schedule week start date containing the specified target date time.
   *           Also returns fixed shifts in the array, provided the business setting :fixed_shifts_enabled is true.
   *            - In this case, still includes fixed shifts in result if specifying :schedule_week_template_id argument.
   *            - Any core shifts from a template that overlap a fixed shift will be returned unassigned.
   *           Sorts whole returned array by shift_start_time.
   *
   *           Must be logged in as schedule manager or possess AOS service token.
   *
   *           Common errors:
   *             AUTHORIZATION_ERROR: Must be schedule manager or AOS service only
   */
  coreShifts: ShiftConnection;
  /**
   * Returns array of current viewed forecasts (obtained by querying analytics directly)
   * that satisfy the filtering arguments.
   * Raises a RESOURCE_NOT_FOUND error if no results returned.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager with pay
   *   RESOURCE_NOT_FOUND: No results found
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   */
  currentViewedForecast: ViewedForecast;
  /**
   * Returns array of day part templates that satisfy the filtering arguments.
   * Returns all day part templates if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  dayPartTemplates: DayPartTemplateConnection;
  /**
   * Returns array of day parts that satisfy the filtering arguments.
   * Returns all day parts if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  dayParts: DayPartConnection;
  /**
   * Returns Differential Definitions for the specified schedule.
   * Default sorted by start_date.
   * Must be logged in as business owner.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR:
   *     - Must be business owner
   *   GENERAL_ERROR:
   *     - Differential Definition cannot be found
   *     - Schedule cannot be found
   */
  differentialDefinitions: DifferentialDefinitionConnection;
  /**
   * Returns array of generic documents that satisfy the filtering arguments.
   * Must be logged in as client support user.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: Employment
   */
  documents: DocumentConnection;
  /**
   * Returns array of employment contracts
   * Must be logged in as business owner.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be business owner
   */
  employmentContracts: EmploymentContractConnection;
  /**
   * Returns array of employment external codes.
   * Must be logged in as employee manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be employee manager
   */
  employmentExternalCodes: EmploymentExternalCodeConnection;
  /**
   * Query Employment Feedback Reason
   * Must be logged in as client support user for the current business
   * Returns array of employment feedback that satisfy the filtering arguments.
   *
   * Common errors:
   *     AUTHORIZATION_ERROR: LIFELENZ support only or Not for current business
   */
  employmentFeedbackReasons: EmploymentFeedbackReasonConnection;
  /**
   * Returns array of employment feedbacks that satisfy the filtering arguments.
   * Must be logged in as a Business Owner.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be a Business Owner
   */
  employmentFeedbacks: EmploymentFeedbackConnection;
  /**
   * Returns array of employment histories that satisfy the filtering arguments.
   * Returns all employment histories if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  employmentHistories: EmploymentHistoryConnection;
  /**
   * Query Employment Metadata
   * LifeLenz Support Only.
   * Returns array of employment metadata that satisfy the filtering arguments.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  employmentMetadata: EmploymentMetadataConnection;
  /**
   * Returns array of employment rates that satisfy the filtering arguments.
   * Returns all employment rates if no filters provided.
   * Must be logged in.
   */
  employmentRates: EmploymentRateConnection;
  /**
   * Returns array of employment roles that satisfy the filtering arguments.
   *
   * Returns all employment roles for the specified employment if
   * provided, or for all employments that are not terminated at
   * some point in the future.
   *
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  employmentRoles: EmploymentRoleConnection;
  employmentScheduleHistories: EmploymentScheduleHistoryConnection;
  /**
   * Returns array of employment schedules that satisfy the filtering arguments.
   * Returns all employment schedules for the specified schedule if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  employmentSchedules: EmploymentScheduleConnection;
  /**
   * Returns array of employment status change reasons that satisfy the filtering arguments.
   * Returns all employment status change reason if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  employmentStatusChangeReasons: EmploymentStatusChangeReasonConnection;
  /**
   * Returns array of employment time lines that satisfy the filtering arguments.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   */
  employmentTimeLines: EmploymentTimeLineConnection;
  /**
   * Generates the data required for invoking the employment time lines service using the specified arguments.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support only
   */
  employmentTimeLinesInputQuery: EmploymentTimeLinesRequest;
  /**
   * Returns a list of employment type configs
   *
   * Common errors:
   *   None
   */
  employmentTypeConfigs: EmploymentTypeConfigConnection;
  /**
   * Returns array of employments that satisfy the filtering arguments.
   * Returns all employments if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  employments: EmploymentConnection;
  /**
   *           Returns array of active employments from any business that match the
   * given employment person keys in the given business ids.
   *           Must be an internal query.
   *
   *           Common error codes:
   *             AUTHORIZATION_ERROR: Must be internal query sent from IDM
   */
  findEmploymentsForSingleSignOn: Array<Employment>;
  /**
   * Returns array of fixed shift time lines that satisfy the filtering arguments.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   */
  fixedShiftTimeLines: FixedShiftTimeLineConnection;
  /**
   * Returns array of forecast adjustments that satisfy the filtering arguments.
   * Returns all forecast adjustments for the specified schedule and time range if no filters provided.
   * Must be logged in as schedule manager with pay.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager with pay
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   */
  forecastAdjustments: ForecastAdjustmentConnection;
  /**
   * Returns array of forecasts that satisfy the filtering arguments.
   * Returns all forecasts for the specified schedule and time range if no filters provided.
   * Must be logged in as schedule manager with pay.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager with pay
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   */
  forecasts: ForecastConnection;
  globalBusinessStack?: Maybe<GlobalBusinessStack>;
  /**
   * Return a list of all Global Businesses
   * LifeLenz Support Only
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  globalBusinesses: GlobalBusinessConnection;
  /**
   * Returns schema definition for the specified schema path.
   * Returns schema path name, and schema in GraphQL and JSON formats
   */
  graphqlSchema: GraphqlSchema;
  /**
   * Returns array of job title types that satisfy the filtering arguments.
   * Returns all job title types if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  jobTitleTypes: JobTitleTypeConnection;
  /**
   * Returns array of job titles that satisfy the filtering arguments.
   * Returns all job titles if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  jobTitles: JobTitleConnection;
  /**
   * Query KPI Screen config - info about what and how KPIs are shown on a given screen.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ support
   */
  kpiScreenConfigs: KpiScreenConfigConnection;
  /**
   * Query KPIs that can be added to a screen's config.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ support
   */
  kpis: KpiConnection;
  /**
   * Returns all legacy rule sets that satisfy the filtering arguments.
   * Will be deprecated when rules central goes live.
   * Must be logged in as business owner.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be business owner
   */
  legacyRuleSets?: Maybe<LegacyRuleSetConnection>;
  /**
   * Returns array of metadata types that satisfy the filtering arguments.
   * Returns all metadata types if no filters provided.
   * Must be logged in as schedule manager
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  metadataTypes: MetadataTypeConnection;
  /**
   * Returns array of misc pay types that satisfy the filtering arguments.
   * Returns all misc pay types if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  miscPayTypes: MiscPayTypeConnection;
  /**
   * Returns array of misc pays that satisfy the filtering arguments.
   * Returns all misc pays for the specified schedule and date range if no filters provided.
   * Must be logged in as group admin.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be group admin
   *   Invalid start_date format: {start_date arg supplied}
   *   Invalid end_date format: {end_date arg supplied}
   *   End date must be after start date
   */
  miscPays: MiscPayConnection;
  onCostDefinitions: OnCostDefinitionConnection;
  /**
   * Returns array of pay periods that satisfy the filtering arguments.
   * Returns all pay periods for the specified schedule and time range if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   */
  payPeriods: PayPeriodConnection;
  /**
   * Returns array of pending employment time lines that satisfy the filtering arguments.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   */
  pendingEmploymentTimeLines: PendingEmploymentTimeLineConnection;
  /**
   * Returns array of quarters that satisfy the filtering arguments.
   * Returns all quarters for the specified schedule if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   {date_time arg supplied} is supplied without {date_time arg not supplied}
   *   Must provide either computed_after, or start_date_time and end_date_time together
   */
  quarters: QuarterConnection;
  /**
   * Returns all rule sets from the rules central service.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  ruleSets: Array<RuleSet>;
  /**
   * Returns the rule file from the rules central repository that is identified by the provided rule set name and
   * version.
   * Must be logged in as client support user or be authenticated as an AOS service.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support or AOS service only
   */
  rulesCentralFile: RuleSetFile;
  /**
   * Generates the data required for invoking the Rules Engine using the specified arguments.
   * Must be logged in as client support user or be authenticated as an AOS service.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support or AOS service only
   */
  rulesInput: RulesInput;
  /**
   * Returns list of schedule break types
   *
   * Common errors:
   * GENERAL_ERROR: Schedule cannot be found
   * AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  scheduleBreakTypes: ScheduleBreakTypeConnection;
  /**
   * Returns array of schedule roles that satisfy the filtering arguments.
   * Returns all schedule roles for the specified schedule if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  scheduleRoles: ScheduleRoleConnection;
  /**
   * Returns the rule file that is associated with the specified schedule. This will return error if the schedule
   * is not associated with rules.
   * Must be logged in as client support user or be authenticated as an AOS service.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support or AOS service only
   */
  scheduleRuleFile: RuleSetFile;
  /**
   * Returns list of schedule target periods
   *
   * Common errors:
   *  AUTHORIZATION_ERROR: LIFELENZ Support Only
   *  GENERAL_ERROR: Schedule cannot be found
   */
  scheduleTargetPeriods: ScheduleTargetPeriodConnection;
  /**
   * Returns array of schedule week templates for the specified schedule.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  scheduleWeekTemplates: ScheduleWeekTemplateConnection;
  /**
   * Returns array of schedule weeks for the specified schedule and time range.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   */
  scheduleWeeks: ScheduleWeekConnection;
  /**
   * Returns array of schedules that satisfy the filtering arguments.
   * Returns all schedules for requesting employment if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  schedules: ScheduleConnection;
  /**
   * Returns array of school holidays that satisfy the filtering arguments.
   * Returns all school holidays for the specified date range if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   Invalid start_date format: {start_date arg supplied}
   *   Invalid end_date format: {end_date arg supplied}
   *   End date must be after start date
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  schoolHolidays: SchoolHolidayConnection;
  /**
   * Returns array of schools that satisfy the filtering arguments.
   * Returns all schools if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  schools: SchoolConnection;
  /**
   * Returns array of employments from any business that match a supplied email or userId.
   * Optional filters deleted, acceptedInvite and currentlyEmployed can be passed.
   * Optionally filter by businessIds to limit what businesses are searched.
   * Setting optional filters to null will allow them to be skipped.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be lifelenz support
   *   INVALID_API_USAGE: Must provide an email or user_id
   */
  searchStackForEmployments: EmploymentConnection;
  /**
   * Returns set names.
   * LifeLenz Support Only
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  setNames: SetNameConnection;
  /**
   * Returns array of set value that satisfy the filtering arguments.
   * Returns all set value if no filters provided.
   * LifeLenz Support Only
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  setValues: SetValueConnection;
  /**
   * Returns array of shift template categories that satisfy the filtering arguments.
   * Returns all shift template categories for the specified schedule if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  shiftTemplateCategories: ShiftTemplateCategoryConnection;
  /**
   * Returns array of shift templates that satisfy the filtering arguments.
   * Returns all shift templates if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   One of scheduleId or shiftTemplateCategoryId required
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  shiftTemplates: ShiftTemplateConnection;
  /**
   * Returns array of shifts that satisfy the filtering arguments.
   * Returns all shifts for the specified schedule and time range if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   */
  shifts: ShiftConnection;
  /**
   * Returns array of all skill levels.
   * LifeLenz Support Only
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  skillLevels: SkillLevelConnection;
  /** Returns details for the current Stack */
  stack?: Maybe<Stack>;
  /**
   * Returns station hour type definitions.
   * Must be logged in as client support user.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  stationHourTypes: StationHourTypeConnection;
  system: System;
  /**
   * Returns array of time clock app approval reasons that satisfy the filtering arguments.
   * Returns all time clock app approval reasons if no filters provided.
   */
  timeClockAppApprovalReasons: TimeClockAppApprovalReasonConnection;
  /**
   * Returns array of time clock devices that satisfy the filtering arguments.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  timeClockDevices: TimeClockDeviceConnection;
  /**
   * Returns array of time clocks that satisfy the filtering arguments.
   * Returns all time clocks for the specified schedule and time range if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   *   TIME_CLOCK_ERROR: Invalid time range
   *   ARGUMENT_ERROR: Provide both updated_at and custom_updated_at
   */
  timeClocks: TimeClockConnection;
  /** List of Time Off Balance */
  timeOffBalances: TimeOffBalanceConnection;
  /**
   * Returns array of time off type that satisfy the filtering arguments.
   * Returns all undeleted time off type if no filters provided.
   * Must be logged in as client support user.
   *
   * Common errors:
   * AUTHORIZATION_ERROR:
   *  - LIFELENZ Support Only
   */
  timeOffTypes: TimeOffTypeConnection;
  /**
   * Returns array of analytics totals for the specified schedule and time range.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   GENERAL_ERROR:
   *     - Schedule does not have analytics enabled
   *     - Failed sales summary request
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   *   ANALYTICS_ERROR:
   *     - Analytics settings not correctly configured. Please contact customer support.
   *     - Analytics service: {Specific error message from Analytics}
   *     - No sales actuals data found
   */
  totalActualSales: AnalyticsTotal;
  /**
   * Returns array of unavailability events that satisfy the filtering arguments.
   * Returns all unavailability events for the specified time range if no filters provided.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   */
  unavailabilityEvents: UnavailabilityEventConnection;
  /**
   * Returns array of users that satisfy the filtering arguments.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  users: UserConnection;
  /**
   * Returns array of viewed forecasts that satisfy the filtering arguments.
   * Returns all viewed forecasts for the specified schedule and time range if no filters provided.
   * Must be logged in as schedule manager with pay.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager with pay
   *   Invalid start_date_time format: {start_date_time arg supplied}
   *   Invalid end_date_time format: {end_date_time arg supplied}
   */
  viewedForecasts: ViewedForecastConnection;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryActionLogsArgs = {
  actionType?: Maybe<Scalars["String"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  endDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  objId?: Maybe<Scalars["ID"]>;
  objType?: Maybe<Scalars["String"]>;
  performedById?: Maybe<Scalars["ID"]>;
  relatedToId?: Maybe<Scalars["ID"]>;
  requestId?: Maybe<Scalars["ID"]>;
  scheduleId?: Maybe<Scalars["ID"]>;
  sort?: Maybe<Array<ActionLogSort>>;
  startDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryActionLogsMetaArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  objType?: Maybe<Scalars["String"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryActualSalesArgs = {
  businessId: Scalars["ID"];
  endDateTime: Scalars["ISO8601DateTime"];
  interval?: Maybe<AnalyticsIntervalEnum>;
  scheduleId: Scalars["ID"];
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryAnalyticsActualsArgs = {
  businessId: Scalars["ID"];
  endDateTime: Scalars["ISO8601DateTime"];
  interval?: Maybe<AnalyticsIntervalEnum>;
  measurementTypes: Array<Scalars["String"]>;
  scheduleId: Scalars["ID"];
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryAnalyticsSalesDataBasedLabourArgs = {
  businessId: Scalars["ID"];
  endDateTime: Scalars["ISO8601DateTime"];
  offset?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryAnalyticsTotalArgs = {
  businessId: Scalars["ID"];
  endDateTime: Scalars["ISO8601DateTime"];
  measurementTypes: Array<Scalars["String"]>;
  scheduleId: Scalars["ID"];
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryAosConfigTemplatesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  last?: Maybe<Scalars["Int"]>;
  search?: Maybe<Scalars["String"]>;
  sort?: Maybe<Array<AosConfigTemplateSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryAuditLogsArgs = {
  actionType?: Maybe<AuditLogActionTypeEnum>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  endDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  objId?: Maybe<Scalars["ID"]>;
  objType?: Maybe<Scalars["String"]>;
  performedById?: Maybe<Scalars["ID"]>;
  relatedToId?: Maybe<Scalars["ID"]>;
  requestId?: Maybe<Scalars["ID"]>;
  scheduleId?: Maybe<Scalars["ID"]>;
  sort?: Maybe<Array<AuditLogSort>>;
  specificAction?: Maybe<Scalars["String"]>;
  startDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryAvailabilitiesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  employeeInitiated?: Maybe<Scalars["Boolean"]>;
  employmentId?: Maybe<Scalars["ID"]>;
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<AvailabilitySort>>;
  startDate?: Maybe<Scalars["ISO8601Date"]>;
  status?: Maybe<Array<AvailabilityStatusEnum>>;
  types?: Maybe<Array<AvailabilityTypeEnum>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryBlackoutDaysArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  endDate: Scalars["String"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  startDate: Scalars["String"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryBreakTypesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["ID"]>;
  includeDeleted?: Maybe<Scalars["Boolean"]>;
  last?: Maybe<Scalars["Int"]>;
  search?: Maybe<Scalars["String"]>;
  sort?: Maybe<Array<BreakTypeSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryBusinessInviteByTokenArgs = {
  businessInviteToken: Scalars["String"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryBusinessInvitesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  employmentId?: Maybe<Scalars["ID"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<BusinessInviteSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryBusinessOfficeLocationsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryBusinessRoleCategoriesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  businessRoleCategoryName?: Maybe<Scalars["String"]>;
  codes?: Maybe<Array<Scalars["String"]>>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<BusinessRoleCategorySort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryBusinessRolesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  businessRoleName?: Maybe<Scalars["String"]>;
  codes?: Maybe<Array<Scalars["String"]>>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  includeDeleted?: Maybe<Scalars["Boolean"]>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<BusinessRoleSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryBusinessesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessName?: Maybe<Scalars["String"]>;
  codes?: Maybe<Array<Scalars["String"]>>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  isFake?: Maybe<Scalars["Boolean"]>;
  last?: Maybe<Scalars["Int"]>;
  search?: Maybe<Scalars["String"]>;
  sort?: Maybe<Array<BusinessSort>>;
  userId?: Maybe<Scalars["ID"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryComplianceWarningsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  employmentIds?: Maybe<Array<Scalars["ID"]>>;
  endDateTime: Scalars["ISO8601DateTime"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  includeHiddenWarnings?: Maybe<Scalars["Boolean"]>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  sort?: Maybe<Array<ComplianceWarningSort>>;
  startDateTime: Scalars["ISO8601DateTime"];
  timeClockIds?: Maybe<Array<Scalars["ID"]>>;
  updatedSince?: Maybe<Scalars["ISO8601DateTimeHighPrecision"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryContractOccurrencesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  employmentIds: Array<Scalars["ID"]>;
  endDateTime: Scalars["ISO8601DateTime"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryCoreShiftsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  scheduleWeekTemplateId?: Maybe<Scalars["ID"]>;
  targetWeekStart?: Maybe<Scalars["ISO8601DateTime"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryCurrentViewedForecastArgs = {
  businessId: Scalars["ID"];
  endDateTime: Scalars["ISO8601DateTime"];
  scheduleId: Scalars["ID"];
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryDayPartTemplatesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryDayPartsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryDifferentialDefinitionsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  sort?: Maybe<Array<DifferentialDefinitionSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryDocumentsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  deleted?: Maybe<Scalars["Boolean"]>;
  documentType?: Maybe<DocumentType>;
  employmentId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<DocumentSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentContractsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  deleted?: Maybe<Scalars["Boolean"]>;
  employmentId?: Maybe<Scalars["ID"]>;
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  endDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<EmploymentContractSort>>;
  startDate?: Maybe<Scalars["ISO8601Date"]>;
  startDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentExternalCodesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  employmentIds?: Maybe<Array<Scalars["ID"]>>;
  externalCodes?: Maybe<Array<Scalars["String"]>>;
  externalSubcodes?: Maybe<Array<Scalars["String"]>>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleIds?: Maybe<Array<Scalars["ID"]>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentFeedbackReasonsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  feedbackType?: Maybe<Array<EmploymentFeedbackTypeEnum>>;
  first?: Maybe<Scalars["Int"]>;
  includeDeleted?: Maybe<Scalars["Boolean"]>;
  last?: Maybe<Scalars["Int"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentFeedbacksArgs = {
  after?: Maybe<Scalars["String"]>;
  assignedEmploymentNameIncludes?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  employmentIds?: Maybe<Array<Scalars["ID"]>>;
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  reasonId?: Maybe<Scalars["ID"]>;
  scheduleId?: Maybe<Scalars["ID"]>;
  sort?: Maybe<Array<EmploymentFeedbackSort>>;
  startDate?: Maybe<Scalars["ISO8601Date"]>;
  status?: Maybe<Array<EmploymentFeedbackRawStatusEnum>>;
  type?: Maybe<Array<EmploymentFeedbackTypeEnum>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentHistoriesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  employmentId?: Maybe<Scalars["ID"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<EmploymentHistorySort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentMetadataArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  employmentId?: Maybe<Scalars["ID"]>;
  endDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  metadataTypeId?: Maybe<Scalars["ID"]>;
  sort?: Maybe<Array<EmploymentMetadataSort>>;
  startDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
  timeboxed?: Maybe<Scalars["Boolean"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentRatesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  employmentId?: Maybe<Scalars["ID"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<EmploymentRateSort>>;
  status?: Maybe<Array<EmploymentRateStatusEnum>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentRolesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  employmentId?: Maybe<Scalars["ID"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  skillLevelId?: Maybe<Scalars["ID"]>;
  sort?: Maybe<Array<EmploymentRoleSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentScheduleHistoriesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  employmentId?: Maybe<Scalars["ID"]>;
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId?: Maybe<Scalars["ID"]>;
  sort?: Maybe<Array<EmploymentScheduleHistorySort>>;
  startDate?: Maybe<Scalars["ISO8601Date"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentSchedulesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  employmentIds?: Maybe<Array<Scalars["ID"]>>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  sort?: Maybe<Array<EmploymentScheduleSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentStatusChangeReasonsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  deleted?: Maybe<Scalars["Boolean"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<EmploymentStatusChangeReasonSort>>;
  status?: Maybe<Array<CurrentEmploymentStatusEnum>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentTimeLinesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  employmentIds?: Maybe<Array<Scalars["ID"]>>;
  endDateTime: Scalars["ISO8601DateTime"];
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  onDefinedAvailability?: Maybe<Scalars["Boolean"]>;
  onFixedShift?: Maybe<Scalars["Boolean"]>;
  onLeave?: Maybe<Scalars["Boolean"]>;
  onPreferredHours?: Maybe<Scalars["Boolean"]>;
  onScheduleId?: Maybe<Scalars["ID"]>;
  scheduleId: Scalars["ID"];
  sort?: Maybe<Array<EmploymentTimeLineSort>>;
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentTimeLinesInputQueryArgs = {
  availabilityIds?: Maybe<Array<Scalars["ID"]>>;
  businessId: Scalars["ID"];
  employmentIds: Array<Scalars["ID"]>;
  endDate: Scalars["ISO8601Date"];
  startDate: Scalars["ISO8601Date"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentTypeConfigsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  deleted?: Maybe<Scalars["Boolean"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  search?: Maybe<Scalars["String"]>;
  sort?: Maybe<Array<EmploymentTypeConfigSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryEmploymentsArgs = {
  acceptedInvite?: Maybe<Scalars["Boolean"]>;
  after?: Maybe<Scalars["String"]>;
  aosView?: Maybe<Scalars["Boolean"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  businessOfficeLocationId?: Maybe<Scalars["ID"]>;
  codes?: Maybe<Array<Scalars["String"]>>;
  currentlyEmployed?: Maybe<Scalars["Boolean"]>;
  datapassUpdatedSince?: Maybe<Scalars["ISO8601DateTimeHighPrecision"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  payrollCode?: Maybe<Scalars["String"]>;
  schedulable?: Maybe<Scalars["Boolean"]>;
  scheduleId?: Maybe<Scalars["ID"]>;
  search?: Maybe<Scalars["String"]>;
  sort?: Maybe<Array<EmploymentSort>>;
  ssoCode?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["ID"]>;
  userLinked?: Maybe<Scalars["Boolean"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryFindEmploymentsForSingleSignOnArgs = {
  attributeName: Scalars["String"];
  autoCreateEmploymentInput?: Maybe<EmploymentAutoCreateInput>;
  businessIds: Array<Scalars["ID"]>;
  values: Array<Scalars["String"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryFixedShiftTimeLinesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  employmentIds?: Maybe<Array<Scalars["ID"]>>;
  endDateTime: Scalars["ISO8601DateTime"];
  first?: Maybe<Scalars["Int"]>;
  fixedShiftIds?: Maybe<Array<Scalars["ID"]>>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId?: Maybe<Scalars["ID"]>;
  sort?: Maybe<Array<FixedShiftTimeLineSort>>;
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryForecastAdjustmentsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  endDateTime: Scalars["ISO8601DateTime"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  sort?: Maybe<ForecastSort>;
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryForecastsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  endDateTime: Scalars["ISO8601DateTime"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  sort?: Maybe<ForecastSort>;
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryGlobalBusinessesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  globalBusinessId?: Maybe<Scalars["ID"]>;
  globalBusinessName?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryGraphqlSchemaArgs = {
  schema: Schema;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryJobTitleTypesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<JobTitleTypeSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryJobTitlesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<JobTitleSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryKpiScreenConfigsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  screenCode?: Maybe<ScreenCodeEnum>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryKpisArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryLegacyRuleSetsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  externalIds?: Maybe<Array<Scalars["String"]>>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryMetadataTypesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  search?: Maybe<Scalars["String"]>;
  sort?: Maybe<Array<MetadataTypeSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryMiscPayTypesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<MiscPayTypeSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryMiscPaysArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  endDate: Scalars["String"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  sort?: Maybe<Array<MiscPaySort>>;
  startDate: Scalars["String"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryOnCostDefinitionsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleIds?: Maybe<Array<Scalars["ID"]>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryPayPeriodsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  endDateTime: Scalars["ISO8601DateTime"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  payPeriodStatus?: Maybe<PayPeriodApprovalStatus>;
  scheduleId?: Maybe<Scalars["ID"]>;
  sort?: Maybe<Array<PayPeriodSort>>;
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryPendingEmploymentTimeLinesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  employmentIds?: Maybe<Array<Scalars["ID"]>>;
  endDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<PendingEmploymentTimeLineSort>>;
  startDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryQuartersArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  computedAfter?: Maybe<Scalars["ISO8601DateTime"]>;
  endDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  recentMinutesToInclude?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  startDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryRulesCentralFileArgs = {
  ruleSetName: Scalars["String"];
  ruleSetVersion: Scalars["String"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryRulesInputArgs = {
  businessId: Scalars["ID"];
  employmentIds?: Maybe<Array<Scalars["ID"]>>;
  scheduleId: Scalars["ID"];
  scheduleWeekStartDate: Scalars["String"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryScheduleBreakTypesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["ID"]>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  search?: Maybe<Scalars["String"]>;
  sort?: Maybe<Array<BreakTypeSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryScheduleRolesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryScheduleRuleFileArgs = {
  businessId: Scalars["ID"];
  scheduleId: Scalars["ID"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryScheduleTargetPeriodsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  endDate: Scalars["ISO8601Date"];
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  startDate: Scalars["ISO8601Date"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryScheduleWeekTemplatesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  usedByAos?: Maybe<Scalars["Boolean"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryScheduleWeeksArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  endDateTime: Scalars["ISO8601DateTime"];
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQuerySchedulesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  first?: Maybe<Scalars["Int"]>;
  hrGoLiveDateAfter?: Maybe<Scalars["ISO8601Date"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  isGroup?: Maybe<Scalars["Boolean"]>;
  last?: Maybe<Scalars["Int"]>;
  scheduleName?: Maybe<Scalars["String"]>;
  search?: Maybe<Scalars["String"]>;
  sort?: Maybe<Array<ScheduleSort>>;
  timekeepingGoLiveDateAfter?: Maybe<Scalars["ISO8601Date"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQuerySchoolHolidaysArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  endDate: Scalars["String"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId?: Maybe<Scalars["ID"]>;
  startDate: Scalars["String"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQuerySchoolsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId?: Maybe<Scalars["ID"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQuerySearchStackForEmploymentsArgs = {
  acceptedInvite?: Maybe<Scalars["Boolean"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessIds?: Maybe<Array<Scalars["ID"]>>;
  currentlyEmployed?: Maybe<Scalars["Boolean"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  draft?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<EmploymentSort>>;
  unlinkedUser?: Maybe<Scalars["Boolean"]>;
  userId?: Maybe<Scalars["ID"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQuerySetNamesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQuerySetValuesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  setNameId?: Maybe<Scalars["ID"]>;
  sort?: Maybe<Array<SetValueSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryShiftTemplateCategoriesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  parentId?: Maybe<Scalars["ID"]>;
  scheduleId: Scalars["ID"];
  sort?: Maybe<Array<ShiftTemplateCategorySort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryShiftTemplatesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  parentId?: Maybe<Scalars["ID"]>;
  scheduleId?: Maybe<Scalars["ID"]>;
  scheduleWeekTemplateId?: Maybe<Scalars["ID"]>;
  shiftTemplateCategoryId?: Maybe<Scalars["ID"]>;
  sort?: Maybe<Array<ShiftTemplateSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryShiftsArgs = {
  after?: Maybe<Scalars["String"]>;
  aosView?: Maybe<Scalars["Boolean"]>;
  assignedEmploymentId?: Maybe<Scalars["ID"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  endDateTime: Scalars["ISO8601DateTime"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  shiftType?: Maybe<Array<ShiftTypeEnum>>;
  sort?: Maybe<Array<ShiftSort>>;
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQuerySkillLevelsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryStationHourTypesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryTimeClockAppApprovalReasonsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  smallIds?: Maybe<Array<Scalars["Int"]>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryTimeClockDevicesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  scheduleIds?: Maybe<Array<Scalars["ID"]>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryTimeClocksArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  customUpdatedSince?: Maybe<Scalars["ISO8601DateTimeHighPrecision"]>;
  employmentIds?: Maybe<Array<Scalars["ID"]>>;
  endDateTime: Scalars["ISO8601DateTime"];
  excludeDeleted?: Maybe<Scalars["Boolean"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  includeInvalid?: Maybe<Scalars["Boolean"]>;
  includeTimeClocksWithUpdatedObjects?: Maybe<Scalars["Boolean"]>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  sort?: Maybe<Array<TimeClockSort>>;
  startDateTime: Scalars["ISO8601DateTime"];
  updatedSince?: Maybe<Scalars["ISO8601DateTimeHighPrecision"]>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryTimeOffBalancesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  employmentIds?: Maybe<Array<Scalars["ID"]>>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<TimeOffBalanceSort>>;
  timeOffTypeIds?: Maybe<Array<Scalars["ID"]>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryTimeOffTypesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  includeDeleted?: Maybe<Scalars["Boolean"]>;
  last?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<TimeOffTypeSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryTotalActualSalesArgs = {
  businessId: Scalars["ID"];
  endDateTime: Scalars["ISO8601DateTime"];
  scheduleId: Scalars["ID"];
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryUnavailabilityEventsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  employmentIds?: Maybe<Array<Scalars["ID"]>>;
  endDateTime: Scalars["ISO8601DateTime"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  includeDeleted?: Maybe<Scalars["Boolean"]>;
  last?: Maybe<Scalars["Int"]>;
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryUsersArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  search?: Maybe<Scalars["String"]>;
  sort?: Maybe<Array<UserSort>>;
};

/**
 * Internal Queries.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type InternalQueryViewedForecastsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  codes?: Maybe<Array<Scalars["String"]>>;
  endDateTime: Scalars["ISO8601DateTime"];
  first?: Maybe<Scalars["Int"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  last?: Maybe<Scalars["Int"]>;
  scheduleId: Scalars["ID"];
  sort?: Maybe<ForecastSort>;
  startDateTime: Scalars["ISO8601DateTime"];
};

/** Internal Job Title fields */
export type JobTitle = {
  __typename?: "JobTitle";
  /** 3rd party unique ID for the shift */
  code?: Maybe<Scalars["String"]>;
  /** Time job title created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Indicates whether job title is marked as deleted. Default: false */
  deleted: Scalars["Boolean"];
  /** Description of the Job title */
  description?: Maybe<Scalars["String"]>;
  /** Job Title ID */
  id: Scalars["ID"];
  /** Indicates whether job title is marked as manager. Default: false */
  isManager: Scalars["Boolean"];
  /** Job Title Type ID */
  jobTitleTypeId: Scalars["ID"];
  /** Indicates employment classifications */
  level?: Maybe<Scalars["Int"]>;
  /** Maximum pay rate for job title */
  maxPayRate?: Maybe<Scalars["Float"]>;
  /** Minimum pay rate for job title */
  minPayRate?: Maybe<Scalars["Float"]>;
  /** The name for Job title */
  name: Scalars["String"];
  /** Indicates whether the job title is salaried (true) or hourly (false). Default: false */
  salaried: Scalars["Boolean"];
  /** Time job title last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for JobTitle. */
export type JobTitleConnection = {
  __typename?: "JobTitleConnection";
  /** A list of edges. */
  edges: Array<JobTitleEdge>;
  /** A list of nodes. */
  nodes: Array<JobTitle>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type JobTitleEdge = {
  __typename?: "JobTitleEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<JobTitle>;
};

/** Job Title Sort fields */
export type JobTitleSort = {
  /** Field to sort by */
  field: JobTitleSortField;
  /** Sort order */
  order: Order;
};

/** Job Title Sort Field values */
export enum JobTitleSortField {
  /** Code */
  Code = "code",
  /** Primary key */
  Id = "id",
}

/** Internal Job Title Type fields */
export type JobTitleType = {
  __typename?: "JobTitleType";
  /** 3rd party unique ID for the job title type */
  code?: Maybe<Scalars["String"]>;
  /** Time job title type created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Job Title Type ID */
  id: Scalars["ID"];
  /** The name for Job title type */
  name: Scalars["String"];
  /** Ordering position within types. Default: New job title type will be added to last position */
  order: Scalars["Int"];
  /** Time job title type last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for JobTitleType. */
export type JobTitleTypeConnection = {
  __typename?: "JobTitleTypeConnection";
  /** A list of edges. */
  edges: Array<JobTitleTypeEdge>;
  /** A list of nodes. */
  nodes: Array<JobTitleType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type JobTitleTypeEdge = {
  __typename?: "JobTitleTypeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<JobTitleType>;
};

/** Job Title Type Sort fields */
export type JobTitleTypeSort = {
  /** Field to sort by */
  field: JobTitleTypeSortField;
  /** Sort order */
  order: Order;
};

/** Job Title Type Sort Field values */
export enum JobTitleTypeSortField {
  /** Code */
  Code = "code",
  /** Primary key */
  Id = "id",
}

/** KPI fields */
export type Kpi = {
  __typename?: "Kpi";
  /** Internal code which identifies the KPI */
  code: Scalars["String"];
  /** Time KPI is created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Description of KPI */
  description: Scalars["String"];
  /** KPI ID */
  id: Scalars["ID"];
  /** Whether the KPI is cost related and therefore should be hidden unless user is a SM$ or higher. Default is false */
  isCostRelated: Scalars["Boolean"];
  /** Internal name which identifies the KPI */
  name: Scalars["String"];
  /** Time KPI is updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Format to display the KPI. If null, display format to be determined by frontend. */
  valueType?: Maybe<ValueTypeEnum>;
};

/** The connection type for Kpi. */
export type KpiConnection = {
  __typename?: "KpiConnection";
  /** A list of edges. */
  edges: Array<KpiEdge>;
  /** A list of nodes. */
  nodes: Array<Kpi>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** KPI Display Config Type */
export type KpiDisplayConfig = {
  __typename?: "KpiDisplayConfig";
  /** KPI display label */
  displayLabel: Scalars["String"];
  /** KPI display order */
  displayOrder: Scalars["Int"];
  /** KPI display tooltip */
  displayTooltip?: Maybe<Scalars["String"]>;
  /** KPI Type */
  kpi?: Maybe<Kpi>;
  /** KPI ID */
  kpiId: Scalars["ID"];
  /** KPI target ID */
  targetId?: Maybe<Scalars["ID"]>;
};

/** KPI Display Config update fields */
export type KpiDisplayConfigUpdateInput = {
  /** KPI display label for display config getting updated */
  displayLabel: Scalars["String"];
  /** KPI display order for display config getting updated */
  displayOrder: Scalars["Int"];
  /** KPI display tooltip for display config getting updated */
  displayTooltip?: Maybe<Scalars["String"]>;
  /** KPI ID field for display config getting updated */
  kpiId: Scalars["ID"];
  /** KPI target ID for display config getting updated */
  targetId?: Maybe<Scalars["ID"]>;
};

/** An edge in a connection. */
export type KpiEdge = {
  __typename?: "KpiEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Kpi>;
};

/** KPI Screen Config Type */
export type KpiScreenConfig = {
  __typename?: "KpiScreenConfig";
  /** Time kpi screen config is created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Kpi Screen Config ID */
  id: Scalars["ID"];
  /** KPI display config */
  kpiDisplayConfig: Array<KpiDisplayConfig>;
  /** The screen code enum */
  screenCode: ScreenCodeEnum;
  /** Time kpi screen config is updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Employment ID of updater */
  updatedById?: Maybe<Scalars["ID"]>;
  /** Name of updater employment */
  updatedByName?: Maybe<Scalars["String"]>;
};

/** The connection type for KpiScreenConfig. */
export type KpiScreenConfigConnection = {
  __typename?: "KpiScreenConfigConnection";
  /** A list of edges. */
  edges: Array<KpiScreenConfigEdge>;
  /** A list of nodes. */
  nodes: Array<KpiScreenConfig>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type KpiScreenConfigEdge = {
  __typename?: "KpiScreenConfigEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<KpiScreenConfig>;
};

/** KPI Screen Config Update fields */
export type KpiScreenConfigUpdateInput = {
  /** Array of KPI display configs to be updated. */
  kpiDisplayConfig: Array<KpiDisplayConfigUpdateInput>;
};

/** Legacy Break Type values */
export enum LegacyBreakTypeEnum {
  Meal = "meal",
  Rest = "rest",
  Unknown = "unknown",
}

export type LegacyRuleSet = {
  __typename?: "LegacyRuleSet";
  /** Time legacy rule set was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Description of the legacy rule set */
  description?: Maybe<Scalars["String"]>;
  /** External ID of the legacy rule set */
  externalId: Scalars["Boolean"];
  /** ID of the legacy rule set */
  id: Scalars["ID"];
  /** Meta information of the legacy rule set */
  meta: Scalars["JSON"];
  /** Name of the legacy rule set */
  name: Scalars["String"];
  /** Time legacy rule set was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for LegacyRuleSet. */
export type LegacyRuleSetConnection = {
  __typename?: "LegacyRuleSetConnection";
  /** A list of edges. */
  edges: Array<LegacyRuleSetEdge>;
  /** A list of nodes. */
  nodes: Array<LegacyRuleSet>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type LegacyRuleSetEdge = {
  __typename?: "LegacyRuleSetEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<LegacyRuleSet>;
};

/** Employment Status Change Reason Configuration input fields */
export type MandatoryReasonsForEmploymentStatus = {
  __typename?: "MandatoryReasonsForEmploymentStatus";
  /** Make reason for active status mandatory */
  active: Scalars["Boolean"];
  /** Make reason for leave_of_absence status mandatory */
  leaveOfAbsence: Scalars["Boolean"];
  /** Make reason for suspended status mandatory */
  suspended: Scalars["Boolean"];
  /** Make reason for terminated status mandatory */
  terminated: Scalars["Boolean"];
};

/** Employment Status Change Reason Configuration input fields */
export type MandatoryReasonsForEmploymentStatusInput = {
  /** Make reason for active status mandatory */
  active?: Maybe<Scalars["Boolean"]>;
  /** Make reason for leave_of_absence status mandatory */
  leaveOfAbsence?: Maybe<Scalars["Boolean"]>;
  /** Make reason for suspended status mandatory */
  suspended?: Maybe<Scalars["Boolean"]>;
  /** Make reason for terminated status mandatory */
  terminated?: Maybe<Scalars["Boolean"]>;
};

/** Internal Max Hour fields */
export type MaxHour = {
  __typename?: "MaxHour";
  code?: Maybe<Scalars["String"]>;
  /** Time max hour was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** End date for date range */
  endDate?: Maybe<Scalars["String"]>;
  /** Max Hour ID */
  id: Scalars["ID"];
  isPermanent?: Maybe<Scalars["Boolean"]>;
  maxHours?: Maybe<Scalars["Float"]>;
  /** Start date for date range */
  startDate: Scalars["String"];
  /** Time max hour was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Internal Metadata Type fields */
export type MetadataType = {
  __typename?: "MetadataType";
  /** ID of the business */
  businessId: Scalars["ID"];
  /** Time record was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Data type of the metadata. Valid values are number, boolean, string, date, timestamp, object */
  dataType: MetadataTypeDataTypeEnum;
  /** Short description of the metadata type */
  description?: Maybe<Scalars["String"]>;
  /** Name used for rendering the data at Employment level, WebUI and Mobile */
  displayName?: Maybe<Scalars["String"]>;
  /** If true, metadata details are encrypted */
  encrypted: Scalars["Boolean"];
  /**
   * Indicates whether the field is managed externally. If true, value may be
   * overwritten via data ingestion. If exposed to WebUI or Mobile must be
   * presented as read only
   */
  external: Scalars["Boolean"];
  /** Primary key */
  id: Scalars["ID"];
  /** If true, value is never allowed to be exposed to WebUI or Mobile */
  internalAccess: Scalars["Boolean"];
  /**
   * Allows to define a timeframe the data is valid for. Once employment metadata
   * is captured for this metadata type is not possible to revert to non timeboxed
   */
  isTimeboxed: Scalars["Boolean"];
  /** For numeric types, the max value. For text, the max length. N/A for others */
  maximum?: Maybe<Scalars["Int"]>;
  /** For numeric types, the min value. For text, the min length. N/A for others */
  minimum?: Maybe<Scalars["Int"]>;
  /** Name of the metadata type */
  name: Scalars["String"];
  /**
   * Allows to apply obfuscation to all characters or a set number of characters
   * from the start of a type defined as string when presented in WebUI or Mobile.
   * Allowed to be used only if the field is set as External
   */
  obfuscatedNumber?: Maybe<Scalars["Int"]>;
  /** The object class this metadata type is attached to. */
  objectClass: MetadataTypeObjectClassEnum;
  /** Indicates whether the metadata is mandatory */
  required: Scalars["Boolean"];
  /** Time record was updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /**
   * Valid values allowed to be associated to String or Number types. Where values
   * provided the field is rendered as a dropdown list
   */
  validValues?: Maybe<Scalars["JSON"]>;
};

/** The connection type for MetadataType. */
export type MetadataTypeConnection = {
  __typename?: "MetadataTypeConnection";
  /** A list of edges. */
  edges: Array<MetadataTypeEdge>;
  /** A list of nodes. */
  nodes: Array<MetadataType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** Internal Metadata Type Create input fields */
export type MetadataTypeCreateInput = {
  /** Data type of the metadata. Valid values are number, boolean, string, date, timestamp, object */
  dataType: MetadataTypeDataTypeEnum;
  /** Short description of the metadata type */
  description?: Maybe<Scalars["String"]>;
  /** Name used for rendering the data at Employment level, WebUI and Mobile */
  displayName?: Maybe<Scalars["String"]>;
  /** If true, metadata details are encrypted */
  encrypted?: Maybe<Scalars["Boolean"]>;
  /**
   * Indicates whether the field is managed externally. If true, value may be
   * overwritten via data ingestion. If exposed to WebUI or Mobile must be
   * presented as read only
   */
  external?: Maybe<Scalars["Boolean"]>;
  /** If true, value is never allowed to be exposed to WebUI or Mobile */
  internalAccess?: Maybe<Scalars["Boolean"]>;
  /**
   * Allows to define a timeframe the data is valid for. Once employment metadata
   * is captured for this metadata type is not possible to revert to non timeboxed
   */
  isTimeboxed?: Maybe<Scalars["Boolean"]>;
  /** For numeric types, the max value. For text, the max length. N/A for others */
  maximum?: Maybe<Scalars["Int"]>;
  /** For numeric types, the min value. For text, the min length. N/A for others */
  minimum?: Maybe<Scalars["Int"]>;
  /** Name of the metadata type */
  name: Scalars["String"];
  /**
   * Allows to apply obfuscation to all characters or a set number of characters
   * from the start of a type defined as string when presented in WebUI or Mobile.
   * Allowed to be used only if the field is set as External
   */
  obfuscatedNumber?: Maybe<Scalars["Int"]>;
  /** The object class this metadata type is attached to. */
  objectClass: MetadataTypeObjectClassEnum;
  /** Indicates whether the metadata is mandatory */
  required?: Maybe<Scalars["Boolean"]>;
  /**
   * Valid values allowed to be associated to String or Number types. Where values
   * provided the field is rendered as a dropdown list
   */
  validValues?: Maybe<Scalars["JSON"]>;
};

/** Metadata Type Data Type values */
export enum MetadataTypeDataTypeEnum {
  Boolean = "boolean",
  Date = "date",
  Number = "number",
  Object = "object",
  String = "string",
  Timestamp = "timestamp",
}

/** An edge in a connection. */
export type MetadataTypeEdge = {
  __typename?: "MetadataTypeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<MetadataType>;
};

/** Metadata Type Object class values */
export enum MetadataTypeObjectClassEnum {
  Employment = "Employment",
}

/** Metadata Type Sort fields */
export type MetadataTypeSort = {
  /** Field to sort by */
  field: MetadataTypeSortField;
  /** Sort order */
  order: Order;
};

/** Metadata Type Sort Field values */
export enum MetadataTypeSortField {
  /** Primary key */
  Id = "id",
  /** name of the metadata type */
  Name = "name",
}

/** Internal Metadata Type Update input fields */
export type MetadataTypeUpdateInput = {
  /** Data type of the metadata. Valid values are number, boolean, string, date, timestamp, object */
  dataType?: Maybe<MetadataTypeDataTypeEnum>;
  /** Short description of the metadata type */
  description?: Maybe<Scalars["String"]>;
  /** Name used for rendering the data at Employment level, WebUI and Mobile */
  displayName?: Maybe<Scalars["String"]>;
  /** If true, metadata details are encrypted */
  encrypted?: Maybe<Scalars["Boolean"]>;
  /**
   * Indicates whether the field is managed externally. If true, value may be
   * overwritten via data ingestion. If exposed to WebUI or Mobile must be
   * presented as read only
   */
  external?: Maybe<Scalars["Boolean"]>;
  /** If true, value is never allowed to be exposed to WebUI or Mobile */
  internalAccess?: Maybe<Scalars["Boolean"]>;
  /**
   * Allows to define a timeframe the data is valid for. Once employment metadata
   * is captured for this metadata type is not possible to revert to non timeboxed
   */
  isTimeboxed?: Maybe<Scalars["Boolean"]>;
  /** For numeric types, the max value. For text, the max length. N/A for others */
  maximum?: Maybe<Scalars["Int"]>;
  /** For numeric types, the min value. For text, the min length. N/A for others */
  minimum?: Maybe<Scalars["Int"]>;
  /** Name of the metadata type */
  name?: Maybe<Scalars["String"]>;
  /**
   * Allows to apply obfuscation to all characters or a set number of characters
   * from the start of a type defined as string when presented in WebUI or Mobile.
   * Allowed to be used only if the field is set as External
   */
  obfuscatedNumber?: Maybe<Scalars["Int"]>;
  /** The object class this metadata type is attached to. */
  objectClass?: Maybe<MetadataTypeObjectClassEnum>;
  /** Indicates whether the metadata is mandatory */
  required?: Maybe<Scalars["Boolean"]>;
  /**
   * Valid values allowed to be associated to String or Number types. Where values
   * provided the field is rendered as a dropdown list
   */
  validValues?: Maybe<Scalars["JSON"]>;
};

/** Internal Misc Pay fields */
export type MiscPay = {
  __typename?: "MiscPay";
  /** Actual work date of the misc pay */
  actualWorkDate?: Maybe<Scalars["String"]>;
  /** Cached Actual work date of the misc pay */
  cachedActualWorkDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Cached Pay Date Time */
  cachedPayDateTime: Scalars["ISO8601DateTime"];
  /** 3rd party unique code */
  code?: Maybe<Scalars["String"]>;
  /** Time misc pay created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Description of the misc pay */
  description?: Maybe<Scalars["String"]>;
  /** Misc pay earnings */
  earnings: Scalars["Float"];
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** Misc Pay ID */
  id: Scalars["ID"];
  /** Job title ID */
  jobTitleId?: Maybe<Scalars["ID"]>;
  /** Whether misc pay is locked */
  locked: Scalars["Boolean"];
  /** Misc Pay Type ID */
  miscPayTypeId: Scalars["ID"];
  /** Pay Date */
  payDate: Scalars["String"];
  /** Pay period status */
  payPeriodStatus: Scalars["String"];
  /** Preferred employment rate index. Default is 0 */
  ratePreferenceIndex: Scalars["Int"];
  /** Schedule ID */
  scheduleId: Scalars["ID"];
  /** Seconds to be paid */
  seconds?: Maybe<Scalars["Int"]>;
  /** Time misc pay last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for MiscPay. */
export type MiscPayConnection = {
  __typename?: "MiscPayConnection";
  /** A list of edges. */
  edges: Array<MiscPayEdge>;
  /** A list of nodes. */
  nodes: Array<MiscPay>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type MiscPayEdge = {
  __typename?: "MiscPayEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<MiscPay>;
};

/** Misc Pay Sort fields */
export type MiscPaySort = {
  /** Field to sort by */
  field: MiscPaySortField;
  /** Sort order */
  order: Order;
};

/** Misc Pay Sort Field values */
export enum MiscPaySortField {
  /** Primary key */
  Id = "id",
  /** Pay Date */
  PayDate = "payDate",
}

/** Internal Misc Pay Type fields */
export type MiscPayType = {
  __typename?: "MiscPayType";
  /** Indicates whether actual work date is needed to create a miscellaneous pay of this type */
  actualWorkDateRequired: Scalars["Boolean"];
  /** ID of the business this misc pay type belongs to */
  businessId: Scalars["ID"];
  /** 3rd party unique code */
  code?: Maybe<Scalars["String"]>;
  /** Time misc pay type created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Whether the misc pay type is soft deleted */
  deleted: Scalars["Boolean"];
  /** Description of the misc pay type */
  description?: Maybe<Scalars["String"]>;
  /** Indicates whether duration is required to create a pay entry of this type */
  hourBased: Scalars["Boolean"];
  /** Misc Pay Type ID */
  id: Scalars["ID"];
  /** Indicates whether this misc pay type is available for crews. Default: false */
  isCrew: Scalars["Boolean"];
  /** Indicates whether this misc pay type is available for managers. Default: false */
  isManager: Scalars["Boolean"];
  /** Misc Pay Type Name */
  name: Scalars["String"];
  /** Time misc pay type last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for MiscPayType. */
export type MiscPayTypeConnection = {
  __typename?: "MiscPayTypeConnection";
  /** A list of edges. */
  edges: Array<MiscPayTypeEdge>;
  /** A list of nodes. */
  nodes: Array<MiscPayType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type MiscPayTypeEdge = {
  __typename?: "MiscPayTypeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<MiscPayType>;
};

/** Misc Pay Type Sort fields */
export type MiscPayTypeSort = {
  /** Field to sort by */
  field: MiscPayTypeSortField;
  /** Sort order */
  order: Order;
};

/** Misc Pay Type Sort Field values */
export enum MiscPayTypeSortField {
  /** Primary key */
  Id = "id",
  /** name of the misc pay type */
  Name = "name",
}

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type Mutation = {
  __typename?: "Mutation";
  /**
   * Add Users to a Forum
   * Must be logged in as owner of the forum
   * Return Forum if succeed
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Owner of the forum
   *   RESOURCE_NOT_FOUND: Forum cannot be found
   */
  addUsersToForum: Forum;
  /**
   * Searches employments based on attribute_name and values in the businesses with the business_ids and
   * applies permissions using given permission mapping.
   * Must be an internal mutation.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be internal query sent from IDM
   */
  assignPermissionsForSingleSignOn: Array<Employment>;
  /**
   * This is written for Test data automation to generate users for test
   * employments. Not to be used in other scenarios.
   * Returns array of new/assigned users.
   * Must be logged in as client support user.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   This mutation is to be used only for generating test data
   *   No employments found with code(s) {invalid codes}
   *   {Employment code} does not have a user associated with it
   */
  assignUserToEmployment: Array<User>;
  /**
   * Wipes all availability for given employments and creates a continuous (24/7) manager initiated permanent
   * availability for them.
   * Returns created availabilities.
   * Must be logged in as client support user.
   * Cannot be run in a production environment.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR:
   *    - LIFELENZ Support Only
   *    - Must be employed
   *   VALIDATION_ERROR:
   *    - Cannot be run in production environments.
   */
  bulkCreateContinuousAvailability: Array<NoPermanentWorkSchedule>;
  /**
   * Bulk upsert the KPI target against a schedule.
   * Returns a boolean indicating if the import is successful.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: Schedule cannot be found
   */
  bulkUpsertScheduleTargets: Scalars["Boolean"];
  /**
   * This mutation is used update a skill level's rank, by moving
   * it in the ranked order of skill levels by one position,
   * given the enum value increase, or decrease.
   *
   * Returns an array of Types::SkillLevelType if successful.
   *
   * LifeLenz Support Only
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: SkillLevel
   */
  changeSkillLevelRank: SkillLevelConnection;
  /**
   * Confirm business invite on stack.
   * This mutation is used by Idm.
   * Returns confirmed employment.
   * Must be service request from IDM.
   *
   * Common errors:
   *   RESOURCE_NOT_FOUND: Invite code not found
   */
  confirmBusinessInvite?: Maybe<ConfirmBusinessInvite>;
  /**
   * Create AOS Configuration Template.
   * Returns created template.
   * If setting :isDefault to true, this sets any existing default to false
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR:
   *    - LIFELENZ Support Only
   *    - Must be employed
   *   VALIDATION_ERROR:
   *    - template_name must be unique
   *    - There can be only one default AOS config template
   */
  createAosConfigTemplate: AosConfigTemplate;
  /**
   * This mutation is used by client support to create a business level break type
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  createBreakType: BreakType;
  /**
   * Create business on stack. Also creates on IDM if stack is idm governed.
   * This mutation is used by Platform Admin to create business on stack and the IDM.
   * Returns true if successful.
   * Must be logged in as client support user.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  createBusiness: Business;
  /**
   * Create Employment Feedback Reason
   * Must be logged in as client support user for the current business
   * Return Employment Feedback Reason on success
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ support only or Not for current business
   *   VALIDATION_ERROR: Invalid Employment Feedback
   */
  createEmploymentFeedbackReason: EmploymentFeedbackReason;
  /**
   * Create Employment Metadata
   * LifeLenz Support Only.
   * Return Employment Metadata on success
   *
   * If setting MetadataType.is_timeboxed is false, start_time and end_time
   * must be null. If setting MetadataType.is_timeboxed is true then start_time and end_time
   * can have value or be null.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   VALIDATION_ERROR: Invalid EmploymentMetadata
   */
  createEmploymentMetadata: EmploymentMetadata;
  /**
   * Create Employment Schedule History
   * Must be logged in as group admin and the employee must be managed by the creator.
   * Return Employment Schedule History if succeed
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Group Admin and Managed by the Creator
   *   RESOURCE_NOT_FOUND: Schedule or Employment cannot be found
   */
  createEmploymentScheduleHistory: EmploymentScheduleHistory;
  /**
   * Create an employment type config.
   * Returns the created object if successful.
   * LifeLenz Support only.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   GENERAL_ERROR: Exactly one default employment type config may exist
   */
  createEmploymentTypeConfig: EmploymentTypeConfig;
  /**
   * Create a Global Business
   * LifeLenz Support Only
   * Returns Internal::Types::CreateGlobalBusinessInputType
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   GLOBAL_BUSINESS_ERROR: Must be called on a stack configured as a designated global stack
   */
  createGlobalBusiness: GlobalBusiness;
  /**
   * Create a Global Business Stack
   * Only one Global Business Stack per deployed stack
   * Returns an Internal::Types::GlobalBusinessStackType
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  createGlobalBusinessStack: GlobalBusinessStack;
  /**
   * Create a Metadata Type
   * Must be logged in as schedule manager
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  createMetadataType: MetadataType;
  /**
   * Create No Permanent Work Schedule Availability.
   * Returns created availability.
   * Start date is defaulted to the date tomorrow if not provided.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR:
   *    - LIFELENZ Support Only
   *    - Must be employed
   *   VALIDATION_ERROR:
   *    - Number of weeks must be greater than or equal to 1
   *    - Start date must not be after end date. End date must not be before start date
   */
  createNoPermanentWorkScheduleAvailability: NoPermanentWorkSchedule;
  /**
   * This mutation is used by client support to create a schedule level break type
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  createScheduleBreakType: ScheduleBreakType;
  /**
   * Create a Set Value
   * LifeLenz Support Only
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  createSetValue: SetValue;
  /**
   * This mutation is used to create a new skill level
   * Returns Types::SkillLevelType if successful.
   * LifeLenz Support Only
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   VALIDATION_ERROR: Invalid SkillLevel
   */
  createSkillLevel: SkillLevel;
  /**
   * Create store hours for business level or schedule
   * Must be logged in as client support user.
   * Return Store Hours if successful
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  createStoreHours: StoreHour;
  /**
   * Create Temporary Availability.
   * Returns created availability.
   * Start date is defaulted to the date tomorrow if not provided.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR:
   *    - LIFELENZ Support Only
   *    - Must be employed
   *   VALIDATION_ERROR:
   *    - Number of weeks must be greater than or equal to 1
   *    - Start date must not be after end date. End date must not be before start date
   *    - Start and End dates must cover a timeframe of at least 7 days
   */
  createTemporaryAvailability: TemporaryAvailability;
  /**
   * Create Time Off Type
   * Must be logged in as group admin and the employee must be managed by the creator.
   * Return Time Off Type if succeed
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Group Admin and Managed by the Creator
   *   RESOURCE_NOT_FOUND: Schedule or Employment cannot be found
   */
  createTimeOffType: TimeOffType;
  /**
   * This mutation is used by client support to deactivate a business level break type
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: Break type could not be found
   *   RESOURCE_DELETE_ERROR: Must have at least 1 active break type remaining
   */
  deactivateBreakType: Scalars["Boolean"];
  /**
   * Deactivate Employment Feedback Reason
   * Must be logged in as client support user for the current business
   * Return the updated Employment Feedback Reason if succeed
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ support only or Not for current business
   *   RESOURCE_NOT_FOUND: Could not find Employment Feedback Reason
   */
  deactivateEmploymentFeedbackReason: EmploymentFeedbackReason;
  /**
   * Delete AOS Configuration Template.
   * Returns true if successful.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: Couldn't find AosConfigTemplate with 'id'={id}
   *   VALIDATION_ERROR:
   *    - template_name must be unique
   *    - There can be only one default AOS config template
   */
  deleteAosConfigTemplate: Scalars["Boolean"];
  /**
   * Delete Availability.
   * Also deletes associated Available Hours.
   * Returns true if successful.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: Availability no longer exists, and must have been deleted
   */
  deleteAvailability: Scalars["Boolean"];
  /**
   * Mark a set of employment external codes belonging to a particular schedule as deleted.
   * Returns the number of rows affected in the DB.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  deleteEmploymentExternalCodes: Scalars["Int"];
  /**
   * Delete Employment Metadata
   * LifeLenz Support Only.
   * Return true if succeed
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: EmploymentMetadata
   */
  deleteEmploymentMetadata: Scalars["Boolean"];
  /**
   * Create Employment Schedule History
   * Must be logged in as group admin and the employee must be managed by the updater.
   * Return true if succeed
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Group Admin and Managed by the Updater
   */
  deleteEmploymentScheduleHistory: Scalars["Boolean"];
  /**
   *           Erases all employment time lines, cached employment time lines and
   * fixed shift time lines for the specified business
   *           Calls the employment time lines service to regenerate the timelines
   * after deleting them if the recreate argument is true
   *           If timelines are regenerated, will be regenerated from 2 months ago to 6 months from now
   *           Cannot be called in production environments
   *
   *           Common error codes:
   *           AUTHORIZATION_ERROR:
   *            - LIFELENZ Support Only
   *           GENERAL_ERROR:
   *            - Employment time lines service is not contactable
   *            - Not to be called in production
   */
  deleteEmploymentTimeLines: Scalars["Boolean"];
  /**
   * This mutation is used delete a employment type config.
   * Returns true if successful.
   * LifeLenz Support Only
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: EmploymentTypeConfig
   *   RESOURCE_DELETE_ERROR: Cannot delete default employment type config
   *   RESOURCE_DELETE_ERROR: Cannot delete employment type config in use
   */
  deleteEmploymentTypeConfig: Scalars["Boolean"];
  /**
   * Delete a Global Business.
   * LifeLenz Support Only
   * Returns Boolean
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   GLOBAL_BUSINESS_ERROR:
   *       - Must be called on a stack configured as a designated global stack
   *       - The Global Business must not have been used to generate GUIDs already
   */
  deleteGlobalBusiness: Scalars["Boolean"];
  /**
   * Delete a Global Business Stack
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  deleteGlobalBusinessStack: Scalars["Boolean"];
  /**
   * Delete a Metadata Type.
   * Must be logged in as schedule manager
   * Returns Boolean
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   RESOURCE_NOT_FOUND: Couldn't find MetadataType with 'id'={id}
   */
  deleteMetadataType: Scalars["Boolean"];
  /**
   * This mutation is used by client support to delete a schedule level break type
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: Schedule break type could not be found
   */
  deleteScheduleBreakType: Scalars["Boolean"];
  /**
   * Delete a Set Value.
   * Returns Boolean
   * LifeLenz Support Only
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: Couldn't find SetValue with 'id'={id}
   */
  deleteSetValue: Scalars["Boolean"];
  /**
   * This mutation is used delete a skill level.
   * Returns true if successful.
   * LifeLenz Support Only
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: SkillLevel
   *   RESOURCE_DELETE_ERROR: Cannot delete default skill level
   */
  deleteSkillLevel: Scalars["Boolean"];
  /**
   * Delete Time Off type.
   * Returns true if successful.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: Time Off type no longer exists, and must have been deleted
   */
  deleteTimeOffType: Scalars["Boolean"];
  /**
   * Deregister a time clock device for a particular business.
   * Returns true if the time clock was successfully deregistered.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   VALIDATION_ERROR: Failed to deregister the time clock device
   */
  deregisterTimeClockDevice: Scalars["Boolean"];
  /**
   * Fix UserBusiness relations for a supplied user.
   * Must be logged in as client support user.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  fixUserBusinesses: User;
  /**
   * Generate quarters for time range.
   * Returns status of result as true if successful.
   * Must be logged in as schedule manager.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   GENERAL_ERROR: Business does not have quarterly aggregation enabled
   */
  generateQuarters: Result;
  /**
   * Generates a time clock app API key for a business, or returns the existing code if it already exists.
   * Returns the TimeClockAppApiKey for the business.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  generateTimeClockAppApiKey: TimeClockAppApiKey;
  /**
   * Create a Forum
   * Must have business-owner rights
   * Return Forum if succeed
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must have business-owner rights
   *   RESOURCE_NOT_FOUND: Employment cannot be found
   */
  importForum: Forum;
  /**
   * Links user to employment.
   * Returns employment linked to user.
   * Must be logged in as client support user.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  linkUserToEmployment: Employment;
  /**
   * Allocates and returns the next available User ID (GEID) for a given Global Business.
   * LifeLenz Support or Stack Service call only
   * Returns BigInt
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only or Stack Service call only
   *   GLOBAL_BUSINESS_ERROR:
   *       - Must be called on a stack configured as a designated global stack
   */
  nextUidFromGlobalBusiness: Scalars["BigInt"];
  /**
   *           This mutation is used by client support to reactivate a business
   * level break type. Returns the reactivated break type.
   *           Must be logged in as client support user.
   *
   *           Common error codes:
   *             AUTHORIZATION_ERROR: LIFELENZ Support Only
   *             RESOURCE_NOT_FOUND: Break type could not be found
   */
  reactivateBreakType: BreakType;
  /**
   * Reactivate Employment Feedback Reason
   * Must be logged in as client support user for the current business
   * Return the updated Employment Feedback Reason if succeed
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ support only or Not for current business
   *   RESOURCE_NOT_FOUND: Could not find Employment Feedback Reason
   */
  reactivateEmploymentFeedbackReason: EmploymentFeedbackReason;
  /**
   * Update compliance results for the schedule.
   * Time range is mandatory and only warnings within the given time range will be returned.
   *
   *   Common errors:
   *     - AUTHORIZATION_ERROR: Must have schedule-manager rights for the schedule
   *     - RULES_ENGINE_ERROR: Rules engine must be accessible
   *     - GENERAL_ERROR: Schedule must be linked to a rule set
   */
  refreshComplianceWarnings: Array<ComplianceWarning>;
  /**
   * Reject business invite on stack.
   * This mutation is used by Idm.
   * Returns true if successful.
   * Must be service request from IDM.
   *
   * Common errors:
   *   RESOURCE_NOT_FOUND: Invite code not found
   */
  rejectBusinessInvite?: Maybe<Scalars["Boolean"]>;
  /**
   * Remove rules on schedule.
   * Returns updated schedule.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  removeRulesOnSchedule: Schedule;
  /**
   * Remove Users from a Forum
   * Must be logged in as owner of the forum
   * Return Forum if succeed
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Owner of the forum
   *   RESOURCE_NOT_FOUND: Forum cannot be found
   */
  removeUsersFromForums: Forum;
  /**
   * Sync users with IDM.
   * This mutation is used by Idm.
   * Returns array of user IDs.
   * Must be service request from IDM
   */
  syncIdmUsers: Array<Scalars["ID"]>;
  /**
   * Unlinks user from employment in the given business.
   * Removes user_stack in IDM if user no longer employed in stack.
   * Returns true if successful.
   * Must be logged in as client support user.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  unlinkUserFromEmployment: Scalars["Boolean"];
  /**
   * Update AOS Configuration Template.
   * Returns updated template.
   * If setting :isDefault to true, this sets any existing default to false
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR:
   *    - LIFELENZ Support Only
   *    - Must be employed
   *   RESOURCE_NOT_FOUND: Couldn't find AosConfigTemplate with 'id'={id}
   *   VALIDATION_ERROR:
   *    - template_name must be unique
   *    - There can be only one default AOS config template
   */
  updateAosConfigTemplate: AosConfigTemplate;
  /**
   * This mutation is used by client support to update a business level break type
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: Break type could not be found
   */
  updateBreakType: BreakType;
  /**
   * Update business.
   * If business name provided, also updates business name on IDM.
   * Returns updated business.
   * Must be logged in as business owner.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be business owner
   *   IDM_ERROR: IDM server error
   */
  updateBusiness: Business;
  /**
   * Update Business Office Location
   * Return updated Business Office Location
   * Must have employee manager rights
   *
   * Common errors:
   *   AUTHORIZATION_ERROR:
   *     - Must be employee manager
   *   GENERAL ERROR:
   *     - Business Office Location cannot be found
   */
  updateBusinessOfficeLocation: BusinessOfficeLocation;
  /**
   * This mutation is used by client support to update documents
   * Returns Types::DocumentType if successful.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: Document
   */
  updateDocument: Document;
  /**
   * Update employment.
   * Returns updated employment.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  updateEmployment: Employment;
  /**
   * Update Employment Feedback Reason
   * Must be logged in as client support user for the current business
   * Return Employment Feedback Reason on success
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ support only or Not for current business
   *   VALIDATION_ERROR: Invalid Employment Feedback Reason
   *   RESOURCE_NOT_FOUND: Could not find Employment Feedback Reason
   */
  updateEmploymentFeedbackReason: EmploymentFeedbackReason;
  /**
   * Update Employment Metadata
   * LifeLenz Support Only.
   * Return Employment Metadata on success
   *
   * If setting MetadataType.is_timeboxed is false, start_time and end_time
   * must be null. If setting MetadataType.is_timeboxed is true then start_time and end_time
   * can have value or be null.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   VALIDATION_ERROR: Invalid EmploymentMetadata
   *   RESOURCE_NOT_FOUND: EmploymentMetadata
   */
  updateEmploymentMetadata: EmploymentMetadata;
  /**
   * Update Employment Schedule History
   * Must be logged in as group admin and the employee must be managed by the updater.
   * Return Employment Schedule History if succeed
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Group Admin and Managed by the Updater
   */
  updateEmploymentScheduleHistory: EmploymentScheduleHistory;
  /**
   * This mutation is used by client support to update a user/employee pin.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: Employment security could not be found
   */
  updateEmploymentSecurity: EmploymentSecurity;
  /**
   * Update an employment type config.
   * Returns the updated object if successful.
   * LifeLenz Support only
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: EmploymentTypeConfig
   *   GENERAL_ERROR: A default employment type config is required
   */
  updateEmploymentTypeConfig: EmploymentTypeConfig;
  /**
   * Update a Global Business
   * LifeLenz Support Only
   * Returns Internal::Types::CreateGlobalBusinessInputType
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   GLOBAL_BUSINESS_ERROR: Must be called on a stack configured as a designated global stack
   */
  updateGlobalBusiness: GlobalBusiness;
  /**
   * Update a Global Business Stack
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  updateGlobalBusinessStack: GlobalBusinessStack;
  /**
   * Update KPI Screen config - to configure what and how KPIs are shown on a given screen.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ support
   */
  updateKpiScreenConfig: KpiScreenConfig;
  /**
   * Update a Metadata Type
   * Must be logged in as schedule manager
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   *   RESOURCE_NOT_FOUND: Couldn't find MetadataType with 'id'={id}
   */
  updateMetadataType: MetadataType;
  /**
   * Update No Permanent Work Schedule Availability.
   * Returns updated availability.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR:
   *    - LIFELENZ Support Only
   *    - Must be employed
   *   RESOURCE_NOT_FOUND: Couldn't find Availability::NoPermanentWorkSchedule with 'id'={id}
   *   VALIDATION_ERROR:
   *    - Number of weeks must be greater than or equal to 1
   *    - Start date must not be after end date. End date must not be before start date
   */
  updateNoPermanentWorkScheduleAvailability: NoPermanentWorkSchedule;
  /**
   * Update pay period.
   * Returns updated pay period.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  updatePayPeriod: PayPeriod;
  /**
   * Update rules on schedule.
   * Returns updated schedule.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   */
  updateRulesOnSchedule: Schedule;
  /**
   * Update schedule.
   * Returns updated schedule.
   * Must be logged in as schedule manager.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: Must be schedule manager
   */
  updateSchedule: Schedule;
  /**
   * This mutation is used by client support to update a schedule level break type
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: Schedule break type could not be found
   */
  updateScheduleBreakType: ScheduleBreakType;
  /**
   * Update a Set Value
   * LifeLenz Support Only
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: Couldn't find SetValue with 'id'={id}
   */
  updateSetValue: SetValue;
  /**
   * This mutation is used update a skill level.
   * Returns a Types::SkillLevelType if successful.
   *
   * If setting :isDefault to true, this sets any existing
   * default to false. If setting :isDefault to false where it
   * was true, an error is raised.
   *
   * Returns the updated Types::SkillLevelType if successful.
   *
   * LifeLenz Support Only
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   RESOURCE_NOT_FOUND: SkillLevel
   *   GENERAL_ERROR: A default skill level is required
   */
  updateSkillLevel: SkillLevel;
  /**
   * Update Temporary Availability.
   * Returns updated availability.
   * Must be logged in as client support user.
   *
   * Common error codes:
   *   AUTHORIZATION_ERROR:
   *    - LIFELENZ Support Only
   *    - Must be employed
   *   RESOURCE_NOT_FOUND: Couldn't find Availability::TemporaryAvailability with 'id'={id}
   *   VALIDATION_ERROR:
   *    - Number of weeks must be greater than or equal to 1
   *    - Start date must not be after end date. End date must not be before start date
   *    - Start and End dates must cover a timeframe of at least 7 days
   */
  updateTemporaryAvailability: TemporaryAvailability;
  updateTimeClockDevice: TimeClockDevice;
  /**
   * Update Time Off Type
   * Must be logged in as group admin and the employee must be managed by the updater.
   * Return Time Off Type if succeed
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: Group Admin and Managed by the Updater
   */
  updateTimeOffType: TimeOffType;
  /**
   * Upsert Employment Metadata
   * LifeLenz Support Only.
   * Returns non-time-boxed array of employments Employment Metadata on success
   *
   * Receives an array of metadata values and smartly creates/updates/deletes metadata for an employee.
   *
   * Create when no record matches existing metadata_type_id.
   * Update when record matches existing metadata_type_id.
   * Delete when details is nil and matches existing metadata_type_id.
   *
   * Supports non-time-boxed metadata only.
   *
   * Common errors:
   *   AUTHORIZATION_ERROR: LIFELENZ Support Only
   *   VALIDATION_ERROR: Invalid EmploymentMetadata
   *   RESOURCE_NOT_FOUND: Employment
   *   RESOURCE_NOT_FOUND: EmploymentMetadata
   */
  upsertEmploymentMetadata: Array<EmploymentMetadata>;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationAddUsersToForumArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  userIds: Array<Scalars["ID"]>;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationAssignPermissionsForSingleSignOnArgs = {
  attributeName: Scalars["String"];
  businessIds: Array<Scalars["ID"]>;
  permissions: Array<PermissionMapInput>;
  userId: Scalars["ID"];
  values: Array<Scalars["String"]>;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationAssignUserToEmploymentArgs = {
  businessId: Scalars["ID"];
  employmentCodes?: Maybe<Array<Scalars["String"]>>;
  userAttributes?: Maybe<Array<UserAttributes>>;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationBulkCreateContinuousAvailabilityArgs = {
  businessId: Scalars["ID"];
  employmentIds?: Maybe<Array<Scalars["ID"]>>;
  scheduleIds?: Maybe<Array<Scalars["ID"]>>;
  startDate?: Maybe<Scalars["ISO8601Date"]>;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationBulkUpsertScheduleTargetsArgs = {
  businessId: Scalars["ID"];
  input: Array<ScheduleTargetBulkUpsertInput>;
  scheduleId: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationChangeSkillLevelRankArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  businessId: Scalars["ID"];
  first?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  input: RankChangeEnum;
  last?: Maybe<Scalars["Int"]>;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationConfirmBusinessInviteArgs = {
  businessInviteToken: Scalars["String"];
  user: ConfirmBusinessInviteInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateAosConfigTemplateArgs = {
  businessId: Scalars["ID"];
  input: AosConfigTemplateCreateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateBreakTypeArgs = {
  businessId: Scalars["ID"];
  input: BreakTypeCreateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateBusinessArgs = {
  input: BusinessInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateEmploymentFeedbackReasonArgs = {
  businessId: Scalars["ID"];
  input: EmploymentFeedbackReasonCreateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateEmploymentMetadataArgs = {
  businessId: Scalars["ID"];
  input: EmploymentMetadataCreateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateEmploymentScheduleHistoryArgs = {
  businessId: Scalars["ID"];
  input: EmploymentScheduleHistoryCreateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateEmploymentTypeConfigArgs = {
  businessId: Scalars["ID"];
  input: EmploymentTypeConfigCreateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateGlobalBusinessArgs = {
  input: CreateGlobalBusinessInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateGlobalBusinessStackArgs = {
  input: CreateGlobalBusinessStackInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateMetadataTypeArgs = {
  businessId: Scalars["ID"];
  input: MetadataTypeCreateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateNoPermanentWorkScheduleAvailabilityArgs = {
  businessId: Scalars["ID"];
  input: NoPermanentWorkScheduleCreateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateScheduleBreakTypeArgs = {
  businessId: Scalars["ID"];
  input: ScheduleBreakTypeCreateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateSetValueArgs = {
  businessId: Scalars["ID"];
  input: SetValueCreateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateSkillLevelArgs = {
  businessId: Scalars["ID"];
  input: SkillLevelInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateStoreHoursArgs = {
  businessId: Scalars["ID"];
  input: StoreHourCreateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateTemporaryAvailabilityArgs = {
  businessId: Scalars["ID"];
  input: TemporaryAvailabilityCreateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationCreateTimeOffTypeArgs = {
  businessId: Scalars["ID"];
  input: TimeOffTypeCreateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeactivateBreakTypeArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeactivateEmploymentFeedbackReasonArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteAosConfigTemplateArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteAvailabilityArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteEmploymentExternalCodesArgs = {
  businessId: Scalars["ID"];
  employmentIds: Array<Scalars["ID"]>;
  scheduleId: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteEmploymentMetadataArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteEmploymentScheduleHistoryArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteEmploymentTimeLinesArgs = {
  businessIds?: Maybe<Array<Scalars["ID"]>>;
  employmentIds?: Maybe<Array<Scalars["ID"]>>;
  recreate?: Maybe<Scalars["Boolean"]>;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteEmploymentTypeConfigArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteGlobalBusinessArgs = {
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteGlobalBusinessStackArgs = {
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteMetadataTypeArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteScheduleBreakTypeArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteSetValueArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteSkillLevelArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeleteTimeOffTypeArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationDeregisterTimeClockDeviceArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationFixUserBusinessesArgs = {
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationGenerateQuartersArgs = {
  businessId: Scalars["ID"];
  endDateTime: Scalars["ISO8601DateTime"];
  immediate?: Maybe<Scalars["Boolean"]>;
  scheduleId: Scalars["ID"];
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationGenerateTimeClockAppApiKeyArgs = {
  businessId: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationImportForumArgs = {
  additionalEmploymentCodes?: Maybe<Array<Scalars["String"]>>;
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  locationCode: Scalars["String"];
  ownerEmploymentCode: Scalars["String"];
  roleCodes: Array<Scalars["String"]>;
  topic: Scalars["String"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationLinkUserToEmploymentArgs = {
  businessId: Scalars["ID"];
  employmentId: Scalars["ID"];
  userId: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationNextUidFromGlobalBusinessArgs = {
  globalBusinessId: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationReactivateBreakTypeArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationReactivateEmploymentFeedbackReasonArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationRefreshComplianceWarningsArgs = {
  businessId: Scalars["ID"];
  endDateTime: Scalars["ISO8601DateTime"];
  includeHiddenWarnings?: Maybe<Scalars["Boolean"]>;
  scheduleId: Scalars["ID"];
  startDateTime: Scalars["ISO8601DateTime"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationRejectBusinessInviteArgs = {
  businessInviteToken: Scalars["String"];
  userId: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationRemoveRulesOnScheduleArgs = {
  businessId: Scalars["ID"];
  scheduleId: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationRemoveUsersFromForumsArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  userIds: Array<Scalars["ID"]>;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationSyncIdmUsersArgs = {
  input: Array<SyncIdmUsersInput>;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUnlinkUserFromEmploymentArgs = {
  businessId: Scalars["ID"];
  userId: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateAosConfigTemplateArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: AosConfigTemplateUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateBreakTypeArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: BreakTypeUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateBusinessArgs = {
  id: Scalars["ID"];
  input: BusinessInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateBusinessOfficeLocationArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: BusinessOfficeLocationUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateDocumentArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: DocumentUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateEmploymentArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: EmploymentInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateEmploymentFeedbackReasonArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: EmploymentFeedbackReasonUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateEmploymentMetadataArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: EmploymentMetadataUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateEmploymentScheduleHistoryArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: EmploymentScheduleHistoryUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateEmploymentSecurityArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: EmploymentSecurityUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateEmploymentTypeConfigArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: EmploymentTypeConfigUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateGlobalBusinessArgs = {
  id: Scalars["ID"];
  input: UpdateGlobalBusinessInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateGlobalBusinessStackArgs = {
  id: Scalars["ID"];
  input: UpdateGlobalBusinessStackInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateKpiScreenConfigArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: KpiScreenConfigUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateMetadataTypeArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: MetadataTypeUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateNoPermanentWorkScheduleAvailabilityArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: NoPermanentWorkScheduleUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdatePayPeriodArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  payPeriodStatus: PayPeriodStatus;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateRulesOnScheduleArgs = {
  businessId: Scalars["ID"];
  ruleSetName: Scalars["String"];
  ruleSetSubscriptionStatus?: Maybe<RuleSetSubscriptionStatusEnum>;
  ruleSetVersion: Scalars["String"];
  scheduleId: Scalars["ID"];
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateScheduleArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: ScheduleUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateScheduleBreakTypeArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: ScheduleBreakTypeUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateSetValueArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: SetValueUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateSkillLevelArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: SkillLevelUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateTemporaryAvailabilityArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: TemporaryAvailabilityUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateTimeClockDeviceArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: TimeClockDeviceInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpdateTimeOffTypeArgs = {
  businessId: Scalars["ID"];
  id: Scalars["ID"];
  input: TimeOffTypeUpdateInput;
};

/**
 * Internal Mutations.
 * Only accessible via internal users or service requests.
 *
 * General errors and their causes:
 *   Note: this general error list excludes GraphQL argument and model validation errors
 *
 *   If must be logged in:
 *     - AUTHENTICATION_ERROR: Invalid user
 *     - GENERAL_ERROR: Invalid user
 *     - INVALID_SESSION_ERROR: Session expired, or password has been changed since last login
 *     - AUTHENTICATION_ERROR: User not confirmed, or account locked
 *
 *   If must be a service request:
 *     - AUTHENTICATION_ERROR: Invalid Service
 *
 *   Checking of X-VERSION header:
 *     - API_VERSION: Incompatible API version
 */
export type MutationUpsertEmploymentMetadataArgs = {
  businessId: Scalars["ID"];
  employmentId: Scalars["ID"];
  input: Array<EmploymentMetadataUpsertInput>;
};

/** Internal Availability No Permanent Work Schedule fields */
export type NoPermanentWorkSchedule = Availability & {
  __typename?: "NoPermanentWorkSchedule";
  /** Array of objects containing minimum hours for each week in availability definition */
  cachedMinHoursPerWeek?: Maybe<Scalars["JSON"]>;
  /** Indicates if the linked employment waived consecutive days off */
  consecutiveDaysOffWaived: Scalars["Boolean"];
  /** Time created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employment id of creator */
  createdById?: Maybe<Scalars["ID"]>;
  /** Association linking the availability definition to day groups containing available hours definitions */
  days: Array<AvailabilityDetailsByDay>;
  /** Whether the availability definition is soft-deleted */
  deleted: Scalars["Boolean"];
  /** Indicates if the linked employment created the availability */
  employeeInitiated: Scalars["Boolean"];
  /** Employment that availability is associated to */
  employmentId: Scalars["ID"];
  /** Availability end date. Can be null */
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Availability ID */
  id: Scalars["ID"];
  /** Time that manager acknowledged notification. Can be null */
  managerAcknowledgedNotificationAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /**
   * The max number of seconds per week for the whole availability definition. It
   * will be the same value for each week in a multi-week definition
   */
  maxDurationPerWeek?: Maybe<Scalars["Seconds"]>;
  /** How many weeks are in the availability definition */
  numberOfWeeks: Scalars["Int"];
  /** The message attached to the request if any */
  requestingMessage?: Maybe<Scalars["String"]>;
  /** The time of the response (could be manager or employee) */
  respondedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The employment id of the responder (could be manager or employee). respondedBy === createdBy =>"Cancelled" */
  respondedById?: Maybe<Scalars["ID"]>;
  /** The message attached to the response if any */
  respondingMessage?: Maybe<Scalars["String"]>;
  /** Availability start date. Defaults to date of tomorrow */
  startDate: Scalars["ISO8601Date"];
  /** Status of availability */
  status: AvailabilityStatusEnum;
  /** Time of submission for approval/acceptance */
  submittedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /**
   * Most often equal to createdBy, but or the case of manager-initiated, Manager A
   * could start a draft and manager B could finish and submit
   */
  submittedById?: Maybe<Scalars["ID"]>;
  /** Type of availability */
  type: AvailabilityTypeEnum;
  /** Time updated */
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Employment id of updater */
  updatedById?: Maybe<Scalars["ID"]>;
  /** If true, employee is willing to work extra hours outside of fixed contract */
  willingToWorkExtraHours: Scalars["Boolean"];
};

/** Internal Availability No Permanent Work Schedule Create input fields */
export type NoPermanentWorkScheduleCreateInput = {
  /** Array of objects containing minimum hours for each week in availability definition */
  cachedMinHoursPerWeek?: Maybe<Scalars["JSON"]>;
  /** Association linking the availability definition to day groups containing available hours, fixed shifts definitions */
  days?: Maybe<Array<AvailabilityDetailsByDayCreateInput>>;
  /** Indicates if the linked employment created the availability */
  employeeInitiated: Scalars["Boolean"];
  /** Employment that availability is associated to */
  employmentId: Scalars["ID"];
  /** Availability end date. Can be null */
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Time that manager acknowledged notification. Can be null */
  managerAcknowledgedNotificationAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /**
   * The max number of seconds per week for the whole availability definition. It
   * will be the same value for each week in a multi-week definition
   */
  maxDurationPerWeek?: Maybe<Scalars["Seconds"]>;
  /** How many weeks are in the availability definition */
  numberOfWeeks?: Maybe<Scalars["Int"]>;
  /** Temporary Availability repeat cycle */
  repeatCycle?: Maybe<TemporaryAvailabilityRepeatCycleEnum>;
  /** The message attached to the request if any */
  requestingMessage?: Maybe<Scalars["String"]>;
  /** The message attached to the response if any */
  respondingMessage?: Maybe<Scalars["String"]>;
  /** Availability start date. Defaults to date of tomorrow */
  startDate: Scalars["ISO8601Date"];
  /** Status of availability */
  status: AvailabilityStatusEnum;
  /** If true, employee is willing to work extra hours outside of fixed contract */
  willingToWorkExtraHours?: Maybe<Scalars["Boolean"]>;
};

/** Internal Availability No Permanent Work Schedule Update input fields */
export type NoPermanentWorkScheduleUpdateInput = {
  /** Array of objects containing minimum hours for each week in availability definition */
  cachedMinHoursPerWeek?: Maybe<Scalars["JSON"]>;
  /**
   * Association linking the availability definition to day groups containing available hours, fixed shifts definitions.
   * Anything provided will replace any existing available hours, fixed shifts.
   * Provide an empty array to delete existing and not create any new ones.
   */
  days?: Maybe<Array<AvailabilityDetailsByDayUpdateInput>>;
  /** Indicates if the linked employment created the availability */
  employeeInitiated?: Maybe<Scalars["Boolean"]>;
  /** Availability end date. Can be null */
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Time that manager acknowledged notification. Can be null */
  managerAcknowledgedNotificationAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /**
   * The max number of seconds per week for the whole availability definition. It
   * will be the same value for each week in a multi-week definition
   */
  maxDurationPerWeek?: Maybe<Scalars["Seconds"]>;
  /** How many weeks are in the availability definition */
  numberOfWeeks?: Maybe<Scalars["Int"]>;
  /** Temporary Availability repeat cycle */
  repeatCycle?: Maybe<TemporaryAvailabilityRepeatCycleEnum>;
  /** The message attached to the request if any */
  requestingMessage?: Maybe<Scalars["String"]>;
  /** The message attached to the response if any */
  respondingMessage?: Maybe<Scalars["String"]>;
  /** Availability start date. Defaults to date of tomorrow */
  startDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Status of availability */
  status?: Maybe<AvailabilityStatusEnum>;
  /** If true, employee is willing to work extra hours outside of fixed contract */
  willingToWorkExtraHours?: Maybe<Scalars["Boolean"]>;
};

/** On Cost Definition */
export type OnCostDefinition = {
  __typename?: "OnCostDefinition";
  /** Importer code */
  code?: Maybe<Scalars["String"]>;
  /** Time created at */
  createdAt: Scalars["ISO8601DateTime"];
  /** Created by user */
  createdById?: Maybe<Scalars["ID"]>;
  /** On cost definition description */
  description?: Maybe<Scalars["String"]>;
  /** External field */
  external: Scalars["Boolean"];
  /** On cost definition ID */
  id: Scalars["ID"];
  /** On cost definition name */
  name: Scalars["String"];
  /** dfsdfsdffsdf */
  timeboxes?: Maybe<Array<OnCostDefinitionTimebox>>;
  /** Last updated at */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Last updated by user */
  updatedById?: Maybe<Scalars["ID"]>;
};

/** The connection type for OnCostDefinition. */
export type OnCostDefinitionConnection = {
  __typename?: "OnCostDefinitionConnection";
  /** A list of edges. */
  edges: Array<OnCostDefinitionEdge>;
  /** A list of nodes. */
  nodes: Array<OnCostDefinition>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type OnCostDefinitionEdge = {
  __typename?: "OnCostDefinitionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<OnCostDefinition>;
};

/** On Cost Definition */
export type OnCostDefinitionTimebox = {
  __typename?: "OnCostDefinitionTimebox";
  /** Time created at */
  createdAt: Scalars["ISO8601DateTime"];
  /** Created by user */
  createdById?: Maybe<Scalars["ID"]>;
  /** Timebox end date */
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  /** On cost definition ID */
  id: Scalars["ID"];
  /** On cost definition timebox belongs to */
  onCostDefinitionId: Scalars["ID"];
  /** External field */
  onCosts: Scalars["JSON"];
  /** Timebox stand date */
  startDate: Scalars["ISO8601Date"];
  /** Last updated at */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Last updated by user */
  updatedById?: Maybe<Scalars["ID"]>;
};

export enum Order {
  /** Ascending */
  Asc = "asc",
  /** Descending */
  Desc = "desc",
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Internal Pay Period fields */
export type PayPeriod = {
  __typename?: "PayPeriod";
  /** Time pay period status was changed to approved */
  approvedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** ID of employment who set status to approved */
  approvedById?: Maybe<Scalars["ID"]>;
  /** Time pay period was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** End time for time range. Range is inclusive of end */
  endTime: Scalars["ISO8601DateTime"];
  /** Pay Period ID */
  id: Scalars["ID"];
  /** Status of pay period */
  payPeriodStatus: Scalars["String"];
  /** Time pay period status was changed to posted */
  postedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** ID of employment who set status to posted */
  postedById?: Maybe<Scalars["ID"]>;
  /** Time pay period status was changed to processed */
  processedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The schedule associated with this pay period */
  schedule: Schedule;
  /** Schedule ID */
  scheduleId: Scalars["ID"];
  /** Start time for time range. Range is inclusive of start */
  startTime: Scalars["ISO8601DateTime"];
  /** Pay period cached summary */
  summary?: Maybe<Scalars["JSON"]>;
  /** Time pay period was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Pay Period Approval Status values */
export enum PayPeriodApprovalStatus {
  /** Approved */
  Approved = "approved",
  /** Open */
  Open = "open",
  /** Posted */
  Posted = "posted",
  /** Processed */
  Processed = "processed",
}

/** The connection type for PayPeriod. */
export type PayPeriodConnection = {
  __typename?: "PayPeriodConnection";
  /** A list of edges. */
  edges: Array<PayPeriodEdge>;
  /** A list of nodes. */
  nodes: Array<PayPeriod>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type PayPeriodEdge = {
  __typename?: "PayPeriodEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<PayPeriod>;
};

/** Pay Period Sort fields */
export type PayPeriodSort = {
  /** Field to sort by */
  field: PayPeriodSortField;
  /** Sort order */
  order: Order;
};

/** Pay Period Sort Field values */
export enum PayPeriodSortField {
  /** End time of the pay period */
  EndTime = "endTime",
  /** Primary key */
  Id = "id",
  /** Pay period status */
  PayPeriodStatus = "payPeriodStatus",
  /** Start time of the pay period */
  StartTime = "startTime",
}

/** Pay Period Status values */
export enum PayPeriodStatus {
  /** Approved */
  Approved = "approved",
  /** Open */
  Open = "open",
  /** Posted */
  Posted = "posted",
  /** Processed */
  Processed = "processed",
}

/** Internal Pending employment Time Line fields */
export type PendingEmploymentTimeLine = {
  __typename?: "PendingEmploymentTimeLine";
  /** Pending employment time line creation time */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employment that pending employment time line is associated to */
  employmentId: Scalars["ID"];
  /** Pending employment time line end time */
  endDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Pending Employment Time Line ID */
  id: Scalars["ID"];
  /** Pending employment time line start time */
  startDateTime: Scalars["ISO8601DateTime"];
};

/** The connection type for PendingEmploymentTimeLine. */
export type PendingEmploymentTimeLineConnection = {
  __typename?: "PendingEmploymentTimeLineConnection";
  /** A list of edges. */
  edges: Array<PendingEmploymentTimeLineEdge>;
  /** A list of nodes. */
  nodes: Array<PendingEmploymentTimeLine>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type PendingEmploymentTimeLineEdge = {
  __typename?: "PendingEmploymentTimeLineEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<PendingEmploymentTimeLine>;
};

/** Pending employment Time Line Sort fields */
export type PendingEmploymentTimeLineSort = {
  /** Field to sort by */
  field: PendingEmploymentTimeLineSortField;
  /** Sort order */
  order: Order;
};

/** Pending Employment Time Line sort field values */
export enum PendingEmploymentTimeLineSortField {
  /** ID of the employment tied to the pending employment time line */
  EmploymentId = "employment_id",
  /** Primary key */
  Id = "id",
  /** Start timestamp of the pending employment time line */
  StartDateTime = "start_date_time",
}

/** Permission Map for SSO */
export type PermissionMapInput = {
  /** Permission Label */
  permission: AccessLevelEnum;
  /** Schedule Code */
  scheduleCode: Scalars["String"];
};

/** Internal System process fields */
export type Process = {
  __typename?: "Process";
  /** Information on process from /proc/$PID/status */
  status: Scalars["JSON"];
};

/** Puma webserver fields */
export type Puma = {
  __typename?: "Puma";
  /** Garbage collector stats for this worker process. */
  gcStats: Scalars["JSON"];
  /** Puma worker stats. */
  stats: Scalars["JSON"];
  /** Backtraces of all worker threads */
  threadBacktraces: Scalars["JSON"];
};

/** Internal Quarter fields */
export type Quarter = {
  __typename?: "Quarter";
  /** Actual quarter data */
  actual?: Maybe<Scalars["JSON"]>;
  /** Time quarter was computed */
  computedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Date time of quarter */
  dateTime: Scalars["ISO8601DateTime"];
  /** Forecast quarter data */
  forecast?: Maybe<Scalars["JSON"]>;
  /** ID of quarter */
  id: Scalars["ID"];
  /** Schedule ID of quarter */
  scheduleId: Scalars["ID"];
  /** Scheduled quarter data */
  scheduled?: Maybe<Scalars["JSON"]>;
};

/** The connection type for Quarter. */
export type QuarterConnection = {
  __typename?: "QuarterConnection";
  /** A list of edges. */
  edges: Array<QuarterEdge>;
  /** A list of nodes. */
  nodes: Array<Quarter>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type QuarterEdge = {
  __typename?: "QuarterEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Quarter>;
};

/** Rank change enum for encoding things like "move up" and "move down" in a list */
export enum RankChangeEnum {
  Decrease = "decrease",
  Increase = "increase",
}

/** Result fields */
export type Result = {
  __typename?: "Result";
  /** Whether execution was a success */
  success: Scalars["String"];
};

/** Internal Rule Set Type fields */
export type RuleSet = {
  __typename?: "RuleSet";
  contents: Array<RuleSetContents>;
  description: Scalars["String"];
  name: Scalars["String"];
};

/** Internal Rule Set Contents fields */
export type RuleSetContents = {
  __typename?: "RuleSetContents";
  level: Scalars["String"];
  version: Scalars["String"];
};

/** Internal Rule Set File fields */
export type RuleSetFile = {
  __typename?: "RuleSetFile";
  /** The content of the rule file */
  content: Scalars["String"];
};

/** Rule Set Subscription Status values */
export enum RuleSetSubscriptionStatusEnum {
  /** The schedule will never automatically update the rule set version it uses */
  NotSubscribed = "not_subscribed",
  /** The schedule will automatically update to the latest production rule set version in Rules Central Repository */
  Production = "production",
  /** The schedule will automatically update to the latest rule set version in Rules Central Repository */
  Staging = "staging",
}

/** Internal Rules input fields */
export type RulesInput = {
  __typename?: "RulesInput";
  /** The input data required for rules engine */
  inputData: Scalars["JSON"];
  /** The request body that would be sent to rules engine directly from backend */
  rulesRequestBody: Scalars["JSON"];
};

/** Internal Schedule fields */
export type Schedule = {
  __typename?: "Schedule";
  /** Indicates whether analytics are enabled for the schedule (provided it is enabled for the business). Default is false. */
  analyticsEnabled: Scalars["Boolean"];
  /** AOS Configuration. */
  aosConfig?: Maybe<AosConfig>;
  /** AOS Configuration Raw Data. */
  aosConfigRaw: Scalars["JSON"];
  /** AOS Configuration Schema. */
  aosConfigSchema: Scalars["JSON"];
  /** Indicates whether manager approval needed on early break end, Default is true */
  approvalNeededForEarlyBreakEnd: Scalars["Boolean"];
  /** Indicates whether manager approval needed on minor violations, Default is true */
  approvalNeededForMinorViolations: Scalars["Boolean"];
  /** Indicates whether manager approval needed on non compliant break, Default is true */
  approvalNeededForNonCompliantBreak: Scalars["Boolean"];
  /** Indicates whether manager approval needed on pin login, Default is true */
  approvalNeededForPinLogin: Scalars["Boolean"];
  /** Indicates whether manager approval needed on role rate change, Default is true */
  approvalNeededForRoleRateChange: Scalars["Boolean"];
  /** Indicates whether manager approval needed on shift change, Default is true */
  approvalNeededForShiftChange: Scalars["Boolean"];
  /** Indicates whether manager approval needed on unplanned shift, Default is true */
  approvalNeededForUnplannedShift: Scalars["Boolean"];
  /** Time GFE was created for schedule */
  autoGeneratedGfeAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Configuration params for auto generating GFE, containing keys: ["start_date", "at_hour"] */
  autoGfeConfig?: Maybe<Scalars["JSON"]>;
  /** Indicates whether GFE is generated automatically or not. Default is false. */
  autoGfeEnabled: Scalars["Boolean"];
  /** Time of the day the auto populate schedule job should be run. Default is 1. */
  autoPopulateScheduleAtTime: Scalars["Int"];
  /** How many days in advance to auto populate the schedule. Default is 14. */
  autoPopulateScheduleDaysInAdvance: Scalars["Int"];
  /** Indicates whether populate schedule jobs are run automatically on this schedule. Default is false. */
  autoPopulateScheduleEnabled: Scalars["Boolean"];
  /**
   * Configuration params for auto publishing the schedule, containing keys:
   * ["start_date", "at_hour", "warning_enabled", "warning_days",
   * "auto_publish_interval_days"]
   */
  autoPublishConfig?: Maybe<Scalars["JSON"]>;
  /** Indicates whether the schedule is published automatically or not. Default is false. */
  autoPublishEnabled: Scalars["Boolean"];
  /** ID of the business this schedule belongs to */
  businessId: Scalars["ID"];
  /** Minutes before the shift start time in which user can clock in */
  clockInThreshold?: Maybe<Scalars["Int"]>;
  /** Minutes after the shift end time in which user can clock out */
  clockOutThreshold?: Maybe<Scalars["Int"]>;
  /** Status of Shift clock-in/out tracking. Default is false. */
  clockTrackingEnabled: Scalars["Boolean"];
  /** 3rd party unique ID for the schedule */
  code?: Maybe<Scalars["String"]>;
  /** If configured, compliance warnings prior to this date will be hidden from the ui */
  complianceStartDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Cached start time computed from compliance_start_date using the schedules day_start_time and time_zone */
  complianceStartDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Time schedule created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Data lake schedule id (used by importer) */
  dataLakeId?: Maybe<Scalars["String"]>;
  /** Latest time of day to show in the Web UI daily view */
  dayEndTime?: Maybe<Scalars["String"]>;
  /** ID of schedule inherited day part */
  dayPartInheritedScheduleId?: Maybe<Scalars["ID"]>;
  /** Day part template to apply for this schedule */
  dayPartTemplateId?: Maybe<Scalars["ID"]>;
  /** Earliest time of day to show in the Web UI daily view */
  dayStartTime?: Maybe<Scalars["String"]>;
  /** Indicates if employee multi-rate is enabled. Default is false. */
  employeeMultiRateEnabled: Scalars["Boolean"];
  /** Index to indicate the first day of a work week, starting from Sunday (0) */
  firstDayOfWeek?: Maybe<Scalars["Int"]>;
  /**
   * The date this store will go live in LIFELENZ for employees HR.
   * The time the store will go live is based on the day_start_time of the schedule (eg. 4am).
   * If NULL, it is assumed the store is inactive.
   */
  hrGoLiveDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Schedule id */
  id: Scalars["ID"];
  /** Indicates if the browser should log the user out if there is :idle_timeout_length minutes of inactivity. Default is false. */
  idleTimeoutEnabled: Scalars["Boolean"];
  /** Length of inactivity in minutes before the user is logged out. Must be between 2 and 200. Default is 10. */
  idleTimeoutLength: Scalars["Int"];
  /** Whether the schedule is a group. Default is false. */
  isGroup: Scalars["Boolean"];
  /** Array of each day of the week (starting Sunday) with a labour-cost percentage, for use in auto-scheduling */
  labourCostPercentage?: Maybe<Array<Scalars["Float"]>>;
  /** Indicates whether auto-scheduling should use a labour-cost percentage. Default is false. */
  labourCostPercentageEnabled: Scalars["Boolean"];
  /** Locale for the schedule */
  locale?: Maybe<Scalars["String"]>;
  /** the last shift end time within schedule */
  maxShiftEndTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Cost loading amount. For example: \"2009.35\" */
  monthlyCostLoading: Scalars["Float"];
  /** Time to next auto publish the schedule */
  nextAutoPublishAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Earliest time to generate next GFE for schedule */
  nextGfeAutoGenerationAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Association on-cost definition for schedule */
  onCostDefinitionId?: Maybe<Scalars["ID"]>;
  /** Schedule ID of the parent schedule this schedule belongs to, or NULL if the schedule does not have a parent */
  parentId?: Maybe<Scalars["ID"]>;
  /** Start date of the pay cycle */
  payCycleStartDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Array of integer to determine the day 1 and 2 of bi-monthly pay period */
  payDates?: Maybe<Array<Scalars["Int"]>>;
  /** Pay frequency. Default is 'weekly' */
  payFrequency: Scalars["String"];
  /** Indicates whether pay period feature is enabled, Default is false */
  payPeriodEnabled: Scalars["Boolean"];
  /** Indicates whether pay period requires approval after posting. Default is false. */
  payPeriodRequiresApproval: Scalars["Boolean"];
  /** Payroll cutoff time. Default is nil */
  payrollCutoffTime?: Maybe<Scalars["String"]>;
  /** Config for async job that populate schedule */
  populateScheduleConfig: Scalars["JSON"];
  /** Indicates whether populate schedule jobs can run on this schedule. Default is false. */
  populateScheduleEnabled: Scalars["Boolean"];
  /** Indicates if populate schedule replanning is enabled. Default is false. */
  populateScheduleReplanningEnabled: Scalars["Boolean"];
  /** Status of async job that populate schedule */
  populateScheduleStatus: Scalars["JSON"];
  /** Business Office Location ID of the primary location this schedule belongs to */
  primaryBusinessOfficeLocationId?: Maybe<Scalars["ID"]>;
  /** The status of print punch slip mode. Default is optional. */
  punchSlipMode: Scalars["String"];
  /** Indicates whether dark or light rest screen theme, Default is provided from business config */
  restScreenTheme: Scalars["String"];
  /** Rule set that this schedule is associated with. (Legacy) */
  ruleSet?: Maybe<LegacyRuleSet>;
  /** ID of the rule set that this schedule is associated with. (Legacy) */
  ruleSetId?: Maybe<Scalars["ID"]>;
  /** Metadata of rule set this schedule belongs to */
  ruleSetMeta: Scalars["JSON"];
  /** Name of the rule set stored in Rules Central that this schedule is associated with */
  ruleSetName?: Maybe<Scalars["String"]>;
  /** Subscription status of rule set this schedule belongs to. Default is 0. */
  ruleSetSubscriptionStatus: RuleSetSubscriptionStatusEnum;
  /** Version of the rule set stored in Rules Central that this schedule is associated with */
  ruleSetVersion?: Maybe<Scalars["String"]>;
  /** The name of the schedule */
  scheduleName: Scalars["String"];
  /** The status of the schedule. Valid values are active and suspended. Default is active */
  scheduleStatus: Scalars["String"];
  /** Schedule settings for rules engine */
  settings?: Maybe<Scalars["JSON"]>;
  /** Allow shifts to overlap leave requests. Default is true. */
  shiftCanOverlapLeaveRequest: Scalars["Boolean"];
  /** Allow shifts to overlap unavailability. Default is true. */
  shiftCanOverlapUnavailability: Scalars["Boolean"];
  /** Indicates whether shift drop feature is enabled. Default is false. */
  shiftDropEnabled: Scalars["Boolean"];
  /** Enable schedule to support multi-rate shift, time clock. Default is false. */
  shiftMultiRateEnabled: Scalars["Boolean"];
  /** Minutes that shift will shown up on time clock rest screen, Default is 30 minutes */
  shiftShowUpThreshold: Scalars["Int"];
  /** Indicates whether shift swap feature is enabled. Default is false. */
  shiftSwapEnabled: Scalars["Boolean"];
  /** Indicates whether shift swap request needs approval. Effective only when shift swap feature is enabled. Default is true */
  shiftSwapNeedsApproval: Scalars["Boolean"];
  /** Indicates whether salary/earnings information should be displayed. Default is false. */
  showEarnings: Scalars["Boolean"];
  /** Duration of the schedule for analytics purposes */
  snapshotDuration?: Maybe<Scalars["Int"]>;
  /** Start date of the schedule sequence for analytics purposes */
  snapshotStartDate?: Maybe<Scalars["ISO8601Date"]>;
  /** formatted time clock pre shift message. */
  timeClockAppPreShiftFormattedMessages: TimeClockAppPreShiftFormattedShiftMessages;
  /** Rest screen settings for the time clock app. */
  timeClockAppRestScreenSettings: TimeClockAppRestScreenSettings;
  /** Time clock app workflow */
  timeClockAppWorkflow?: Maybe<Scalars["String"]>;
  /** The time in minutes that job rate switch is requested with manager authorize. Default is 10 */
  timeClockRoleRateChangeAuthThreshold: Scalars["Int"];
  /** Time zone of schedule */
  timeZone?: Maybe<Scalars["String"]>;
  /**
   * The date this store will go live in LIFELENZ for timekeeping.
   * At this time payroll, time, scheduling, publishing etc. will start flowing.
   */
  timekeepingGoLiveDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Week to auto publish schedule. */
  toBeAutoPublishedScheduleWeek?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Minutes before is considered as unplanned shift start, Default is 60 minutes */
  unplannedShiftStartThreshold: Scalars["Int"];
  /** Time business last updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Indicates whether biometrics login is enabled, Default is true */
  usesBiometrics: Scalars["Boolean"];
  /** Indicates whether pin login is enabled, Default is false */
  usesPin: Scalars["Boolean"];
  /** Indicates whether employees can voluntarily clock out late in the TimeClockApp. Default is false. */
  voluntaryLateClockOutEnabled: Scalars["Boolean"];
};

/** Schedule Break Type fields */
export type ScheduleBreakType = {
  __typename?: "ScheduleBreakType";
  /** Available range of the break. */
  availableRange?: Maybe<HoursMinutesRange>;
  /** Ordering of the break types in time clock "Select break type" screen. Must be unique */
  breakScreenOrdering: Scalars["Int"];
  /** Code of the break type */
  breakTypeCode: Scalars["String"];
  /** ID of the associated break type (if schedule break type) */
  breakTypeId: Scalars["ID"];
  /** If true, the paid / unpaid value of a time clock break can be modified, regardless of threshold */
  canOverrideClockedPaidBreak: Scalars["Boolean"];
  /** 3rd party code for the break type */
  code?: Maybe<Scalars["String"]>;
  /** Time the break type was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Default duration of the break in minutes. Can be nil to signify no default duration */
  defaultDuration?: Maybe<Scalars["Minutes"]>;
  /** Whether the break type has been soft deleted */
  deleted: Scalars["Boolean"];
  /** Description of the break type */
  description?: Maybe<Scalars["String"]>;
  /** Employment metadata used to determine break eligibility */
  employmentMetadata?: Maybe<Scalars["String"]>;
  /** Types of employee eligible for this break type, defaults to empty array. */
  employmentTypeCodes: Array<Scalars["String"]>;
  /** Types of employee eligible for this break type, defaults to empty array. */
  employmentTypeConfigs: Array<EmploymentTypeConfig>;
  /** Small ids of types of employee eligible for this break type, defaults to empty array. */
  employmentTypeSmallIds: Array<Scalars["Int"]>;
  /**
   * Legacy types of employee eligible for this break type, defaults to empty array.
   * @deprecated Use employmentTypeCodes or employmentTypeConfigs { nodes { employmentTypeCode } } instead.
   */
  employmentTypes: Array<EmploymentTypeEnum>;
  /** Whether the break type is hidden from the given schedule (if schedule break type). Defaults to false. */
  hidden: Scalars["Boolean"];
  /** Break Type ID */
  id: Scalars["ID"];
  /** Legacy break type this break is associated with. Unknown is allowed only for the default unknown break type. */
  legacyBreakType: LegacyBreakTypeEnum;
  /** Name of the break type */
  name: Scalars["String"];
  /** Duration above which break becomes unpaid */
  paidThreshold: Scalars["Minutes"];
  /** Whether the break type can be scheduled for a shift. */
  schedulable: Scalars["Boolean"];
  /**
   * If true, the paid / unpaid value of a time clock break can be modified,
   * regardless of threshold. Null implies we use the business level break type value
   */
  scheduleCanOverrideClockedPaidBreak?: Maybe<Scalars["Boolean"]>;
  /** ID of the associated schedule (if schedule break type) */
  scheduleId?: Maybe<Scalars["ID"]>;
  /** Whether the schedule has overwritten this break type */
  scheduleOverride: Scalars["Boolean"];
  /** Duration above which break becomes unpaid. Null implies we use the business level break type value */
  schedulePaidThreshold?: Maybe<Scalars["Minutes"]>;
  /** Time the break type was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for ScheduleBreakType. */
export type ScheduleBreakTypeConnection = {
  __typename?: "ScheduleBreakTypeConnection";
  /** A list of edges. */
  edges: Array<ScheduleBreakTypeEdge>;
  /** A list of nodes. */
  nodes: Array<ScheduleBreakType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** Internal Schedule Break Type Create input fields */
export type ScheduleBreakTypeCreateInput = {
  /** ID of the associated break type (if schedule break type) */
  breakTypeId: Scalars["ID"];
  /** If true, the paid / unpaid value of a time clock break can be modified, regardless of threshold */
  canOverrideClockedPaidBreak?: Maybe<Scalars["Boolean"]>;
  /** Whether the break type is hidden from the given schedule (if schedule break type). Defaults to false. */
  hidden?: Maybe<Scalars["Boolean"]>;
  /** Duration above which break becomes unpaid */
  paidThreshold?: Maybe<Scalars["Int"]>;
  /** ID of the associated schedule (if schedule break type) */
  scheduleId: Scalars["ID"];
};

/** An edge in a connection. */
export type ScheduleBreakTypeEdge = {
  __typename?: "ScheduleBreakTypeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ScheduleBreakType>;
};

/** Internal Schedule Break Type Update input fields */
export type ScheduleBreakTypeUpdateInput = {
  /** If true, the paid / unpaid value of a time clock break can be modified, regardless of threshold */
  canOverrideClockedPaidBreak?: Maybe<Scalars["Boolean"]>;
  /** Whether the break type is hidden from the given schedule (if schedule break type). Defaults to false. */
  hidden?: Maybe<Scalars["Boolean"]>;
  /** Duration above which break becomes unpaid */
  paidThreshold?: Maybe<Scalars["Minutes"]>;
};

/** The connection type for Schedule. */
export type ScheduleConnection = {
  __typename?: "ScheduleConnection";
  /** A list of edges. */
  edges: Array<ScheduleEdge>;
  /** A list of nodes. */
  nodes: Array<Schedule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ScheduleEdge = {
  __typename?: "ScheduleEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Schedule>;
};

/** Internal Schedule Role fields */
export type ScheduleRole = {
  __typename?: "ScheduleRole";
  businessRoleId: Scalars["ID"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  scheduleId: Scalars["ID"];
  threshold: Scalars["Int"];
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for ScheduleRole. */
export type ScheduleRoleConnection = {
  __typename?: "ScheduleRoleConnection";
  /** A list of edges. */
  edges: Array<ScheduleRoleEdge>;
  /** A list of nodes. */
  nodes: Array<ScheduleRole>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ScheduleRoleEdge = {
  __typename?: "ScheduleRoleEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ScheduleRole>;
};

/** Schedule Sort fields */
export type ScheduleSort = {
  /** Field to sort by */
  field: ScheduleSortField;
  /** Sort order */
  order: Order;
};

/** Schedule Sort Field values */
export enum ScheduleSortField {
  /** Primary key */
  Id = "id",
  /** name of the schedule */
  ScheduleName = "scheduleName",
}

/** Schedule target fields */
export type ScheduleTarget = {
  __typename?: "ScheduleTarget";
  /** Time schedule target created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Schedule Target ID */
  id: Scalars["ID"];
  /** Identifies the schedule date period this target is for. */
  scheduleTargetPeriodId: Scalars["ID"];
  /** Association of Set Value of the target */
  setValue: SetValue;
  /** The set which is associated with this value */
  setValueId: Scalars["ID"];
  /** Time schedule target last updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** The target value, must be positive */
  value: Scalars["Float"];
};

/** schedule target input fields */
export type ScheduleTargetBulkUpsertInput = {
  /** The end date for the target period (inclusive) */
  endDate: Scalars["ISO8601Date"];
  /** The start date for the target period */
  startDate: Scalars["ISO8601Date"];
  /** The code associated with the set value */
  targetCode: Scalars["String"];
  /** The target value, must be positive */
  targetValue: Scalars["Float"];
};

/** Schedule target period fields */
export type ScheduleTargetPeriod = {
  __typename?: "ScheduleTargetPeriod";
  /** Time schedule target period created */
  createdAt: Scalars["ISO8601DateTime"];
  /** The end date for the target period (inclusive) */
  endDate: Scalars["ISO8601Date"];
  /** Schedule Target Period ID */
  id: Scalars["ID"];
  /** Schedule ID */
  scheduleId: Scalars["ID"];
  /** Association of Schedule Target of the target period */
  scheduleTargets: Array<ScheduleTarget>;
  /** The start date for the target period */
  startDate: Scalars["ISO8601Date"];
  /** Time schedule target period last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for ScheduleTargetPeriod. */
export type ScheduleTargetPeriodConnection = {
  __typename?: "ScheduleTargetPeriodConnection";
  /** A list of edges. */
  edges: Array<ScheduleTargetPeriodEdge>;
  /** A list of nodes. */
  nodes: Array<ScheduleTargetPeriod>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ScheduleTargetPeriodEdge = {
  __typename?: "ScheduleTargetPeriodEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ScheduleTargetPeriod>;
};

/** Internal Schedule input fields */
export type ScheduleUpdateInput = {
  /** Indicates whether analytics are enabled for the schedule (provided it is enabled for the business). Default is false. */
  analyticsEnabled?: Maybe<Scalars["Boolean"]>;
  /** AOS Configuration */
  aosConfig?: Maybe<AosConfigInput>;
  /** Indicates whether manager approval needed on early break end, Default is true */
  approvalNeededForEarlyBreakEnd?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether manager approval needed on minor violations, Default is true */
  approvalNeededForMinorViolations?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether manager approval needed on non compliant break, Default is true */
  approvalNeededForNonCompliantBreak?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether manager approval needed on pin login, Default is true */
  approvalNeededForPinLogin?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether manager approval needed on role rate change, Default is true */
  approvalNeededForRoleRateChange?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether manager approval needed on shift change, Default is true */
  approvalNeededForShiftChange?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether manager approval needed on unplanned shift, Default is true */
  approvalNeededForUnplannedShift?: Maybe<Scalars["Boolean"]>;
  /** Configuration params for auto generating GFE, containing keys: ["start_date", "at_hour"] */
  autoGfeConfig?: Maybe<Scalars["JSON"]>;
  /** Indicates whether GFE is generated automatically or not. Default is false. */
  autoGfeEnabled?: Maybe<Scalars["Boolean"]>;
  /** Time of the day the auto populate schedule job should be run. Default is 1. */
  autoPopulateScheduleAtTime?: Maybe<Scalars["Int"]>;
  /** How many days in advance to auto populate the schedule. Default is 14. */
  autoPopulateScheduleDaysInAdvance?: Maybe<Scalars["Int"]>;
  /** Indicates whether populate schedule jobs are run automatically on this schedule. Default is false. */
  autoPopulateScheduleEnabled?: Maybe<Scalars["Boolean"]>;
  /**
   * Configuration params for auto publishing the schedule, containing keys:
   * ["start_date", "at_hour", "warning_enabled", "warning_days",
   * "auto_publish_interval_days"]
   */
  autoPublishConfig?: Maybe<Scalars["JSON"]>;
  /** Indicates whether the schedule is published automatically or not. Default is false. */
  autoPublishEnabled?: Maybe<Scalars["Boolean"]>;
  /** Minutes before the shift start time in which user can clock in */
  clockInThreshold?: Maybe<Scalars["Int"]>;
  /** Minutes after the shift end time in which user can clock out */
  clockOutThreshold?: Maybe<Scalars["Int"]>;
  /** Status of Shift clock-in/out tracking. Default is false. */
  clockTrackingEnabled?: Maybe<Scalars["Boolean"]>;
  /** 3rd party unique ID for the schedule */
  code?: Maybe<Scalars["String"]>;
  /** If configured, compliance warnings prior to this date will be hidden from the ui */
  complianceStartDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Data lake schedule id (used by importer) */
  dataLakeId?: Maybe<Scalars["String"]>;
  /** Latest time of day to show in the Web UI daily view */
  dayEndTime?: Maybe<Scalars["String"]>;
  /** ID of schedule inherited day part */
  dayPartInheritedScheduleId?: Maybe<Scalars["ID"]>;
  /** Day part template to apply for this schedule */
  dayPartTemplateId?: Maybe<Scalars["ID"]>;
  /** Earliest time of day to show in the Web UI daily view */
  dayStartTime?: Maybe<Scalars["String"]>;
  /** Indicates if employee multi-rate is enabled. Default is false. */
  employeeMultiRateEnabled?: Maybe<Scalars["Boolean"]>;
  /** Index to indicate the first day of a work week, starting from Sunday (0) */
  firstDayOfWeek?: Maybe<Scalars["Int"]>;
  /**
   * The date this store will go live in LIFELENZ for employees HR.
   * The time the store will go live is based on the day_start_time of the schedule (eg. 4am).
   * If NULL, it is assumed the store is inactive.
   */
  hrGoLiveDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Indicates if the browser should log the user out if there is :idle_timeout_length minutes of inactivity. Default is false. */
  idleTimeoutEnabled?: Maybe<Scalars["Boolean"]>;
  /** Length of inactivity in minutes before the user is logged out. Must be between 2 and 200. Default is 10. */
  idleTimeoutLength?: Maybe<Scalars["Int"]>;
  /** Array of each day of the week (starting Sunday) with a labour-cost percentage, for use in auto-scheduling */
  labourCostPercentage?: Maybe<Array<Scalars["Float"]>>;
  /** Indicates whether auto-scheduling should use a labour-cost percentage. Default is false. */
  labourCostPercentageEnabled?: Maybe<Scalars["Boolean"]>;
  /** Locale for the schedule */
  locale?: Maybe<Scalars["String"]>;
  /** Cost loading amount. For example: \"2009.35\" */
  monthlyCostLoading?: Maybe<Scalars["Float"]>;
  /** Association on-cost definition for schedule */
  onCostDefinitionId?: Maybe<Scalars["ID"]>;
  /** Start date of the pay cycle */
  payCycleStartDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Array of integer to determine the day 1 and 2 of bi-monthly pay period */
  payDates?: Maybe<Array<Scalars["Int"]>>;
  /** Pay frequency. Default is 'weekly' */
  payFrequency?: Maybe<Scalars["String"]>;
  /** Indicates whether pay period feature is enabled, Default is false */
  payPeriodEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether pay period requires approval after posting. Default is false. */
  payPeriodRequiresApproval?: Maybe<Scalars["Boolean"]>;
  /** Payroll cutoff time. Default is nil */
  payrollCutoffTime?: Maybe<Scalars["String"]>;
  /** Config for async job that populate schedule */
  populateScheduleConfig?: Maybe<Scalars["JSON"]>;
  /** Indicates whether populate schedule jobs can run on this schedule. Default is false. */
  populateScheduleEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates if populate schedule replanning is enabled. Default is false. */
  populateScheduleReplanningEnabled?: Maybe<Scalars["Boolean"]>;
  /** The status of print punch slip mode. Default is optional. */
  punchSlipMode?: Maybe<Scalars["String"]>;
  /** Indicates whether dark or light rest screen theme, Default is provided from business config */
  restScreenTheme?: Maybe<Scalars["String"]>;
  /** ID of the rule set that this schedule is associated with. (Legacy) */
  ruleSetId?: Maybe<Scalars["ID"]>;
  /** The name of the schedule */
  scheduleName?: Maybe<Scalars["String"]>;
  /** The status of the schedule. Valid values are active and suspended. Default is active */
  scheduleStatus?: Maybe<Scalars["String"]>;
  /** Schedule settings for rules engine */
  settings?: Maybe<Scalars["JSON"]>;
  /** Allow shifts to overlap leave requests. Default is true. */
  shiftCanOverlapLeaveRequest?: Maybe<Scalars["Boolean"]>;
  /** Allow shifts to overlap unavailability. Default is true. */
  shiftCanOverlapUnavailability?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether shift drop feature is enabled. Default is false. */
  shiftDropEnabled?: Maybe<Scalars["Boolean"]>;
  /** Enable schedule to support multi-rate shift, time clock. Default is false. */
  shiftMultiRateEnabled?: Maybe<Scalars["Boolean"]>;
  /** Minutes that shift will shown up on time clock rest screen, Default is 30 minutes */
  shiftShowUpThreshold?: Maybe<Scalars["Int"]>;
  /** Indicates whether shift swap feature is enabled. Default is false. */
  shiftSwapEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether shift swap request needs approval. Effective only when shift swap feature is enabled. Default is true */
  shiftSwapNeedsApproval?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether salary/earnings information should be displayed. Default is false. */
  showEarnings?: Maybe<Scalars["Boolean"]>;
  /** Duration of the schedule for analytics purposes */
  snapshotDuration?: Maybe<Scalars["Int"]>;
  /** Start date of the schedule sequence for analytics purposes */
  snapshotStartDate?: Maybe<Scalars["ISO8601Date"]>;
  /** formatted time clock message. */
  timeClockAppPreShiftFormattedMessages?: Maybe<TimeClockAppPreShiftFormattedShiftMessagesInput>;
  /** Rest screen settings for the time clock app. */
  timeClockAppRestScreenSettings?: Maybe<TimeClockAppRestScreenSettingsInput>;
  /** Time clock app workflow */
  timeClockAppWorkflow?: Maybe<Scalars["String"]>;
  /** The time in minutes that job rate switch is requested with manager authorize. Default is 10 */
  timeClockRoleRateChangeAuthThreshold?: Maybe<Scalars["Int"]>;
  /**
   * The date this store will go live in LIFELENZ for timekeeping.
   * At this time payroll, time, scheduling, publishing etc. will start flowing.
   */
  timekeepingGoLiveDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Minutes before is considered as unplanned shift start, Default is 60 minutes */
  unplannedShiftStartThreshold?: Maybe<Scalars["Int"]>;
  /** Indicates whether biometrics login is enabled, Default is true */
  usesBiometrics?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether pin login is enabled, Default is false */
  usesPin?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether employees can voluntarily clock out late in the TimeClockApp. Default is false. */
  voluntaryLateClockOutEnabled?: Maybe<Scalars["Boolean"]>;
};

/** Internal Schedule Week fields */
export type ScheduleWeek = {
  __typename?: "ScheduleWeek";
  /** The time that the shifts in the week were added by AOS. If null, AOS has not run on this week */
  autoPopulatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Time schedule week auto-published */
  autoPublishedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  autoPublishedMeta?: Maybe<Scalars["JSON"]>;
  /** Time schedule week created */
  createdAt: Scalars["ISO8601DateTime"];
  /** End time for Schedule Week. Range is inclusive of end. */
  endTime: Scalars["ISO8601DateTime"];
  /** Schedule Week ID */
  id: Scalars["ID"];
  /** The latest aos job result. For debugging. */
  populateScheduleMeta?: Maybe<Scalars["JSON"]>;
  /** Schedule ID */
  scheduleId: Scalars["ID"];
  /** Start time for Schedule Week. Range is inclusive of start. */
  startTime: Scalars["ISO8601DateTime"];
  /** Time schedule week last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for ScheduleWeek. */
export type ScheduleWeekConnection = {
  __typename?: "ScheduleWeekConnection";
  /** A list of edges. */
  edges: Array<ScheduleWeekEdge>;
  /** A list of nodes. */
  nodes: Array<ScheduleWeek>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ScheduleWeekEdge = {
  __typename?: "ScheduleWeekEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ScheduleWeek>;
};

/** Schedule Week Template fields */
export type ScheduleWeekTemplate = {
  __typename?: "ScheduleWeekTemplate";
  /** Schedule week template creation date */
  createdAt: Scalars["ISO8601DateTime"];
  /** Schedule week template ID */
  id: Scalars["ID"];
  /** Schedule week template name */
  name: Scalars["String"];
  /** Schedule ID */
  scheduleId: Scalars["ID"];
  /** Schedule week template update date */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Schedule week template last updated by ID */
  updatedById: Scalars["ID"];
  /** Whether schedule week template is used by AOS */
  usedByAos: Scalars["Boolean"];
};

/** The connection type for ScheduleWeekTemplate. */
export type ScheduleWeekTemplateConnection = {
  __typename?: "ScheduleWeekTemplateConnection";
  /** A list of edges. */
  edges: Array<ScheduleWeekTemplateEdge>;
  /** A list of nodes. */
  nodes: Array<ScheduleWeekTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ScheduleWeekTemplateEdge = {
  __typename?: "ScheduleWeekTemplateEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ScheduleWeekTemplate>;
};

/** Schema values */
export enum Schema {
  /** Employee Schema to be used by Mobile App */
  Employee = "employee",
  /** Internal Schema */
  Internal = "internal",
  /** Manager Schema to be used by WebUI and MMA */
  Manager = "manager",
}

/** Internal School fields */
export type School = {
  __typename?: "School";
  /** Time School created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Days of the week for School period */
  daysOfWeek?: Maybe<Array<Scalars["String"]>>;
  /** End time of day for School */
  endTimeOfDay?: Maybe<Scalars["String"]>;
  /** School ID */
  id: Scalars["ID"];
  /** Name of School */
  name: Scalars["String"];
  /** Schedule ID */
  scheduleId: Scalars["ID"];
  schoolHolidays: Array<SchoolHoliday>;
  /** Start Time for School */
  startTimeOfDay?: Maybe<Scalars["String"]>;
  /** Time Zone of School. Default - Australia/Adelaide */
  timeZone?: Maybe<Scalars["String"]>;
  /** Time School was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Internal School fields */
export type SchoolSchoolHolidaysArgs = {
  endDate: Scalars["String"];
  startDate: Scalars["String"];
};

/** The connection type for School. */
export type SchoolConnection = {
  __typename?: "SchoolConnection";
  /** A list of edges. */
  edges: Array<SchoolEdge>;
  /** A list of nodes. */
  nodes: Array<School>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type SchoolEdge = {
  __typename?: "SchoolEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<School>;
};

/** Internal School Holiday fields */
export type SchoolHoliday = {
  __typename?: "SchoolHoliday";
  /** Time School Holiday created */
  createdAt: Scalars["ISO8601DateTime"];
  /** School Holiday End Date */
  endDate?: Maybe<Scalars["String"]>;
  /** School Holiday End Time */
  endTimeOfDay?: Maybe<Scalars["String"]>;
  /** School Holiday ID */
  id: Scalars["ID"];
  /** Name of School Holiday */
  name: Scalars["String"];
  /** School ID */
  schoolId: Scalars["ID"];
  /** School Holiday Start Date */
  startDate?: Maybe<Scalars["String"]>;
  /** School Holiday Start Time */
  startTimeOfDay?: Maybe<Scalars["String"]>;
  /** Time School Holiday was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for SchoolHoliday. */
export type SchoolHolidayConnection = {
  __typename?: "SchoolHolidayConnection";
  /** A list of edges. */
  edges: Array<SchoolHolidayEdge>;
  /** A list of nodes. */
  nodes: Array<SchoolHoliday>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type SchoolHolidayEdge = {
  __typename?: "SchoolHolidayEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<SchoolHoliday>;
};

/** Screen code type values for KPI */
export enum ScreenCodeEnum {
  Schedule = "schedule",
  Timekeeping = "timekeeping",
}

/** Set Value fields */
export type SetName = {
  __typename?: "SetName";
  /** Time set name created */
  createdAt: Scalars["ISO8601DateTime"];
  /** The description of the configured set */
  description: Scalars["String"];
  /** value ID */
  id: Scalars["ID"];
  /** The name of the configured set */
  name: Scalars["String"];
  /** Time set name last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for SetName. */
export type SetNameConnection = {
  __typename?: "SetNameConnection";
  /** A list of edges. */
  edges: Array<SetNameEdge>;
  /** A list of nodes. */
  nodes: Array<SetName>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type SetNameEdge = {
  __typename?: "SetNameEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<SetName>;
};

/** Set Value fields */
export type SetValue = {
  __typename?: "SetValue";
  /** The code associated with the set value */
  code: Scalars["String"];
  /** Time set value created */
  createdAt: Scalars["ISO8601DateTime"];
  /** The description of the set value */
  description: Scalars["String"];
  /** Contains any additional details that relate to the value */
  details?: Maybe<Scalars["JSON"]>;
  /** Defines the order in which the items in the set should be displayed */
  displayOrder: Scalars["Int"];
  /** value ID */
  id: Scalars["ID"];
  /** Association of Set Name of the Set value */
  setName: SetName;
  /** The set which is associated with this value. */
  setNameId: Scalars["ID"];
  /** Time set value last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for SetValue. */
export type SetValueConnection = {
  __typename?: "SetValueConnection";
  /** A list of edges. */
  edges: Array<SetValueEdge>;
  /** A list of nodes. */
  nodes: Array<SetValue>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** Internal Set Value Create input fields */
export type SetValueCreateInput = {
  /** The code associated with the set value */
  code: Scalars["String"];
  /** The description of the set value */
  description: Scalars["String"];
  /** Contains any additional details that relate to the value */
  details?: Maybe<Scalars["JSON"]>;
  /** Defines the order in which the items in the set should be displayed */
  displayOrder?: Maybe<Scalars["Int"]>;
  /** The set which is associated with this value. */
  setNameId: Scalars["ID"];
};

/** An edge in a connection. */
export type SetValueEdge = {
  __typename?: "SetValueEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<SetValue>;
};

/** Set Value Sort fields */
export type SetValueSort = {
  /** Field to sort by */
  field: SetValueSortField;
  /** Sort order */
  order: Order;
};

/** Set Value Sort Field values */
export enum SetValueSortField {
  /** Code of the set value */
  Code = "code",
  /** Primary key */
  Id = "id",
  /** Set Name ID */
  SetNameId = "setNameId",
}

/** Internal Set Value Update input fields */
export type SetValueUpdateInput = {
  /** The code associated with the set value */
  code?: Maybe<Scalars["String"]>;
  /** The description of the set value */
  description?: Maybe<Scalars["String"]>;
  /** Contains any additional details that relate to the value */
  details?: Maybe<Scalars["JSON"]>;
  /** Defines the order in which the items in the set should be displayed */
  displayOrder?: Maybe<Scalars["Int"]>;
  /** The set which is associated with this value. */
  setNameId?: Maybe<Scalars["ID"]>;
};

/** Internal Shift fields */
export type Shift = {
  __typename?: "Shift";
  /**
   * Contains the pivot metrics for additional payments for the shift. This will be
   * used for non-differential payments (such as laundry allowance)
   * Notes: They should have the same structure as the existing pivot_metrics field, with the following additions:
   *  - Entries may be one-off payments, which will be 0 length pivots at the start
   * of the shift with a earnings value of the one off payment
   *  - Entries may overlap - as there may be multiple payments from different sources.
   *  - paycode will be used to store the additional pay code.
   */
  additionalPivotMetrics?: Maybe<Scalars["JSON"]>;
  /** Shift assigned employee ID */
  assignedEmploymentId?: Maybe<Scalars["ID"]>;
  /** Shift assignment status */
  assignmentStatus: ShiftAssignmentStatusEnum;
  /** Business office location ID */
  businessOfficeLocationId: Scalars["ID"];
  /** 3rd party unique ID for the shift */
  code?: Maybe<Scalars["String"]>;
  /** Shift Consent Needed Offer ID */
  consentNeededOfferId?: Maybe<Scalars["ID"]>;
  /** Time shift created */
  createdAt: Scalars["ISO8601DateTime"];
  /**
   * Contains the pivot metrics for differential payments for the shift.
   * Notes: They should have the same structure as the existing pivot_metrics field, with the following additions:
   *  - Entries may be one-off payments, which will be 0 length pivots at the start
   * of the shift with a earnings value of the one off payment
   *  - Entries may overlap - as there may be multiple payments from different
   * sources - note will not currently occur as we only allow on differential to trigger
   *  - paycode will be used to store the differential code.
   *  - differential_id guid that caused the payment will be added
   */
  differentialPivotMetrics?: Maybe<Scalars["JSON"]>;
  /** Time shift drop created */
  dropCreatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Drop status of shift */
  dropStatus: Scalars["String"];
  /** Employment IDs */
  employmentIds: Scalars["JSON"];
  /** The total of the additional_pivot_metrics. Value is not included in 'expected_earnings' field */
  expectedAdditionalEarnings?: Maybe<Scalars["Currency"]>;
  /** The total of the differential_pivot_metrics. Value is not included in 'expected_earnings' field */
  expectedDifferentialEarnings?: Maybe<Scalars["Currency"]>;
  /** Shift expected earnings */
  expectedEarnings?: Maybe<Scalars["Currency"]>;
  /** Shift expected hours */
  expectedHours?: Maybe<Scalars["Hours"]>;
  /** Expected seconds of shift */
  expectedSeconds: Scalars["Int"];
  /** Fixed shift ID that it is created from */
  fixedShiftId?: Maybe<Scalars["ID"]>;
  /** Indicated the shift has been modified to differ from the fixed shift start time or end time. Default: false. */
  fixedShiftModified: Scalars["Boolean"];
  /** Shift ID */
  id: Scalars["ID"];
  /** Shift absent detail */
  isAbsent: Scalars["Boolean"];
  /** Whether shift is locked */
  locked: Scalars["Boolean"];
  /** Pivot metrics for shift */
  pivotMetrics?: Maybe<Scalars["JSON"]>;
  /** An array of planned breaks for the shift */
  plannedBreaks?: Maybe<Scalars["JSON"]>;
  /** An array of planned business roles for the shift (business_role_id and start_sec) */
  plannedRoles: Scalars["JSON"];
  /** Shift published datetime */
  publishedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Publishable status of the shift. Default is unpublished */
  publishedStatus: ShiftPublishedStatusEnum;
  /** Queued employment code */
  queuedEmploymentCode?: Maybe<Scalars["String"]>;
  /** An array of Employment IDs, defining which employees will be offered this shift and in which order */
  queuedEmploymentIds?: Maybe<Array<Scalars["ID"]>>;
  /** Rate preference index of the shift */
  ratePreferenceIndex: Scalars["Int"];
  /** Time shift reminder */
  reminderTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Schedule ID */
  scheduleId: Scalars["ID"];
  /** Description of the shift */
  shiftDescription?: Maybe<Scalars["String"]>;
  /** Association of employments associated with this shift */
  shiftEmployments: Array<ShiftEmployment>;
  /** The end time of the shift */
  shiftEndTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The start time of the shift */
  shiftStartTime: Scalars["ISO8601DateTime"];
  /** Title of the shift */
  shiftTitle?: Maybe<Scalars["String"]>;
  /** The shift type */
  shiftType: Scalars["String"];
  /** Shift submission status */
  submissionStatus: ShiftSubmissionStatusEnum;
  /** ID of the time off type the employee is on during this shift */
  timeOffTypeId?: Maybe<Scalars["ID"]>;
  /** Time shift last updated */
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** High precision time shift last updated */
  updatedAtHighPrecision: Scalars["ISO8601DateTimeHighPrecision"];
};

/** Shift Assignment Status values */
export enum ShiftAssignmentStatusEnum {
  Accepted = "accepted",
  InQueue = "in_queue",
  NotApplicable = "not_applicable",
  Offered = "offered",
  Rejected = "rejected",
  Unpublished = "unpublished",
}

/** The connection type for Shift. */
export type ShiftConnection = {
  __typename?: "ShiftConnection";
  /** A list of edges. */
  edges: Array<ShiftEdge>;
  /** A list of nodes. */
  nodes: Array<Shift>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ShiftEdge = {
  __typename?: "ShiftEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Shift>;
};

/** Internal Shift Employment fields */
export type ShiftEmployment = {
  __typename?: "ShiftEmployment";
  /**
   * Contains the pivot metrics for additional payments for the shift employment.
   * This will be used for non-differential payments (such as laundry allowance)
   * Notes: They should have the same structure as the existing pivot_metrics field, with the following additions:
   *  - Entries may be one-off payments, which will be 0 length pivots at the start
   * of the shift with a earnings value of the one off payment
   *  - Entries may overlap - as there may be multiple payments from different sources.
   *  - paycode will be used to store the additional pay code.
   */
  additionalPivotMetrics?: Maybe<Scalars["JSON"]>;
  /** Time shift employment created */
  createdAt: Scalars["ISO8601DateTime"];
  /**
   * Contains the pivot metrics for differential payments for the shift employment.
   * Notes: They should have the same structure as the existing pivot_metrics field, with the following additions:
   *  - Entries may be one-off payments, which will be 0 length pivots at the start
   * of the shift with a earnings value of the one off payment
   *  - Entries may overlap - as there may be multiple payments from different
   * sources - note will not currently occur as we only allow on differential to trigger
   *  - paycode will be used to store the differential code.
   *  - differential_id guid that caused the payment will be added
   */
  differentialPivotMetrics?: Maybe<Scalars["JSON"]>;
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** The total of the additional_pivot_metrics. Value is not included in 'expected_earnings' field */
  expectedAdditionalEarnings?: Maybe<Scalars["Float"]>;
  /** The total of the differential_pivot_metrics. Value is not included in 'expected_earnings' field */
  expectedDifferentialEarnings?: Maybe<Scalars["Float"]>;
  /** Expected earnings of shift for employment */
  expectedEarnings: Scalars["Float"];
  /** Shift Employment ID */
  id: Scalars["ID"];
  /** Offer status of Shift Employment */
  offerStatus: ShiftOfferStatusEnum;
  /** Whether estimates are pending for Shift Employment */
  pendingEstimates: Scalars["Boolean"];
  /** Pivot metrics for Shift Employment */
  pivotMetrics?: Maybe<Scalars["JSON"]>;
  /** Position of employment in employment queue */
  queuePosition?: Maybe<Scalars["Int"]>;
  /** Type of queue Shift Employment is in */
  queueType: Scalars["String"];
  /** Time offer status changed to accepted or rejected */
  respondedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Shift ID */
  shiftId: Scalars["ID"];
  /** Time shift employment last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Shift Offer Status values */
export enum ShiftOfferStatusEnum {
  Accepted = "accepted",
  PendingResponse = "pending_response",
  Rejected = "rejected",
}

/** Shift published status */
export enum ShiftPublishedStatusEnum {
  NotApplicable = "not_applicable",
  Published = "published",
  Unpublished = "unpublished",
}

/** Shift Sort fields */
export type ShiftSort = {
  /** Field to sort by */
  field: ShiftSortField;
  /** Sort order */
  order: Order;
};

/** Shift Sort Field values */
export enum ShiftSortField {
  /** Primary key */
  Id = "id",
  /** Sorts shifts by whether they partially overlap the given time frame or are completely covered */
  IsPartiallyOverlappingTimeFrame = "isPartiallyOverlappingTimeFrame",
  /** Sorts time_off shifts and non time_off shifts */
  IsTimeOffShift = "isTimeOffShift",
  /** end time of the shift */
  ShiftEndTime = "shiftEndTime",
  /** start time of the shift */
  ShiftStartTime = "shiftStartTime",
  /** title of the shift */
  ShiftTitle = "shiftTitle",
}

/** Shift Submission Status values */
export enum ShiftSubmissionStatusEnum {
  Approved = "approved",
  Rejected = "rejected",
  Submitted = "submitted",
  Unsubmitted = "unsubmitted",
}

/** Internal Shift Template fields */
export type ShiftTemplate = {
  __typename?: "ShiftTemplate";
  /** The ID of the employment assigned to shifts created from this template */
  assignedEmploymentId?: Maybe<Scalars["ID"]>;
  /** 3rd party unique ID for the template */
  code?: Maybe<Scalars["String"]>;
  /** Time shift template created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Description of the shift template */
  description?: Maybe<Scalars["String"]>;
  /** Shift Template ID */
  id: Scalars["ID"];
  /** The name of the shift template */
  name: Scalars["String"];
  /** The planned breaks of shifts created from this template */
  plannedBreaks: Scalars["JSON"];
  /** The planned roles of shifts created from this template */
  plannedRoles: Scalars["JSON"];
  /** The ID of the schedule week template this shift template belongs to */
  scheduleWeekTemplateId?: Maybe<Scalars["ID"]>;
  /** Description of shifts created from this template */
  shiftDescription?: Maybe<Scalars["String"]>;
  /** End time of shifts created from this template */
  shiftEndTime: Scalars["ISO8601DateTime"];
  /** Start time of shifts created from this template */
  shiftStartTime: Scalars["ISO8601DateTime"];
  /** The shift template category ID */
  shiftTemplateCategoryId: Scalars["ID"];
  /** Title of shifts created from this template */
  shiftTitle?: Maybe<Scalars["String"]>;
  /** Type of shifts created from this template */
  shiftType: Scalars["String"];
  /** Time shift template last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Internal Shift Template Category fields */
export type ShiftTemplateCategory = {
  __typename?: "ShiftTemplateCategory";
  /** 3rd party unique ID for the role category */
  code?: Maybe<Scalars["String"]>;
  /** Time shift template category created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Shift Template Category ID */
  id: Scalars["ID"];
  /** The name of the shift template category */
  name?: Maybe<Scalars["String"]>;
  /** Ordering position within sibling categories. Default category will be added to last position */
  order: Scalars["Int"];
  /** Shift Template Category ID of the parent category this category belongs to, or NULL if the category does not have a parent */
  parentId?: Maybe<Scalars["ID"]>;
  /** The schedule that the Shift Template Category is associated with */
  scheduleId: Scalars["ID"];
  /** Time shift template category last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for ShiftTemplateCategory. */
export type ShiftTemplateCategoryConnection = {
  __typename?: "ShiftTemplateCategoryConnection";
  /** A list of edges. */
  edges: Array<ShiftTemplateCategoryEdge>;
  /** A list of nodes. */
  nodes: Array<ShiftTemplateCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ShiftTemplateCategoryEdge = {
  __typename?: "ShiftTemplateCategoryEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ShiftTemplateCategory>;
};

/** Shift Template Category Sort fields */
export type ShiftTemplateCategorySort = {
  /** Field to sort by */
  field: ShiftTemplateCategorySortField;
  /** Sort order */
  order: Order;
};

/** Shift Template Category Sort Field values */
export enum ShiftTemplateCategorySortField {
  /** Identifier of the category in 3rd party systems */
  Code = "code",
  /** Primary key */
  Id = "id",
  /** Order of the category among it's siblings */
  Order = "order",
  /** ID of the parent shift template category (may be null) */
  ParentId = "parentId",
}

/** The connection type for ShiftTemplate. */
export type ShiftTemplateConnection = {
  __typename?: "ShiftTemplateConnection";
  /** A list of edges. */
  edges: Array<ShiftTemplateEdge>;
  /** A list of nodes. */
  nodes: Array<ShiftTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ShiftTemplateEdge = {
  __typename?: "ShiftTemplateEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ShiftTemplate>;
};

/** Shift Template Sort fields */
export type ShiftTemplateSort = {
  /** Field to sort by */
  field: ShiftTemplateSortField;
  /** Sort order */
  order: Order;
};

/** Shift Template Sort Field values */
export enum ShiftTemplateSortField {
  /** ID of the employment assigned to shifts created by the template */
  AssignedEmploymentId = "assignedEmploymentId",
  /** Identifier of the category in 3rd party systems */
  Code = "code",
  /** Primary key */
  Id = "id",
  /** ID of the category the shift template belongs to */
  ShiftTemplateCategoryId = "shiftTemplateCategoryId",
}

/** Shift Type values */
export enum ShiftTypeEnum {
  Offer = "offer",
  OfferToAll = "offer_to_all",
  Roster = "roster",
  TimeOff = "time_off",
}

/** Manager Employment Role Skill Level fields */
export type SkillLevel = {
  __typename?: "SkillLevel";
  /** Represents the skill level as AOS sees it, null means AOS will never make a shift assignment involving this skill level */
  aosLevel?: Maybe<Scalars["Int"]>;
  /** Action to take when a shift assignment is made that involves an employment operating at this skill level */
  assignmentAction: SkillLevelAssignmentActionEnum;
  /** Time skill level was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Skill Level ID */
  id: Scalars["ID"];
  /** When true, the absence of an skill level implies this skill level. Exactly one skill level may be the default. */
  isDefault: Scalars["Boolean"];
  /** A short notation of the skill level, typically a digit, but could be a letter or a short code */
  level: Scalars["String"];
  /** The name of the skill level, typically a word or phrase such as "Excellent" */
  name: Scalars["String"];
  /**
   * Represents the order of the skill level among its
   * peers. Used for sorting, where the lowest rank is
   * the "worst" skill level and normally appears
   * first. This attribute cannot be set directly or
   * used for lookup and can change, so it should only
   * be used to position a skill level in order with
   * other skill levels that were fetched at the same time.
   */
  rank: Scalars["Int"];
  /** Time skill level was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Assignment actions for a skill level */
export enum SkillLevelAssignmentActionEnum {
  None = "none",
  Warn = "warn",
}

/** The connection type for SkillLevel. */
export type SkillLevelConnection = {
  __typename?: "SkillLevelConnection";
  /** A list of edges. */
  edges: Array<SkillLevelEdge>;
  /** A list of nodes. */
  nodes: Array<SkillLevel>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type SkillLevelEdge = {
  __typename?: "SkillLevelEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<SkillLevel>;
};

/** Skill Level input type */
export type SkillLevelInput = {
  /** Represents the skill level as AOS sees it, null means AOS will never make a shift assignment involving this skill level */
  aosLevel?: Maybe<Scalars["Int"]>;
  /** Action to take when a shift assignment is made that involves an employment operating at this skill level */
  assignmentAction?: Maybe<SkillLevelAssignmentActionEnum>;
  /** When true, the absence of an skill level implies this skill level. Exactly one skill level may be the default. */
  isDefault?: Maybe<Scalars["Boolean"]>;
  /** A short notation of the skill level, typically a digit, but could be a letter or a short code */
  level: Scalars["String"];
  /** The name of the skill level, typically a word or phrase such as "Excellent" */
  name: Scalars["String"];
};

/** Skill Level input type for updates */
export type SkillLevelUpdateInput = {
  /** Represents the skill level as AOS sees it, null means AOS will never make a shift assignment involving this skill level */
  aosLevel?: Maybe<Scalars["Int"]>;
  /** Action to take when a shift assignment is made that involves an employment operating at this skill level */
  assignmentAction?: Maybe<SkillLevelAssignmentActionEnum>;
  /** When true, the absence of an skill level implies this skill level. Exactly one skill level may be the default. */
  isDefault?: Maybe<Scalars["Boolean"]>;
  /** A short notation of the skill level, typically a digit, but could be a letter or a short code */
  level?: Maybe<Scalars["String"]>;
  /** The name of the skill level, typically a word or phrase such as "Excellent" */
  name?: Maybe<Scalars["String"]>;
};

/** Internal Stack fields */
export type Stack = {
  __typename?: "Stack";
  /** Time the stack was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Stack ID */
  id: Scalars["ID"];
  /** IDM address */
  idmDomainName: Scalars["String"];
  /** Stack code */
  stackCode: Scalars["String"];
  /** Time the stack was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Manager Station Hour fields */
export type StationHour = {
  __typename?: "StationHour";
  /** Station hour was created */
  createdAt: Scalars["ISO8601DateTime"];
  /**
   * The hour ranges per day for which this station is open.
   * If a day is marked as 'Closed', it shouldn't be in the array.
   * Array may be empty (Closed every day).
   */
  days: Array<StationHourDay>;
  /** Station Hour ID */
  id: Scalars["ID"];
  /** Type of Station Hour */
  stationHourType?: Maybe<StationHourType>;
  /** The station that this set of hours belongs to. If missing, these are the default store hours. */
  stationHourTypeId?: Maybe<Scalars["ID"]>;
  /** Store Hour ID */
  storeHourId: Scalars["ID"];
  /** Station hour was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Interal Station Hour input fields for creation */
export type StationHourCreateInput = {
  /**
   * The hour ranges per day for which this station is open.
   * If a day is marked as 'Closed', it shouldn't be in the array.
   * Array may be empty (Closed every day).
   */
  days: Array<StationHourDayInput>;
  /** The station that this set of hours belongs to. If missing, these are the default store hours. */
  stationHourTypeId?: Maybe<Scalars["ID"]>;
};

/** Manager Station Hour fields */
export type StationHourDay = {
  __typename?: "StationHourDay";
  /** Represents Sunday to Saturday day of week */
  dayOfWeek: DayOfWeekEnum;
  /** End time of station hours */
  endTime: Scalars["ISO8601HoursMinutes"];
  /** Start time of station hours */
  startTime: Scalars["ISO8601HoursMinutes"];
};

/** Station Hour Day Input */
export type StationHourDayInput = {
  /** Represents Sunday to Saturday day of week */
  dayOfWeek: DayOfWeekEnum;
  /** End time of station hours */
  endTime: Scalars["ISO8601HoursMinutes"];
  /** Start time of station hours */
  startTime: Scalars["ISO8601HoursMinutes"];
};

/** Manager Station Hour Type fields */
export type StationHourType = {
  __typename?: "StationHourType";
  /** Time Station Hour Type created at */
  createdAt: Scalars["ISO8601DateTime"];
  /** Id of Station Hour Type */
  id: Scalars["ID"];
  /** Name of Station Hour Type */
  name: Scalars["String"];
  /** Time Station Hour Type updated at */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for StationHourType. */
export type StationHourTypeConnection = {
  __typename?: "StationHourTypeConnection";
  /** A list of edges. */
  edges: Array<StationHourTypeEdge>;
  /** A list of nodes. */
  nodes: Array<StationHourType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StationHourTypeEdge = {
  __typename?: "StationHourTypeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<StationHourType>;
};

/** Manager Store Hour fields */
export type StoreHour = {
  __typename?: "StoreHour";
  /** Store hour was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Store Hour ID */
  id: Scalars["ID"];
  /** Schedule ID. If missing, this is a business-level definition. */
  scheduleId?: Maybe<Scalars["ID"]>;
  /** Station Hours configured for this Store Hour */
  stationHours: Array<StationHour>;
  /** Store hour was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Interal Store Hour input fields for creation */
export type StoreHourCreateInput = {
  /** Schedule ID. If missing, this is a business-level definition. */
  scheduleId?: Maybe<Scalars["ID"]>;
  /** Station Hours for this Store Hours */
  stationHours: Array<StationHourCreateInput>;
};

/** Swap Drop Filtering values */
export enum SwapDropFilteringEnum {
  Default = "default",
  Jtc = "jtc",
}

/** Swap Sorting values */
export enum SwapSortingEnum {
  Default = "default",
  SkillLevel = "skill_level",
}

/** Sync Idm Users input fields */
export type SyncIdmUsersInput = {
  /** User access type */
  accessType?: Maybe<AccessTypeEnum>;
  /** Time user confirmed */
  confirmedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Whether the user is soft deleted */
  deleted?: Maybe<Scalars["Boolean"]>;
  /** User email */
  email: Scalars["String"];
  /** User id */
  id: Scalars["ID"];
  /** User locale */
  locale?: Maybe<Scalars["String"]>;
  /** Time user account locked */
  lockedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Time password last changed */
  passwordChangedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** System fields */
export type System = {
  __typename?: "System";
  /** Information from database metadata */
  database: Database;
  /** Information about the current process */
  process: Process;
  /** Information from Puma control app */
  puma: Puma;
};

/** System fields */
export type SystemDatabaseArgs = {
  schemaNames?: Maybe<Array<Scalars["String"]>>;
};

/** System sources for action logs */
export enum SystemSourceEnum {
  BackgroundJob = "background_job",
  ClientSupport = "client_support",
  Internal = "internal",
}

/** Internal Availability Temporary Availability fields */
export type TemporaryAvailability = Availability & {
  __typename?: "TemporaryAvailability";
  /** Array of objects containing minimum hours for each week in availability definition */
  cachedMinHoursPerWeek?: Maybe<Scalars["JSON"]>;
  /** Indicates if the linked employment waived consecutive days off */
  consecutiveDaysOffWaived: Scalars["Boolean"];
  /** Time created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employment id of creator */
  createdById?: Maybe<Scalars["ID"]>;
  /** Association linking the availability definition to day groups containing available hours definitions */
  days: Array<AvailabilityDetailsByDay>;
  /** Whether the availability definition is soft-deleted */
  deleted: Scalars["Boolean"];
  /** Indicates if the linked employment created the availability */
  employeeInitiated: Scalars["Boolean"];
  /** Employment that availability is associated to */
  employmentId: Scalars["ID"];
  /** Availability end date. Can be null */
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Availability ID */
  id: Scalars["ID"];
  /** Time that manager acknowledged notification. Can be null */
  managerAcknowledgedNotificationAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /**
   * The max number of seconds per week for the whole availability definition. It
   * will be the same value for each week in a multi-week definition
   */
  maxDurationPerWeek?: Maybe<Scalars["Seconds"]>;
  /** How many weeks are in the availability definition */
  numberOfWeeks: Scalars["Int"];
  /** Temporary Availability repeat cycle */
  repeatCycle: TemporaryAvailabilityRepeatCycleEnum;
  /** The message attached to the request if any */
  requestingMessage?: Maybe<Scalars["String"]>;
  /** The time of the response (could be manager or employee) */
  respondedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The employment id of the responder (could be manager or employee). respondedBy === createdBy =>"Cancelled" */
  respondedById?: Maybe<Scalars["ID"]>;
  /** The message attached to the response if any */
  respondingMessage?: Maybe<Scalars["String"]>;
  /** Availability start date. Defaults to date of tomorrow */
  startDate: Scalars["ISO8601Date"];
  /** Status of availability */
  status: AvailabilityStatusEnum;
  /** Time of submission for approval/acceptance */
  submittedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /**
   * Most often equal to createdBy, but or the case of manager-initiated, Manager A
   * could start a draft and manager B could finish and submit
   */
  submittedById?: Maybe<Scalars["ID"]>;
  /** Type of availability */
  type: AvailabilityTypeEnum;
  /** Time updated */
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Employment id of updater */
  updatedById?: Maybe<Scalars["ID"]>;
  /** If true, employee is willing to work extra hours outside of fixed contract */
  willingToWorkExtraHours: Scalars["Boolean"];
};

/** Internal Availability Temporary Availability Create input fields */
export type TemporaryAvailabilityCreateInput = {
  /** Association linking the availability definition to day groups containing available hours definitions */
  days?: Maybe<Array<AvailableHoursByDayCreateInput>>;
  /** Employment that availability is associated to */
  employmentId: Scalars["ID"];
  /** Availability end date. Can be null */
  endDate: Scalars["ISO8601Date"];
  /**
   * The max number of seconds per week for the whole availability definition. It
   * will be the same value for each week in a multi-week definition
   */
  maxDurationPerWeek?: Maybe<Scalars["Seconds"]>;
  /** How many weeks are in the availability definition */
  numberOfWeeks?: Maybe<Scalars["Int"]>;
  /** Temporary Availability repeat cycle */
  repeatCycle: TemporaryAvailabilityRepeatCycleEnum;
  /** The message attached to the request if any */
  requestingMessage?: Maybe<Scalars["String"]>;
  /** Availability start date. Defaults to date of tomorrow */
  startDate: Scalars["ISO8601Date"];
  /** If true, employee is willing to work extra hours outside of fixed contract */
  willingToWorkExtraHours?: Maybe<Scalars["Boolean"]>;
};

/** Temporary Availability repeat cycle */
export enum TemporaryAvailabilityRepeatCycleEnum {
  BiWeekly = "bi_weekly",
  Weekly = "weekly",
}

/** Internal Availability Temporary Availability Update input fields */
export type TemporaryAvailabilityUpdateInput = {
  /**
   * Association linking the availability definition to day groups containing available hours definitions.
   * Anything provided will replace any existing available hours.
   * Provide an empty array to delete existing and not create any new ones.
   */
  days?: Maybe<Array<AvailableHoursByDayUpdateInput>>;
  /** Availability end date. Can be null */
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  /**
   * The max number of seconds per week for the whole availability definition. It
   * will be the same value for each week in a multi-week definition
   */
  maxDurationPerWeek?: Maybe<Scalars["Seconds"]>;
  /** How many weeks are in the availability definition */
  numberOfWeeks?: Maybe<Scalars["Int"]>;
  /** Temporary Availability repeat cycle */
  repeatCycle?: Maybe<TemporaryAvailabilityRepeatCycleEnum>;
  /** The message attached to the request if any */
  requestingMessage?: Maybe<Scalars["String"]>;
  /** Availability start date. Defaults to date of tomorrow */
  startDate?: Maybe<Scalars["ISO8601Date"]>;
  /** If true, employee is willing to work extra hours outside of fixed contract */
  willingToWorkExtraHours?: Maybe<Scalars["Boolean"]>;
};

/** Internal Time Clock fields */
export type TimeClock = {
  __typename?: "TimeClock";
  /** The total of the additional_pivot_metrics. Value is not included in 'earnings' field */
  additionalEarnings?: Maybe<Scalars["Float"]>;
  /**
   * Contains the pivot metrics for additional payments for the shift. This will be
   * used for non-differential payments (such as laundry allowance)
   * Notes: They should have the same structure as the existing pivot_metrics field, with the following additions:
   *  - Entries may be one-off payments, which will be 0 length pivots at the start
   * of the shift with a earnings value of the one off payment
   *  - Entries may overlap - as there may be multiple payments from different sources.
   *  - paycode will be used to store the additional pay code.
   */
  additionalPivotMetrics?: Maybe<Scalars["JSON"]>;
  /** Array of break objects for time clock */
  breaks?: Maybe<Scalars["JSON"]>;
  /** Role ID */
  businessRoleId?: Maybe<Scalars["ID"]>;
  /** Time employment clocked in */
  clockIn?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Time employment clocked out */
  clockOut?: Maybe<Scalars["ISO8601DateTime"]>;
  /** 3rd party unique ID for the time clock entry */
  code?: Maybe<Scalars["String"]>;
  /** Consent status of the time clock */
  consentStatus?: Maybe<TimeClockConsentStatusEnum>;
  /**
   * Used to dismiss notification, indicates the time the manager acknowledged the
   * employee's contestation. Nil if yet to acknowledge
   */
  contestAcknowledgedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /**
   * Denotes the id of the manager employment who acknowledged the employee's
   * contestation of the most recent edit. Nil if yet to acknowledge
   */
  contestAcknowledgedById?: Maybe<Scalars["ID"]>;
  /** The employees reason for contesting */
  contestReason?: Maybe<Scalars["String"]>;
  /** The time that the time clock was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** DateTime representing the time of the last change of specific fields */
  customUpdatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** High precision time representing the time of the last change of specific fields */
  customUpdatedAtHighPrecision?: Maybe<Scalars["ISO8601DateTimeHighPrecision"]>;
  /** Whether the time clock is soft deleted */
  deleted: Scalars["Boolean"];
  /** The total of the differential_pivot_metrics. Value is not included in 'earnings' field */
  differentialEarnings?: Maybe<Scalars["Float"]>;
  /**
   * Contains the pivot metrics for differential payments for the shift.
   * Notes: They should have the same structure as the existing pivot_metrics field, with the following additions:
   *  - Entries may be one-off payments, which will be 0 length pivots at the start
   * of the shift with a earnings value of the one off payment
   *  - Entries may overlap - as there may be multiple payments from different
   * sources - note will not currently occur as we only allow on differential to trigger
   *  - paycode will be used to store the differential code.
   *  - differential_id guid that caused the payment will be added
   */
  differentialPivotMetrics?: Maybe<Scalars["JSON"]>;
  /** Time clock earnings */
  earnings: Scalars["Float"];
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** Indicates whether the time clock can be force accepted while the changes are contested or pending consent */
  forceAcceptable: Scalars["Boolean"];
  /** Time clock ID */
  id: Scalars["ID"];
  /** Whether ignored event for time clock need review */
  ignoredEventsNeedReview: Scalars["Boolean"];
  /** Whether time clock start day is valid. Computed value based on validity of punches JSON */
  isStartDayValid: Scalars["Boolean"];
  /**
   * Manually set to true by a manager as part of payroll posting if an employee is
   * still on shift after the end of pay period (ie. an allowed open time clock)
   */
  isStillWorking: Scalars["Boolean"];
  /** Whether time clock is valid. Computed value based on validity of punches JSON */
  isValid: Scalars["Boolean"];
  /** Whether time clock is locked */
  locked: Scalars["Boolean"];
  /** Snapshot of the time clock changes made by manager */
  managerAdjustment?: Maybe<TimeClockManagerAdjustedValue>;
  /** Whether estimates are pending for time clock */
  pendingEstimates: Scalars["Boolean"];
  /** Time clock pivot metrics */
  pivotMetrics?: Maybe<Scalars["JSON"]>;
  /** Time clock out is planned to occur */
  plannedClockOut?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The managers optional commentary regarding the punch edit */
  punchEditReason?: Maybe<Scalars["String"]>;
  /** Time the manager resolved the most recent edit. Nil if yet to resolve */
  punchEditResolvedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Denotes the id of the manager employment who resolved the recent edit. Nil if yet to resolve */
  punchEditResolvedById?: Maybe<Scalars["ID"]>;
  /** Time the employee responded to the most recent edit. Nil if yet to respond */
  punchEditRespondedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Time the manager performed the most recent edit. Nil if yet to respond */
  punchEditedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Denotes the id of the manager employment who performed the recent edit */
  punchEditedById?: Maybe<Scalars["ID"]>;
  /** Array of punch objects */
  punches?: Maybe<Scalars["JSON"]>;
  ratePreferenceIndex: Scalars["Int"];
  /** Time the employee acknowledged the most recent edit resolution. Nil if yet to acknowledge */
  resolutionAcknowledgedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The managers optional commentary for resolving the contestation */
  resolutionReason?: Maybe<Scalars["String"]>;
  /** The type of contestation resolution */
  resolutionType?: Maybe<TimeClockResolutionTypeEnum>;
  roles?: Maybe<Scalars["JSON"]>;
  /** Schedule ID */
  scheduleId: Scalars["ID"];
  /** Time clock payable seconds */
  seconds: Scalars["Int"];
  shift?: Maybe<Shift>;
  /** Shift ID */
  shiftId?: Maybe<Scalars["ID"]>;
  /** Time clock events */
  timeClockEvents: Array<TimeClockEvent>;
  timeRange?: Maybe<Scalars["String"]>;
  /** The time that the time clock was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** High precision time that the time clock was last updated */
  updatedAtHighPrecision: Scalars["ISO8601DateTimeHighPrecision"];
};

/** Internal Time Clock App API Key fields */
export type TimeClockAppApiKey = {
  __typename?: "TimeClockAppApiKey";
  /** Time clock app API key */
  apiKey: Scalars["String"];
  /** Business ID of the time clock app api key */
  businessId: Scalars["ID"];
  /** Time the time clock app api key was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Time clock app api key ID */
  id: Scalars["ID"];
  /** Time the time clock app api key was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Time Clock Approval Reason */
export type TimeClockAppApprovalReason = {
  __typename?: "TimeClockAppApprovalReason";
  /** Time Clock Approval Reason ID */
  id: Scalars["ID"];
  /** Reason text */
  reason: Scalars["String"];
  /** Small ID */
  smallId: Scalars["Int"];
};

/** The connection type for TimeClockAppApprovalReason. */
export type TimeClockAppApprovalReasonConnection = {
  __typename?: "TimeClockAppApprovalReasonConnection";
  /** A list of edges. */
  edges: Array<TimeClockAppApprovalReasonEdge>;
  /** A list of nodes. */
  nodes: Array<TimeClockAppApprovalReason>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type TimeClockAppApprovalReasonEdge = {
  __typename?: "TimeClockAppApprovalReasonEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<TimeClockAppApprovalReason>;
};

/** Internal Time Clock App Pin Configuration fields */
export type TimeClockAppPinConfig = {
  __typename?: "TimeClockAppPinConfig";
  /** Maximum pin size */
  max: Scalars["Int"];
  /** Minimum pin size */
  min: Scalars["Int"];
};

/** Time Clock App Pin Configuration input fields */
export type TimeClockAppPinConfigInput = {
  /** Maximum pin size */
  max: Scalars["Int"];
  /** Minimum pin size */
  min: Scalars["Int"];
};

/** Time Clock App Pre Shift Formatted Messages fields */
export type TimeClockAppPreShiftFormattedShiftMessages = {
  __typename?: "TimeClockAppPreShiftFormattedShiftMessages";
  /** formatted pre shift messages */
  configurable?: Maybe<Array<TimeClockAppPreShiftMessageConfigurable>>;
  /** array of predefined/static messages */
  predefined?: Maybe<Array<Scalars["String"]>>;
  /** identify the formatted message as predefined vs configured */
  style?: Maybe<TimeClockAppPreShiftMessageTypeEnum>;
};

/** Time Clock App Rest Screen Settings input fields */
export type TimeClockAppPreShiftFormattedShiftMessagesInput = {
  /** formatted pre shift messages */
  configurable?: Maybe<Array<TimeClockAppPreShiftMessageConfigurableInput>>;
  /** array of predefined/static messages */
  predefined?: Maybe<Array<Scalars["String"]>>;
  /** identify the formatted message as predefined vs configured */
  style?: Maybe<TimeClockAppPreShiftMessageTypeEnum>;
};

/** Time Clock App Pre Shift Formatted Messages configuration */
export type TimeClockAppPreShiftMessageConfigurable = {
  __typename?: "TimeClockAppPreShiftMessageConfigurable";
  /** button configuration for formatting */
  buttons: Array<Scalars["String"]>;
  /** identify the category for message */
  code: Scalars["String"];
  /** message text */
  message: Scalars["String"];
  /** title text for the message */
  title: Scalars["String"];
  /** version */
  version: Scalars["Int"];
};

/** Time Clock App Pre Shift Formatted Messages configuration inputs */
export type TimeClockAppPreShiftMessageConfigurableInput = {
  /** button configuration for formatting */
  buttons: Array<Scalars["String"]>;
  /** identify the category for the message */
  code: Scalars["String"];
  /** message text */
  message: Scalars["String"];
  /** title text for the message */
  title: Scalars["String"];
  /** version */
  version: Scalars["Int"];
};

/** Either we do have fixed pre shift message or formatted one. */
export enum TimeClockAppPreShiftMessageTypeEnum {
  /** we do have option to configure the pre shift message */
  Configurable = "configurable",
  /** we do have fixed array of text as pre shift message */
  Predefined = "predefined",
}

/** Time Clock App Rest Screen Settings fields */
export type TimeClockAppRestScreenSettings = {
  __typename?: "TimeClockAppRestScreenSettings";
  /** Minutes after a completed break to stop displaying break card on completed screen */
  breakCompletedUpperBound: Scalars["Int"];
  /** Minutes prior to meal break start to display on rest screen */
  mealBreakApproachingLowerBound: Scalars["Int"];
  /** Minutes after meal break start to stop displaying meal break due on rest screen */
  mealBreakDueUpperBound: Scalars["Int"];
  /** Minutes prior to meal break end to display meal break end on rest screen */
  mealBreakEndApproachingLowerBound: Scalars["Int"];
  /** Minutes after meal break end to stop displaying meal break end due on rest screen */
  mealBreakEndDueUpperBound: Scalars["Int"];
  /** Minutes after meal break start to stop displaying meal break overdue on rest screen */
  mealBreakOverdueUpperBound: Scalars["Int"];
  /** Number of minutes before or after shift start considered on time */
  onTimeThreshold: Scalars["Int"];
  /** Minutes prior to shift start to display start due on rest screen */
  shiftApproachingLowerBound: Scalars["Int"];
  /** Minutes after shift start to stop displaying start due on rest screen */
  shiftDueUpperBound: Scalars["Int"];
  /** Minutes prior to shift end to display shift end on rest screen */
  shiftEndApproachingLowerBound: Scalars["Int"];
  /** Minutes after shift end to stop displaying shift end due on rest screen */
  shiftEndDueUpperBound: Scalars["Int"];
  /** Minutes after shift start to stop displaying shift late on rest screen */
  shiftLateUpperBound: Scalars["Int"];
  /** Minutes after shift start to stop displaying start overdue on rest screen */
  shiftOverdueUpperBound: Scalars["Int"];
};

/** Time Clock App Rest Screen Settings input fields */
export type TimeClockAppRestScreenSettingsInput = {
  /** Minutes after a completed break to stop displaying break card on completed screen */
  breakCompletedUpperBound?: Maybe<Scalars["Int"]>;
  /** Minutes prior to meal break start to display on rest screen */
  mealBreakApproachingLowerBound?: Maybe<Scalars["Int"]>;
  /** Minutes after meal break start to stop displaying meal break due on rest screen */
  mealBreakDueUpperBound?: Maybe<Scalars["Int"]>;
  /** Minutes prior to meal break end to display meal break end on rest screen */
  mealBreakEndApproachingLowerBound?: Maybe<Scalars["Int"]>;
  /** Minutes after meal break end to stop displaying meal break end due on rest screen */
  mealBreakEndDueUpperBound?: Maybe<Scalars["Int"]>;
  /** Minutes after meal break start to stop displaying meal break overdue on rest screen */
  mealBreakOverdueUpperBound?: Maybe<Scalars["Int"]>;
  /** Number of minutes before or after shift start considered on time */
  onTimeThreshold?: Maybe<Scalars["Int"]>;
  /** Minutes prior to shift start to display start due on rest screen */
  shiftApproachingLowerBound?: Maybe<Scalars["Int"]>;
  /** Minutes after shift start to stop displaying start due on rest screen */
  shiftDueUpperBound?: Maybe<Scalars["Int"]>;
  /** Minutes prior to shift end to display shift end on rest screen */
  shiftEndApproachingLowerBound?: Maybe<Scalars["Int"]>;
  /** Minutes after shift end to stop displaying shift end due on rest screen */
  shiftEndDueUpperBound?: Maybe<Scalars["Int"]>;
  /** Minutes after shift start to stop displaying shift late on rest screen */
  shiftLateUpperBound?: Maybe<Scalars["Int"]>;
  /** Minutes after shift start to stop displaying start overdue on rest screen */
  shiftOverdueUpperBound?: Maybe<Scalars["Int"]>;
};

/** Time Clock Break fields */
export type TimeClockBreak = {
  __typename?: "TimeClockBreak";
  /** break_type_code of the break_type */
  breakType: Scalars["String"];
  /** ID of the end punch */
  endPunchId?: Maybe<Scalars["ID"]>;
  /** The end time of the break */
  endTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Whether break is paid */
  paid?: Maybe<Scalars["Boolean"]>;
  /** Planned number of seconds duration for this break */
  plannedDuration?: Maybe<Scalars["Seconds"]>;
  /** ID of the start punch */
  startPunchId?: Maybe<Scalars["ID"]>;
  /** The start time of the break */
  startTime?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** The connection type for TimeClock. */
export type TimeClockConnection = {
  __typename?: "TimeClockConnection";
  /** A list of edges. */
  edges: Array<TimeClockEdge>;
  /** A list of nodes. */
  nodes: Array<TimeClock>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** Time Clock Consent Status values */
export enum TimeClockConsentStatusEnum {
  Accepted = "accepted",
  ConsentPending = "consent_pending",
  ContestResolved = "contest_resolved",
  Contested = "contested",
}

/** Internal Time Clock Device fields */
export type TimeClockDevice = {
  __typename?: "TimeClockDevice";
  /** Time the time clock device was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Time Clock Device ID */
  id: Scalars["ID"];
  /** Name of the time clock device */
  name: Scalars["String"];
  /** Time the time clock device was registered at */
  registeredAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** ID of the employment who registered the time clock device */
  registeredById?: Maybe<Scalars["ID"]>;
  /** Schedule ID of the time clock device */
  scheduleId: Scalars["ID"];
  /** Time the time clock device was last synchronized */
  synchronizedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Time the time clock device was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Version of the time clock device */
  version?: Maybe<Scalars["String"]>;
};

/** The connection type for TimeClockDevice. */
export type TimeClockDeviceConnection = {
  __typename?: "TimeClockDeviceConnection";
  /** A list of edges. */
  edges: Array<TimeClockDeviceEdge>;
  /** A list of nodes. */
  nodes: Array<TimeClockDevice>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type TimeClockDeviceEdge = {
  __typename?: "TimeClockDeviceEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<TimeClockDevice>;
};

/** Internal Time Clock Device input fields */
export type TimeClockDeviceInput = {
  /** Name of the time clock device */
  name?: Maybe<Scalars["String"]>;
};

/** An edge in a connection. */
export type TimeClockEdge = {
  __typename?: "TimeClockEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<TimeClock>;
};

/** Internal Time Clock Event fields */
export type TimeClockEvent = {
  __typename?: "TimeClockEvent";
  /** break_type_code of a BreakType within the specified business [mandatory for (break_start, break_start_adjusted)] */
  breakType?: Maybe<Scalars["String"]>;
  /** ID of business role [Needed for role_rate_selected, role_rate_adjusted. Can be null.] */
  businessRoleId?: Maybe<Scalars["ID"]>;
  /** 3rd party unique ID for the time clock entry */
  code?: Maybe<Scalars["String"]>;
  /** The employees reason for contesting */
  contestReason?: Maybe<Scalars["String"]>;
  /** Time the event was created */
  createdAt: Scalars["ISO8601DateTime"];
  /** ID of the device associated with the event */
  deviceId?: Maybe<Scalars["ID"]>;
  /** Indicates whether the deviation from schedule was employee initiated. Mandatory for all events except 'deleted'. */
  employeeInitiatedChange: Scalars["Boolean"];
  /** ID of the associated employment */
  employmentId: Scalars["ID"];
  /** Type of event */
  eventType: Scalars["String"];
  /** Time Clock Event ID */
  id: Scalars["ID"];
  /** Reason event was ignored */
  ignoredReason?: Maybe<Scalars["String"]>;
  /** Reason code imported from the third party system indicating the reason for the adjustment */
  meta?: Maybe<Scalars["JSON"]>;
  /** Indicates whether the break is paid. Mandatory for (break_end, break_end_adjusted). */
  paidBreak?: Maybe<Scalars["Boolean"]>;
  /** ID of the employment who performed the event */
  performedById?: Maybe<Scalars["ID"]>;
  /**
   * Number of seconds duration for the current (in progress) break. Only
   * applicable for the following events: break_start, break_start_adjusted. May be
   * omitted from the events to use the current behaviour.
   */
  plannedBreakDuration?: Maybe<Scalars["Seconds"]>;
  /** The managers optional commentary regarding the punch edit */
  punchEditReason?: Maybe<Scalars["String"]>;
  /** Mandatory for all events except for (clock_in, clock_out, break_start, break_end) */
  punchId?: Maybe<Scalars["ID"]>;
  /** The method of the punch */
  punchMethod?: Maybe<Scalars["String"]>;
  /** Mandatory for all events except 'deleted' */
  punchTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The type of the punch */
  punchType?: Maybe<Scalars["String"]>;
  /** Preferred employment rate index. Default is 0 */
  ratePreferenceIndex: Scalars["Int"];
  /** Approval Reasons */
  reasons?: Maybe<Array<Scalars["Int"]>>;
  /** The managers optional commentary for resolving the contestation */
  resolutionReason?: Maybe<Scalars["String"]>;
  /** The type of contestation resolution */
  resolutionType?: Maybe<TimeClockResolutionTypeEnum>;
  /** ID of the associated schedule */
  scheduleId: Scalars["ID"];
  /** Id allocated to events that correspond to the same adjustment */
  sessionId?: Maybe<Scalars["ID"]>;
  /** Source of the time clock event */
  source?: Maybe<Scalars["String"]>;
  /** ID of the associated time clock */
  timeClockId: Scalars["ID"];
  /** Time the event was last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

export type TimeClockManagerAdjustedAttributes = {
  __typename?: "TimeClockManagerAdjustedAttributes";
  /** Array of break objects for time clock */
  breaks?: Maybe<Array<TimeClockBreak>>;
  /** Time employment clocked in */
  clockIn?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Time employment clocked out */
  clockOut?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Role objects associated with the time clock */
  roles?: Maybe<Array<TimeClockRole>>;
};

/** Time Clock Manager Adjusted */
export type TimeClockManagerAdjustedValue = {
  __typename?: "TimeClockManagerAdjustedValue";
  /** Snapshot of current time clock */
  current?: Maybe<TimeClockManagerAdjustedAttributes>;
  /** Snapshot of time clock before change */
  previous?: Maybe<TimeClockManagerAdjustedAttributes>;
};

/** Time Clock Resolution Type values */
export enum TimeClockResolutionTypeEnum {
  AdjustedValueKept = "adjusted_value_kept",
  ForceAccepted = "force_accepted",
  RevertedToOriginal = "reverted_to_original",
}

/** Time Clock Role fields */
export type TimeClockRole = {
  __typename?: "TimeClockRole";
  /** Business role ID */
  businessRoleId?: Maybe<Scalars["ID"]>;
  /** ID of the associated punch */
  punchId?: Maybe<Scalars["ID"]>;
  /** Preferred employment rate index. Default is 0 */
  ratePreferenceIndex: Scalars["Int"];
  /** The start offset of the role */
  startSec: Scalars["Seconds"];
  /** Roles start time */
  startTime?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** Time Clock Sort fields */
export type TimeClockSort = {
  /** Field to sort by */
  field: TimeClockSortField;
  /** Sort order */
  order: Order;
};

/** Time Clock Sort Field values */
export enum TimeClockSortField {
  /** Clock in */
  ClockIn = "clockIn",
  /** Clock out */
  ClockOut = "clockOut",
  /** Primary key */
  Id = "id",
}

/** Time Off Type fields */
export type TimeOffBalance = {
  __typename?: "TimeOffBalance";
  /** Timestamp when the hours_balance was extracted from third party system. */
  accruedTo: Scalars["ISO8601DateTime"];
  /** Time time off type created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employment ID */
  employmentId: Scalars["ID"];
  /** Time off balances as imported from the 3rd party system */
  hoursBalance: Scalars["Hours"];
  /** Time Off Balance ID */
  id: Scalars["ID"];
  /** The time off type of the associated record */
  timeOffType: TimeOffType;
  /** Time off type ID */
  timeOffTypeId: Scalars["ID"];
  /** Time time off type last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for TimeOffBalance. */
export type TimeOffBalanceConnection = {
  __typename?: "TimeOffBalanceConnection";
  /** A list of edges. */
  edges: Array<TimeOffBalanceEdge>;
  /** A list of nodes. */
  nodes: Array<TimeOffBalance>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type TimeOffBalanceEdge = {
  __typename?: "TimeOffBalanceEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<TimeOffBalance>;
};

/** Time Off Type Sort fields */
export type TimeOffBalanceSort = {
  /** Field to sort by */
  field: TimeOffBalanceSortField;
  /** Sort order */
  order: Order;
};

/** Time off balance Sort Field values */
export enum TimeOffBalanceSortField {
  DateAccruedTo = "dateAccruedTo",
  EmploymentId = "employmentId",
  /** Primary key */
  Id = "id",
  TimeOffTypeId = "timeOffTypeId",
}

/** Time Off Type fields */
export type TimeOffType = {
  __typename?: "TimeOffType";
  /** 3rd party unique ID for the time off type */
  code?: Maybe<Scalars["String"]>;
  /** Time time off type created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Indicates whether the time off type is soft deleted */
  deleted: Scalars["Boolean"];
  /** Types of employee eligible for this time off type, defaults to empty array. */
  employmentTypeCodes: Array<Scalars["String"]>;
  /** Types of employee eligible for this time off type, defaults to empty array. */
  employmentTypeConfigs: Array<EmploymentTypeConfig>;
  /** Small ids of types of employee eligible for this time off type, defaults to empty array. */
  employmentTypeSmallIds: Array<Scalars["Int"]>;
  /**
   * Legacy types of employee eligible for this time off type, defaults to empty array.
   * @deprecated Use employmentTypeCodes or employmentTypeConfigs { nodes { employmentTypeCode } } instead.
   */
  employmentTypes: Array<EmploymentTypeEnum>;
  /** Time Off Type ID */
  id: Scalars["ID"];
  /** Indicates whether this time off type should ignore the auto reject threshold. Default: false */
  ignoreAutoReject: Scalars["Boolean"];
  /**
   * If set to true, leave type can only be set via manager/via manager apps (i.e.
   * setting the leave type by proxy for an employee). Default is false
   */
  isManagerByProxyOnly: Scalars["Boolean"];
  /** Time Off Type Name */
  name: Scalars["String"];
  /** Indicates whether the time off type is paid or not */
  paid: Scalars["Boolean"];
  /** Time Off Type Short Name */
  shortName?: Maybe<Scalars["String"]>;
  /** Time time off type last updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for TimeOffType. */
export type TimeOffTypeConnection = {
  __typename?: "TimeOffTypeConnection";
  /** A list of edges. */
  edges: Array<TimeOffTypeEdge>;
  /** A list of nodes. */
  nodes: Array<TimeOffType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** Internal Time Off Type input fields for creation */
export type TimeOffTypeCreateInput = {
  /** 3rd party unique ID for the time off type */
  code?: Maybe<Scalars["String"]>;
  /** Types of employee eligible for this time off type, defaults to empty array. */
  employmentTypeCodes?: Maybe<Array<Scalars["String"]>>;
  /** Small ids of types of employee eligible for this time off type, defaults to empty array. */
  employmentTypeSmallIds?: Maybe<Array<Scalars["Int"]>>;
  /** Legacy types of employee eligible for this time off type, defaults to empty array. */
  employmentTypes?: Maybe<Array<EmploymentTypeEnum>>;
  /** Indicates whether this time off type should ignore the auto reject threshold. Default: false */
  ignoreAutoReject?: Maybe<Scalars["Boolean"]>;
  /**
   * If set to true, leave type can only be set via manager/via manager apps (i.e.
   * setting the leave type by proxy for an employee). Default is false
   */
  isManagerByProxyOnly: Scalars["Boolean"];
  /** Descriptive name. Must be unique. */
  name: Scalars["String"];
  /** Indicates whether the time off type is paid or not */
  paid?: Maybe<Scalars["Boolean"]>;
  /** Time Off Type Short Name */
  shortName?: Maybe<Scalars["String"]>;
};

/** An edge in a connection. */
export type TimeOffTypeEdge = {
  __typename?: "TimeOffTypeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<TimeOffType>;
};

/** Time Off Type Sort fields */
export type TimeOffTypeSort = {
  /** Field to sort by */
  field: TimeOffTypeSortField;
  /** Sort order */
  order: Order;
};

/** Time Off Type Sort Field values */
export enum TimeOffTypeSortField {
  Deleted = "deleted",
  Id = "id",
  Name = "name",
}

/** Internal time off type input fields for update */
export type TimeOffTypeUpdateInput = {
  /** 3rd party unique ID for the time off type */
  code?: Maybe<Scalars["String"]>;
  /** Types of employee eligible for this time off type, defaults to empty array. */
  employmentTypeCodes?: Maybe<Array<Scalars["String"]>>;
  /** Small ids of types of employee eligible for this time off type, defaults to empty array. */
  employmentTypeSmallIds?: Maybe<Array<Scalars["Int"]>>;
  /** Legacy types of employee eligible for this time off type, defaults to empty array. */
  employmentTypes?: Maybe<Array<EmploymentTypeEnum>>;
  /** Indicates whether this time off type should ignore the auto reject threshold. Default: false */
  ignoreAutoReject?: Maybe<Scalars["Boolean"]>;
  /**
   * If set to true, leave type can only be set via manager/via manager apps (i.e.
   * setting the leave type by proxy for an employee). Default is false
   */
  isManagerByProxyOnly: Scalars["Boolean"];
  /** Time Off Type Name */
  name?: Maybe<Scalars["String"]>;
  /** Indicates whether the time off type is paid or not */
  paid?: Maybe<Scalars["Boolean"]>;
  /** Time Off Type Short Name */
  shortName?: Maybe<Scalars["String"]>;
};

/** Internal Unavailability Event fields */
export type UnavailabilityEvent = {
  __typename?: "UnavailabilityEvent";
  /** Time created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Whether this record is soft-deleted. Default is false */
  deleted: Scalars["Boolean"];
  /** Time of last employment_acknowledgement_status update, or null if not acknowledged */
  employeeAcknowledgedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Set to false if created via manager by proxy. */
  employeeInitiated: Scalars["Boolean"];
  /** Employment Id of the unavailability event */
  employmentId: Scalars["ID"];
  /** Unavailability event end date and time */
  endDateTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Hours that will be reduce from leave balance */
  hoursRequested: Scalars["Hours"];
  /** Id of the unavailability event */
  id: Scalars["ID"];
  /** Id of the manager employment who responded to this unavailability event */
  managerEmploymentId?: Maybe<Scalars["ID"]>;
  /** Comment added by the manager while responding to this unavailability event */
  managerMessage?: Maybe<Scalars["String"]>;
  /** Time at which the manager responded to this unavailability event */
  managerResponseTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Description of the unavailability event */
  reason?: Maybe<Scalars["String"]>;
  /** The reason why the manager chose that the employee doesn't need to consent */
  reasonForAutoConsent?: Maybe<Scalars["String"]>;
  /** The reason why the record was modified. Also used for the reason the record was deleted. */
  reasonForChange?: Maybe<Scalars["String"]>;
  /** Set to true when manager doesn't uncheck "requires consent" when creating the time off request. Default is false */
  requiresEmployeeConsent: Scalars["Boolean"];
  /** Unavailability event start date and time */
  startDateTime: Scalars["ISO8601DateTime"];
  /** Status of the unavailability event */
  status: Scalars["String"];
  /** Time off type ID */
  timeOffTypeId: Scalars["ID"];
  /** Timezone of start and end time */
  timeZone?: Maybe<Scalars["String"]>;
  /** Title of the unavailability event */
  title?: Maybe<Scalars["String"]>;
  /** Time updated */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for UnavailabilityEvent. */
export type UnavailabilityEventConnection = {
  __typename?: "UnavailabilityEventConnection";
  /** A list of edges. */
  edges: Array<UnavailabilityEventEdge>;
  /** A list of nodes. */
  nodes: Array<UnavailabilityEvent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type UnavailabilityEventEdge = {
  __typename?: "UnavailabilityEventEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<UnavailabilityEvent>;
};

/** Input for creating a Global Business */
export type UpdateGlobalBusinessInput = {
  /** The start of the Employment GUID sequence */
  employmentUidStart?: Maybe<Scalars["BigInt"]>;
  /** Name of the Global Business */
  name?: Maybe<Scalars["String"]>;
};

/** Input for updating a Global Business Stack */
export type UpdateGlobalBusinessStackInput = {
  /** True if the Global Business Stack refers to the current (local) stack */
  isSelf?: Maybe<Scalars["Boolean"]>;
  /** Domain of the Global Business Stack */
  stackDomainName?: Maybe<Scalars["String"]>;
};

/** Internal User fields */
export type User = {
  __typename?: "User";
  /** User access type */
  accessType: AccessTypeEnum;
  /** Time user confirmed */
  confirmedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Time user created */
  createdAt: Scalars["ISO8601DateTime"];
  /** Whether the user is soft deleted */
  deleted: Scalars["Boolean"];
  /** User email */
  email?: Maybe<Scalars["String"]>;
  /** User id */
  id: Scalars["ID"];
  /** Domain component of user JID */
  jabberDomain?: Maybe<Scalars["String"]>;
  /** Jabber password for user */
  jabberPassword?: Maybe<Scalars["String"]>;
  /** Whether jabber is registered for user */
  jabberRegistered?: Maybe<Scalars["Boolean"]>;
  /** User locale */
  locale: Scalars["String"];
  /** Time user account locked */
  lockedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Time password last changed */
  passwordChangedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Time user last updated */
  updatedAt: Scalars["ISO8601DateTime"];
  /** User-business for user */
  userBusinesses: Array<UserBusiness>;
};

/** User Attributes fields */
export type UserAttributes = {
  /** Designate whether to accept or reject the privacy policy */
  acceptedPrivatePolicy?: Maybe<Scalars["Boolean"]>;
  /** Employment code to link user to */
  employmentCode: Scalars["String"];
};

/** Internal User Business fields */
export type UserBusiness = {
  __typename?: "UserBusiness";
  /** Business id */
  businessId?: Maybe<Scalars["ID"]>;
  /** User Business id */
  id: Scalars["ID"];
  /** Jabber unemploy for user */
  jabberUnemploy?: Maybe<Scalars["Boolean"]>;
  /** Whether the User Business is pending deletion */
  pendingDelete: Scalars["Boolean"];
  /** User id */
  userId: Scalars["ID"];
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: "UserConnection";
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** A list of nodes. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: "UserEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

/** User Sort fields */
export type UserSort = {
  /** Field to sort by */
  field: UserSortField;
  /** Sort order */
  order: Order;
};

/** User Sort Field values */
export enum UserSortField {
  /** First name of the employee */
  FirstName = "firstName",
  /** Primary key */
  Id = "id",
  /** Last name of the employee */
  LastName = "lastName",
}

/** Business Value Type values */
export enum ValueTypeEnum {
  Currency = "currency",
  Float = "float",
  Hours = "hours",
  Int = "int",
  Percentage = "percentage",
  Seconds = "seconds",
  String = "string",
}

/** Internal Viewed Forecast fields */
export type ViewedForecast = {
  __typename?: "ViewedForecast";
  /** Time the viewed forecast was created */
  createdAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** End time of the viewed forecast */
  endTime: Scalars["ISO8601DateTime"];
  /** Forecast associated with the viewed forecast */
  forecast: Forecast;
  /** Forecast ID */
  forecastId: Scalars["ID"];
  /** Viewed Forecast ID */
  id?: Maybe<Scalars["ID"]>;
  /** Whether the viewed forecast is pending approval */
  pendingApproval: Scalars["Boolean"];
  /** Schedule ID */
  scheduleId: Scalars["ID"];
  /** Start time of the viewed forecast */
  startTime: Scalars["ISO8601DateTime"];
  /** Time the viewed forecast was last updated */
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** The connection type for ViewedForecast. */
export type ViewedForecastConnection = {
  __typename?: "ViewedForecastConnection";
  /** A list of edges. */
  edges: Array<ViewedForecastEdge>;
  /** A list of nodes. */
  nodes: Array<ViewedForecast>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
  /** Total number of pages */
  totalPageCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ViewedForecastEdge = {
  __typename?: "ViewedForecastEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ViewedForecast>;
};
