/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AuditLogActionTypeEnum = "created" | "deleted" | "specific" | "updated" | "%future added value";
export type AuditLogsQueries_DetailsQueryVariables = {
    businessId: string;
    ids?: Array<string> | null | undefined;
};
export type AuditLogsQueries_DetailsQueryResponse = {
    readonly auditLogs: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly actionType: AuditLogActionTypeEnum;
            readonly code: string | null;
            readonly objId: string;
            readonly objType: string;
            readonly occurredAt: string;
            readonly performedBy: {
                readonly id: string;
                readonly computedName: string;
            } | null;
            readonly performedById: string | null;
            readonly schedule: {
                readonly scheduleName: string;
            } | null;
            readonly relatedToId: string | null;
            readonly requestId: string | null;
            readonly scheduleId: string | null;
            readonly specificAction: string | null;
            readonly related: unknown | null;
            readonly meta: unknown;
        }>;
    };
};
export type AuditLogsQueries_DetailsQuery = {
    readonly response: AuditLogsQueries_DetailsQueryResponse;
    readonly variables: AuditLogsQueries_DetailsQueryVariables;
};



/*
query AuditLogsQueries_DetailsQuery(
  $businessId: ID!
  $ids: [ID!]
) {
  auditLogs(businessId: $businessId, ids: $ids) {
    nodes {
      id
      actionType
      code
      objId
      objType
      occurredAt
      performedBy {
        id
        computedName
      }
      performedById
      schedule {
        scheduleName
        id
      }
      relatedToId
      requestId
      scheduleId
      specificAction
      related
      meta
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actionType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "occurredAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Employment",
  "kind": "LinkedField",
  "name": "performedBy",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "computedName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "performedById",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduleName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relatedToId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requestId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduleId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "specificAction",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "related",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "meta",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuditLogsQueries_DetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AuditLogConnection",
        "kind": "LinkedField",
        "name": "auditLogs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditLog",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuditLogsQueries_DetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AuditLogConnection",
        "kind": "LinkedField",
        "name": "auditLogs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditLog",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f2ce576712985eab63217fcc46760c7f",
    "id": null,
    "metadata": {},
    "name": "AuditLogsQueries_DetailsQuery",
    "operationKind": "query",
    "text": "query AuditLogsQueries_DetailsQuery(\n  $businessId: ID!\n  $ids: [ID!]\n) {\n  auditLogs(businessId: $businessId, ids: $ids) {\n    nodes {\n      id\n      actionType\n      code\n      objId\n      objType\n      occurredAt\n      performedBy {\n        id\n        computedName\n      }\n      performedById\n      schedule {\n        scheduleName\n        id\n      }\n      relatedToId\n      requestId\n      scheduleId\n      specificAction\n      related\n      meta\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f72bc572d4de2ea45f9ed87fbcb86288';
export default node;
