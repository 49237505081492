/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LegacyBreakTypeEnum = "meal" | "rest" | "unknown" | "%future added value";
export type BreakTypeQueriesMutations_GetSingleScheduleBreakType_QueryVariables = {
    businessId: string;
    scheduleId: string;
    id: string;
    skip: boolean;
};
export type BreakTypeQueriesMutations_GetSingleScheduleBreakType_QueryResponse = {
    readonly scheduleBreakTypes?: {
        readonly nodes: ReadonlyArray<{
            readonly hidden: boolean;
            readonly id: string;
            readonly deleted: boolean;
            readonly schedulePaidThreshold: unknown | null;
            readonly name: string;
            readonly breakTypeCode: string;
            readonly legacyBreakType: LegacyBreakTypeEnum;
            readonly breakScreenOrdering: number;
            readonly description: string | null;
            readonly code: string | null;
            readonly defaultDuration: unknown | null;
            readonly availableRange: {
                readonly rangeStartTime: unknown;
                readonly rangeEndTime: unknown;
            } | null;
            readonly schedulable: boolean;
            readonly paidThreshold: unknown;
            readonly canOverrideClockedPaidBreak: boolean;
            readonly employmentTypeCodes: ReadonlyArray<string>;
            readonly employmentMetadata: string | null;
        }>;
    } | undefined;
};
export type BreakTypeQueriesMutations_GetSingleScheduleBreakType_Query = {
    readonly response: BreakTypeQueriesMutations_GetSingleScheduleBreakType_QueryResponse;
    readonly variables: BreakTypeQueriesMutations_GetSingleScheduleBreakType_QueryVariables;
};



/*
query BreakTypeQueriesMutations_GetSingleScheduleBreakType_Query(
  $businessId: ID!
  $scheduleId: ID!
  $id: ID!
  $skip: Boolean!
) {
  scheduleBreakTypes(businessId: $businessId, scheduleId: $scheduleId, id: $id) @skip(if: $skip) {
    nodes {
      hidden
      id
      deleted
      schedulePaidThreshold
      name
      breakTypeCode
      legacyBreakType
      breakScreenOrdering
      description
      code
      defaultDuration
      availableRange {
        rangeStartTime
        rangeEndTime
      }
      schedulable
      paidThreshold
      canOverrideClockedPaidBreak
      employmentTypeCodes
      employmentMetadata
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scheduleId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v4 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "businessId",
            "variableName": "businessId"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "scheduleId",
            "variableName": "scheduleId"
          }
        ],
        "concreteType": "ScheduleBreakTypeConnection",
        "kind": "LinkedField",
        "name": "scheduleBreakTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleBreakType",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hidden",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schedulePaidThreshold",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "breakTypeCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legacyBreakType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "breakScreenOrdering",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultDuration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HoursMinutesRange",
                "kind": "LinkedField",
                "name": "availableRange",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rangeStartTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rangeEndTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schedulable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paidThreshold",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canOverrideClockedPaidBreak",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentTypeCodes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentMetadata",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BreakTypeQueriesMutations_GetSingleScheduleBreakType_Query",
    "selections": (v4/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "BreakTypeQueriesMutations_GetSingleScheduleBreakType_Query",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "dd8c50211ac665e0eef25a87b188b88e",
    "id": null,
    "metadata": {},
    "name": "BreakTypeQueriesMutations_GetSingleScheduleBreakType_Query",
    "operationKind": "query",
    "text": "query BreakTypeQueriesMutations_GetSingleScheduleBreakType_Query(\n  $businessId: ID!\n  $scheduleId: ID!\n  $id: ID!\n  $skip: Boolean!\n) {\n  scheduleBreakTypes(businessId: $businessId, scheduleId: $scheduleId, id: $id) @skip(if: $skip) {\n    nodes {\n      hidden\n      id\n      deleted\n      schedulePaidThreshold\n      name\n      breakTypeCode\n      legacyBreakType\n      breakScreenOrdering\n      description\n      code\n      defaultDuration\n      availableRange {\n        rangeStartTime\n        rangeEndTime\n      }\n      schedulable\n      paidThreshold\n      canOverrideClockedPaidBreak\n      employmentTypeCodes\n      employmentMetadata\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '14d3d98958fe445db30fc96541a0f7c2';
export default node;
